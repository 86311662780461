import React, { useEffect, useState } from "react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import styles from "../../../donate/donate.module.scss";
import CheckboxTree from "react-checkbox-tree";
import { ChevronDown } from "react-feather";

const Visibility = ({ visibility, onChange }) => {
  const [dropdown, setDropdown] = useState(false);
  const [values, setValues] = useState([]);
  const [visibilityTypesTreeValues, setVisibilityTypesTreeValues] = useState({
    checked: values || [],
    expanded: visibility || [],
  });

  useEffect(() => {
    if (visibility && visibility.length > 0) {
      setValues(visibility);
      setVisibilityTypesTreeValues((prev) => ({
        ...prev,
        checked: visibility,
      }));
    }
  }, [visibility]);

  return (
    <>
      <Dropdown
        isOpen={dropdown}
        toggle={() => {
          setDropdown((prev) => !prev);
        }}
      >
        <DropdownToggle className={styles.dropdown}>
          <div className="d-flex flex-wrap">
            {visibilityTypesTreeValues.checked?.map((x, i) => (
              <div
                key={i}
                className={styles.tag + " mr-1"}
              >
                {x}
              </div>
            ))}
          </div>
          <ChevronDown />
        </DropdownToggle>

        <DropdownMenu>
          <CheckboxTree
            onlyLeafCheckboxes
            nodes={[
              {
                value: "marketplace",
                label: "Marketplace",
                className: "d-block w-100",
              },
              {
                value: "nonprofit_marketplace",
                label: "Nonprofit Marketplace",
                className: "d-block w-100",
              },
              {
                value: "availability_email",
                label: "Availability Email",
                className: "d-block w-100",
              },
              {
                value: "facebook_page",
                label: "Facebook Page",
                className: "d-block w-100",
              },
              {
                value: "incognito",
                label: "Incognito",
                className: "d-block w-100",
              },
            ]}
            icons={{
              parentClose: null,
              parentOpen: null,
              leaf: null,
            }}
            checked={visibilityTypesTreeValues.checked}
            expanded={visibilityTypesTreeValues.expanded}
            onCheck={(checked) => {
              setVisibilityTypesTreeValues(() => ({
                checked,
                expanded: visibilityTypesTreeValues.expanded,
              }));
              setValues(checked);
              onChange(checked);
            }}
            onExpand={(expanded) =>
              setVisibilityTypesTreeValues(() => ({
                expanded,
                checked: visibilityTypesTreeValues.checked,
              }))
            }
          />
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default Visibility;
