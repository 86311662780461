import moment from "moment";

const valOrDash = (v) => {
  if (v) {
    return v;
  }
  return "-";
};
const eventsFormatter = (events) => {
  if (!events?.length) {
    return;
  }

  const formattedEvents = events.map((d) => {
    return {
      timestamp: moment(d.timestamp).format("DD MMM, YYYY hh:mm:SS A"),
      event: d.event,
      category: valOrDash(d.category),
      response: valOrDash(d.response),
      url: valOrDash(d.url),
      status: valOrDash(d.status),
      reason: valOrDash(d.reason),
      "Single Send Name": valOrDash(d.singleSendName),
      "Marketing Campaign Name": valOrDash(d.marketingCampaignName),
      "Bounce Classification": valOrDash(d.bounceClassification),
      "Group ID": valOrDash(d.groupID),
    };
  });

  return formattedEvents;
};

export default eventsFormatter;
