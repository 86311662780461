import React from "react";
import CheckboxTree from "react-checkbox-tree";
import { ChevronDown } from "react-feather";
import { DropdownMenu, Dropdown, DropdownToggle } from "reactstrap";
import styles from "../donate/donate.module.scss";

const EVENTS = [
  { value: "bounce", title: "Bounce" },
  { value: "click", title: "Click" },
  { value: "deferred", title: "Deferred" },
  { value: "delivered", title: "Delivered" },
  { value: "dropped", title: "Dropped" },
  { value: "group_resubscribe", title: "Group Resubscribe" },
  { value: "group_unsubscribe", title: "Group Unsubscribe" },
  { value: "open", title: "Open" },
  { value: "processed", title: "Processed" },
  { value: "spamreport", title: "Spam Report" },
  { value: "unsubscribe", title: "Unsubscribe" },
];

const EventsSelect = ({
  isOpen,
  toggle,
  values,
  setValues,
  style = {},
  placeholder,
  onSelect,
}) => {
  return (
    <Dropdown
      isOpen={isOpen}
      toggle={toggle}
    >
      <DropdownToggle
        className={styles.dropdown}
        style={style}
      >
        <div className="d-flex flex-wrap">
          {values?.length ? (
            values?.map((x, i) => (
              <div
                key={i}
                className={styles.category + " mr-1"}
              >
                {x}
              </div>
            ))
          ) : (
            <span style={{ fontWeight: 500, color: "gray" }}>
              {placeholder}
            </span>
          )}
        </div>
        <ChevronDown />
      </DropdownToggle>
      <DropdownMenu>
        <CheckboxTree
          onlyLeafCheckboxes
          nodes={EVENTS.map((x) => ({
            value: x.value,
            label: x.title,
            className: "d-block w-100",
          }))}
          icons={{
            parentClose: null,
            parentOpen: null,
            leaf: null,
          }}
          checked={values}
          onCheck={(checked) => {
            setValues(checked);
            if (onSelect) {
              onSelect();
            }
          }}
        />
      </DropdownMenu>
    </Dropdown>
  );
};

export default EventsSelect;
