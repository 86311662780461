import React from "react";
import { toast } from "react-toastify";

export const parse_graph_error = (err) => [
  err.extensions?.code,
  err.extensions?.cause,
  err.message,
  err.path?.join("."),
];

export const err_msgs = ({ graphQLErrors, message, networkError }) => {
  return [
    ...new Set(
      [
        [message],
        graphQLErrors?.map(parse_graph_error).flat(),
        networkError?.result?.errors?.map(parse_graph_error).flat(),
      ]
        .filter((x) => x)
        .flat(),
    ),
  ];
};

export const err_toast = (err) => {
  toast.error(
    <>
      {err_msgs(err).map((err, idx) => (
        <div key={idx}>{err}</div>
      ))}
    </>,
    {
      autoClose: false,
    },
  );
};
