import { Col, Label, Row } from "reactstrap";
import React, { useEffect, useMemo, useState } from "react";
import RangeSlider from "react-bootstrap-range-slider";
import scss from "./slider.module.scss";

const NumberOfItemsPerMonth = ({ numberOfItems, setNumberOfItems }) => {
  const [value, setValue] = useState(1);
  const [numberOfItemsOptions] = useMemo(() => {
    return [
      [
        { max: 25 },
        { max: 50 },
        { max: 100 },
        { max: 250 },
        { max: 500 },
        { max: null },
      ],
    ];
  }, []);

  useEffect(() => {
    numberOfItemsOptions.forEach((o, i) => {
      if (o.max === numberOfItems) {
        setValue(i);
      }
    });
  }, [numberOfItems, numberOfItemsOptions]);

  return (
    <>
      <Row>
        <Col>
          <h5>How many items do you want to receive per month?</h5>
        </Col>
      </Row>
      <Row className="my-4">
        <Col xs={2}>
          <Label>25 items</Label>
        </Col>
        <Col xs={8}>
          <div style={{ minHeight: "90px" }}>
            <RangeSlider
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
                setNumberOfItems(numberOfItemsOptions[e.target.value].max);
              }}
              tooltipLabel={(currentValue) => {
                let max = numberOfItemsOptions[currentValue].max;
                return max ? `${max} Items` : `You accept any number of items.`;
              }}
              tooltip="on"
              tooltipPlacement="top"
              tooltipProps={{
                className: "bold",
              }}
              tooltipStyle={{
                paddingTop: "1rem",
                fontWeight: "600",
              }}
              step={1}
              min={0}
              max={5}
              size="lg"
              style={{
                width: "100%",
              }}
              bsPrefix={scss.itemsSlider}
            />
          </div>
        </Col>
        <Col xs={2}>
          <Label>No limit</Label>
        </Col>
      </Row>
    </>
  );
};

export default NumberOfItemsPerMonth;
