import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap";
import { ToastContainer } from "react-toastify";
import { gql, useLazyQuery } from "@apollo/client";
import Datatable from "../common/datatable";
import Text from "../common/text";
import subscriptionPlansFormatter from "src/helpers/subscriptionPlansFormatter";

const ADMIN_GET_SUBSCRIPTION_PLANS = gql`
    query($input: AdminGetSubscriptionPlansInput!) {
      adminGetAllSubscriptionPlans(input: $input) {
        subscriptionPlans {
          uuid
          id
          status
          title
          isDefault
          monthlyAmount {
            value
            currencyCode
          }
          includedDonationValue {
            value
            currencyCode
          }
          individualItemPrice {
            value
            currencyCode
          }
          unboxedPrice {
            value
            currencyCode
          }
          boxPrice {
            value
            currencyCode
          }
          largeBoxPrice {
            value
            currencyCode
          }
          oversizedItemPrice {
            value
            currencyCode
          }
          palletPrice {
            value
            currencyCode
          }
          largePalletPrice {
            value
            currencyCode
          }
          isShippingFeePassThrough
          shippingFee {
            value
            currencyCode
          }
          isDeliveryFeePassThrough
          deliveryFee {
            value
            currencyCode
          }
        }
        hasMore
        total
      }
    }
`;

const SubscriptionPlanList = () => {
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [term, setTerm] = useState("");
  const [statuses, _] = useState();
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(25);
  const [indexFrom, setIndexFrom] = useState(0);

  const [adminGetAllSubscriptionPlans, { loading, error, data }] = useLazyQuery(
    ADMIN_GET_SUBSCRIPTION_PLANS,
    {
      variables: {
        fetchPolicy: "no-cache",
        input: {
          term: term,
          statuses: statuses,
          indexFrom: indexFrom,
          limit: limit,
        },
      },
    },
  );

  const onChangePage = (newPage, _) => {
    setIndexFrom((newPage - 1) * limit);
  };

  const onChangeRowsPerPage = (newPerPage, page) => {
    setIndexFrom((page - 1) * newPerPage);
    setLimit(newPerPage);
  };

  useEffect(() => {
    if (data && data.adminGetAllSubscriptionPlans) {
      setSubscriptionPlans(
        subscriptionPlansFormatter(
          data.adminGetAllSubscriptionPlans.subscriptionPlans,
        ),
      );
      setTotalRows(data.adminGetAllSubscriptionPlans.total);
    } else {
      setSubscriptionPlans([]);
    }
  }, [data, loading, statuses, term]);

  useEffect(() => {
    setTotalRows(0);
    setIndexFrom(0);
    setLimit(25);
    setSubscriptionPlans([]);
    adminGetAllSubscriptionPlans();
  }, [term]);

  return (
    <Card>
      <CardHeader>
        <h3>Subscription Plans</h3>
        <Row>
          <Col md="4">
            <Label>Term </Label>
            <Input
              name="term"
              type="text"
              value={term}
              onChange={(e) => setTerm(e.target.value)}
            />
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        {error ? (
          <>Some Error Occured! Try Reloading the page.</>
        ) : subscriptionPlans ? (
          <Datatable
            hideActions={true}
            myData={subscriptionPlans}
            loading={loading}
            pageSize={limit}
            pagination={true}
            paginationServer={true}
            totalRows={totalRows}
            myClass="-striped -highlight"
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            noHeader
          />
        ) : (
          <Text>No Subscription Plans</Text>
        )}
      </CardBody>
    </Card>
  );
};

const ListSubscriptionPlans = () => {
  return (
    <Fragment>
      <Breadcrumb
        title="Subscription Plan"
        parent="List Subscription Plans"
      />
      <Container fluid={true}>
        <SubscriptionPlanList />
      </Container>
      <ToastContainer />
    </Fragment>
  );
};

export default ListSubscriptionPlans;
