import { DateTime } from "luxon";

export const getThreadImage = () => {
  return "/assets/icons/user.svg";
};

export const getThreadName = (thread) => {
  return (
    "Conversation between " +
    thread?.donorRetailer?.name +
    " & " +
    thread?.recipientNonprofit?.organizationName
  );
};

export const getLink = (thread) => {
  return `/order/${thread?.order?.uuid}`;
};

export const getFulfillments = (thread) => {
  return thread?.order?.fulfillments?.filter(
    (f) =>
      f.donorId == thread?.donorId &&
      f.donorRetailerId == thread?.donorRetailerId,
  );
};

export const getDefaultFulfillment = (thread) => {
  const fulfillments = getFulfillments(thread);
  if (fulfillments?.length > 0) {
    return getFulfillments(thread)[0];
  }
  return null;
};

export const getThreadDate = (thread) => {
  if (thread?.messages == null) {
    return DateTime.fromISO(thread?.order?.createdDate).toFormat("MMM dd");
  } else {
    if (thread?.messages?.length > 0) {
      return DateTime.fromISO(thread?.messages?.[0].createdAt).toFormat(
        "MMM dd",
      );
    } else {
      return DateTime.fromISO(thread?.order?.createdDate).toFormat("MMM dd");
    }
  }
};

export const getThreadNumberOfItems = (thread) => {
  if (!thread || !thread.order?.fulfillments?.[0]?.items) {
    return 0; // Return 0 if thread or items are not defined or empty
  }

  // Use map to extract quantities from items array
  var fulfillments = getFulfillments(thread);
  const totalSum = fulfillments.reduce((outerAccumulator, fulfillment) => {
    // Calculate sum of current inner array
    const innerSum = fulfillment?.items?.reduce(
      (innerAccumulator, item) => innerAccumulator + item.quantity,
      0,
    );
    // Add the inner sum to the outer accumulator
    return outerAccumulator + innerSum;
  }, 0);

  return totalSum; // Return the sum of quantities
};

export const getItems = (thread) => {
  if (!thread || !thread.order?.fulfillments?.[0]?.items) {
    return [];
  }

  let fulfillmentItems = [];
  thread.order?.fulfillments?.forEach((fulfillment) => {
    fulfillment?.items?.forEach((item) => {
      fulfillmentItems.push(item);
    });
  });

  return fulfillmentItems; // Return the sum of quantities
};

export const getFormattedName = (message) => {
  if (message?.type == "system") {
    return `LiquiDonate`;
  } else {
    return `${message?.createdBy?.user?.firstName} ${
      message?.createdBy?.user?.lastName ?? ""
    }`;
  }
};

export const getMessageDate = (message) => {
  return DateTime.fromISO(message?.createdAt).toFormat("MMM dd, hh:mm a");
};
