import { gql, useQuery } from "@apollo/client";
import React, { Fragment, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import Breadcrumb from "../common/breadcrumb";
import Text from "../common/text";
import Bold from "../common/bold";
import { initializeApollo } from "../../helpers/apollo";
import { SuccessToastAndReload } from "../../helpers/utils";
import { toast, ToastContainer } from "react-toastify";
import {
  ExternalLink,
  Copy,
  Trash2,
  Edit,
  User,
  DollarSign,
  Zap,
  PenTool,
  ZapOff,
} from "react-feather";
import {
  formatAddress,
  copyAddress,
  getGoogleMapsAddress,
} from "../../helpers/address";
import moment from "moment";
import OrdersList from "../sales/orders/ordersList";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import UserList from "../users/user-list";
import usersFormatter from "../../helpers/usersFormatter";
import AssignUser from "../users/assign-user";
import MatchesList from "../matches/matchesList";
import DonationReceiptsList from "../donationReceipts/donation-receipts-list";
import MagicMatchesPreferences from "../matches/MagicMatchesPreferences";
import AssignAddress from "../addresses/assign-address";
import AddressList from "../addresses/address-list";
import addressesFormatter from "src/helpers/addressesFormatter";
import ConfirmationModal from "../modals/ConfirmationModal";

const ADD_NONPROFIT_USER = gql`
  mutation adminAddNonprofitUser($data: AdminAddNonprofitUserInput!) {
    adminAddNonprofitUser(input: $data)
  }
`;

const ADD_NONPROFIT_ADDRESS = gql`
  mutation adminAddNonprofitAddress($data: AdminAddNonprofitAddressInput!) {
    adminAddNonprofitAddress(input: $data)
  }
`;

const DELETE_NONPROFIT_ADDRESS = gql`
  mutation adminDeleteNonprofitAddress($uuid: String!) {
    adminDeleteNonprofitAddress(nonprofitAddressUUID: $uuid)
  }
`;

const DELETE_NONPROFIT = gql`
  mutation adminDeleteNonprofit($data: AdminDeleteNonprofitInput!) {
    adminDeleteNonprofit(input: $data)
  }
`;

const NON_PROFIT = gql`
  query ($input: GetNonprofitInput!) {
    getAdminNonprofit(input: $input) {
      uuid
      createdDate
      description
      mission
      referralSource
      focusAreas
      populationsServed
      canReceivePallets
      organizationName
      address {
        streetAddress1
        streetAddress2
        city
        state
        zip
        countryCode
        latitude
        longitude
      }
      nonprofitAddresses {
        uuid
        address {
          streetAddress1
          streetAddress2
          city
          state
          zip
          countryCode
          latitude
          longitude
        }
      }
      ein
      categoryTypes
      establishmentType
      businessPhone
      website
      users {
        uuid
        firstName
        lastName
        email
        phoneNumber
        roles
      }
      magicMatchesPreferences {
        uuid
        enabled
        numberOfItemsPerMonth
        agreeResaleRestriction
        createdAt
        updatedAt
      }
    }
  }
`;

const NonprofitDetails = () => {
  const { id } = useParams();
  const history = useHistory();
  const [user, setUser] = useState({});
  const [address, setAddress] = useState({});
  const [addressUUIDToRemove, setAddressUUIDToRemove] = useState();
  const [deleteNonprofitAddressModalOpen, setDeleteNonprofitAddressModalOpen] =
    useState(false);
  const [deleteNonprofitAddressLoading, setDeleteNonprofitAddressLoading] =
    useState(false);

  const onConfirmDeleteNonprofitAddress = () => {
    setDeleteNonprofitAddressLoading(true);
    let client = initializeApollo();
    client
      .mutate({
        mutation: DELETE_NONPROFIT_ADDRESS,
        variables: { uuid: addressUUIDToRemove },
      })
      .then(() => {
        SuccessToastAndReload("Address removed successfully!");
        setSubmitting(false);
      })
      .catch((err) => {
        toast.error(err.message || "something might went wrong!");
        setSubmitting(false);
      });
  };

  const [submitting, setSubmitting] = useState(false);
  const { loading, data } = useQuery(NON_PROFIT, {
    fetchPolicy: "no-cache",
    variables: {
      input: {
        uuid: id,
      },
    },
  });

  const nonprofit = useMemo(() => {
    return loading ? {} : data?.getAdminNonprofit;
  }, [loading]);

  const handleAddNonprofitUser = () => {
    const { email, role } = user;
    if (!email || !role) {
      toast.error("Enter email and role to assign user to nonprofit");
      return;
    }
    setSubmitting(true);
    let client = initializeApollo();
    client
      .mutate({
        mutation: ADD_NONPROFIT_USER,
        variables: { data: { ...user, nonprofitUUID: id } },
      })
      .then(() => {
        SuccessToastAndReload("User added successfully!");
        setSubmitting(false);
      })
      .catch((err) => {
        toast.error(err.message || "something might went wrong!");
        setSubmitting(false);
      });
  };

  const handleDeleteNonprofitAddress = (uuid) => {
    setAddressUUIDToRemove(uuid);
    setDeleteNonprofitAddressModalOpen(true);
  };

  const handleAddNonprofitAddress = () => {
    setSubmitting(true);
    let client = initializeApollo();
    client
      .mutate({
        mutation: ADD_NONPROFIT_ADDRESS,
        variables: { data: { address, nonprofitUUID: id } },
      })
      .then(() => {
        SuccessToastAndReload("Address added successfully!");
        setSubmitting(false);
      })
      .catch((err) => {
        toast.error(err.message || "something might went wrong!");
        setSubmitting(false);
      });
  };

  const getWebsite = (site) => {
    if (!site) return "N/A";
    if (!site.includes("http")) {
      return "https://" + site;
    }
    return site;
  };

  const onChangeUser = (e) => {
    if (e && e.target) {
      const { name, value } = e.target;
      user[name] = value;
    } else {
      user["role"] = e[0].value;
    }
    setUser({ ...user });
  };

  const onChangeAddress = (value) => {
    console.log("value: ", value);
    setAddress({ ...value });
  };

  const handleEditNonprofit = () => {
    history.push(`/nonprofit/${id}/edit`);
  };

  const handleDeleteNonprofit = () => {
    if (window.confirm("Are you sure you wish to delete this nonprofit?")) {
      let client = initializeApollo();
      client
        .mutate({
          mutation: DELETE_NONPROFIT,
          variables: { data: { uuid: id } },
        })
        .then(() => {
          toast.success("Successfully deleted nonprofit!");
          history.push("/nonprofits");
        })
        .catch((err) => {
          toast.error(err.message || "something might went wrong!");
        });
    }
  };

  return (
    <Fragment>
      <Breadcrumb
        title="Nonprofit Details"
        parent="Dashboard"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                {loading ? (
                  <div className="text-center my-5">
                    <Spinner />
                  </div>
                ) : (
                  <>
                    <Row>
                      <Col
                        xs="12"
                        className="d-flex align-items-start justify-content-end"
                      >
                        <Button
                          className={"btn btn-info mr-2"}
                          onClick={handleEditNonprofit}
                        >
                          <Edit
                            size={20}
                            className="mr-1"
                          />
                        </Button>
                        <Button
                          className={"btn btn-danger"}
                          onClick={handleDeleteNonprofit}
                        >
                          <Trash2
                            size={20}
                            className="mr-1"
                          />
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs="12"
                        md="6"
                      >
                        <Bold>Organization</Bold>
                        <Text>{nonprofit?.organizationName}</Text>
                      </Col>
                      <Col
                        xs="12"
                        md="6"
                      >
                        <Bold>EIN</Bold>
                        <Text>{nonprofit?.ein}</Text>
                      </Col>
                      <Col
                        xs="12"
                        md="6"
                      >
                        <Bold>Mission</Bold>
                        <Text>{nonprofit?.mission}</Text>
                      </Col>
                      <Col
                        xs="12"
                        md="6"
                      >
                        <Bold>Description</Bold>
                        <Text>{nonprofit?.description}</Text>
                      </Col>
                      <Col
                        xs="12"
                        md="6"
                      >
                        <Bold>Address</Bold>
                        <Text>
                          {formatAddress(nonprofit?.address)} &nbsp;&nbsp;
                          <a href="#">
                            <Copy
                              onClick={() => copyAddress(nonprofit?.address)}
                            />
                          </a>
                          {"  "}
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={getGoogleMapsAddress(nonprofit?.address)}
                          >
                            <ExternalLink />
                          </a>
                        </Text>
                      </Col>
                      <Col
                        xs="12"
                        md="6"
                      >
                        <Bold>Business Phone</Bold>
                        <Text>{nonprofit?.businessPhone}</Text>
                      </Col>
                      <Col
                        xs="12"
                        md="6"
                      >
                        <Bold>Website</Bold>
                        <Text>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={getWebsite(nonprofit?.website)}
                          >
                            {nonprofit?.website}
                          </a>
                        </Text>
                      </Col>
                      <Col
                        xs="12"
                        md="6"
                      >
                        <Bold>Focus Areas</Bold>
                        <Text>
                          {nonprofit?.focusAreas?.length
                            ? nonprofit?.focusAreas.join(", ")
                            : "N/A"}
                        </Text>
                      </Col>
                      <Col
                        xs="12"
                        md="6"
                      >
                        <Bold>Populations Served</Bold>
                        <Text>
                          {nonprofit?.populationsServed?.length
                            ? nonprofit?.populationsServed.join(", ")
                            : "N/A"}
                        </Text>
                      </Col>
                      <Col
                        xs="12"
                        md="6"
                      >
                        <Bold>Referral Source</Bold>
                        <Text>{nonprofit?.referralSource || "N/A"}</Text>
                      </Col>
                      <Col
                        xs="12"
                        md="6"
                      >
                        <Bold>Can Receive Pallets</Bold>
                        <Text>
                          {nonprofit?.canReceivePallets != undefined
                            ? nonprofit?.canReceivePallets
                              ? "Yes"
                              : "No"
                            : "Unknown"}
                        </Text>
                      </Col>
                      <Col
                        xs="12"
                        md="6"
                      >
                        <Bold>Created Date</Bold>
                        <Text>
                          {moment(nonprofit?.createdDate).format(
                            "DD MMM, YYYY hh:mm:SS A",
                          )}
                        </Text>
                      </Col>
                      <Col
                        xs="12"
                        md="6"
                      >
                        <Bold>Category Types</Bold>
                        <Text>
                          {nonprofit?.categoryTypes?.length
                            ? nonprofit?.categoryTypes.join(", ")
                            : "N/A"}
                        </Text>
                      </Col>
                      <Col
                        xs="12"
                        md="6"
                      >
                        <Bold>Establishment Type</Bold>
                        <Text>{nonprofit?.establishmentType || "N/A"}</Text>
                      </Col>
                    </Row>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Tabs>
                  <TabList className="nav nav-tabs tab-coupon">
                    <Tab className="nav-link">
                      <ZapOff className="mr-2" /> Magic Matches Preferences
                    </Tab>
                    <Tab className="nav-link">
                      <User className="mr-2" /> Users
                    </Tab>
                    <Tab className="nav-link">
                      <DollarSign className="mr-2" /> Orders Placed
                    </Tab>
                    <Tab className="nav-link">
                      <Zap className="mr-2" /> Matches
                    </Tab>
                    <Tab className="nav-link">
                      <PenTool className="mr-2" /> Donation Receipts
                    </Tab>
                    <Tab className="nav-link">
                      <PenTool className="mr-2" /> Addresses
                    </Tab>
                  </TabList>
                  <TabPanel>
                    <Row>
                      <Col>
                        <MagicMatchesPreferences nonprofit={nonprofit} />
                      </Col>
                    </Row>
                  </TabPanel>

                  <TabPanel>
                    <Row>
                      <Col>
                        <AssignUser
                          isNonprofit={true}
                          loading={submitting}
                          onChange={onChangeUser}
                          onSubmit={handleAddNonprofitUser}
                          user={user}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs="12"
                        className="mt-2"
                      >
                        <UserList
                          users={usersFormatter(nonprofit?.users)}
                          loading={loading}
                        />
                      </Col>
                    </Row>
                  </TabPanel>
                  <TabPanel>
                    <OrdersList
                      nonprofitUUID={nonprofit?.uuid}
                      hideStatusFilter={true}
                      hideNonprofitSearch={true}
                    />
                  </TabPanel>
                  <TabPanel>
                    <Row>
                      <Col>
                        <MatchesList
                          hideNonprofitSearch={true}
                          nonprofitUUID={nonprofit?.uuid}
                        />
                      </Col>
                    </Row>
                  </TabPanel>
                  <TabPanel>
                    <Row>
                      <Col>
                        <DonationReceiptsList
                          nonprofitUUID={nonprofit?.uuid}
                          hideRetailerSearch={true}
                          hideStatusFilter={true}
                          hideNonprofitSearch={true}
                        />
                      </Col>
                    </Row>
                  </TabPanel>
                  <TabPanel>
                    <AssignAddress
                      address={address}
                      onChange={onChangeAddress}
                      onSubmit={handleAddNonprofitAddress}
                    />
                    <hr />
                    <Row>
                      <Col
                        xs="12"
                        className="mt-2"
                      >
                        <AddressList
                          addresses={addressesFormatter(
                            nonprofit.nonprofitAddresses,
                            handleDeleteNonprofitAddress,
                          )}
                          loading={loading}
                        />
                        <ConfirmationModal
                          open={deleteNonprofitAddressModalOpen}
                          onClose={() =>
                            setDeleteNonprofitAddressModalOpen(false)
                          }
                          title={"Delete address?"}
                          desc={
                            <>
                              Are you sure you want to delete this nonprofit
                              address:
                              {/* <br />
                              <b>{values?.title}</b> */}
                            </>
                          }
                          onConfirm={onConfirmDeleteNonprofitAddress}
                          loading={deleteNonprofitAddressLoading}
                        />
                      </Col>
                    </Row>
                  </TabPanel>
                </Tabs>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </Fragment>
  );
};

export default NonprofitDetails;
