import { gql, useQuery, useLazyQuery } from "@apollo/client";
import React, { useState } from "react";
import { useEffect } from "react";
import { CSVLink } from "react-csv";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Spinner,
  Input,
} from "reactstrap";
import Datatable from "../common/datatable";
import CategoryDropdown from "../products/physical/CategoryDropdown";
import Select from "react-dropdown-select";
import { toast } from "react-toastify";
import moment from "moment/moment";
import circularityPartnersFormatter from "../../helpers/circularityPartnersFormatter";
import SearchLocationInput from "../common/SearchLocationInput";
import { formatAddress } from "../../helpers/address";
import { XCircle } from "react-feather";

const sort_by_options = [
  { label: "Distance", value: "distance" },
  { label: "City", value: "city" },
  { label: "State", value: "state" },
  { label: "Zip", value: "zip" },
  { label: "Category", value: "category" },
  { label: "Number of Orders", value: "number_of_orders" },
  { label: "Verified Users", value: "verified_users" },
];

const GET_ADMIN_CIRCULARITY_PARTNERS = gql`
  query ($input: GetAdminCircularityPartnersInput!) {
    getAdminCircularityPartners(input: $input) {
      total
      circularityPartners {
        uuid
        categoryTypes
        organizationName
        distanceMiles
        address {
          streetAddress1
          streetAddress2
          city
          state
          zip
          countryCode
          latitude
          longitude
        }
        ein
        businessPhone
        website
        description
        users {
          uuid
          firstName
          lastName
          email
        }
        createdDate
      }
      hasMore
    }
  }
`;

const CircularityPartnersList = ({
  title,
  showTermSearch,
  showCategoryFilter,
  showLocationInput,
  showCSVDownload,
  hideActions = true,
  onDeleteRows,
}) => {
  const [circularityPartners, setCircularityPartners] = useState([]);
  const [categoryTreeValues, setCategoryTreeValues] = useState({
    checked: [],
    expanded: [],
  });
  const [catDropdownOpen, setCatDropdownOpen] = useState(false);
  const [term, setTerm] = useState();
  const [category, setCategory] = useState();
  const [sort, setSort] = useState();
  const [csvData, setCsvData] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const [indexFrom, setIndexFrom] = useState(0);
  const [loc, setLocation] = useState({ address: null });

  let { loading, error, data, refetch } = useQuery(
    GET_ADMIN_CIRCULARITY_PARTNERS,
    {
      fetchPolicy: "no-cache",
      variables: {
        input: {
          indexFrom: indexFrom,
          limit: limit,
          categoryTypes: category,
          location: loc?.address ? loc : undefined,
          sortOrder: sort,
          term: term,
        },
      },
    },
  );

  let [fetchCsvData, { loading: csvLoading, data: CSV_DATA, error: csvError }] =
    useLazyQuery(GET_ADMIN_CIRCULARITY_PARTNERS, {
      fetchPolicy: "no-cache",
      variables: {
        input: {
          categoryTypes: category,
          location: loc?.address ? loc : undefined,
          sortOrder: sort,
          term: term,
        },
      },
    });

  const onClickDownloadCSV = () => {
    fetchCsvData().then((_) => {
      document.getElementById("csv-link").click();
      toast.success("Downloaded Successfully!");
    });
  };

  const handleSetLocation = (value) => setLocation({ address: { ...value } });
  const handleRemoveLocation = () => setLocation({ address: null });

  useEffect(() => {
    if (csvLoading) return;
    if (csvError) {
      let message;
      if (csvError.graphQLErrors[0]?.extensions?.code === "otp_required") {
        message = "Too large Request!";
      } else {
        message = csvError.message || "Something might went wrong!";
      }
      toast.error(message);
      return;
    }
    if (!CSV_DATA?.getAdminCircularityPartners?.circularityPartners) return;
    setCsvData([
      ...CSV_DATA.getAdminCircularityPartners.circularityPartners.map((d) => ({
        uuid: d?.uuid,
        organizationName: d?.organizationName,
        ein: d?.ein,
        categoryTypes: d?.categoryTypes ? d?.categoryTypes?.join("/") : "N/A",
        businessPhone: d?.businessPhone,
        email: d?.users?.length ? d?.users[0]?.email : null,
        streetAddress1: d?.address?.streetAddress1,
        streetAddress2: d?.address?.streetAddress2,
        city: d?.address?.city,
        state: d?.address?.state,
        zip: d?.address?.zip,
        countryCode: d?.address?.countryCode,
        createdDate:
          d?.createdDate && moment(d.createdDate).format("YYYY-MM-DD"),
      })),
    ]);
  }, [csvLoading]);

  useEffect(() => {
    if (!loading) {
      if (data && data.getAdminCircularityPartners) {
        setCircularityPartners(
          circularityPartnersFormatter(
            data?.getAdminCircularityPartners?.circularityPartners,
          ),
        );
        setTotalRows(data?.getAdminCircularityPartners.total);
      } else {
        setCircularityPartners([]);
        setTotalRows(0);
      }
    }
  }, [loading, data]);

  const handleSetCategory = (value) => {
    setCategory(value);
  };

  const handleSetSort = (value) => {
    const val = value[0].value;
    setSort(val);
  };

  const handleChangeTerm = (e) => {
    const value = e.target.value;
    setTerm(value);
  };

  const onChangePage = (newPage, _) => {
    setIndexFrom((newPage - 1) * limit);
  };

  const onChangeRowsPerPage = (newPerPage, page) => {
    setIndexFrom((page - 1) * newPerPage);
    setLimit(newPerPage);
  };

  const getCSVFileName = () => {
    return `circularity-partners.csv`;
  };

  useEffect(() => {
    refetch();
  }, [indexFrom]);

  return (
    <>
      <Card>
        <CardHeader>
          {title && <h3>{title}</h3>}
          <Row>
            {showTermSearch && (
              <Col
                sm="12"
                md="3"
              >
                <p className="mb-0 mt-2">Search term</p>
                <Input
                  className="form-control"
                  id="searchTerm"
                  type="text"
                  value={term}
                  onChange={handleChangeTerm}
                />
              </Col>
            )}
            {showCategoryFilter && (
              <Col
                sm="12"
                md="2"
              >
                <p className="mb-0 mt-2">Filter By Category</p>
                <CategoryDropdown
                  isOpen={catDropdownOpen}
                  toggle={() => setCatDropdownOpen((prev) => !prev)}
                  categoryTreeValues={categoryTreeValues}
                  onCheck={handleSetCategory}
                  setCategoryTreeValues={setCategoryTreeValues}
                />
              </Col>
            )}
            <Col
              sm="12"
              md="2"
            >
              <p className="mb-0 mt-2">Sort by</p>
              <Select
                options={sort_by_options}
                value={sort}
                placeholder={sort || "Sort By"}
                style={{
                  textTransform: "capitalize",
                  fontWeight: 500,
                }}
                onChange={handleSetSort}
              />
            </Col>
            {showLocationInput && (
              <Col
                sm="12"
                md="2"
              >
                <Row>
                  <Col sm="10">
                    <p className="mb-0 mt-2">Location</p>
                    <SearchLocationInput
                      placeholdertext={"Enter location"}
                      required={false}
                      value={formatAddress(loc.address)}
                      setAddress={handleSetLocation}
                      styles={{ padding: "12px 10px" }}
                    />
                  </Col>
                  <Col sm="2">
                    {loc && loc.address && (
                      <XCircle
                        color="red"
                        className="mt-2 ml-1 cursor-pointer"
                        onClick={handleRemoveLocation}
                      />
                    )}
                  </Col>
                </Row>
              </Col>
            )}
            {showCSVDownload && (
              <Col
                sm="12"
                md="2"
              >
                <p className="mb-0 mt-2">&nbsp;</p>
                <Button onClick={onClickDownloadCSV}>
                  {csvLoading ? <Spinner size="sm" /> : "Download CSV"}
                </Button>

                <CSVLink
                  data={csvData || []}
                  filename={getCSVFileName()}
                  id="csv-link"
                  style={{ opacity: 0 }}
                />
              </Col>
            )}
          </Row>
        </CardHeader>
        <CardBody>
          {error ? (
            <>Some Error Occurred! Try Reloading the page.</>
          ) : (
            circularityPartners && (
              <Datatable
                hideActions={hideActions}
                myData={circularityPartners}
                loading={loading}
                pageSize={limit}
                pagination={true}
                paginationServer={true}
                totalRows={totalRows}
                myClass="-striped -highlight"
                multiSelectOption={!hideActions}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                onDeleteRows={onDeleteRows}
                noHeader
              />
            )
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default CircularityPartnersList;
