import React from "react";
import { Card, CardHeader, CardBody } from "reactstrap";
import Datatable from "../../common/datatable";
import Text from "../../common/text";
import styles from "../../donate/donate.module.scss";

const LabelUpdatesList = ({ labels }) => {
  return labels.map((label) =>
    label.labelUpdates ? (
      <Card
        className={styles.donateItemEdit}
        key={label.id}
      >
        <CardHeader>
          <h3>Tracking #{label.trackingNumber} Updates</h3>
        </CardHeader>
        <CardBody>
          <Datatable
            hideActions={true}
            myData={label.labelUpdates}
            pagination={false}
            paginationServer={false}
            totalRows={label.labelUpdates?.length}
            myClass="-striped -highlight"
            noHeader
          />
        </CardBody>
      </Card>
    ) : (
      <Text key={label.id}>No updates</Text>
    ),
  );
};

export default LabelUpdatesList;
