import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import TimeWindowsDropdown from "./timeWindowsDropdown";
import { useEffect, useState } from "react";
import cx from "classnames";

export const allTimeWindows = [
  {
    id: 1,
    startTime: "08:00",
    endTime: "10:00",
    display: "8am - 10am",
  },

  {
    id: 2,
    startTime: "10:00",
    endTime: "12:00",
    display: "10am - 12pm",
  },

  {
    id: 3,
    startTime: "12:00",
    endTime: "14:00",
    display: "12pm - 2pm",
  },

  {
    id: 4,
    startTime: "14:00",
    endTime: "16:00",
    display: "2pm - 4pm",
  },
];

// 8  - 10   1
// 10 - 12   2
// 12 - 14   3
// 14 - 16   4

// m = (1 - 2) / (8 - 10) = -1 / -2 = 1/2
// c =  1 - (1/2 * 8) = -3
const getInterval = (startTime) => {
  let parts = startTime.split(":");
  let id = 0.5 * Number(parts[0]) - 3;
  return allTimeWindows.find((t) => t.id === id);
};

const getSplitInterval = (interval) => {
  let startTime = interval?.startTimeLocal;
  let endTime = interval?.endTimeLocal;

  const validTimes = ["08:00", "10:00", "12:00", "14:00", "16:00"];
  if (
    validTimes.indexOf(startTime) === -1 ||
    validTimes.indexOf(endTime) === -1
  ) {
    return [interval];
  }

  let newIntervals = [];
  let startHour = Number(startTime.split(":")[0]);
  let endHour = Number(endTime.split(":")[0]);

  const zeroPad = (num, places) => String(num).padStart(places, "0");

  for (let i = startHour; i < endHour; i += 2) {
    startTime = `${zeroPad(i, 2)}:00`;
    newIntervals.push(getInterval(startTime));
  }

  return newIntervals;
};

export const getIntervalsByDays = (hours) => {
  if (!hours || !hours?.intervals?.length) return;
  let wDays = {};
  hours.intervals.forEach((interval) => {
    let splitIntervals = getSplitInterval(interval);
    interval.days.forEach((day) => {
      let windows = [...(wDays[day] || []), ...splitIntervals];
      wDays[day] = windows.sort((t1, t2) => t1.id - t2.id);
    });
  });
  return wDays;
};

const getJoinedIntervals = (windows) => {
  if (!windows || windows.length === 0) return null;
  windows.sort((w1, w2) => w1.id - w2.id);
  let prevEnd = "";
  let prevStart = "";
  let joined = [];
  for (let i = 0; i < windows.length; i++) {
    if (prevEnd === "") {
      prevStart = windows[i].startTime;
      prevEnd = windows[i].endTime;
    } else if (prevEnd === windows[i].startTime) {
      prevEnd = windows[i].endTime;
    } else {
      joined.push({
        startTime: prevStart,
        endTime: prevEnd,
        key: `${prevStart}-${prevEnd}`,
      });
      prevStart = windows[i].startTime;
      prevEnd = windows[i].endTime;
    }
  }
  joined.push({
    startTime: prevStart,
    endTime: prevEnd,
    key: `${prevStart}-${prevEnd}`,
  });
  return joined;
};

const PickupWindows = ({ hours, onSelect, timezone }) => {
  const [selectedWindowsByDays, setSelectedWindowsByDays] = useState({});
  const [hoverDay, setHoverDay] = useState({});

  useEffect(() => {
    setSelectedWindowsByDays(getIntervalsByDays(hours));
  }, [hours]);

  const onSelectTimeWindowsDropdown = (day, tws) => {
    let newWindowsByDays = { ...selectedWindowsByDays };
    newWindowsByDays[day] = tws;

    setSelectedWindowsByDays(newWindowsByDays);
    onSelect(getHours(newWindowsByDays));
  };

  const days = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];

  const copyDayToAll = (day) => {
    let newWindowsByDays = { ...selectedWindowsByDays };
    let windowsByDay = selectedWindowsByDays[day];
    days.forEach((d) => {
      newWindowsByDays[d] = windowsByDay;
    });

    setSelectedWindowsByDays(newWindowsByDays);
    onSelect(getHours(newWindowsByDays));
  };

  const getHours = (windows) => {
    let intervals = {};
    for (let key in windows) {
      getJoinedIntervals(windows[key])?.forEach((interval) => {
        let days = intervals[interval.key] || [];
        days.push(key.toLowerCase());
        intervals[interval.key] = days;
      });
    }

    let newIntervals = [];
    for (let key in intervals) {
      let parts = key.split("-");
      newIntervals.push({
        days: intervals[key],
        startTimeLocal: parts[0],
        endTimeLocal: parts[1],
      });
    }

    return { intervals: newIntervals, timezone: timezone };
  };

  return (
    <div className="my-4">
      <Card>
        <CardBody>
          {days.map((d) => (
            <Row
              className="my-3"
              key={d}
            >
              <Col
                xs="12"
                onMouseEnter={() =>
                  setHoverDay((prev) => ({ ...prev, [d]: true }))
                }
                onMouseLeave={() =>
                  setHoverDay((prev) => ({ ...prev, [d]: false }))
                }
              >
                <Row>
                  <Col xs="6">
                    <div
                      className="w-75"
                      style={{ textTransform: "capitalize" }}
                    >
                      <h4>{d}</h4>
                    </div>
                  </Col>
                  {hoverDay[d] && (
                    <Col xs="6">
                      <div
                        className={cx("d-flex justify-content-end link")}
                        style={{ fontSize: "0.8rem" }}
                        onClick={() => copyDayToAll(d)}
                      >
                        Copy to all days
                      </div>
                    </Col>
                  )}
                </Row>
                <TimeWindowsDropdown
                  onSelect={(tws) => onSelectTimeWindowsDropdown(d, tws)}
                  timeWindows={selectedWindowsByDays?.[d] || []}
                />
              </Col>
            </Row>
          ))}
        </CardBody>
      </Card>
    </div>
  );
};

export default PickupWindows;
