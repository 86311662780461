import { gql, useQuery } from "@apollo/client";
import { Container, Spinner } from "reactstrap";
import React, { useEffect, useState } from "react";
import FulfillmentCard from "./card";

const GET_FULFILLMENT = gql`
  query ($uuid: String!) {
    getAdminFulfillment(uuid: $uuid) {
      uuid
      noResaleAgreed
      items {
        uuid
        quantity
        product {
          uuid
          slug
          title
          unitType
          weightLbs
          availableQuantity
          noResale
          fairMarketValue {
            value
          }
          images {
            uuid
            resolutions {
              s {
                url
                height
                width
                size
              }
            }
          }
        }
      }
      type
      pickupLocation {
        address {
          streetAddress1
          streetAddress2
          streetAddress3
          streetAddress4
          state
          zip
          city
          countryCode
          longitude
          latitude
        }
        notes
        maskedContactNumber
        contactNumber
        contactEmail
      }
      recipientLocation {
        maskedContactNumber
        contactNumber
      }
      distance
      priceInfo {
        lineItems {
          type
          label
          amount {
            value
            currencyCode
          }
        }
      }
      pickupBy
      donor {
        user {
          firstName
          lastName
          uuid
        }
        retailer {
          uuid
          name
        }
      }
      donorRetailer {
        name
        uuid
      }
      recipientNonprofit {
        organizationName
        uuid
      }
      status
      statusDescription
      notes {
        uuid
        text
        user {
          uuid
          firstName
          lastName
        }
        createdDate
      }
      updates {
        status
        createdAt
        description
        eventType
      }
      donationReceipt {
        uuid
        status
      }
      order {
        uuid
      }
      hours {
        timezone
        intervals {
          date
          days
          startTimeLocal
          endTimeLocal
        }
      }
      distance
      fulfillmentOptions {
        label
        type
        selected
        amount {
          value
          currencyCode
        }
        disabledReason
      }
      labels {
        url
        trackingNumber
        trackingURL
        status
      }
    }
  }`;

const FulfillmentDetails = ({ fulfillmentUUID }) => {
  const [fulfillment, setFulfillment] = useState();
  const { loading, data, error } = useQuery(GET_FULFILLMENT, {
    variables: { uuid: fulfillmentUUID },
  });

  useEffect(() => {
    if (!loading && data && data.getAdminFulfillment) {
      setFulfillment(data.getAdminFulfillment);
    }
  }, [loading]);

  return (
    <Container fluid={true}>
      {loading ? (
        <div className="d-flex justify-content-around mt-4">
          <Spinner />
        </div>
      ) : fulfillment ? (
        <FulfillmentCard
          distance={fulfillment?.distance}
          donor={fulfillment?.donor}
          donorRetailer={
            fulfillment?.donorRetailer || fulfillment?.donor?.retailer
          }
          pickupLocation={fulfillment?.pickupLocation}
          recipientLocation={fulfillment?.recipientLocation}
          items={fulfillment?.items}
          pickupBy={fulfillment?.pickupBy}
          priceInfo={fulfillment?.priceInfo}
          status={fulfillment?.status}
          type={fulfillment?.type}
          statusDescription={fulfillment?.statusDescription}
          notes={fulfillment?.notes}
          uuid={fulfillment?.uuid}
          donationReceipt={fulfillment?.donationReceipt}
          updates={fulfillment?.updates}
          orderUUID={fulfillment?.order.uuid}
          hours={fulfillment?.hours}
          fulfillmentOptions={fulfillment?.fulfillmentOptions}
          noResaleAgreed={fulfillment?.noResaleAgreed}
          recipientNonprofit={fulfillment?.recipientNonprofit}
          labels={fulfillment?.labels}
        />
      ) : error ? (
        <>Some Error Occured! Try Reloading the page.</>
      ) : (
        <p>No fulfillment</p>
      )}
    </Container>
  );
};

export default FulfillmentDetails;
