import React, { Fragment, useState } from "react";
import { Col, Container, Row, Spinner } from "reactstrap";
import Breadcrumb from "../common/breadcrumb";
import styles from "./donate.module.scss";
import DonateCard from "./common/donate-card";
import { gql, useQuery } from "@apollo/client";
import { Search } from "react-feather";

const GET_ITEM_DONATIONS = gql`
  query adminGetItemDonations($input: GetItemDonationsInput!) {
    adminGetItemDonations(input: $input) {
      uuid
      sku
      productClass {
        uuid
      }
      title
      description
      status
      fairMarketValue {
        value
        currencyCode
      }
      quantity
      quantityPalletsPerTruckload
      quantityBoxPerPallet
      quantityIndividualItemPerBox
      conditionTagTypes
      fulfillmentTypes
      recipientTypes
      unitType
      sizeType
      weightLbs
      lengthInches
      widthInches
      heightInches
      categoryTypes
      location {
        address {
          streetAddress1
          streetAddress2
          streetAddress3
          streetAddress4
          city
          state
          zip
          countryCode
          latitude
          longitude
        }
        contactNumber
        contactEmail
        notes
      }
      images {
        uuid
        alt
        displayOrder
        resolutions {
          s {
            url
          }
          l {
            url
          }
        }
      }
      donor {
        user {
          firstName
          lastName
          email
          phoneNumber
          uuid

        }
        nonprofit {
          uuid
          organizationName
        }
        retailer {
          uuid
          name
        }
      }
      donorRetailer {
        uuid
        name
      }
      paymentIntentID
      total {
        value
        currencyCode
      }
      serviceFees {
        value
        currencyCode
      }
      totalDiscounts {
        value
        currencyCode
      }
      donationServiceFee {
        value
        currencyCode
      }
      fairMarketValue {
        value
        currencyCode
      }
    }
  }
`;
const ItemDonations = () => {
  const [titleHeight, setTitleHeight] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  var { loading, data } = useQuery(GET_ITEM_DONATIONS, {
    variables: {
      input: {
        indexFrom: 0,
        limit: 10,
        status: "unreviewed",
      },
    },
  });

  return (
    <Fragment>
      <Breadcrumb
        title="Review"
        parent="Item Donations"
      />
      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner />
        </div>
      ) : data && data.adminGetItemDonations ? (
        <Container fluid={true}>
          <Row>
            <Col
              security="12"
              md="6"
              lg="5"
              xl="3"
              className="ml-3 mb-5 d-flex align-items-center"
            >
              <Search />
              <input
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className={styles.searchInput}
                placeholder="Search with product name..."
              />
            </Col>
          </Row>
          {data.adminGetItemDonations
            .map((item, idx) => [item, idx])
            .filter(
              ([d]) =>
                !searchTerm ||
                d.title
                  .toLowerCase()
                  .includes(searchTerm.trim().toLocaleLowerCase()),
            )
            .map(([x, i]) => {
              return (
                <DonateCard
                  key={x.uuid}
                  index={i}
                  data={x}
                  titleHeight={titleHeight}
                  setTitleHeight={setTitleHeight}
                />
              );
            })}
          <div className={styles.nextButtonWrapper}>
            <button>Next page</button>
          </div>
        </Container>
      ) : (
        <Container>
          <Row>No item donations to review</Row>
        </Container>
      )}
    </Fragment>
  );
};

export default ItemDonations;
