import { Card, CardBody } from "reactstrap";
import React, { useEffect, useState } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import Datatable from "../../common/datatable";
import productsFormatter from "../../../helpers/productsFormatter";
import Text from "../../common/text";
import styles from "../../donate/donate.module.scss";

const GET_ADMIN_PRODUCTS = gql`
  query getAdminProducts($input: GetAdminProductsInput!) {
    getAdminProducts(input: $input) {
      total
      items {
        uuid
        slug
        sku
        productClass {
          uuid
        }
        title
        availableQuantity
        quantity
        createdDate
        categories {
          name
        }
        conditionTags {
          label
          type
        }
        location {
          address {
            state
            city
          }
        }
        images {
          resolutions {
            xs {
              url
            }
          }
        }
      }
      hasMore
    }
  }
`;

const ProductsList = ({ title, userUUID, emailOrPhone, retailerUUID }) => {
  const [products, setProducts] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const [indexFrom, setIndexFrom] = useState(0);

  const PRODUCTS_VARIABLES = {
    fetchPolicy: "no-cache",
    variables: {
      input: {
        userUUID: userUUID,
        emailOrPhone: emailOrPhone,
        retailerUUID: retailerUUID,
        sortOrder: "availability",
        limit: limit,
        indexFrom: indexFrom,
      },
    },
  };

  const [getAdminProducts, { loading, error, data: _data }] = useLazyQuery(
    GET_ADMIN_PRODUCTS,
    PRODUCTS_VARIABLES,
  );

  const onChangePage = (newPage, _) => {
    setIndexFrom((newPage - 1) * limit);
  };

  const onChangeRowsPerPage = (newPerPage, page) => {
    setIndexFrom((page - 1) * newPerPage);
    setLimit(newPerPage);
  };

  useEffect(() => {
    if (!userUUID && !emailOrPhone && !retailerUUID) {
      return;
    }
    getAdminProducts();
  }, [indexFrom, userUUID, emailOrPhone, retailerUUID]);

  useEffect(() => {
    if (!loading) {
      if (_data && _data.getAdminProducts) {
        setProducts(productsFormatter(_data?.getAdminProducts?.items));
        setTotalRows(_data?.getAdminProducts.total);
      } else {
        setProducts([]);
      }
    }
  }, [loading]);

  return (
    <Card className={styles.donateItemEdit}>
      <h3>{title}</h3>
      <CardBody className="order-datatable">
        {error ? (
          <>Some Error Occured! Try Reloading the page.</>
        ) : products ? (
          <Datatable
            hideActions={true}
            myData={products}
            loading={loading}
            pageSize={limit}
            pagination={true}
            paginationServer={true}
            totalRows={totalRows}
            myClass="-striped -highlight"
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
          />
        ) : (
          <Text>No products</Text>
        )}
      </CardBody>
    </Card>
  );
};

export default ProductsList;
