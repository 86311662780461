import React, { useState, useEffect, useRef } from "react";
import { formatAddress } from "../../helpers/address";
import s from "./searchlocationinput.module.scss";

let autoComplete;

function SearchLocationInput({
  setAddress,
  placeholdertext,
  required,
  locationVal,
  value,
  disabled,
  styles = {},
}) {
  const [query, setQuery] = useState("");
  const autoCompleteRef = useRef(null);

  useEffect(() => {
    if (!!value && !query) {
      setQuery(value);
    }
  }, [value]);

  function handleScriptLoad() {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      {
        fields: ["address_components", "geometry"],
        types: ["address"],
        //componentRestrictions: { country: ["us", "ca"] },
      },
    );
    autoComplete.setFields(["address_components", "formatted_address"]);
    autoComplete.addListener("place_changed", () => handlePlaceSelect());
  }
  async function handlePlaceSelect() {
    const addressObject = autoComplete.getPlace();
    if (!addressObject || !addressObject?.geometry) return;
    if (addressObject.geometry) {
      let query = addressObject.formatted_address;
      let data = {
        streetAddress1: "",
        streetAddress2: "",
        city: "",
        state: "",
        zip: "",
        countryCode: "US",
        latitude: 0,
        longitude: 0,
      };
      data.latitude = addressObject.geometry.location.lat();
      data.longitude = addressObject.geometry.location.lng();
      for (const component of addressObject.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
          case "street_number": {
            data.streetAddress1 = `${component.short_name}`;
            break;
          }

          case "route": {
            data.streetAddress1 =
              data.streetAddress1 + " " + component.long_name;
            break;
          }

          case "postal_code": {
            data.zip = `${component.long_name}`;
            break;
          }

          case "postal_code_suffix": {
            data.zip = `${data.zip}-${component.long_name}`;
            break;
          }
          case "locality":
            data.city = component.long_name;
            break;
          case "administrative_area_level_1": {
            data.state = component.short_name;
            break;
          }
          case "country": {
            data.countryCode = component.short_name;
            break;
          }
          default: {
            //DO NOTHING
            break;
          }
        }
      }
      if (!query) query = formatAddress(data);
      setQuery(query);
      setAddress(data);
    }
  }

  useEffect(() => {
    handleScriptLoad();
  }, []);

  useEffect(() => {
    if (!!locationVal) setQuery(locationVal);
  }, [locationVal]);

  const onChange = (event) => {
    setQuery(event.target.value);
  };

  const clearInput = () => {
    setQuery("");
    setAddress(null);
  };

  return (
    <div className={`${s.searchLocationInputContainer}`}>
      <input
        className={`${s.searchLocationInput} form-control`}
        required={required}
        style={{
          width: "100%",
          borderColor: "#dddddd",
          fontSize: 12,
          padding: "17px 25px",
          marginBottom: 30,
          height: "inherit",
          ...styles,
        }}
        autoComplete="off"
        ref={autoCompleteRef}
        onChange={onChange}
        placeholder={placeholdertext}
        value={query}
        disabled={disabled}
      />
      <span
        className={`${s.searchLocationInputClearBtn}`}
        onClick={clearInput}
      >
        &#10005;
      </span>
    </div>
  );
}

export default SearchLocationInput;
