import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import userDetailsFormatter from "./userDetailsFormatter";
import itemsFormatter from "./itemsFormatter";

const mapStatusToBadge = {
  fulfilled: "badge-success",
  ongoing: "badge-secondary",
  cancelled: "badge-danger",
  incomplete: "badge-warning",
};

const getItems = (fulfillments) => {
  const allImgs = [];
  fulfillments.forEach((fulfillment) => {
    allImgs.push(...itemsFormatter(fulfillment.items));
  });
  return allImgs;
};

const getDonors = (fulfillments) => {
  const donors = [];
  fulfillments.forEach((fulfillment) => {
    if (fulfillment.donorRetailer) {
      donors.push(
        <Link
          key={fulfillment.donorRetailer.uuid}
          target="_blank"
          to={`./retailers/details/${fulfillment.donorRetailer.uuid}`}
        >
          {fulfillment.donorRetailer.name} &nbsp;&nbsp;
          <span className={`badge badge-danger`}>retailer</span>
        </Link>,
      );
    } else if (fulfillment.donor) {
      donors.push(
        userDetailsFormatter(fulfillment.donor, fulfillment.donorRetailer),
      );
    }
    donors.push(<br />);
  });
  return donors;
};

const ordersFormatter = (ordersArr) => {
  if (!ordersArr?.length) {
    return;
  }
  const formattedOrders = ordersArr.map((order) => {
    return {
      date_created: (
        <Link
          key={order.uuid}
          target="_blank"
          to={`/order/${order.uuid}`}
        >
          {moment(order.createdDate).format("DD MMM, YYYY hh:mm:SS A")}
        </Link>
      ),
      shipping:
        order?.priceInfo?.lineItems?.filter((li) => li.type == "shipping")[0]
          ?.amount.value || "-",
      delivery:
        order?.priceInfo?.lineItems?.filter((li) => li.type == "delivery")[0]
          ?.amount.value || "-",
      items: getItems(order.fulfillments),
      order_status: (
        <span
          key={order.uuid}
          className={`badge ${mapStatusToBadge[order.status]}`}
        >
          {order.status}
        </span>
      ),
      recipient: userDetailsFormatter(order.recipient),
      donors: getDonors(order.fulfillments),
      fulfillments: order?.fulfillments?.length,
    };
  });
  return formattedOrders;
};

export default ordersFormatter;
