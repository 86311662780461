import Breadcrumb from "../common/breadcrumb";
import React, { Fragment } from "react";
import RetailerList from "./retailer-list";

const Retailers = () => {
  return (
    <Fragment>
      <Breadcrumb
        title="Retailers"
        parent="Dashboard"
      />
      <RetailerList />
    </Fragment>
  );
};

export default Retailers;
