import React, { Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import FulfillmentDetails from "./details";

const FulfillmentDetailsContainer = ({ match }) => {
  const uuid = match?.params?.fulfillmentUuid;
  return (
    <Fragment>
      <Breadcrumb
        title="Fulfillment"
        parent="Dashboard"
      />
      <FulfillmentDetails fulfillmentUUID={uuid} />
    </Fragment>
  );
};

export default FulfillmentDetailsContainer;
