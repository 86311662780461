import React, { Fragment, useState, useEffect } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { User } from "react-feather";
import { withRouter, useHistory, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Button, Form, FormGroup, Input, Label, Spinner } from "reactstrap";
import { gql } from "@apollo/client";
import "../../assets/scss/slick-theme.scss";
import { initializeApollo } from "../../helpers/apollo";
import { ADMIN_ROLE, SUPER_ADMIN_ROLE } from "../../constants/perms";
import {
  LIQUID_COOKIE,
  LIQUID_TOKEN,
  LIQUID_USER,
} from "../../constants/cookie";
import Cookies from "universal-cookie";
import getConfigs from "../../config/base";
import { Buffer } from "buffer";

const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        firstName
        lastName
        email
        roles
        groups
      }
    }
  }
`;

const LoginTabset = () => {
  const history = useHistory();
  const location = useLocation();
  const [loginData, setLoginData] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);

  const onFieldChange = (e) => {
    const { value, name } = e.target;
    loginData[name] = value;
    setLoginData({ ...loginData });
  };

  const clickActive = (event) => {
    document.querySelector(".nav-link").classList.remove("show");
    event.target.classList.add("show");
  };

  const routeChange = () => {
    history.push(`${process.env.PUBLIC_URL}/dashboard`);
  };

  useEffect(() => {
    const cookie = new Cookies();
    const data = cookie.get(LIQUID_COOKIE);
    if (data?.token && !location.state?.logout) routeChange();
  });

  const decodeJWT = (token) => {
    const parts = token.split(".");
    if (parts.length !== 3) {
      throw new Error("Invalid token format");
    }
    const header = JSON.parse(Buffer.from(parts[0], "base64"));
    const payload = JSON.parse(Buffer.from(parts[1], "base64"));
    return { header, payload };
  };

  const login = (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      var client = initializeApollo();
      client
        .mutate({
          mutation: LOGIN,
          variables: {
            password: loginData?.password,
            email: loginData?.email,
          },
        })
        .then((result) => {
          if (!result || !result.data) return;

          const { user, token } = result.data.login;

          if (!user) return;

          const { roles, groups } = user;

          if (
            roles?.includes(ADMIN_ROLE) ||
            roles?.includes(SUPER_ADMIN_ROLE) ||
            groups?.includes("admin") ||
            groups?.includes("super_admin")
          ) {
            const decoded = decodeJWT(token);
            const expiresAt = new Date(decoded.payload.exp);
            const cookies = new Cookies();
            const cookie_obj = {
              [LIQUID_TOKEN]: token,
              [LIQUID_USER]: user,
            };
            cookies.set(LIQUID_COOKIE, cookie_obj, {
              path: "/",
              expires: expiresAt,
              sameSite: true,
            });
            setLoading(false);
            setTimeout(() => {
              routeChange();
            }, 750);
          } else {
            toast.error("Sorry, you dont have admin permissions");
            setLoading(false);
          }
        })
        .catch((error) => {
          toast.error(error?.message);
          setLoading(false);
        });
    } catch (err) {
      toast.error(err?.message);
      setLoading(false);
    }
  };

  return (
    <div>
      <Fragment>
        <Tabs>
          <TabList className="nav nav-tabs tab-coupon">
            <Tab
              className="nav-link"
              onClick={(e) => clickActive(e)}
            >
              <User />
              Login
            </Tab>
          </TabList>

          <TabPanel>
            <Form className="form-horizontal auth-form">
              <FormGroup>
                <Input
                  required=""
                  name="email"
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  id="exampleInputEmail1"
                  value={loginData.email}
                  onChange={onFieldChange}
                />
              </FormGroup>
              <FormGroup>
                <Input
                  required=""
                  name="password"
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  value={loginData.password}
                  onChange={onFieldChange}
                  autoComplete="password"
                />
              </FormGroup>
              <div className="form-terms">
                <div className="custom-control custom-checkbox mr-sm-2">
                  <Label className="d-block">
                    <span className="pull-left">
                      <a
                        style={{ color: "black" }}
                        target="_blank"
                        rel="noreferrer"
                        className="btn btn-default p-0"
                        href={getConfigs().frontend + "/create-account"}
                      >
                        Sign up
                      </a>
                    </span>
                  </Label>
                  <Label className="d-block">
                    <span className="pull-right">
                      <a
                        style={{ color: "black" }}
                        target="_blank"
                        rel="noreferrer"
                        className="btn btn-default p-0"
                        href={getConfigs().frontend + "/forgot-password"}
                      >
                        forgot password
                      </a>
                    </span>
                  </Label>
                </div>
              </div>
              <div className="form-button">
                <Button
                  color="primary"
                  type="submit"
                  onClick={login}
                >
                  {loading ? <Spinner /> : "Login"}
                </Button>
              </div>
            </Form>
          </TabPanel>
        </Tabs>
        <ToastContainer />
      </Fragment>
    </div>
  );
};

export default withRouter(LoginTabset);
