import React from "react";
import { formatAddress } from "./address";

const storesFormatter = (stores) => {
  if (!stores?.length) {
    return;
  }

  const formattedStores = stores.map((d) => {
    return {
      uuid: (
        <a
          href={"/store/details/" + d?.uuid}
          style={{ color: "tomato", letterSpacing: -0.1 }}
        >
          {d?.uuid}
        </a>
      ),
      name: d.name,
      businessPhoneNumber: d.businessPhoneNumber,
      description: d.description,
      address: formatAddress(d.businessAddress),
    };
  });
  return formattedStores;
};

export default storesFormatter;
