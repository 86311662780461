import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import Cookies from "universal-cookie";
import { LIQUID_COOKIE, LIQUID_TOKEN } from "../../constants/cookie";
import axios from "axios";
import getConfigs from "../../config/base";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { FulfilledFulfillmentStatuses } from "src/constants/fulfillmentStatus";

const ADMIN_RETRIEVE_RECEIPT = gql`
  mutation ($input: AdminRetrieveReceiptInput!) {
    adminRetrieveReceipt(input: $input) {
      uuid
      status
    }
  }`;

const ADMIN_REHANDLE_RECEIPT = gql`
  mutation ($fulfillmentUUID: String!) {
    adminRehandleReceipt(fulfillmentUUID: $fulfillmentUUID)
  }`;

const DownloadReceipt = ({ donationReceipt, fulfillment }) => {
  const [loadingReceipt, setLoadingReceipt] = useState(false);
  const [adminRetrieveReceipt] = useMutation(ADMIN_RETRIEVE_RECEIPT);
  const [adminRehandleReceipt] = useMutation(ADMIN_REHANDLE_RECEIPT);

  const handleDownloadAddendum = (donationReceiptUUID) => {
    const cookies = new Cookies();
    const liquid_cookie = cookies.get(LIQUID_COOKIE);

    let token = "";
    if (liquid_cookie) {
      token = liquid_cookie[LIQUID_TOKEN];
    }

    if (!token) {
      toast.error("You are not logged in");
      return;
    }

    const configs = getConfigs();
    axios
      .get(
        configs.backend +
          `/donation-receipt/${donationReceiptUUID}/addendum/download`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
          responseType: "blob",
        },
      )
      .then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `donation-receipt-addendum-${donationReceiptUUID}.pdf`;
        link.click();
      });
  };
  const handleDownloadReceipt = (donationReceiptUUID) => {
    const cookies = new Cookies();
    const liquid_cookie = cookies.get(LIQUID_COOKIE);

    let token = "";
    if (liquid_cookie) {
      token = liquid_cookie[LIQUID_TOKEN];
    }

    if (!token) {
      toast.error("You are not logged in");
      return;
    }

    const configs = getConfigs();
    axios
      .get(
        configs.backend + `/donation-receipt/${donationReceiptUUID}/download`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
          responseType: "blob",
        },
      )
      .then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `donation-receipt-${donationReceiptUUID}.pdf`;
        link.click();
      });
  };

  const handleRefreshDonationReceiptStatus = (donationReceiptUUID) => {
    setLoadingReceipt(true);

    adminRetrieveReceipt({
      variables: {
        input: {
          donationReceiptUUID: donationReceiptUUID,
        },
      },
    })
      .then((result) => {
        const d = result.data.adminRetrieveReceipt;
        if (d.status !== "completed") {
          toast.info("Receipt isn't signed yet");
          return;
        }

        toast.success("Signature is completed");

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => toast.error(err.message))
      .finally(() => {
        setLoadingReceipt(false);
      });
  };

  const rehandleReceipt = (fulfillmentUUID) => {
    setLoadingReceipt(true);

    adminRehandleReceipt({
      variables: {
        fulfillmentUUID: fulfillmentUUID,
      },
    })
      .then((_) => {
        toast.success("Receipt has been reprocessed");
      })
      .catch((err) => toast.error(err.message))
      .finally(() => {
        setLoadingReceipt(false);
      });
  };

  return (
    <>
      {!donationReceipt &&
        fulfillment &&
        fulfillment.nonprofit &&
        FulfilledFulfillmentStatuses.includes(fulfillment.status) && (
          <button
            type="button"
            className="btn btn-info mx-2"
            disabled={loadingReceipt}
            onClick={() => rehandleReceipt(fulfillment.uuid)}
          >
            <FontAwesomeIcon
              icon={solid("envelope")}
              spin={loadingReceipt}
            />
            &nbsp;Reprocess Receipt
          </button>
        )}

      {donationReceipt && (
        <button
          type="button"
          className="btn btn-info"
          disabled={loadingReceipt}
          onClick={() => handleDownloadAddendum(donationReceipt.uuid)}
        >
          <FontAwesomeIcon
            icon={solid("download")}
            spin={loadingReceipt}
          />
          &nbsp;Download Addendum
        </button>
      )}

      {donationReceipt?.status === "completed" && (
        <button
          type="button"
          className="btn btn-info"
          onClick={() => handleDownloadReceipt(donationReceipt.uuid)}
        >
          <FontAwesomeIcon icon={solid("download")} />
          &nbsp;Download Receipt
        </button>
      )}
      {donationReceipt?.uuid &&
        donationReceipt?.status !== "completed" &&
        donationReceipt?.status !== "pending" && (
          <button
            type="button"
            className="btn btn-secondary"
            disabled={loadingReceipt}
            onClick={() =>
              handleRefreshDonationReceiptStatus(donationReceipt.uuid)
            }
          >
            <FontAwesomeIcon
              icon={solid("refresh")}
              spin={loadingReceipt}
            />
            &nbsp;Check Receipt Status
          </button>
        )}
    </>
  );
};
export default DownloadReceipt;
