import Breadcrumb from "../common/breadcrumb";
import React, { Fragment, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import Bold from "../common/bold";
import Text from "../common/text";
import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import {
  copyAddress,
  formatAddress,
  getGoogleMapsAddress,
} from "../../helpers/address";
import { ExternalLink, Copy } from "react-feather";
import { ToastContainer } from "react-toastify";
import Datatable from "../common/datatable";
import moment from "moment";

const getImages = (images) => {
  const allImages = [];
  images?.reduce((acc, el) => {
    acc.push(
      <img
        src={el.resolutions.xs.url}
        alt="img"
      />,
    );
    return acc;
  }, allImages);
  return allImages;
};

const GET_STORE = gql`
  query ($input: GetStoreInput!) {
    adminGetStore(input: $input) {
      uuid
      name
      businessPhoneNumber
      description
      createdDate
      businessAddress {
        streetAddress1
        streetAddress2
        streetAddress3
        streetAddress4
        city
        state
        zip
        countryCode
      }
      retailer {
        name
        uuid
      }
      locations {
        address {
          streetAddress1
          streetAddress2
          streetAddress3
          streetAddress4
          city
          state
          zip
          countryCode
          latitude
          longitude
        }
        contactNumber
        notes
      }
      products {
        uuid
        title
        availableQuantity
        quantity
        images {
          resolutions {
            xs {
              url
              size
            }
          }
        }
      }
    }
  }
`;

const StoreDetails = () => {
  const { uuid } = useParams();
  const [store, setStore] = useState({});
  const { loading, data } = useQuery(GET_STORE, {
    variables: {
      input: {
        uuid: uuid,
      },
    },
  });

  useEffect(() => {
    if (!loading) {
      if (data && data.adminGetStore) {
        setStore(data.adminGetStore);
      } else {
        setStore({});
      }
    }
  }, [loading]);
  return (
    <Fragment>
      <Breadcrumb
        title="Location Details"
        parent="Dashboard"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col
                    sm="12"
                    md="6"
                  >
                    <h3>{store.name}</h3>
                    {store && store.retailer && (
                      <h4 style={{ color: "tomato" }}>
                        (
                        <a href={"/retailers/details/" + store.retailer.uuid}>
                          {store.retailer.name}
                        </a>
                        )
                      </h4>
                    )}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {loading ? (
                  <div className="text-center my-5">
                    <Spinner />
                  </div>
                ) : (
                  <Row>
                    <Col
                      xs="12"
                      md="6"
                    >
                      <Bold>Name</Bold>
                      <Text>{store.name}</Text>
                    </Col>
                    <Col
                      xs="12"
                      md="6"
                    >
                      <Bold>Description</Bold>
                      <Text>{store.description}</Text>
                    </Col>
                    <Col
                      xs="12"
                      md="6"
                    >
                      <Bold>Business Phone Number</Bold>
                      <Text>{store.businessPhoneNumber}</Text>
                    </Col>
                    <Col
                      xs="12"
                      md="6"
                    >
                      <Bold>Address</Bold>
                      <Text>
                        {formatAddress(store?.businessAddress)} &nbsp;&nbsp;
                        <a href="#">
                          <Copy
                            onClick={() => copyAddress(store?.businessAddress)}
                          />
                        </a>
                        {"  "}
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={getGoogleMapsAddress(store?.businessAddress)}
                        >
                          <ExternalLink />
                        </a>
                      </Text>
                    </Col>
                    <Col
                      xs="12"
                      md="6"
                    >
                      <Bold>Date Created</Bold>
                      <Text>
                        {moment(store.createdDate).format(
                          "DD MMM, YYYY hh:mm:SS A",
                        )}
                      </Text>
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                {loading ? (
                  <div className="text-center my-5">
                    <Spinner />
                  </div>
                ) : (
                  <Row>
                    <Col
                      xs="12"
                      className="mt-2"
                    >
                      <h3>Locations</h3>
                      {store.locations?.length ? (
                        <Datatable
                          multiSelectOption={false}
                          myData={store.locations.map((d) => ({
                            address: (
                              <Text>
                                {formatAddress(d?.address)} &nbsp;&nbsp;
                                <a href="#">
                                  <Copy
                                    onClick={() => copyAddress(d?.address)}
                                  />
                                </a>
                                {"  "}
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  href={getGoogleMapsAddress(d?.address)}
                                >
                                  <ExternalLink />
                                </a>
                              </Text>
                            ),
                            contactNumber: d.contactNumber,
                            notes: d.notes,
                          }))}
                          pageSize={10}
                          pagination={true}
                          class="-striped -highlight"
                          hideActions
                        />
                      ) : (
                        <Text>No addresses for the location</Text>
                      )}
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                {loading ? (
                  <div className="text-center my-5">
                    <Spinner />
                  </div>
                ) : (
                  <Row>
                    <Col
                      xs="12"
                      className="mt-2"
                    >
                      <h3>Donated Products</h3>
                      {store.products?.length ? (
                        <Datatable
                          multiSelectOption={false}
                          myData={store.products.map((d) => ({
                            title: (
                              <a
                                href={"/product/" + d.uuid}
                                style={{ color: "tomato", letterSpacing: -0.1 }}
                              >
                                {d?.title}
                              </a>
                            ),
                            images: getImages(d.images),
                            date: moment(d.createdDate).format(
                              "DD MMM, YYYY hh:mm:SS A",
                            ),
                            availableQuantity: d.availableQuantity,
                            categories: d.categories
                              ?.map((c) => c.name)
                              .join(", "),
                            condition: d.conditionTags
                              ?.map((c) => c.label)
                              .join(", "),
                          }))}
                          pageSize={10}
                          pagination={true}
                          class="-striped -highlight"
                          hideActions
                        />
                      ) : (
                        <Text>No products donated</Text>
                      )}
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </Fragment>
  );
};

export default StoreDetails;
