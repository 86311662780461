import React from "react";
import { Status } from '@googlemaps/react-wrapper';

export const createMarker = (loc, map, title) => {
    const marker = new window.google.maps.Marker({
        position: loc,
        map,
        title: title,
    });
    return marker;
}

export const createInfoWindow = (contentString, marker, map, shouldFocus = false) => {
    const infowindow = new window.google.maps.InfoWindow({
        content: contentString,
    });

    infowindow.open({
        anchor: marker,
        map,
        shouldFocus: shouldFocus,
    });
    return infowindow;
}

export const renderMap = (status) => {
    if (status === Status.LOADING) return <div className='h-full w-full text-center text-blue-500 pt-10 bg-gray-300'>LOADING</div>;
    if (status === Status.FAILURE) return <div className='h-full w-full text-center text-red-500 pt-10 bg-gray-300'>ERROR</div>;
    return null;
}

