import { gql } from "@apollo/client";
import React, { useState } from "react";
import {
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Label,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import LoadingButton from "../common/loadingButton";
import { initializeApollo } from "src/helpers/apollo";
import { num_to_money } from "src/helpers/utils";
import { toast } from "react-toastify";
import { err_toast } from "src/helpers/errors";
import { ChevronDown } from "react-feather";
import styles from "./transactions.module.scss";

const TRANSACTION_TYPES = [
  { title: "Donation Service Fees", value: "donation_service_fees" },
  { title: "Shipping Fees", value: "shipping_fees" },
  { title: "Delivery Fees", value: "delivery_fees" },
  { title: "Service Fees", value: "service_fees" },
];

const ADMIN_ADD_TRANSACTION = gql`
    mutation adminAddTransaction($input: AdminAddTransactionInput!) {
        adminAddTransaction(input: $input) {
            uuid
            description
            type
            amount {
                value
                currencyCode
            }
            retailer {
                name
                uuid
            }
            invoice {
                uuid
            }
            match {
                uuid
            }
            fulfillment {
                uuid
            }
            createdAt
        }
    }
`;

const AddTransaction = ({
  retailerUUID,
  invoiceUUID,
  matchUUID,
  fulfillmentUUID,
}) => {
  const [open, setOpen] = useState(false);
  const [type, setType] = useState();
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const onOpenModal = () => {
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  let client = initializeApollo();

  const addTransaction = () => {
    if (!description) {
      return;
    }
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: ADMIN_ADD_TRANSACTION,
          variables: {
            input: {
              retailerUUID: retailerUUID,
              invoiceUUID: invoiceUUID,
              matchUUID: matchUUID,
              fulfillmentUUID: fulfillmentUUID,
              type: type,
              description: description,
              amount: num_to_money(amount, "USD"),
            },
          },
        })
        .then((_) => {
          setTimeout(() => {
            resolve("done");
            toast.success("Added new transaction");
            window.location.reload();
          }, 1000);
        })
        .catch((e) => {
          reject(e);
          err_toast(e);
        });

      setTimeout(() => resolve("done"), 2000);
    });
  };

  return (
    <div className="d-flex justify-content-end mb-2">
      <Button
        color="primary"
        onClick={onOpenModal}
      >
        Add New Transaction
      </Button>
      <Modal
        isOpen={open}
        toggle={onCloseModal}
        size="sm"
      >
        <ModalHeader>
          <span>Add New Transaction</span>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Label>Description</Label>
              <Input
                name="description"
                type="text"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                invalid={!description}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Label>Type</Label>
              <Dropdown
                required={true}
                isOpen={isDropdownOpen}
                toggle={(_) => {
                  setIsDropdownOpen(!isDropdownOpen);
                }}
              >
                <DropdownToggle className={styles.dropdown}>
                  {type || "Select type"} <ChevronDown />
                </DropdownToggle>
                <DropdownMenu>
                  {TRANSACTION_TYPES.map((t) => (
                    <DropdownItem
                      key={t.value}
                      onClick={(_) => setType(t.value)}
                    >
                      {t.title}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Label>Amount ($)</Label>
              <Input
                name="amount"
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                step={0.01}
                invalid={amount === null}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <LoadingButton
            text="Save"
            onClick={addTransaction}
            color="primary"
          />
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default AddTransaction;
