import React from "react";

const Text = ({ children, style = {}, ...props }) => {
  return (
    <p
      className="c-black mb-0"
      style={{ fontSize: "15px", ...style }}
      {...props}
    >
      {children}
    </p>
  );
};

export default Text;
