import React, { Fragment } from "react";
import { Container } from "reactstrap";
import Breadcrumb from "../common/breadcrumb";
import { gql, useQuery } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";
import { initializeApollo } from "../../helpers/apollo";
import { ToastContainer } from "react-toastify";
import { addCountryCode } from "../../..//src/helpers/utils.js";
import { toast } from "react-toastify";
import { err_toast } from "src/helpers/errors";
import AddEditRetailer from "./AddEditRetailer";

const ADMIN_GET_RETAILER = gql`
  query ($input: GetRetailerInput!) {
    adminGetRetailer(input: $input) {
      name
      businessPhoneNumber
      businessAddress {
        streetAddress1
        streetAddress2
        city
        state
        zip
        countryCode
        latitude
        longitude
      }
      website
      description
      shopifyShopID
      enableResaleRestriction
      focusAreas
      populationsServed
      createdDate
      billingEmail
      billingType
    }
  }
`;

const ADMIN_EDIT_RETAILER = gql`
  mutation ($input: AdminEditRetailerInput!) {
    adminEditRetailer(input: $input) {
      name
      businessPhoneNumber
      businessAddress {
        streetAddress1
        streetAddress2
        city
        state
        zip
        countryCode
        latitude
        longitude
      }
      website
      description
      shopifyShopID
      enableResaleRestriction
      focusAreas
      populationsServed
      createdDate
      billingEmail
      billingType
    }
  }
`;

const RetailerEdit = () => {
  const { uuid } = useParams();
  const history = useHistory();

  const { data } = useQuery(ADMIN_GET_RETAILER, {
    fetchPolicy: "no-cache",
    variables: {
      input: {
        uuid: uuid,
      },
    },
  });

  let client = initializeApollo();

  const handleEditRetailer = (vals, cb) => {
    const newValues = { ...vals };

    client
      .mutate({
        mutation: ADMIN_EDIT_RETAILER,
        fetchPolicy: "no-cache",
        variables: {
          input: {
            uuid: uuid,
            retailer: {
              ...newValues,
              businessPhoneNumber: addCountryCode(
                newValues.businessPhoneNumber,
              ),
            },
          },
        },
      })
      .then((_) => {
        toast.success("Retailer updated successfully!");
        setTimeout(() => {
          history.replace("/retailers/details/" + uuid);
          cb();
        }, 1000);
      })
      .catch((err) => {
        err_toast(err);
        cb();
      });
  };

  return (
    <Fragment>
      <Breadcrumb
        title="Edit Retailer"
        parent="Dashboard"
      />

      <Container fluid={true}>
        <AddEditRetailer
          data={data?.adminGetRetailer}
          onSubmit={handleEditRetailer}
        />
      </Container>
      <ToastContainer />
    </Fragment>
  );
};

export default RetailerEdit;
