import React, { useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import { Button, Card, CardBody, Col, FormGroup, Input, Row } from "reactstrap";
import { initializeApollo } from "../../helpers/apollo";
import { ToastContainer, toast } from "react-toastify";

const GET_ROLES = gql`
    query getRoles {
        getRoles {
          role
          description
          userTypes
        }
    }
`;

const GET_GROUPS = gql`
    query getGroups {
        getGroups {
          group
          description
          userTypes
        }
    }
`;

const ADMIN_EDIT_USER = gql`
    mutation adminEditUser($input: AdminEditUserInput!) {
        adminEditUser(input: $input) {
            user {
                roles
                groups
            }
        }
    }
`;

const RolesAndGroups = ({ userUUID, selectedRoles, selectedGroups }) => {
  const { data: _getRoles } = useQuery(GET_ROLES);
  const { data: _getGroups } = useQuery(GET_GROUPS);
  const client = initializeApollo();

  const [roles, setRoles] = useState({});
  const [groups, setGroups] = useState({});
  const [rolesByUserType, setRolesByUserType] = useState(new Map());
  const [groupsByUserType, setGroupsByUserType] = useState(new Map());

  const onSave = () => {
    if (Object.keys(roles).length === 0 && Object.keys(groups).length === 0) {
      return;
    }

    client
      .mutate({
        mutation: ADMIN_EDIT_USER,
        variables: {
          input: {
            uuid: userUUID,
            roles: Object.entries(roles).map((r) => ({
              role: r[0],
              selected: r[1],
            })),
            groups: Object.entries(groups)?.map((g) => ({
              group: g[0],
              selected: g[1],
            })),
          },
        },
      })
      .then((_res) => {
        toast.success("Saved groups and roles");
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  useEffect(() => {
    setRoles({});
    setGroups({});
  }, [userUUID]);

  useEffect(() => {
    let r = _getRoles?.getRoles?.reduce((m, el) => {
      el.userTypes.forEach((userType) => {
        let rs = m.get(userType) || [];
        rs.push(el);
        m.set(userType, rs);
      });
      return m;
    }, new Map());
    setRolesByUserType(r);
  }, [_getRoles]);

  useEffect(() => {
    let r = _getGroups?.getGroups?.reduce((m, el) => {
      el.userTypes.forEach((userType) => {
        let rs = m.get(userType) || [];
        rs.push(el);
        m.set(userType, rs);
      });
      return m;
    }, new Map());
    setGroupsByUserType(r);
  }, [_getGroups]);

  const isSelectedRole = (value) =>
    Boolean(
      (roles[value] !== false && selectedRoles?.includes(value)) ||
        !!roles[value],
    );
  const isSelectedGroup = (value) =>
    Boolean(
      (groups[value] !== false && selectedGroups?.includes(value)) ||
        !!groups[value],
    );

  return (
    <>
      <Card>
        <CardBody>
          {userUUID ? (
            <>
              <Row>
                {rolesByUserType &&
                  Array.from(rolesByUserType?.keys()).map((k) => {
                    return (
                      <Col
                        key={k}
                        sm="12"
                        md="3"
                      >
                        <h4>
                          <u>{k?.split("_")?.join(" ") || ""} Roles</u>
                        </h4>
                        {rolesByUserType.get(k)?.map((r) => {
                          return (
                            <Row key={r.role}>
                              <Col sm="9">
                                <label>{r.role.split("_").join(" ")}</label>
                              </Col>
                              <Col sm="3">
                                <FormGroup className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                  <Input
                                    className="radio_animated"
                                    id="edo-ani1"
                                    type="checkbox"
                                    name="rdo-ani"
                                    checked={isSelectedRole(r.role)}
                                    onChange={(e) => {
                                      setRoles((rs) => ({
                                        ...(rs || {}),
                                        [r.role]: e.target.checked,
                                      }));
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          );
                        })}
                      </Col>
                    );
                  })}
              </Row>
              <hr />
              <Row>
                {groupsByUserType &&
                  Array.from(groupsByUserType?.keys()).map((k) => {
                    return (
                      <Col
                        key={k}
                        sm="12"
                        md="3"
                      >
                        <h4>
                          <u>{k} Groups</u>
                        </h4>
                        {groupsByUserType.get(k)?.map((r) => {
                          return (
                            <Row key={r.group}>
                              <Col sm="9">
                                <label>{r.group.split("_").join(" ")}</label>
                              </Col>
                              <Col sm="3">
                                <FormGroup className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                  <Input
                                    className="radio_animated"
                                    id="edo-ani1"
                                    type="checkbox"
                                    name="rdo-ani"
                                    checked={isSelectedGroup(r.group)}
                                    onChange={(e) => {
                                      setGroups((rs) => ({
                                        ...(rs || {}),
                                        [r.group]: e.target.checked,
                                      }));
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          );
                        })}
                      </Col>
                    );
                  })}
              </Row>
              <Row className="mt-2">
                <Button onClick={onSave}>Save</Button>
              </Row>
            </>
          ) : (
            <div>There are no records to display</div>
          )}
        </CardBody>
      </Card>
      <ToastContainer />
    </>
  );
};

export default RolesAndGroups;
