import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { CheckCircle, Edit, XCircle } from "react-feather";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import { FulfilledFulfillmentStatuses } from "src/constants/fulfillmentStatus";

const ADMIN_EDIT_FULFILLMENT = gql`
    mutation adminEditFulfillment($input: AdminEditFulfillmentInput!) {
        adminEditFulfillment(input: $input) {
            uuid
            items {
                uuid
                quantity
            }
        }
    }
`;

const ItemQuantity = ({ fulfillmentUUID, fulfillmentStatus, item }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [quantity, setQuantity] = useState(item.quantity);
  const [isLoading, setIsLoading] = useState(false);
  const [editFulfillment] = useMutation(ADMIN_EDIT_FULFILLMENT);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const handleIconClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (e) => {
    setQuantity(e.target.value);
  };

  const cancelEdit = () => {
    setIsEditing(false);
    setQuantity(item.quantity);
  };

  const onChangeQuantity = () => {
    setIsLoading(true);
    editFulfillment({
      variables: {
        input: {
          fulfillmentUUID: fulfillmentUUID,
          items: [
            {
              itemUUID: item.uuid,
              quantity: quantity,
            },
          ],
        },
      },
    })
      .then(() => {
        toast.success("Successfully editted the item quantity");
        setIsLoading(false);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.message || "something went wrong");
      });
  };

  return (
    <>
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{ position: "relative" }}
      >
        X{" "}
        {isEditing ? (
          <>
            <input
              type="number"
              style={{
                border: "1px solid lightgrey",
                width: "30%",
                marginRight: "5px",
                outline: "none",
              }}
              value={quantity}
              onChange={handleInputChange}
              autoFocus
              min={0}
              max={item?.product?.availableQuantity + item?.quantity}
            />
            {item?.product?.unitType}

            {isLoading ? (
              <Spinner />
            ) : (
              <>
                <CheckCircle
                  style={{
                    color: "green",
                    marginLeft: "5px",
                    cursor: "pointer",
                  }}
                  onClick={onChangeQuantity}
                />
                <XCircle
                  style={{ color: "red", cursor: "pointer" }}
                  onClick={cancelEdit}
                />
              </>
            )}
          </>
        ) : (
          <>
            {quantity} {item?.product?.unitType}{" "}
            {isHovering &&
              !FulfilledFulfillmentStatuses.includes(fulfillmentStatus) && (
                <Edit
                  onClick={handleIconClick}
                  style={{
                    height: "15px",
                    marginLeft: "5px",
                    cursor: "pointer",
                  }}
                />
              )}
          </>
        )}
      </div>
    </>
  );
};

export default ItemQuantity;
