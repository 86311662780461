import React, { Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Container } from "reactstrap";
import { ToastContainer } from "react-toastify";
import SubscriptionPlan from "./SubscriptionPlan";
import { useParams } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";

const ADMIN_GET_SUBSCRIPTION_PLAN = gql`
    query($input: AdminGetSubscriptionPlanInput!) {
       adminGetSubscriptionPlan(input: $input) {
          uuid
          id
          status
          title
          isDefault
          monthlyAmount {
            value
            currencyCode
          }
          includedDonationValue {
            value
            currencyCode
          }
          individualItemPrice {
            value
            currencyCode
          }
          unboxedPrice {
            value
            currencyCode
          }
          boxPrice {
            value
            currencyCode
          }
          largeBoxPrice {
            value
            currencyCode
          }
          oversizedItemPrice {
            value
            currencyCode
          }
          palletPrice {
            value
            currencyCode
          }
          largePalletPrice {
            value
            currencyCode
          }
          isShippingFeePassThrough
          shippingFee {
            value
            currencyCode
          }
          isDeliveryFeePassThrough
          deliveryFee {
            value
            currencyCode
          }
        }
    }
`;

const EditSubscriptionPlan = () => {
  const { subscriptionPlanUUID } = useParams();
  const { data } = useQuery(ADMIN_GET_SUBSCRIPTION_PLAN, {
    fetchPolicy: "no-cache",
    variables: {
      input: {
        uuid: subscriptionPlanUUID,
      },
    },
  });

  return (
    <Fragment>
      <Breadcrumb
        title="Subscription Plan"
        parent="Edit Subscription Plan"
      />
      <Container fluid={true}>
        <SubscriptionPlan data={data?.adminGetSubscriptionPlan} />
      </Container>
      <ToastContainer />
    </Fragment>
  );
};

export default EditSubscriptionPlan;
