import { Card, CardBody, Col, Row, Button } from "reactstrap";
import React, { useState } from "react";
import NumberOfItemsPerMonth from "./NumberOfItemsPerMonth";

const MagicMatchesSignUpForm = ({ onSubmit }) => {
  const [numberOfItems, setNumberOfItems] = useState(50);

  const onClickSubmit = () => {
    onSubmit({
      numberOfItemsPerMonth: numberOfItems,
      enabled: true,
    });
  };

  return (
    <>
      <Card>
        <CardBody
          className="mx-auto"
          style={{ maxWidth: "900px" }}
        >
          <Row className="my-3 mx-auto">
            <Col className="text-center justify-items-center">
              <NumberOfItemsPerMonth setNumberOfItems={setNumberOfItems} />
            </Col>
          </Row>

          <Row className="mt-5 mx-auto">
            <Col className="text-center justify-items-center">
              <div className="mx-auto text-center justify-items-center">
                <Button
                  className="mx-auto"
                  onClick={onClickSubmit}
                  color="primary"
                >
                  Sign up for Magic Matches
                </Button>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default MagicMatchesSignUpForm;
