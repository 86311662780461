import React from "react";
import { Col, Row, CardBody, Card } from "reactstrap";
const Prices = ({ prices }) => {
  const showPrices = () =>
    prices?.paymentIntentID ||
    prices?.total ||
    prices?.serviceFees ||
    prices?.totalDiscounts ||
    prices?.donationServiceFee;

  return (
    <>
      {showPrices() && (
        <Card
          style={{
            boxShadow: "1px 4px 14px rgba(60, 72, 72, 0.4)",
          }}
        >
          <CardBody>
            {prices?.donationServiceFee && (
              <Row>
                <Col xs="8">
                  <h4>Donation Service Fee:</h4>
                </Col>
                <Col xs="4">
                  <h4 style={{ fontWeight: "bold" }}>
                    ${prices?.donationServiceFee?.value}
                  </h4>
                </Col>
              </Row>
            )}
            {prices?.serviceFees && (
              <Row>
                <Col xs="8">
                  <h4>Service Fees:</h4>
                </Col>
                <Col xs="4">
                  <h4 style={{ fontWeight: "bold" }}>
                    ${prices?.serviceFees?.value}
                  </h4>
                </Col>
              </Row>
            )}
            {prices?.totalDiscounts &&
              prices?.totalDiscounts?.value !== "0.00" && (
                <Row>
                  <Col xs="8">
                    <h4 style={{ color: "green" }}>Total Discounts:</h4>
                  </Col>
                  <Col xs="4">
                    <h4 style={{ fontWeight: "bold", color: "green" }}>
                      - ${prices?.totalDiscounts?.value}
                    </h4>
                  </Col>
                </Row>
              )}
            {prices?.promoCode && (
              <Row>
                <Col xs="8">
                  <h4>Promo Code:</h4>
                </Col>
                <Col xs="4">
                  <b>{prices?.promoCode}</b>
                </Col>
              </Row>
            )}
            <hr />
            {prices?.total && (
              <Row>
                <Col xs="8">
                  <h4>Total:</h4>
                </Col>
                <Col xs="4">
                  <h4 style={{ fontWeight: "bold" }}>
                    ${prices?.total?.value}
                  </h4>
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default Prices;
