import { gql, useQuery } from "@apollo/client";
import React, { Fragment, useMemo } from "react";
import { useParams } from "react-router-dom";
import styles from "./messaging.module.scss";
import userImage from "../../assets/images/user.png";
import cx from "classnames";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardTitle,
  CardText,
  Spinner,
} from "reactstrap";
import Breadcrumb from "../common/breadcrumb";
import { ToastContainer } from "react-toastify";
import { getFormattedName, getMessageDate } from "../../helpers/messaging";

export const GET_THREAD = gql`
  query adminGetThread($input: String!) {
    adminGetThread(fulfillmentUUID: $input) {
      uuid
      messages {
        type
        content
        createdAt
        createdBy {
            user {
              uuid
              firstName
              lastName
            }
        }
      }
      donorRetailer {
        uuid
        name
      }
      recipientNonprofit {
        organizationName
        uuid
      }
      threadParticipants {
        uuid
        name
        emailAddress
      }
      order {
        uuid
        fulfillments {
          uuid
          createdDate
          order {
            uuid
          }
          donorRetailer {
              uuid
              name
          }
          recipientNonprofit {
              organizationName
              uuid
          }
          status
          type
          recipientLocation {
            address {
              streetAddress1
              streetAddress2
              streetAddress3
              streetAddress4
              state
              zip
              city
              countryCode
              timezone
            }
            notes
          }
          pickupLocation {
            address {
              streetAddress1
              streetAddress2
              streetAddress3
              streetAddress4
              state
              zip
              city
              countryCode
              timezone
            }
            notes
            maskedContactNumber
          }
          items {
              quantity
              product {
                  title
                  quantity
                  unitType
              }
          }
        }
      }
    }
  }
`;

const MessagingDetails = () => {
  const { uuid } = useParams();
  const { loading, data } = useQuery(GET_THREAD, {
    fetchPolicy: "no-cache",
    variables: {
      input: uuid,
    },
  });

  const selectedThread = useMemo(() => {
    return loading ? {} : data?.adminGetThread;
  }, [loading]);

  return (
    <Fragment>
      <Breadcrumb
        title="Thread"
        parent="Fulfillment"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            {selectedThread ? (
              <Card>
                <CardBody>
                  <CardTitle>
                    Retailer:{" "}
                    <a
                      href={
                        "/retailers/details/" +
                        selectedThread?.donorRetailer?.uuid
                      }
                    >
                      {selectedThread?.donorRetailer?.name}
                    </a>
                    <br />
                    Nonprofit:{" "}
                    <a
                      href={
                        "/nonprofit/" + selectedThread?.recipientNonprofit?.uuid
                      }
                    >
                      {selectedThread?.recipientNonprofit?.organizationName}
                    </a>
                    <br />
                    Thread UUID: {selectedThread?.uuid}
                    <br />
                    Order UUID:{" "}
                    <a href={"/order/" + selectedThread?.order?.uuid}>
                      {selectedThread?.order?.uuid}
                    </a>
                    <br />
                    Participants:{" "}
                    {selectedThread?.threadParticipants
                      ?.map((person) => person.emailAddress)
                      .join(", ")}
                  </CardTitle>
                  <div className={cx(styles.conversationContainer)}>
                    <div className={cx(styles.conversation)}>
                      <CardText
                        key={`message_fulfillment_closed`}
                        className="message"
                      >
                        <div
                          className="mt-3 d-flex align-items-center overflow-hidden"
                          style={{ maxWidth: "100%" }}
                        >
                          <img
                            src={userImage}
                            alt="User Icon"
                            className="rounded-circle mr-2"
                            width="35"
                            height="35"
                          />
                          <div
                            className="d-flex flex-column overflow-hidden"
                            style={{ width: "100%" }}
                          >
                            <div className={styles.messageContentContainer}>
                              <div className={styles.messageName}>
                                <strong>LiquiDonate</strong>
                              </div>
                              <div className={styles.messageDate}>
                                <small>Now</small>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            marginLeft: "2.70em",
                            whiteSpace: "pre-line",
                          }}
                        >
                          Hello, thank you for choosing LiquiDonate. Please
                          follow the following guidelines when connecting:
                          <br />
                          1. Ensure that your replies are specifically related
                          to these items for quicker resolution.
                          <br />
                          2. We encourage maintaining a respectful and polite
                          tone in all communications.
                        </div>
                      </CardText>
                      {selectedThread?.messages?.map((message, i) => (
                        <CardText
                          key={`message_${i}`}
                          className="message"
                        >
                          {(i == 0 ||
                            selectedThread?.messages[i - 1]?.createdBy?.user
                              ?.uuid !== message?.createdBy?.user?.uuid) && (
                            <div
                              className="mt-3 d-flex align-items-center overflow-hidden"
                              style={{ maxWidth: "100%" }}
                            >
                              <img
                                src={message?.createdBy?.imageUrl || userImage}
                                alt="User Icon"
                                className="rounded-circle mr-2"
                                width="35"
                                height="35"
                              />
                              <div
                                className="d-flex flex-column overflow-hidden"
                                style={{ width: "100%" }}
                              >
                                <div className={styles.messageContentContainer}>
                                  <div className={styles.messageName}>
                                    <strong>{getFormattedName(message)}</strong>
                                  </div>
                                  <div className={styles.messageDate}>
                                    <small>{getMessageDate(message)}</small>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          <div
                            style={{
                              marginLeft: "2.70em",
                              whiteSpace: "pre-line",
                            }}
                          >
                            {message.content}
                          </div>
                        </CardText>
                      ))}
                    </div>
                  </div>
                </CardBody>
              </Card>
            ) : (
              <Card>
                <CardBody>
                  <div className="text-center my-5">
                    <Spinner />
                  </div>
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </Fragment>
  );
};

export default MessagingDetails;
