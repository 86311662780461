import React, { Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Container } from "reactstrap";
import { ToastContainer } from "react-toastify";
import Pricing from "./Pricing";
import { useParams } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";

const ADMIN_GET_SUBSCRIPTION_PLAN = gql`
    query($input: String!) {
       adminGetRetailerSubscriptionPlan(retailerSubscriptionPlanUUID: $input) {
          uuid
          id
          status
          retailer {
            uuid
            name
          }
          subscriptionPlan {
            title
          }
          monthlyAmount {
            value
            currencyCode
          }
          includedDonationValue {
            value
            currencyCode
          }
          individualItemPrice {
            value
            currencyCode
          }
          unboxedPrice {
            value
            currencyCode
          }
          boxPrice {
            value
            currencyCode
          }
          largeBoxPrice {
            value
            currencyCode
          }
          oversizedItemPrice {
            value
            currencyCode
          }
          palletPrice {
            value
            currencyCode
          }
          largePalletPrice {
            value
            currencyCode
          }
          isShippingFeePassThrough
          shippingFee {
            value
            currencyCode
          }
          isDeliveryFeePassThrough
          deliveryFee {
            value
            currencyCode
          }
        }
    }
`;

const EditPricing = () => {
  const { uuid } = useParams();
  const { data } = useQuery(ADMIN_GET_SUBSCRIPTION_PLAN, {
    fetchPolicy: "no-cache",
    variables: {
      input: uuid,
    },
  });

  return (
    <Fragment>
      <Breadcrumb
        title="Subscription Plan"
        parent="Edit Subscription Plan"
      />
      <Container fluid={true}>
        <Pricing data={data?.adminGetRetailerSubscriptionPlan} />
      </Container>
      <ToastContainer />
    </Fragment>
  );
};

export default EditPricing;
