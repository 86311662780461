import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import ConfirmationModal from "../modals/ConfirmationModal";

const ADMIN_GENERATE_LABEL_FULFILLMENT = gql`
  mutation($input: AdminGenerateLabelInput!) {
    adminGenerateLabel(input: $input) {
      uuid
    }
  }
`;

const GenerateLabel = ({ fulfillmentUUID, fulfillmentType }) => {
  const [generateLabelModalOpen, setGenerateLabelModalOpen] = useState(false);
  const [adminGenerateLabel, { loading: generateLabelLoading }] = useMutation(
    ADMIN_GENERATE_LABEL_FULFILLMENT,
  );
  const onConfirmGenerateLabel = () => {
    adminGenerateLabel({
      variables: {
        input: {
          fulfillmentUUID: fulfillmentUUID,
        },
      },
    })
      .then(() => {
        setTimeout(() => {
          toast.success("Updated fulfillment");
          window.location.reload();
        }, 1500);
        setGenerateLabelModalOpen(false);
      })
      .catch((err) => toast.error(err.message));
  };

  return (
    <>
      {fulfillmentType == "shipping" && (
        <button
          className="btn btn-primary mx-2"
          onClick={() => setGenerateLabelModalOpen(true)}
        >
          Generate Label
        </button>
      )}

      <ConfirmationModal
        open={generateLabelModalOpen}
        onClose={() => setGenerateLabelModalOpen(false)}
        title={"Generate Shipping Label"}
        desc={
          "Are you sure you want to generate shipping label for this fulfillment?"
        }
        onConfirm={onConfirmGenerateLabel}
        loading={generateLabelLoading}
      />
    </>
  );
};

export default GenerateLabel;
