import React from "react";
import moment from "moment";
import { formatAddress } from "./address";

const productsFormatter = (products) => {
  if (!products.length) {
    return;
  }

  const formattedProducts = products.map((d) => {
    return {
      title: (
        <a
          href={"/product/" + d.slug}
          style={{ color: "tomato", letterSpacing: -0.1 }}
        >
          {d?.title}
        </a>
      ),
      images: d.images?.map((el) => (
        <img
          key={el.uuid}
          src={el.resolutions.xs.url}
          alt="img"
        />
      )),
      city: formatAddress(d?.location?.address),
      date: moment(d.createdDate).format("DD MMM, YYYY hh:mm:SS A"),
      availableQuantity: d.availableQuantity,
      categories: d.categories?.map((c) => c.name).join(", "),
      condition: d.conditionTags?.map((c) => c.label).join(", "),
    };
  });
  return formattedProducts;
};

export default productsFormatter;
