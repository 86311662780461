import React from "react";
import { Link } from "react-router-dom";

const itemsFormatter = (items) => {
  if (!items?.length) {
    return;
  }
  const formattedItems = items.map((item) => {
    const image = item.product.images[0];
    return (
      <Link
        key={item?.uuid}
        target="_blank"
        to={`/product/${item.product.uuid}`}
      >
        <img
          key={image?.uuid}
          src={image?.resolutions.xs.url}
          alt={item.product.title}
          title={item.product.title}
        />
      </Link>
    );
  });
  return formattedItems;
};

export default itemsFormatter;
