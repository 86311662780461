import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import styles from "./styles.module.scss";
import { Check, X } from "react-feather";
import { allTimeWindows } from ".";

const TimeWindowsDropdown = ({ timeWindows, onSelect }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedTimeWindows, setSelectedTimeWindows] = useState([]);

  const isSelected = (timeWindow) =>
    selectedTimeWindows.find((tw) => tw.id === timeWindow.id);

  const toggleTimeWindow = (timeWindow) => {
    let tws = [];
    if (isSelected(timeWindow)) {
      tws = selectedTimeWindows.filter((tw) => tw.id !== timeWindow.id);
    } else {
      tws = [...selectedTimeWindows, timeWindow].sort(
        (t1, t2) => t1.id - t2.id,
      );
    }

    setSelectedTimeWindows(tws);
    onSelect && onSelect(tws);
  };

  useEffect(() => {
    if (timeWindows) setSelectedTimeWindows(timeWindows);
  }, [timeWindows]);

  return (
    <>
      <Dropdown
        isOpen={isDropdownOpen}
        toggle={(_) => {
          setIsDropdownOpen((prev) => !prev);
        }}
      >
        <DropdownToggle className={styles.dropdown}>
          <div className="d-flex flex-wrap">
            {selectedTimeWindows.map((tw) => (
              <div
                key={tw.display}
                style={{ position: "relative" }}
              >
                <p key={tw.display}>{tw.display}</p>
                <X
                  style={{
                    position: "absolute",
                    right: 15,
                    top: "15%",
                    background: "#363636",
                    borderRadius: "100%",
                    padding: 3,
                  }}
                  color="#fff"
                  size={20}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    toggleTimeWindow(tw);
                  }}
                />
              </div>
            ))}
            {selectedTimeWindows.length === 0 && (
              <span style={{ fontSize: "1rem" }}>Eg: 8am - 10am</span>
            )}
          </div>
        </DropdownToggle>
        <DropdownMenu className={styles.menu}>
          {allTimeWindows.map((tw) => (
            <DropdownItem
              className={styles.dropdownItem}
              key={tw.display}
              onClick={(_) => {
                toggleTimeWindow(tw);
              }}
            >
              <div className="d-flex flex-row">
                <span style={{ minWidth: "2rem" }}>
                  <Check
                    color="green"
                    style={{
                      opacity: isSelected(tw) ? 1 : 0,
                    }}
                  />{" "}
                  &nbsp;{" "}
                </span>
                <span>{tw.display}</span>
              </div>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default TimeWindowsDropdown;
