import { Card, CardHeader, CardBody, Col, Row } from "reactstrap";
import { gql, useLazyQuery } from "@apollo/client";
import donationReceiptsFormatter from "../../helpers/donationReceiptsFormatter";
import Datatable from "../common/datatable";
import React, { useState, useEffect } from "react";
import styles from "../donate/donate.module.scss";
import Text from "../common/text";
import RetailerSelect from "../retailers/retailer-select";
import DonationReceiptStatusSelect from "./donationReceiptStatusSelect";
import NonprofitSelect from "../nonprofits/nonprofit-select";

const ADMIN_GET_DONATION_RECEIPTS = gql`
  query ($input: AdminGetDonationReceiptsInput!) {
    adminGetDonationReceipts(input: $input) { 
      total
      donationReceipts {
        uuid
        status
        startDate
        endDate
        totalItems
        totalFairMarketValue {
          value
          currencyCode
        }
        retailer {
          name
          uuid
        }
        nonprofit {
          organizationName
          uuid
          address {
            countryCode
          }
        }
        fulfillment {
          uuid
        }
        createdAt
      }
      hasMore
    }
  }
`;

const DonationReceiptsList = ({
  title,
  hideRetailerSearch,
  hideNonprofitSearch,
  hideStatusFilter,
  retailerUUID,
  nonprofitUUID,
}) => {
  const [selectOpen, setSelectOpen] = useState(false);
  const [donationReceipts, setDonationReceipts] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(25);
  const [indexFrom, setIndexFrom] = useState(0);
  const [statuses, setStatuses] = useState();
  const [retailer, setRetailer] = useState(null);
  const [nonprofit, setNonprofit] = useState(null);

  const [adminGetDonationReceipts, { loading, error, data }] = useLazyQuery(
    ADMIN_GET_DONATION_RECEIPTS,
    {
      variables: {
        fetchPolicy: "no-cache",
        input: {
          retailerUUID: retailer?.uuid || retailerUUID,
          nonprofitUUID: nonprofit?.uuid || nonprofitUUID,
          statuses: statuses,
          indexFrom: indexFrom,
          limit: limit,
        },
      },
    },
  );

  const onChangePage = (newPage, _) => {
    setIndexFrom((newPage - 1) * limit);
  };

  const onChangeRowsPerPage = (newPerPage, page) => {
    setIndexFrom((page - 1) * newPerPage);
    setLimit(newPerPage);
  };

  const toggleSelect = () => setSelectOpen((val) => !val);

  useEffect(() => {
    if (!loading) {
      if (data && data.adminGetDonationReceipts) {
        setDonationReceipts(
          donationReceiptsFormatter(
            data.adminGetDonationReceipts.donationReceipts,
          ),
        );
        setTotalRows(data.adminGetDonationReceipts.total);
      } else {
        setDonationReceipts([]);
      }
    }
  }, [loading, statuses, retailerUUID, retailer]);

  useEffect(() => {
    if (retailerUUID) {
      setRetailer({ uuid: retailerUUID });
    }
    adminGetDonationReceipts();
  }, [indexFrom, retailerUUID, statuses]);

  return (
    <Card className={styles.donateItemEdit}>
      <CardHeader>
        <h3>{title}</h3>
        <Row>
          {!hideStatusFilter && (
            <Col md="3">
              <DonationReceiptStatusSelect
                isOpen={selectOpen}
                placeholder="Filter By status"
                setValues={setStatuses}
                values={statuses}
                style={{
                  width: "300px",
                  marginLeft: "20px",
                  height: "38px",
                  overflow: "hidden",
                }}
                toggle={toggleSelect}
              />
            </Col>
          )}
          {!hideRetailerSearch && (
            <Col md="3">
              <RetailerSelect
                {...{
                  retailer: {
                    uuid: retailer?.uuid,
                    name: retailer?.name,
                  },
                  style: {
                    width: "300px",
                    marginLeft: "20px",
                    height: "38px",
                    overflow: "hidden",
                  },
                  onSelect: (r) => {
                    setIndexFrom(0);
                    setRetailer(r);
                  },
                }}
              />
            </Col>
          )}
          {!hideNonprofitSearch && (
            <Col md="3">
              <NonprofitSelect
                {...{
                  nonprofit: {
                    uuid: nonprofit?.uuid,
                    name: nonprofit?.name,
                  },
                  style: {
                    width: "300px",
                    marginLeft: "20px",
                    height: "38px",
                    overflow: "hidden",
                  },
                  onSelect: (n) => {
                    setIndexFrom(0);
                    setNonprofit(n);
                  },
                }}
              />
            </Col>
          )}
        </Row>
      </CardHeader>
      <CardBody>
        {error ? (
          <>Some Error Occured! Try Reloading the page.</>
        ) : donationReceipts ? (
          <Datatable
            hideActions={true}
            myData={donationReceipts}
            loading={loading}
            pageSize={limit}
            pagination={true}
            paginationServer={true}
            totalRows={totalRows}
            myClass="-striped -highlight"
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            noHeader
          />
        ) : (
          <Text>No donation receipts</Text>
        )}
      </CardBody>
    </Card>
  );
};

export default DonationReceiptsList;
