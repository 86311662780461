import { gql } from "@apollo/client";
import React, { Fragment } from "react";
import { Link, useHistory } from "react-router-dom";
import Cookies from "universal-cookie";
//images import
import man from "../../../assets/images/dashboard/man.png";
import {
  LIQUID_COOKIE,
  LIQUID_TOKEN,
  LIQUID_USER,
} from "../../../constants/cookie";
import { initializeApollo } from "../../../helpers/apollo";
import { err_msgs } from "src/helpers/errors";

const LOGOUT = gql`
  mutation logout($email: String!) {
    logout(email: $email)
  }
`;

export const logout = async (cb) => {
  const cookie = new Cookies();
  const data = cookie.get(LIQUID_COOKIE);
  const email = data?.user?.email;

  const removeCookie = () => {
    cookie.remove(LIQUID_COOKIE);
    cookie.remove(LIQUID_USER);
    cookie.remove(LIQUID_TOKEN);
  };

  const finallyRun = () => {
    removeCookie();
    cb();
  };

  if (email) {
    const client = initializeApollo();
    try {
      await client.mutate({
        mutation: LOGOUT,
        variables: {
          email: email,
        },
      });
    } catch (err) {
      err_msgs(err);
    }
    finallyRun();
  } else {
    finallyRun();
  }
};

const UserMenu = () => {
  const history = useHistory();

  const handleLogout = () => {
    logout(() => history.push(`${process.env.PUBLIC_URL}`, { logout: true }));
  };

  return (
    <Fragment>
      <li className="onhover-dropdown">
        <div className="media align-items-center">
          <img
            className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded"
            src={man}
            alt="header-user"
          />
          <div className="dotted-animation">
            <span className="animate-circle"></span>
            <span className="main-circle"></span>
          </div>
        </div>
        <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
          <li>
            <Link to={`${process.env.PUBLIC_URL}/settings/profile`}>
              <i data-feather="user"></i>Edit Profile
            </Link>
          </li>
          <li>
            <a href="#javaScript">
              <i data-feather="mail"></i>Inbox
            </a>
          </li>
          <li>
            <a href="#javaScript">
              <i data-feather="lock"></i>Lock Screen
            </a>
          </li>
          <li>
            <a href="#javaScript">
              <i data-feather="settings"></i>Settings
            </a>
          </li>
          <li>
            <p
              style={{ color: "black" }}
              onClick={handleLogout}
            >
              <i data-feather="log-out"></i>Logout
            </p>
          </li>
        </ul>
      </li>
    </Fragment>
  );
};

export default UserMenu;
