import React, { useEffect, useRef, useState } from "react";
import { LIQUID_COOKIE } from "../../constants/cookie";
import Cookies from "universal-cookie";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import { toast } from "react-toastify";
import "@mdxeditor/editor/style.css";
import {
  BoldItalicUnderlineToggles,
  MDXEditor,
  UndoRedo,
  headingsPlugin,
  linkDialogPlugin,
  listsPlugin,
  quotePlugin,
  thematicBreakPlugin,
  toolbarPlugin,
  linkPlugin,
  InsertImage,
  imagePlugin,
  tablePlugin,
  InsertTable,
  diffSourcePlugin,
  DiffSourceToggleWrapper,
  markdownShortcutPlugin,
  BlockTypeSelect,
  InsertThematicBreak,
  ListsToggle,
} from "@mdxeditor/editor";
import { initializeApollo } from "src/helpers/apollo";
import { gql, useMutation } from "@apollo/client";
import { err_toast } from "src/helpers/errors";
import moment from "moment";
import { Copy, Trash2 } from "react-feather";
import styles from "./blogEditor.module.scss";
import ConfirmationModal from "../modals/ConfirmationModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ADMIN_UPLOAD_IMAGES = gql`
  mutation adminUploadImages($data: UploadImagesInput!) {
    adminUploadImages(input: $data) {
      uuid
      resolutions {
        xxl {
          url
        }
      }
    }
  }
`;

const ADMIN_SAVE_BLOG = gql`
    mutation adminSaveBlog($input: BlogInput!) {
      adminSaveBlog(input: $input) {
        uuid
        title
        url
        slug
        updatedAt
        content
      }
    }
`;

const ADMIN_UPDATE_BLOG_STATUS = gql`
    mutation adminUpdateBlogStatus($uuid: String!, $blogStatus: BlogStatus!) {
      adminUpdateBlogStatus(uuid: $uuid, status: $blogStatus) {
        uuid
        title
        url
        slug
        status
        updatedAt
      }
    }
`;

const ADMIN_DELETE_BLOG = gql`
  mutation adminDeleteBlog($uuid: String!) {
    adminDeleteBlog(uuid: $uuid)
  }
`;

const BlogEditor = ({ data }) => {
  // get the authentication token from cookie if it exists
  const cookies = new Cookies();
  const liquidCookie = cookies.get(LIQUID_COOKIE);

  const ref = useRef();
  const [values, setValues] = useState({
    status: "draft",
    authorEmail: liquidCookie?.user?.email,
  });

  const history = useHistory();
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [needsSave, setNeedsSave] = useState(false);
  const [deleteBlogModalOpen, setDeleteBlogModalOpen] = useState(false);
  const [adminDeleteBlog, { loading: deleteBlogLoading }] =
    useMutation(ADMIN_DELETE_BLOG);
  const onConfirmDeleteBlog = () => {
    adminDeleteBlog({
      variables: {
        uuid: values?.uuid,
      },
    })
      .then(() => {
        history.push("/blogs");
      })
      .catch((err) => toast.error(err.message));
  };

  const setItem = (name, value) => {
    setNeedsSave(true);
    setValues((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      setValues({
        uuid: data?.uuid,
        title: data?.title,
        slug: data?.slug,
        description: data?.description,
        authorEmail: data?.author?.email,
        categoryTags:
          data?.categoryTags?.length > 0
            ? data?.categoryTags.sort().join(", ")
            : "",
        seoKeywords:
          data?.seoKeywords?.length > 0
            ? data?.seoKeywords.sort().join(", ")
            : "",
        content: data?.content,
        status: data?.status,
        url: data?.url,
        updatedAt: data?.updatedAt,
        createdAt: data?.createdAt,
        publishedAt: data?.publishedAt,
        images: data?.images,
      });
      setContent(data?.content);
      ref?.current?.setMarkdown(data?.content || "");
    }
  }, [data]);

  const client = initializeApollo();

  const getImageUUIDs = (markdown) => {
    const regex = /https?:\/\/imageproxy\.liquidonate\.com\/[^\s"']+/g;
    const urls = [];
    let match;

    while ((match = regex.exec(markdown)) !== null) {
      urls.push(match[0]);
    }

    return urls.map((u) => {
      let parts = u.split("/");
      parts = parts[parts.length - 1].split(".");
      return parts[0];
    });
  };

  const saveBlog = () => {
    let seoKeywords =
      values?.seoKeywords?.length > 0
        ? [
            ...new Set(
              values?.seoKeywords
                .split(",")
                .map((k) => k?.trim().toLowerCase())
                .sort(),
            ),
          ]
        : null;
    let categoryTags =
      values?.categoryTags?.length > 0
        ? [
            ...new Set(
              values?.categoryTags
                .split(",")
                .map((t) => t?.trim().toLowerCase())
                .sort(),
            ),
          ]
        : null;

    let unpublished = false;
    let status = values?.status;
    const images = getImageUUIDs(content);
    if (images.length == 0 && status === "published") {
      setItem("status", "unpublished");
      status = "unpublished";
      unpublished = true;
    }

    setLoading(true);
    client
      .mutate({
        mutation: ADMIN_SAVE_BLOG,
        variables: {
          input: {
            uuid: values?.uuid,
            title: values?.title?.trim(),
            slug: values?.slug,
            status: status,
            description: values?.description?.trim(),
            authorEmail: values?.authorEmail?.trim(),
            content: content?.trim(),
            categoryTags: categoryTags,
            seoKeywords: seoKeywords,
            images: images.map((i) => ({
              uuid: i,
            })),
          },
        },
      })
      .then((r) => {
        toast.success("Saved blog");
        if (!values?.uuid) {
          setItem("uuid", r?.data?.adminSaveBlog?.uuid);

          setTimeout(() => {
            window.location.replace(
              `/blog/edit/${r?.data?.adminSaveBlog?.uuid}`,
            );
          }, 1000);
        }

        if (unpublished) {
          toast.warning("Blog was unpublished as it does not have any images", {
            autoClose: false,
          });
        }

        setItem("updatedAt", r?.data?.adminSaveBlog?.updatedAt);
        setItem("url", r?.data?.adminSaveBlog?.url);
        setItem("content", r?.data?.adminSaveBlog?.content);
        setItem("images", images);
        setLoading(false);
        setNeedsSave(false);
      })
      .catch((err) => {
        err_toast(err);
        setLoading(false);
      });
  };

  const updateBlogStatus = (status) => {
    if (status === "published") {
      if (!values?.title) {
        toast.error("need a title to publish");
        return;
      }

      if (!values?.description) {
        toast.error("need a description to publish");
        return;
      }

      if (!values?.slug) {
        toast.error("need a slug to publish");
        return;
      }

      if (!values?.content) {
        toast.error("need content to publish");
        return;
      }

      if (values?.seoKeywords?.length == 0) {
        toast.error("need seo keywords to publish");
        return;
      }

      if (values?.categoryTags?.length == 0) {
        toast.error("need blog category tags to publish");
        return;
      }

      if (values?.images?.length == 0) {
        toast.error("blog needs images to publish");
        return;
      }
    }

    setLoading(true);
    client
      .mutate({
        mutation: ADMIN_UPDATE_BLOG_STATUS,
        variables: {
          uuid: values?.uuid,
          blogStatus: status,
        },
      })
      .then((r) => {
        toast.success(`updated blog status to ${status}`);
        setItem("updatedAt", r?.data?.adminUpdateBlogStatus?.updatedAt);
        setItem("publishedAt", r?.data?.adminUpdateBlogStatus?.publishedAt);
        setItem("status", r?.data?.adminUpdateBlogStatus?.status);
        setLoading(false);
        setNeedsSave(false);
      })
      .catch((err) => {
        err_toast(err);
        setLoading(false);
      });
  };

  const imageUploadHandler = async function (image) {
    const response = await client.mutate({
      mutation: ADMIN_UPLOAD_IMAGES,
      variables: {
        data: {
          images: [image],
        },
      },
    });

    return response.data.adminUploadImages[0].resolutions.xxl.url;
  };

  const getSlug = (t) => {
    return t
      ?.toLowerCase() // Convert to lowercase
      .replace(/[^-a-z0-9\s]/g, "") // Remove special characters
      .trim() // Trim leading and trailing spaces
      .replace(/\s+/g, "-"); // Replace spaces with dashes
  };

  return (
    <Card>
      <CardHeader>
        <Row className="mb-3">
          <Col md="6">
            <>
              {values?.title && (
                <h3 style={{ color: "black" }}>{values?.title}</h3>
              )}

              {values?.url && (
                <h4 style={{ textTransform: "lowercase" }}>
                  <a
                    href={values?.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {values?.url}{" "}
                  </a>
                  <Copy
                    style={{ cursor: "pointer", color: "tomato" }}
                    onClick={() => {
                      navigator.clipboard.writeText(values?.url);
                      toast.success(`Copied Blog Link`);
                    }}
                  />
                </h4>
              )}
              <Badge
                color={
                  values?.status === "published"
                    ? "success"
                    : values?.status === "unpublished"
                    ? "warning"
                    : "info"
                }
              >
                <h5>{values?.status}</h5>
              </Badge>
              {values?.publishedAt ? (
                <span>
                  {" "}
                  {moment(values?.publishedAt).format("YYYY-MM-DD HH:mm:ss")}
                </span>
              ) : (
                <></>
              )}
            </>
            <hr />
          </Col>
          <Col
            md="6"
            className="d-flex justify-content-end"
          >
            <span>
              Created: {moment(values?.createdAt).format("YYYY-MM-DD HH:mm:ss")}
              <br />
              Updated: {moment(values?.updatedAt).format("YYYY-MM-DD HH:mm:ss")}
            </span>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col
            md="12"
            className="d-flex justify-content-end"
          >
            {(values?.status === "draft" ||
              values?.status === "unpublished") && (
              <>
                <Button
                  style={{
                    float: "inline-start",
                    height: "38px",
                  }}
                  className="btn btn-info mr-2"
                  onClick={() => {
                    updateBlogStatus("published");
                  }}
                  disabled={loading || needsSave}
                  title={needsSave ? "Save before publishing" : ""}
                >
                  {loading ? <Spinner /> : "Publish"}
                </Button>
                <Button
                  className="mr-2"
                  color="danger"
                  onClick={() => setDeleteBlogModalOpen(true)}
                >
                  <Trash2 height="15px" />
                </Button>

                <ConfirmationModal
                  open={deleteBlogModalOpen}
                  onClose={() => setDeleteBlogModalOpen(false)}
                  title={"Delete blog?"}
                  desc={
                    <>
                      Are you sure you want to delete blog with title:
                      <br />
                      <b>{values?.title}</b>
                    </>
                  }
                  onConfirm={onConfirmDeleteBlog}
                  loading={deleteBlogLoading}
                />
              </>
            )}
            {values?.status === "published" && (
              <Button
                style={{
                  float: "inline-start",
                  height: "38px",
                }}
                className="btn btn-warning mr-2"
                onClick={() => {
                  updateBlogStatus("unpublished");
                }}
                disabled={loading}
              >
                {loading ? <Spinner /> : "Unpublish"}
              </Button>
            )}
            <Button
              style={{
                float: "inline-start",
                height: "38px",
              }}
              className="btn btn-success mr-2"
              onClick={saveBlog}
              disabled={loading}
            >
              {loading ? <Spinner /> : "Save"}
            </Button>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md="6">
            <h4>
              Title<span style={{ color: "red" }}>*</span>
            </h4>
            <Input
              name="title"
              type="text"
              value={values.title}
              onChange={(e) => {
                setItem("title", e.target.value);
                if (e.target.value) {
                  setItem("slug", getSlug(e.target.value));
                }
              }}
            />
          </Col>
          <Col md="6">
            <h4>
              Slug<span style={{ color: "red" }}>*</span>
            </h4>
            <Input
              disabled={true}
              name="slug"
              type="text"
              value={values.slug}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md="12">
            <h4>
              Description<span style={{ color: "red" }}>*</span>
            </h4>
            <Input
              name="description"
              type="textarea"
              value={values.description}
              onChange={(e) => setItem("description", e.target.value)}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md="6">
            <h4>
              SEO Keywords<span style={{ color: "red" }}>*</span> &nbsp;
            </h4>
            <span>(Comma separated words or phrases. Limit to 5.)</span>
            <Input
              name="seoKeywords"
              type="text"
              value={values.seoKeywords}
              onChange={(e) => setItem("seoKeywords", e.target.value)}
            />
          </Col>
          <Col md="6">
            <h4>
              Blog Category Tags<span style={{ color: "red" }}>*</span> &nbsp;
            </h4>
            <span>(Comma separated words or phrases. Limit to 2.)</span>
            <Input
              name="categoryTags"
              type="text"
              value={values.categoryTags}
              onChange={(e) => setItem("categoryTags", e.target.value)}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md="6">
            <h4>
              Author Email<span style={{ color: "red" }}>*</span> &nbsp;
            </h4>
            <Input
              name="authorEmail"
              type="text"
              value={values.authorEmail}
              onChange={(e) => setItem("authorEmail", e.target.value)}
            />
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col>
            <div style={{ border: "1px solid lightgray" }}>
              <MDXEditor
                ref={ref}
                contentEditableClassName={styles.blogEditor}
                markdown={content || ""}
                plugins={[
                  headingsPlugin(),
                  listsPlugin(),
                  quotePlugin(),
                  thematicBreakPlugin(),
                  linkPlugin(),
                  linkDialogPlugin(),
                  imagePlugin({ imageUploadHandler }),
                  tablePlugin(),
                  quotePlugin(),
                  markdownShortcutPlugin(),
                  diffSourcePlugin({
                    diffMarkdown: values?.content || "",
                    viewMode: "rich-text",
                  }),
                  toolbarPlugin({
                    toolbarContents: () => (
                      <>
                        <BoldItalicUnderlineToggles />
                        <BlockTypeSelect />
                        <ListsToggle />
                        <InsertThematicBreak />
                        <InsertImage />
                        <InsertTable />

                        <DiffSourceToggleWrapper>
                          <UndoRedo />
                        </DiffSourceToggleWrapper>
                      </>
                    ),
                  }),
                ]}
                onChange={(e) => {
                  setNeedsSave(e !== values?.content);
                  setContent(e);
                }}
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col
            md="12"
            className="d-flex justify-content-end"
          >
            <Button
              style={{
                float: "inline-start",
                height: "38px",
              }}
              className="btn btn-success mr-2"
              onClick={saveBlog}
              disabled={loading}
            >
              {loading ? <Spinner /> : "Save"}
            </Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default BlogEditor;
