import React, { useState } from "react";
import { Spinner, Button } from "reactstrap";

const LoadingButton = ({ text, onClick, type, color }) => {
  const [loading, setLoading] = useState(false);
  const onClickBtn = () => {
    setLoading(true);
    onClick().finally(() => setLoading(false));
  };
  return (
    <>
      <Button
        color={color}
        type={type}
        onClick={onClickBtn}
      >
        {loading ? <Spinner /> : text}
      </Button>
    </>
  );
};

export default LoadingButton;
