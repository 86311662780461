import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import styles from "../donate/donate.module.scss";
import { ChevronDown } from "react-feather";
import { gql, useQuery } from "@apollo/client";

const GET_UNIT_TYPES = gql`
  query {
    getUnitTypes {
      type
      label
    }
  }
`;

const UnitTypeDropdown = ({ initialUnitType, onSelect }) => {
  let { data } = useQuery(GET_UNIT_TYPES);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedUnitType, setSelectedUnitType] = useState("");
  const selectUnitType = (unitType) => {
    setSelectedUnitType(unitType);
    if (onSelect) onSelect(unitType);
  };

  useEffect(() => {
    if (initialUnitType) setSelectedUnitType(initialUnitType);
  }, [initialUnitType]);

  return (
    <>
      <Dropdown
        required={true}
        isOpen={isDropdownOpen}
        toggle={(_) => {
          setIsDropdownOpen((prev) => !prev);
        }}
      >
        <DropdownToggle className={styles.dropdown}>
          {data?.getUnitTypes?.find((u) => u.type === selectedUnitType)
            ?.label ||
            selectedUnitType ||
            "None"}
          <ChevronDown />
        </DropdownToggle>
        <DropdownMenu>
          {data?.getUnitTypes?.map((u) => (
            <DropdownItem
              key={u.type}
              onClick={(_) => {
                selectUnitType(u.type);
              }}
            >
              {u.label}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default UnitTypeDropdown;
