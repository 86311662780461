import { useEffect, useState } from "react";
import phoneFormatter from "phone-formatter";

// XXX this should probably be bigtime deprecated
function useFormattedPhone(value) {
  const [phone, setPhone] = useState("");

  useEffect(() => {
    if (value) {
      setPhone(value);
    }
  }, [value]);

  const [rawPhone, setRawPhone] = useState("");

  useEffect(() => {
    if (phone) {
      let ph = phone;

      if (ph.startsWith("+1")) {
        ph = ph.substring(2, ph.length);
      }

      ph = ph.replace(/\D+/g, "");
      if (ph.length > 10) {
        ph = rawPhone;
      } else {
        setRawPhone(ph);
      }

      setPhone(
        phoneFormatter.format(ph, "(NNN) NNN-NNNN").replace(/\D+$/g, ""),
      );
    }
  }, [phone, rawPhone]);

  return [phone, setPhone];
}

export default useFormattedPhone;
