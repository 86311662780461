import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import {
  CancelledFulfillmentStatuses,
  FulfilledFulfillmentStatuses,
  OnGoingFulfillmentStatuses,
} from "../constants/fulfillmentStatus";
import userDetailsFormatter from "./userDetailsFormatter";
import itemsFormatter from "./itemsFormatter";
import { truncate } from "./utils";

const getStatusBadge = (status) => {
  if (OnGoingFulfillmentStatuses.includes(status)) {
    return "badge-secondary";
  }

  if (FulfilledFulfillmentStatuses.includes(status)) {
    return "badge-success";
  }

  if (CancelledFulfillmentStatuses.includes(status)) {
    return "badge-danger";
  }

  return "badge-warning";
};

const fulfillmentsFormatter = (fulfillments) => {
  if (!fulfillments.length) {
    return;
  }

  const formattedFulfillments = fulfillments.map((f) => {
    return {
      createdDate: (
        <Link
          key={f.uuid}
          target="_blank"
          to={`/fulfillment/${f.uuid}`}
        >
          {moment(f.createdDate).format("DD MMM, YYYY hh:mm:SS A")}
        </Link>
      ),
      shipping:
        f?.priceInfo?.lineItems?.filter((li) => li.type == "shipping")[0]
          ?.amount.value || "-",
      delivery:
        f?.priceInfo?.lineItems?.filter((li) => li.type == "delivery")[0]
          ?.amount.value || "-",
      items: itemsFormatter(f.items),
      type: (
        <div key={f.uuid}>
          {f.type}
          &nbsp;&nbsp;
          <span className={`badge ${getStatusBadge(f.status)}`}>
            {f.status}
          </span>
        </div>
      ),
      recipient: userDetailsFormatter(f.order?.recipient),
      donor: userDetailsFormatter(f.donor, f.donorRetailer),
      order: f.order && (
        <Link
          key={f.order.uuid}
          target="_blank"
          to={`/order/${f.order?.uuid}`}
        >
          {truncate(f.order.uuid, 8)}
        </Link>
      ),
    };
  });
  return formattedFulfillments;
};

export default fulfillmentsFormatter;
