import React, { useEffect, useState } from "react";
import MagicMatchesSignUpForm from "./MagicMatchesSignup.jsx";
import { gql } from "@apollo/client";
import { Container, Card, CardBody, Row, Col, Button } from "reactstrap";
import Switch from "react-switch";
import NumberOfItemsPerMonth from "./NumberOfItemsPerMonth.jsx";
import { initializeApollo } from "src/helpers/apollo.js";
import ConfirmationModal from "../modals/ConfirmationModal.jsx";
import { toast } from "react-toastify";

const UPDATE_MAGIC_MATCHES_PREFERENCES = gql`
  mutation adminUpdateMagicMatchesPreferences($input: UpdateMagicMatchesPreferencesInput!) {
    adminUpdateMagicMatchesPreferences(input: $input) {
      enabled
      numberOfItemsPerMonth
      agreeResaleRestriction
      createdAt
      updatedAt
      uuid
    }
  }
`;

const MagicMatchesPreferences = ({ nonprofit }) => {
  const [magicMatchesPreferences, setMagicMatchesPreferences] = useState();
  const [updatingPreferences, setUpdatingPreferences] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [agreeResaleRestriction, setAgreeResaleRestriction] = useState(false);
  const [
    openResaleRestrictionConfirmationModal,
    setOpenResaleRestrictionConfirmationModal,
  ] = useState(false);
  const [numberOfItems, setNumberOfItems] = useState(50);

  const client = initializeApollo();
  const updateMagicMatchesPreferences = (vals) => {
    client
      .mutate({
        mutation: UPDATE_MAGIC_MATCHES_PREFERENCES,
        variables: {
          input: {
            enabled: vals?.enabled,
            numberOfItemsPerMonth: vals?.numberOfItemsPerMonth,
            agreeResaleRestriction: vals?.agreeResaleRestriction,
            nonprofitUUID: nonprofit?.uuid,
          },
        },
      })
      .then((r) => {
        setMagicMatchesPreferences(r?.data?.adminUpdateMagicMatchesPreferences);
        setEnabled(r?.data?.adminUpdateMagicMatchesPreferences?.enabled);
        setNumberOfItems(
          r?.data?.adminUpdateMagicMatchesPreferences?.numberOfItemsPerMonth,
        );
        setAgreeResaleRestriction(
          r?.data?.adminUpdateMagicMatchesPreferences?.agreeResaleRestriction,
        );
        toast.success("Successfully updated magic matches preferences");
        setUpdatingPreferences(false);
        setOpenConfirmationModal(false);
      })
      .catch((e) => {
        toast.error(e);
      });
  };

  useEffect(() => {
    setMagicMatchesPreferences(nonprofit?.magicMatchesPreferences);
    setEnabled(nonprofit?.magicMatchesPreferences?.enabled);
    setNumberOfItems(nonprofit?.magicMatchesPreferences?.numberOfItemsPerMonth);
    setAgreeResaleRestriction(
      nonprofit?.magicMatchesPreferences?.agreeResaleRestriction,
    );
  }, [nonprofit]);

  const getConfirmationTitle = () => {
    return magicMatchesPreferences?.enabled
      ? "Opt-out of Magic Matches"
      : "Opt-in to Magic Matches";
  };

  const getConfirmationDesc = () => {
    return magicMatchesPreferences?.enabled
      ? "Are you sure you want to opt out of Magic Matches?"
      : "Are you sure you want to opt in to Magic Matches?";
  };

  const getResaleRestrictionConfirmationTitle = () => {
    return agreeResaleRestriction
      ? "Opt-out of Magic Match Resale Restriction"
      : "Opt-in to Magic Match Resale Restriction";
  };

  const getResaleRestrictionConfirmationDesc = () => {
    return agreeResaleRestriction ? (
      "Are you sure you want to opt out of Magic Match Resale Restriction?"
    ) : (
      <>
        Please agree to the following regarding resale restrictions on Magic
        Match donations:
        <br />
        <br />
        <p>
          It is understood and agreed that Nonprofit will use the Property in
          furtherance of its mission and for religious, charitable, scientific,
          literary or educational purposes within the meaning of Section
          501(c)(3) of the Code.In connection therewith, Nonprofit may
          redistribute all or a portion of the Property to the individuals or
          entities it serves; provided, however, that Nonprofit shall not sell,
          redistribute or otherwise dispose of the Property in return for
          compensation.
        </p>
        <p>
          Info: If you do not agree, we will still match you with donations that
          do not have a resale restriction!
        </p>
      </>
    );
  };

  return (
    <>
      {!magicMatchesPreferences && (
        <>
          <h4>Magic Matches is disabled for {nonprofit?.organizationName}</h4>
          <MagicMatchesSignUpForm onSubmit={updateMagicMatchesPreferences} />
        </>
      )}
      {magicMatchesPreferences && (
        <Container>
          <Card
            className="my-4"
            style={{ borderRadius: "0.5rem", padding: "1rem" }}
          >
            <CardBody>
              <Row>
                <Col
                  xs="12"
                  md="8"
                >
                  <h5
                    className="py-2"
                    style={{ color: enabled ? "black" : "gray" }}
                  >
                    Magic Matches is {enabled ? "enabled" : "disabled"} for{" "}
                    {nonprofit?.organizationName}
                  </h5>
                </Col>
                <Col
                  xs="12"
                  md="4"
                  className="d-flex justify-content-end"
                >
                  <Switch
                    checked={enabled}
                    onChange={() => setOpenConfirmationModal(true)}
                    onColor="#cee0e1"
                    onHandleColor="#0b6568"
                    handleDiameter={30}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={25}
                    width={60}
                    id="magic-matches-enabled-switch"
                  />
                  <ConfirmationModal
                    loading={updatingPreferences}
                    title={getConfirmationTitle()}
                    desc={getConfirmationDesc()}
                    open={!!openConfirmationModal}
                    onClose={() => setOpenConfirmationModal(false)}
                    onConfirm={() => {
                      setUpdatingPreferences(true);
                      updateMagicMatchesPreferences({
                        ...magicMatchesPreferences,
                        enabled: !enabled,
                      });
                    }}
                  />
                </Col>
              </Row>
              {enabled && (
                <>
                  <Row className="mt-5">
                    <Col className="text-center justify-items-center">
                      <NumberOfItemsPerMonth
                        numberOfItems={numberOfItems}
                        setNumberOfItems={setNumberOfItems}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col
                      xs="12"
                      md="8"
                    >
                      <h5
                        className="py-2"
                        style={{
                          color: agreeResaleRestriction ? "black" : "gray",
                        }}
                      >
                        {agreeResaleRestriction
                          ? "You are receiving items with resale restriction."
                          : "You are not receiving items with resale restrictions."}
                      </h5>
                    </Col>
                    <Col
                      xs="12"
                      md="4"
                      className="d-flex justify-content-end"
                    >
                      <Switch
                        checked={agreeResaleRestriction}
                        onChange={() =>
                          setOpenResaleRestrictionConfirmationModal(true)
                        }
                        onColor="#cee0e1"
                        onHandleColor="#0b6568"
                        handleDiameter={30}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={25}
                        width={60}
                        id="agree-resale-restriction-switch"
                      />
                      <ConfirmationModal
                        loading={updatingPreferences}
                        title={getResaleRestrictionConfirmationTitle()}
                        desc={getResaleRestrictionConfirmationDesc()}
                        open={!!openResaleRestrictionConfirmationModal}
                        onClose={() =>
                          setOpenResaleRestrictionConfirmationModal(false)
                        }
                        onConfirm={() => {
                          setAgreeResaleRestriction(!agreeResaleRestriction);
                          setOpenResaleRestrictionConfirmationModal(false);
                        }}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button
                        color="primary"
                        onClick={() => {
                          setUpdatingPreferences(true);
                          updateMagicMatchesPreferences(
                            {
                              ...magicMatchesPreferences,
                              numberOfItemsPerMonth: numberOfItems,
                              agreeResaleRestriction: agreeResaleRestriction,
                              enabled: enabled,
                            },
                            () => {
                              setMagicMatchesPreferences((p) => ({
                                ...p,
                                numberOfItemsPerMonth: numberOfItems,
                                agreeResaleRestriction: agreeResaleRestriction,
                              }));
                              setUpdatingPreferences(false);
                              toast.success("Updated Magic Match preferences");
                            },
                          );
                        }}
                        disabled={
                          numberOfItems ===
                            magicMatchesPreferences?.numberOfItemsPerMonth &&
                          agreeResaleRestriction ===
                            magicMatchesPreferences?.agreeResaleRestriction
                        }
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </CardBody>
          </Card>
        </Container>
      )}
    </>
  );
};

export default MagicMatchesPreferences;
