import { Card, CardBody, CardHeader } from "reactstrap";
import React, { useEffect, useState } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import Datatable from "../common/datatable";
import Text from "../common/text";
import fulfillmentsFormatter from "../../helpers/fulfillmentsFormatter";

const ADMIN_GET_FULFILLMENTS = gql`
	query adminGetFulfillments($input: AdminGetFulfillmentsInput!) {
		adminGetFulfillments(input: $input) {
      total
      fulfillments {
        uuid
        type
        createdDate
        status
        priceInfo {
          lineItems {
            type
            label
            amount {
              value
              currencyCode
            }
          }
        }
        donor {
          user {
            uuid
            firstName
            lastName
          }
          retailer {
            name
            uuid
          }
          nonprofit {
            organizationName
            uuid
          }
        }
        donorRetailer {
          uuid
          name
        }
        items {
          uuid
          product {
            uuid
            slug
            title
            images {
              uuid
              resolutions {
                xs {
                  url
                }
              }
            }
          }
        }
        pickupLocation {
          address {
            city
            state
            zip
            countryCode
          }
        }
        recipientLocation {
          address {
            city
            state
            zip
            countryCode
          }
        }
        distance
        order {
          uuid
          recipient {
            user {
              uuid
              firstName
              lastName
            }
            nonprofit {
              organizationName
              uuid
            }
            retailer {
              name
              uuid
            }
          }
        }
      }
		}
	}
`;

const Fulfillments = ({ title, retailerUUID, productUUID }) => {
  const [fulfillments, setFulfillments] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const [indexFrom, setIndexFrom] = useState(0);

  const [adminGetFulfillments, { loading, error, data }] = useLazyQuery(
    ADMIN_GET_FULFILLMENTS,
    {
      fetchPolicy: "no-cache",
      variables: {
        input: {
          retailerUUID: retailerUUID,
          productUUID: productUUID,
          limit: limit,
          indexFrom: indexFrom,
        },
      },
    },
  );

  const onChangePage = (newPage, _) => {
    setIndexFrom((newPage - 1) * limit);
  };

  const onChangeRowsPerPage = (newPerPage, page) => {
    setIndexFrom((page - 1) * newPerPage);
    setLimit(newPerPage);
  };

  useEffect(() => {
    if (!loading) {
      if (data && data.adminGetFulfillments) {
        setFulfillments(
          fulfillmentsFormatter(data.adminGetFulfillments?.fulfillments),
        );
        setTotalRows(data?.adminGetFulfillments.total);
      } else {
        setFulfillments([]);
        setTotalRows(0);
      }
    }
  }, [loading, retailerUUID, productUUID]);

  useEffect(() => {
    if (!retailerUUID && !productUUID) {
      return;
    }
    adminGetFulfillments();
  }, [indexFrom, retailerUUID, productUUID]);

  return (
    <Card>
      <CardHeader>
        <h3>{title}</h3>
      </CardHeader>
      <CardBody>
        {error ? (
          <>Some Error Occured! Try Reloading the page.</>
        ) : fulfillments ? (
          <Datatable
            hideActions={true}
            myData={fulfillments}
            loading={loading}
            pageSize={limit}
            pagination={true}
            paginationServer={true}
            totalRows={totalRows}
            myClass="-striped -highlight"
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            noHeader
          />
        ) : (
          <Text>No fulfillments</Text>
        )}
      </CardBody>
    </Card>
  );
};

export default Fulfillments;
