import Breadcrumb from "../common/breadcrumb";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Container,
  Spinner,
} from "reactstrap";
import React, { Fragment, useEffect, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";
import Bold from "../common/bold";
import Text from "../common/text";
import { initializeApollo } from "../../helpers/apollo";
import {
  formatAddress,
  copyAddress,
  getGoogleMapsAddress,
} from "../../helpers/address";
import {
  Edit,
  Copy,
  User,
  ShoppingCart,
  Truck,
  Box,
  ExternalLink,
  Zap,
  LifeBuoy,
  DollarSign,
  CreditCard,
  Book,
  List,
  Trash2,
  PenTool,
} from "react-feather";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import Fulfillments from "../fulfillment/fulfillments";
import ProductsList from "../products/physical/product-list";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import UserList from "../users/user-list";
import usersFormatter from "../../helpers/usersFormatter";
import AssignUser from "../users/assign-user";
import StoreList from "../stores/store-list";
import storesFormatter from "../../helpers/storesFormatter";
import MatchesList from "../matches/matchesList";
import NonprofitList from "../nonprofits/NonprofitList";
import NonprofitSelect from "../nonprofits/nonprofit-select";
import Pricing from "../pricing";
import BankAccounts from "../banking/bankAccounts";
import InvoiceList from "../invoices/invoice-list";
import TransactionList from "../transactions/transaction-list";
import ConfirmationModal from "../modals/ConfirmationModal";
import DonationReceiptsList from "../donationReceipts/donation-receipts-list";

const ADMIN_ADD_SELECT_NONPROFITS = gql`
  mutation adminAddSelectNonprofits($data: AdminAddSelectNonprofitsInput) {
    adminAddSelectNonprofits(input: $data) {
      msg
    }
  }
`;

const ADMIN_REMOVE_SELECT_NONPROFITS = gql`
  mutation adminRemoveSelectNonprofits($data: AdminRemoveSelectNonprofitsInput) {
    adminRemoveSelectNonprofits(input: $data) {
      msg
    }
  }
`;

const ADD_RETAIL_USER = gql`
  mutation adminAddRetailUser($data: AdminAddRetailUserInput!) {
    adminAddRetailUser(input: $data)
  }
`;

const GET_RETAILER = gql`
  query ($input: GetRetailerInput!) {
    adminGetRetailer(input: $input) {
      uuid
      name
      businessPhoneNumber
      businessAddress {
          streetAddress1
          streetAddress2
          streetAddress3
          streetAddress4
          city
          state
          zip
          countryCode
        }
      activePricing {
        uuid
      }
      website
      description
      enableResaleRestriction
      focusAreas
      populationsServed
      shopifyShopID
      createdDate
      billingType
      billingEmail
      stores {
        uuid
        name
        description
        businessPhoneNumber
        businessAddress {
          streetAddress1
          streetAddress2
          streetAddress3
          streetAddress4
          city
          state
          zip
          countryCode
        }
      }
      users {
        uuid
        firstName
        lastName
        email
        roles
      }
    }
  }
`;

const ADMIN_DELETE_RETAILER = gql`
mutation adminDeleteRetailer($input: AdminDeleteRetailerInput!) {
  adminDeleteRetailer(input: $input) 
}
`;

const TABS = [
  {
    icon: <Book className="mr-2" />,
    title: "Invoices",
  },
  {
    icon: <PenTool className="mr-2" />,
    title: "Donation Receipts",
  },
  {
    icon: <List className="mr-2" />,
    title: "Transactions",
  },
  {
    icon: <DollarSign className="mr-2" />,
    title: "Pricing",
  },
  {
    icon: <CreditCard className="mr-2" />,
    title: "Bank Accounts",
  },
  {
    icon: <User className="mr-2" />,
    title: "Users",
  },
  {
    icon: <ShoppingCart className="mr-2" />,
    title: "Locations",
  },
  {
    icon: <Truck className="mr-2" />,
    title: "Fulfillments",
  },
  {
    icon: <Box className="mr-2" />,
    title: "Donated Products",
  },
  {
    icon: <Zap className="mr-2" />,
    title: "Matches",
  },
  {
    icon: <LifeBuoy className="mr-2" />,
    title: "Select Nonprofits",
  },
];

const RetailerDetails = () => {
  const { uuid } = useParams();

  const history = useHistory();
  const [retailer, setRetailer] = useState({});
  const [nonprofit, setNonprofit] = useState({});
  const [nonprofitUUIDs, setNonprofitUUIDs] = useState([]);
  const [addLoading, setAddLoading] = useState(false);
  const [addSelectNonprofitLoading, setAddSelectNonprofitLoading] =
    useState(false);
  const { loading, data, refetch } = useQuery(GET_RETAILER, {
    variables: {
      input: {
        uuid: uuid,
      },
    },
  });

  const editRetailer = () => {
    history.push("/retailer/details/" + uuid + "/edit");
  };

  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  const onChangeUser = (e) => {
    if (e && e.target) {
      const { name, value } = e.target;
      user[name] = value;
    } else {
      user["role"] = e[0].value;
    }
    setUser({ ...user });
  };

  const handleAddUser = () => {
    setAddLoading(true);
    let client = initializeApollo();
    client
      .mutate({
        mutation: ADD_RETAIL_USER,
        variables: { data: { ...user, retailerUUID: retailer.uuid } },
      })
      .then(() => {
        toast.success("User added successfully!");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        toast.error(err.message || "something might went wrong!");
      })
      .finally(() => {
        setAddLoading(false);
      });
  };

  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(retailer?.uuid);
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const onChangeNonprofitUUIDs = (e) => {
    const value = e.target.value;
    setNonprofitUUIDs(value.split("\n").filter((v) => !!v));
  };

  const [deleteRetailerModalOpen, setDeleteRetailerModalOpen] = useState(false);
  const [adminDeleteRetailer, { loading: deleteRetailerLoading }] = useMutation(
    ADMIN_DELETE_RETAILER,
  );
  const onConfirmDeleteRetailer = () => {
    adminDeleteRetailer({
      variables: {
        input: {
          uuid: retailer.uuid,
        },
      },
    })
      .then(() => {
        history.push("/retailers");
      })
      .catch((err) => toast.error(err.message));
  };

  useEffect(() => {
    if (!loading) {
      if (data && data.adminGetRetailer) {
        setRetailer(data.adminGetRetailer);
      } else {
        setRetailer({});
      }
    }
  }, [loading]);

  useEffect(() => {
    refetch();
  }, []);

  const addSelectNonprofits = () => {
    setAddSelectNonprofitLoading(true);
    let client = initializeApollo();
    client
      .mutate({
        mutation: ADMIN_ADD_SELECT_NONPROFITS,
        variables: {
          data: {
            retailerUUID: retailer.uuid,
            nonprofitUUIDs: nonprofitUUIDs,
          },
        },
      })
      .then(() => {
        toast.success("Nonprofits selected successfully!");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        let msgs = err.graphQLErrors
          .map((e) => e.message + "\n" + e?.extensions?.cause)
          .join("\n");
        toast.error(msgs || "something went wrong!");
      })
      .finally(() => {
        setAddSelectNonprofitLoading(false);
        setNonprofitUUIDs([]);
      });
  };

  const removeSelectNonprofits = (nonprofitUUIDs) => {
    let client = initializeApollo();
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: ADMIN_REMOVE_SELECT_NONPROFITS,
          variables: {
            data: {
              retailerUUID: retailer.uuid,
              nonprofitUUIDs: nonprofitUUIDs,
            },
          },
        })
        .then((r) => {
          toast.success("Nonprofits deleted successfully");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
          resolve(r);
        })
        .catch((err) => {
          let msgs = err.graphQLErrors
            .map((e) => e.message + "\n" + e?.extensions?.cause)
            .join("\n");
          toast.error(msgs || "something went wrong!");
          reject(err);
        });
    });
  };

  return (
    <Fragment>
      <Breadcrumb
        title="Retailer Details"
        parent="Dashboard"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col
                    className="product-title"
                    sm={"12"}
                    md={"6"}
                  >
                    <h2>{retailer.name}</h2>
                    <h6>
                      {retailer.uuid}
                      <Copy
                        size={18}
                        className={`Copy ${
                          isCopied ? "copied" : ""
                        } cursor-pointer`}
                        onClick={handleCopy}
                      ></Copy>
                    </h6>
                  </Col>

                  <Col
                    sm={"12"}
                    md={"6"}
                    className="d-flex justify-content-end align-items-start"
                  >
                    <Button
                      style={{
                        float: "inline-start",
                        height: "38px",
                      }}
                      className="btn btn-info mr-2"
                      onClick={editRetailer}
                    >
                      <Edit size={20} />
                    </Button>
                    <Button
                      className="btn btn-info mr-2"
                      onClick={() =>
                        history.push("/retailers/" + uuid + "/store/create")
                      }
                    >
                      Create store
                    </Button>

                    <Button
                      color="danger"
                      onClick={() => setDeleteRetailerModalOpen(true)}
                    >
                      <Trash2 /> Delete Retailer
                    </Button>

                    <ConfirmationModal
                      open={deleteRetailerModalOpen}
                      onClose={() => setDeleteRetailerModalOpen(false)}
                      title={"Delete retailer?"}
                      desc={`Are you sure you want to delete ${retailer?.name}?`}
                      onConfirm={onConfirmDeleteRetailer}
                      loading={deleteRetailerLoading}
                    />
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {loading ? (
                  <div className="text-center my-5">
                    <Spinner />
                  </div>
                ) : (
                  <Row>
                    <Col
                      xs="12"
                      md="6"
                    >
                      <Bold>Name</Bold>
                      <Text>{retailer.name}</Text>
                    </Col>
                    <Col
                      xs="12"
                      md="6"
                    >
                      <Bold>Website</Bold>
                      <Text>{retailer.website}</Text>
                    </Col>
                    <Col
                      xs="12"
                      md="6"
                    >
                      <Bold>Business Phone Number</Bold>
                      <Text>{retailer.businessPhoneNumber}</Text>
                    </Col>
                    <Col
                      xs="12"
                      md="6"
                    >
                      <Bold>Business Address</Bold>
                      <Text>
                        {formatAddress(retailer?.businessAddress)} &nbsp;&nbsp;
                        <a href="#">
                          <Copy
                            onClick={() =>
                              copyAddress(retailer?.businessAddress)
                            }
                          />
                        </a>
                        {"  "}
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={getGoogleMapsAddress(retailer?.businessAddress)}
                        >
                          <ExternalLink />
                        </a>
                      </Text>
                    </Col>
                    <Col
                      xs="12"
                      md="6"
                    >
                      <Bold>Description</Bold>
                      <Text>{retailer.description}</Text>
                    </Col>
                    <Col
                      xs="12"
                      md="6"
                    >
                      <Bold>Shopify Shop ID</Bold>
                      <Text>{retailer.shopifyShopID}</Text>
                    </Col>
                    <Col
                      xs="12"
                      md="6"
                    >
                      <Bold>Billing Email</Bold>
                      <Text>{retailer.billingEmail}</Text>
                    </Col>
                    <Col
                      xs="12"
                      md="6"
                    >
                      <Bold>Billing Type</Bold>
                      <Text>{retailer.billingType}</Text>
                    </Col>
                    <Col
                      xs="12"
                      md="6"
                    >
                      <Bold>Focus Areas</Bold>
                      <Text>
                        {retailer?.focusAreas?.length
                          ? retailer?.focusAreas.join(", ")
                          : "N/A"}
                      </Text>
                    </Col>
                    <Col
                      xs="12"
                      md="6"
                    >
                      <Bold>Populations Served</Bold>
                      <Text>
                        {retailer?.populationsServed?.length
                          ? retailer?.populationsServed.join(", ")
                          : "N/A"}
                      </Text>
                    </Col>
                    <Col
                      xs="12"
                      md="6"
                    >
                      <Bold>Resale Restriction</Bold>
                      <Text>
                        {retailer.enableResaleRestriction
                          ? "Enabled"
                          : "Disabled"}
                      </Text>
                    </Col>
                    <Col
                      xs="12"
                      md="6"
                    >
                      <Bold>Date Created</Bold>
                      <Text>
                        {moment(retailer.createdDate).format(
                          "DD MMM, YYYY hh:mm:SS A",
                        )}
                      </Text>
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col>
            <Card>
              <CardBody>
                <Tabs>
                  <TabList className="nav nav-tabs tab-coupon">
                    {TABS.map(({ icon, title }, i) => (
                      <Tab
                        className="nav-link d-flex align-items-center"
                        key={i}
                      >
                        {icon} {title}
                      </Tab>
                    ))}
                  </TabList>
                  <TabPanel>
                    {Object.keys(retailer).length > 0 && (
                      <InvoiceList
                        retailerUUID={retailer?.uuid}
                        hideRetailerSearch={true}
                      />
                    )}
                  </TabPanel>
                  <TabPanel>
                    <DonationReceiptsList
                      retailerUUID={retailer?.uuid}
                      hideNonprofitSearch={true}
                      hideRetailerSearch={true}
                      hideStatusFilter={true}
                    />
                  </TabPanel>
                  <TabPanel>
                    <TransactionList
                      retailerUUID={retailer?.uuid}
                      hideRetailerSearch={true}
                    />
                  </TabPanel>
                  <TabPanel>
                    <Pricing retailer={retailer} />
                  </TabPanel>
                  <TabPanel>
                    <BankAccounts retailer={retailer} />
                  </TabPanel>
                  <TabPanel>
                    <AssignUser
                      isRetailer={true}
                      onChange={onChangeUser}
                      onSubmit={handleAddUser}
                      user={user}
                      loading={addLoading}
                    />
                    <hr />
                    <Row>
                      <Col
                        xs="12"
                        className="mt-2"
                      >
                        <UserList
                          users={usersFormatter(retailer.users)}
                          loading={loading}
                        />
                      </Col>
                    </Row>
                  </TabPanel>
                  <TabPanel>
                    <StoreList stores={storesFormatter(retailer.stores)} />
                  </TabPanel>
                  <TabPanel>
                    <Fulfillments retailerUUID={retailer?.uuid} />
                  </TabPanel>
                  <TabPanel>
                    <ProductsList retailerUUID={retailer?.uuid} />
                  </TabPanel>
                  <TabPanel>
                    <MatchesList
                      hideRetailerSearch={true}
                      retailerUUID={retailer?.uuid}
                    />
                  </TabPanel>
                  <TabPanel>
                    <Row>
                      <Col md="6">
                        <Row>
                          <Col sm="10">
                            <NonprofitSelect
                              style={{
                                marginLeft: "20px",
                                height: "38px",
                                overflow: "hidden",
                              }}
                              nonprofit={nonprofit}
                              onSelect={(n) => {
                                setNonprofit(n);
                                setNonprofitUUIDs([n.uuid]);
                              }}
                            />
                          </Col>
                          <Col sm="2">
                            <Button onClick={addSelectNonprofits}>
                              {addSelectNonprofitLoading ? (
                                <Spinner />
                              ) : (
                                "Select"
                              )}
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                      <Col md="6">
                        <Row>
                          <Col sm="10">
                            <textarea
                              placeholder="Enter Nonprofit UUIDs separated by newlines"
                              style={{
                                width: "100%",
                                padding: "10px",
                                border: "1px solid #ccc",
                                height: "65px",
                              }}
                              onChange={onChangeNonprofitUUIDs}
                            ></textarea>
                          </Col>
                          <Col sm="2">
                            <Button onClick={addSelectNonprofits}>
                              {addSelectNonprofitLoading ? (
                                <Spinner />
                              ) : (
                                "Select"
                              )}
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <hr />
                    <NonprofitList
                      title={"Selected Nonprofits"}
                      retailerUUID={retailer?.uuid}
                      showTermSearch={true}
                      showCategoryFilter={true}
                      showCSVDownload={true}
                      hideActions={false}
                      onDeleteRows={removeSelectNonprofits}
                    />
                  </TabPanel>
                </Tabs>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </Fragment>
  );
};

export default RetailerDetails;
