import { Card, CardHeader, CardBody, Col, Row } from "reactstrap";
import { gql, useLazyQuery } from "@apollo/client";
import invoicesFormatter from "../../helpers/invoicesFormatter";
import Datatable from "../common/datatable";
import React, { useState, useEffect } from "react";
import styles from "../donate/donate.module.scss";
import Text from "../common/text";
import moment from "moment";
import DateRangePicker from "react-daterange-picker";
import { Edit } from "react-feather";
import RetailerSelect from "../retailers/retailer-select";
import InvoiceStatusSelect from "./InvoiceStatusSelect";

const ADMIN_GET_INVOICES = gql`
  query ($input: AdminGetInvoicesInput!) {
    adminGetInvoices(input: $input) { 
      total
      items {
        uuid
        status
        startDate
        endDate
        totalItems
        total {
          value
          currencyCode
        }
        shippingFees {
          value
          currencyCode
        }
        deliveryFees {
          value
          currencyCode
        }
        donationServiceFees {
          value
          currencyCode
        }
        serviceFees {
          value
          currencyCode
        }
        retailer {
          name
          uuid
        }
        createdAt
      }
      hasMore
    }
  }
`;

const InvoiceList = ({ title, hideRetailerSearch, retailerUUID }) => {
  const END_OF_DAY = {
    hour: 23,
    minute: 59,
    second: 59,
    millisecond: 0,
  };
  const MIDNIGHT = {
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  };

  const [selectOpen, setSelectOpen] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(25);
  const [indexFrom, setIndexFrom] = useState(0);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [statuses, setStatuses] = useState();
  const [dateEditMode, setDateEditMode] = useState(false);
  const [date, setDate] = useState(null);
  const [retailer, setRetailer] = useState(null);

  const [adminGetInvoices, { loading, error, data }] = useLazyQuery(
    ADMIN_GET_INVOICES,
    {
      variables: {
        fetchPolicy: "no-cache",
        input: {
          retailerUUID: retailer?.uuid || retailerUUID,
          startDate: startTime ? startTime.toISOString() : null,
          endDate: endTime ? endTime.toISOString() : null,
          statuses: statuses,
          indexFrom: indexFrom,
          limit: limit,
        },
      },
    },
  );

  const onChangePage = (newPage, _) => {
    setIndexFrom((newPage - 1) * limit);
  };

  const onChangeRowsPerPage = (newPerPage, page) => {
    setIndexFrom((page - 1) * newPerPage);
    setLimit(newPerPage);
  };

  const onClickEditDate = () => setDateEditMode(true);
  const onSelectRange = (value) => {
    setStartTime(
      moment()
        .utc(true)
        .set({
          year: value.start.year(),
          month: value.start.month(),
          date: value.start.date(),
        })
        .set(MIDNIGHT),
    );
    setEndTime(
      moment()
        .utc(true)
        .set({
          year: value.end.year(),
          month: value.end.month(),
          date: value.end.date(),
        })
        .set(END_OF_DAY),
    );
    setDate(value);
    setDateEditMode(false);
  };

  const toggleSelect = () => setSelectOpen((val) => !val);

  useEffect(() => {
    if (!loading) {
      if (data && data.adminGetInvoices) {
        setInvoices(invoicesFormatter(data.adminGetInvoices.items));
        setTotalRows(data.adminGetInvoices.total);
      } else {
        setInvoices([]);
      }
    }
  }, [loading, statuses, retailerUUID, retailer]);

  useEffect(() => {
    if (retailerUUID) {
      setRetailer({ uuid: retailerUUID });
    }
    adminGetInvoices();
  }, [indexFrom, retailerUUID, statuses]);

  return (
    <Card className={styles.donateItemEdit}>
      <CardHeader>
        <h3>{title}</h3>
        <Row>
          <Col
            xs="24"
            md="4"
            style={{
              position: "relative",
              height: "35px",
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {dateEditMode ? (
              <DateRangePicker
                value={date}
                onSelect={onSelectRange}
                singleDateRange={true}
              />
            ) : (
              <div className="d-flex align-items-center">
                <p
                  className="mb-0 fs-16 mt-1"
                  style={{
                    opacity: 0.5,
                    fontWeight: 600,
                    letterSpacing: "-0.1px",
                  }}
                >
                  <i className="fw-600">
                    {startTime ? startTime.format("DD MMM, YYYY") : "-"} to{" "}
                    {endTime ? endTime.format("DD MMM, YYYY") : "-"}
                  </i>
                </p>
                <Edit
                  onClick={onClickEditDate}
                  className="ml-2 cursor-pointer c-primary"
                />
              </div>
            )}
          </Col>
          <Col md="4">
            <InvoiceStatusSelect
              isOpen={selectOpen}
              placeholder="Filter By status"
              setValues={setStatuses}
              values={statuses}
              style={{
                width: "300px",
                marginLeft: "20px",
                height: "38px",
                overflow: "hidden",
              }}
              toggle={toggleSelect}
            />
          </Col>
          {!hideRetailerSearch && (
            <Col md="4">
              <RetailerSelect
                {...{
                  retailer: {
                    uuid: retailer?.uuid,
                    name: retailer?.name,
                  },
                  style: {
                    width: "300px",
                    marginLeft: "20px",
                    height: "38px",
                    overflow: "hidden",
                  },
                  onSelect: (r) => {
                    setIndexFrom(0);
                    setRetailer(r);
                  },
                }}
              />
            </Col>
          )}
        </Row>
      </CardHeader>
      <CardBody>
        {error ? (
          <>Some Error Occured! Try Reloading the page.</>
        ) : invoices ? (
          <Datatable
            hideActions={true}
            myData={invoices}
            loading={loading}
            pageSize={limit}
            pagination={true}
            paginationServer={true}
            totalRows={totalRows}
            myClass="-striped -highlight"
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            noHeader
          />
        ) : (
          <Text>No invoices</Text>
        )}
      </CardBody>
    </Card>
  );
};

export default InvoiceList;
