import React, { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

const Datatable = ({
  myData,
  myClass,
  multiSelectOption,
  loading,
  pagination,
  paginationServer,
  totalRows,
  pageSize,
  hideActions = false,
  onClickRow,
  onChangePage,
  onChangeRowsPerPage,
  noHeader = false,
  onDeleteRows,
}) => {
  const [open, setOpen] = useState(false);
  const [checkedValues, setCheckedValues] = useState([]);
  const [data, setData] = useState([]);
  const selectRow = (e, i) => {
    if (!e.target.checked) {
      setCheckedValues(checkedValues.filter((item, _) => i !== item));
    } else {
      checkedValues.push(i);
      setCheckedValues(checkedValues);
    }
  };
  useEffect(() => {
    setData([...myData]);
  }, [myData, myData?.length]);

  const handleDeleteRows = () => {
    if (checkedValues.length == 0) {
      return;
    }
    if (onDeleteRows) {
      onDeleteRows(checkedValues).then((_) => {
        const updatedData = myData.filter(function (el) {
          return checkedValues.indexOf(el.uuid) < 0;
        });

        setData([...updatedData]);
        setCheckedValues([]);
      });
    }
  };

  const renderEditable = (cellInfo) => {
    return (
      <div
        style={{ backgroundColor: "#fafafa" }}
        contentEditable
        suppressContentEditableWarning
        onBlur={(e) => {
          data[cellInfo.index][cellInfo.index.id] = e.target.innerHTML;
          setData({ myData: data });
        }}
        dangerouslySetInnerHTML={{
          __html: myData[cellInfo.index][cellInfo.index.id],
        }}
      />
    );
  };

  const handleDelete = (index) => {
    if (window.confirm("Are you sure you wish to delete this item?")) {
      const del = data;
      del.splice(index, 1);
      setData([...del]);
    }
    toast.success("Successfully Deleted !");
  };
  const onOpenModal = () => {
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const columns = [];
  for (var key in myData[0]) {
    let editable = renderEditable;
    if (key === "image") {
      editable = null;
    }
    if (key === "status") {
      editable = null;
    }
    if (key === "avtar") {
      editable = null;
    }
    if (key === "vendor") {
      editable = null;
    }
    if (key === "order_status") {
      editable = null;
    }

    columns.push({
      name: <b>{Capitalize(key.toString())}</b>,
      header: <b>{Capitalize(key.toString())}</b>,
      selector: key,
      Cell: editable,
      wrap: true,
    });
  }

  if (!hideActions) {
    if (multiSelectOption === true) {
      columns.push({
        name: (
          <button
            className="btn btn-danger btn-sm btn-delete mb-0 b-r-4"
            onClick={(_) => {
              if (checkedValues.length == 0) {
                return;
              }
              if (window.confirm("Are you sure you wish to delete this item?"))
                handleDeleteRows();
            }}
          >
            Delete
          </button>
        ),
        id: "delete",
        accessor: (_) => "delete",
        cell: (row) => (
          <div>
            <span>
              <input
                type="checkbox"
                name={row.uuid}
                value={checkedValues.includes(row.uuid)}
                defaultChecked={checkedValues.includes(row.uuid)}
                onChange={(e) => selectRow(e, row.uuid)}
              />
            </span>
          </div>
        ),
        style: {
          textAlign: "center",
        },
        sortable: false,
      });
    } else {
      columns.push({
        name: <b>Action</b>,
        id: "delete",
        accessor: (_) => "delete",
        cell: (_, index) => (
          <div>
            <span onClick={() => handleDelete(index)}>
              <i
                className="fa fa-trash"
                style={{
                  width: 35,
                  fontSize: 20,
                  padding: 11,
                  color: "#e4566e",
                }}
              ></i>
            </span>
            <span>
              <i
                onClick={onOpenModal}
                className="fa fa-pencil"
                style={{
                  width: 35,
                  fontSize: 20,
                  padding: 11,
                  color: "rgb(40, 167, 69)",
                }}
              ></i>
              <Modal
                isOpen={open}
                toggle={onCloseModal}
                style={{ overlay: { opacity: 0.1 } }}
              >
                <ModalHeader toggle={onCloseModal}>
                  <h5
                    className="modal-title f-w-600"
                    id="exampleModalLabel2"
                  >
                    Edit Product
                  </h5>
                </ModalHeader>
                <ModalBody>
                  <Form>
                    <FormGroup>
                      <Label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Category Name :
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label
                        htmlFor="message-text"
                        className="col-form-label"
                      >
                        Category Image :
                      </Label>
                      <Input
                        className="form-control"
                        id="validationCustom02"
                        type="file"
                      />
                    </FormGroup>
                  </Form>
                </ModalBody>
                <ModalFooter>
                  <Button
                    type="button"
                    color="primary"
                    onClick={() => onCloseModal("VaryingMdo")}
                  >
                    Update
                  </Button>
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => onCloseModal("VaryingMdo")}
                  >
                    Close
                  </Button>
                </ModalFooter>
              </Modal>
            </span>
          </div>
        ),
        style: {
          textAlign: "center",
        },
        sortable: false,
      });
    }
  }
  return (
    <div>
      <Fragment>
        <DataTable
          data={data}
          columns={columns}
          className={myClass}
          pagination={pagination}
          paginationServer={paginationServer}
          paginationTotalRows={totalRows}
          paginationPerPage={pageSize}
          paginationDefaultPage={1}
          progressPending={loading}
          striped={true}
          center={true}
          onRowClicked={onClickRow}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          noHeader={noHeader}
        />

        <ToastContainer />
      </Fragment>
    </div>
  );
};

export default Datatable;
