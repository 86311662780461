import React from "react";
import { Button } from "reactstrap";
import { CheckSquare } from "react-feather";

const subscriptionPlansFormatter = (subscriptionPlans, onClick) => {
  if (!subscriptionPlans?.length) {
    return;
  }

  const formattedInvoices = subscriptionPlans.map((d) => {
    return {
      Title: d.title || "<no-title>",
      "Monthly Amount":
        d?.monthlyAmount?.value + " " + d?.monthlyAmount?.currencyCode,
      "Included Donation Value":
        d?.includedDonationValue?.value +
        " " +
        d?.includedDonationValue?.currencyCode,
      "Shipping Fee Type": d?.isShippingFeePassThrough
        ? "Pass-through"
        : "Fixed",
      "Shipping Fee": d?.isShippingFeePassThrough
        ? "N/A"
        : d?.shippingFee?.value + " " + d?.shippingFee?.currencyCode,
      "Delivery Fee Type": d?.isDeliveryFeePassThrough
        ? "Pass-through"
        : "Fixed",
      "Delivery Fee": d?.isDeliveryFeePassThrough
        ? "N/A"
        : d?.deliveryFee?.value + " " + d?.deliveryFee?.currencyCode,
      Select: (
        <Button
          key={d.uuid}
          onClick={() => onClick(d?.uuid)}
          className="pr-2 mr-2"
        >
          <CheckSquare />
        </Button>
      ),
    };
  });

  return formattedInvoices;
};

export default subscriptionPlansFormatter;
