import React, { useEffect, useState } from "react";
import { Button, Col, Row, Spinner } from "reactstrap";
import Photos from "../common/photos";
import styles from "./category.module.scss";
import { X } from "react-feather";
import { ToastContainer, toast } from "react-toastify";
import { imagesToGraph, update_fields } from "src/helpers/utils";
import CategoryDropdown from "../products/physical/CategoryDropdown";

const AddEditCategory = ({ category, onSubmit, onClose }) => {
  const [name, setName] = useState();
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [parentCategoryType, setParentCategoryType] = useState();
  const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false);

  const [categoryTreeValues, setCategoryTreeValues] = useState({
    checked: [],
    expanded: [],
  });

  useEffect(() => {
    if (category) {
      setName(category?.name);
      setImages(category?.images);
    }
  }, [category]);

  const onSuccessOrFailure = () => {
    setLoading(false);
  };

  const onSubmitAddEditCategory = async () => {
    if (!name) {
      toast.error("Require name");
      return;
    }

    if (!images?.length) {
      toast.error("Require image");
      return;
    }

    let submitValues = {
      name,
      images,
      uuid: category?.uuid,
      parentCategoryType,
    };

    await onSubmit(
      update_fields({
        images: imagesToGraph,
      })(submitValues),
      onSuccessOrFailure,
    );
  };

  return (
    <div className={styles.categoryEdit}>
      <X
        className={styles.x}
        onClick={() => onClose()}
      />
      <Row>
        <Col
          xs={{ size: 12, order: 2 }}
          lg={{ size: 6, order: 1 }}
        >
          <Photos
            selected={images || []}
            hidePreview
            onSelect={(images) => {
              if (!images) {
                return;
              }
              setImages(images);
            }}
            numberOfPhotos={1}
          />
        </Col>
        <Col
          xs={{ size: 12, order: 1 }}
          lg={{ size: 6, order: 2 }}
        >
          <Row>
            <Col>
              <label className={styles.label}>Name</label>
              <input
                className={styles.input + " form-control"}
                value={name || ""}
                type="text"
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </Col>
          </Row>
          {!category?.uuid && (
            <Row>
              <Col xs="12">
                <label className={styles.label}>Parent Category</label>
                <CategoryDropdown
                  isOpen={isCategoryDropdownOpen}
                  toggle={() => setIsCategoryDropdownOpen((prev) => !prev)}
                  categoryTreeValues={categoryTreeValues}
                  onCheck={(checked) => {
                    let other = checked.filter(
                      (c) =>
                        c !== parentCategoryType &&
                        c !== "other" &&
                        c !== "all",
                    );
                    setCategoryTreeValues((prev) => ({
                      ...prev,
                      checked: other,
                    }));
                    setParentCategoryType(other[0]);
                  }}
                  setCategoryTreeValues={setCategoryTreeValues}
                  onlyLeafCheckboxes={false}
                  noCascade={true}
                />
              </Col>
            </Row>
          )}
          <Row className="mt-3">
            <Col>
              {loading ? (
                <div className="d-flex justify-content-end">
                  <Spinner />
                </div>
              ) : (
                <div className="d-flex justify-content-end">
                  <Button
                    color="primary"
                    onClick={onSubmitAddEditCategory}
                  >
                    Submit
                  </Button>
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <ToastContainer />
    </div>
  );
};

export default AddEditCategory;
