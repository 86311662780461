import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import Datatable from "../common/datatable";
import Text from "../common/text";
import styles from "../donate/donate.module.scss";

const AddressList = ({ addresses, loading }) => {
  const [limit, setLimit] = useState(10);
  const [_, setIndexFrom] = useState(0);

  const onChangePage = (newPage, _) => {
    setIndexFrom((newPage - 1) * limit);
  };

  const onChangeRowsPerPage = (newPerPage, page) => {
    setIndexFrom((page - 1) * newPerPage);
    setLimit(newPerPage);
  };

  return (
    <div
      style={{ padding: "10px 20px" }}
      className={styles.donateItemEdit}
    >
      <Row
        xs="12"
        className="align-items-start"
      >
        <Col xs="12">
          {addresses && addresses?.length ? (
            <Datatable
              hideActions={true}
              myData={addresses}
              loading={loading}
              pageSize={limit}
              pagination={true}
              paginationServer={false}
              totalRows={addresses?.length}
              myClass="-striped -highlight"
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              noHeader
            />
          ) : (
            <Text>
              <i>No Addresses</i>
            </Text>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AddressList;
