import React from "react";
import { Media } from "reactstrap";
import styles from "./photos.module.scss";

const ImageZoom = (props) => {
  const { image, onClick = Function.prototype } = props;

  return (
    <div
      onClick={onClick}
      className={styles.zoomImage}
    >
      <Media
        src={`${image}`}
        alt={image}
        className="img-fluid d-block m-auto"
      />
    </div>
  );
};

export default ImageZoom;
