import { gql } from "@apollo/client";

export const CREATE_PRODUCT_BUNDLE = gql`
    mutation createProductBundle($input: CreateProductBundleInput!){
        createProductBundle(input: $input){
            quantity
            product {
                uuid
                title
            }
            bundle {
                uuid
                title
            }
        }
    }
`;

export const DELETE_PRODUCT_BUNDLE = gql`
    mutation deleteProductBundle($input: DeleteProductBundleInput!){
        deleteProductBundle(input : $input)
    }
`;
