import React, { Fragment } from "react";
import Breadcrumb from "../../common/breadcrumb";
import { Container } from "reactstrap";
import AddEditProduct from "./AddEditProduct";
import { initializeApollo } from "../../../helpers/apollo";
import { toast } from "react-toastify";
import { gql } from "@apollo/client";
import { err_toast } from "src/helpers/errors";
import { useHistory } from "react-router-dom";

const ADD_PRODUCT = gql`
  mutation adminAddProduct($data: AddProductInput!) {
    adminAddProduct(input: $data) {
      uuid
      title
      description
      images {
        resolutions {
          m {
            url
          }
        }
      }
    }
  }
`;

const AddProduct = () => {
  const history = useHistory();

  const handleValidSubmit = async (vals, cb) => {
    const data = { ...vals };

    let client = initializeApollo();
    delete data.files;
    const obj = {
      product: data,
    };
    client
      .mutate({
        mutation: ADD_PRODUCT,
        variables: { data: obj },
      })
      .then((_) => {
        toast.success("Product added successfully!");
        cb();
        setTimeout(() => {
          window.location.assign("/products");
        }, 1000);
      })
      .catch((err) => {
        err_toast(err);
        cb();
      });
  };

  return (
    <Fragment>
      <Breadcrumb
        title="Add Product"
        parent="Physical"
      />
      <Container fluid={true}>
        <AddEditProduct
          data={{}}
          useRecentLocationInfo={true}
          onSubmit={handleValidSubmit}
          autoSuggestStockImage={true}
          onClose={() => history.push("/products")}
          isDonation={false}
        />
      </Container>
    </Fragment>
  );
};

export default AddProduct;
