import { toast } from "react-toastify";

export const copyAddress = (address) => {
  let formattedAddress = formatAddress(address);
  navigator.clipboard.writeText(formattedAddress);
  toast.success(`Copied address ${formattedAddress}`, { autoClose: 1500 });
};

export const formatAddress = (data, noStreet) => {
  if (!data || !Object.keys(data)?.length) return null;
  const { streetAddress1, streetAddress2, city, state, zip, countryCode } =
    data;
  return (
    (!noStreet ? (streetAddress1 ? streetAddress1 + ", " : "") : "") +
    (streetAddress2 ? streetAddress2 + ", " : "") +
    (city ? city + ", " : "") +
    (state ? state + ", " : "") +
    (countryCode ? countryCode + " " : "") +
    (zip ? ", " + zip : "")
  );
};

export const formatAddressLine1 = (location) => {
  if (!location?.address) return "";

  const {
    streetAddress1 = "",
    streetAddress2 = "",
    streetAddress3 = "",
  } = location.address;
  const term1 = !!streetAddress1 ? `${streetAddress1}, ` : "";
  const term2 = !!streetAddress2 ? `${streetAddress2}, ` : "";
  const term3 = !!streetAddress3 ? `${streetAddress3}, ` : "";
  return term1 + term2 + term3;
};

export const getGoogleMapsAddress = (address) => {
  let formattedAddress = formatAddress(address);
  if (!formattedAddress) return "#";
  let regex = /\s/i;
  return `https://www.google.com/maps?q=${formattedAddress.replace(
    regex,
    "+",
  )}`;
};
