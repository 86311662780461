import React, { useRef, useEffect, useState } from "react";
import { useMemo } from "react";
import { createMarker } from "./map-utils";

const Map = ({ source, destination }) => {
  const data = useMemo(() => [source, destination], [source, destination]);

  const mapRef = useRef(null);
  const [map, setMap] = useState(null);

  const MapOptions = useMemo(
    () => ({
      center: data[0],
      zoom: 9,
      mapTypeId: "roadmap",
      scaleControl: true,
      mapTypeControl: false,
      streetViewControl: false,
      zoomControl: true,
      zoomControlOptions: {
        position: window.google?.maps?.ControlPosition?.TOP_RIGHT,
      },
      rotateControl: false,
      fullscreenControl: false,
    }),
    [data]
  );

  useEffect(() => {
    if (mapRef?.current && !map) {
      setMap(new window.google.maps.Map(mapRef?.current, MapOptions));
    }
  }, [mapRef, map, MapOptions]);

  useEffect(() => {
    if (!map) return;

    for (const d of data) {
      createMarker(d, map, JSON.stringify(d));
    }

    new window.google.maps.Polyline({
      path: data,
      geodesic: true,
      map,
      strokeColor: "red",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
  }, [map, mapRef, data]);

  return (
    <div
      ref={mapRef}
      id="map"
      style={{ height: 500, width: "100%" }}
      className="w-full h-full"
    ></div>
  );
};

export default Map;
