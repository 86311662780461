import React from "react";
import { Card, CardHeader, CardBody } from "reactstrap";
import Datatable from "../../common/datatable";
import Text from "../../common/text";
import styles from "../../donate/donate.module.scss";

const MatchUpdatesList = ({ matchUpdates }) => {
  return (
    <Card className={styles.donateItemEdit}>
      <CardHeader>
        <h3>Match Updates</h3>
      </CardHeader>
      <CardBody>
        {matchUpdates ? (
          <Datatable
            hideActions={true}
            myData={matchUpdates}
            pagination={false}
            paginationServer={false}
            totalRows={matchUpdates?.length}
            myClass="-striped -highlight"
            noHeader
          />
        ) : (
          <Text>No transactions</Text>
        )}
      </CardBody>
    </Card>
  );
};

export default MatchUpdatesList;
