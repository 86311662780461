import React, { Fragment } from "react";
import { Col, Container, Row } from "reactstrap";
import Breadcrumb from "../common/breadcrumb";
import styles from "../donate/donate.module.scss";
import NonprofitList from "./NonprofitList";

const NonProfits = () => {
  return (
    <Fragment>
      <Breadcrumb
        title="Nonprofits"
        parent="Dashboard"
      />
      <Container
        fluid={true}
        className={styles.donateItemEdit}
      >
        <Row>
          <Col sm="12">
            <NonprofitList
              showTermSearch={true}
              showCategoryFilter={true}
              showCSVDownload={true}
              showLocationInput={true}
            />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default NonProfits;
