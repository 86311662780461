import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import React, { Fragment, useState } from "react";
import { gql } from "@apollo/client";
import { initializeApollo } from "../../helpers/apollo";
import { toast, ToastContainer } from "react-toastify";
import SearchLocationInput from "../common/SearchLocationInput";
import Breadcrumb from "../common/breadcrumb";
import styles from "../donate/donate.module.scss";
import { useHistory, useParams } from "react-router-dom";
import useFormattedPhone from "../../helpers/hooks/useFormattedPhone";
import { addCountryCode } from "../../helpers/utils";

const CREATE_STORE = gql`
  mutation adminAddStore($data: StoreInput!) {
    adminAddRetailStore(input: $data) {
      uuid
    }
  }
`;

const CreateStore = () => {
  const { uuid } = useParams();
  const history = useHistory();
  const [storeData, setStoreData] = useState({
    retailerUUID: uuid,
    businessAddress: {},
    businessPhoneNumber: null,
  });

  const [phone, setPhone] = useFormattedPhone(storeData?.businessPhoneNumber);

  const handleCancel = () => {
    history.goBack();
  };

  const handleSubmit = () => {
    let client = initializeApollo();

    client
      .mutate({
        mutation: CREATE_STORE,
        variables: {
          data: {
            ...storeData,
            businessPhoneNumber: addCountryCode(storeData.businessPhoneNumber),
          },
        },
      })
      .then(() => {
        toast.success("Location added successfully!");
        setTimeout(() => {
          history.push("/retailers/details/" + uuid);
        }, 1000);
      })
      .catch((err) => {
        toast.error(err.message || "something might went wrong!");
      });
  };

  const setItem = (name, value) => {
    setStoreData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <Fragment>
      <Breadcrumb
        title="Create store"
        parent="Retailers"
      />

      <Container
        fluid={true}
        className={styles.donateItemEdit}
      >
        <Row>
          <Col sm="12">
            <Card>
              <Form className="needs-validation">
                <FormGroup>
                  <Label for="name">
                    <span>*</span> Name
                  </Label>
                  <Input
                    className="form-control"
                    id="name"
                    type="text"
                    required
                    value={storeData.name}
                    onChange={(e) => setItem("name", e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="description">Description</Label>
                  <textarea
                    id="description"
                    className="form-control"
                    value={storeData.description}
                    onChange={(e) => setItem("description", e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="businessPhoneNumber">Business phone number</Label>
                  <Input
                    className="form-control"
                    id="businessPhoneNumber"
                    type="text"
                    required
                    value={phone || ""}
                    onChange={(e) => {
                      setItem("businessPhoneNumber", e.target.value);
                      setPhone(e.target.value);
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="name">
                    {" "}
                    <span>*</span>Business address
                  </Label>
                  <SearchLocationInput
                    placeholdertext={""}
                    required={true}
                    setAddress={(input) => setItem("businessAddress", input)}
                  />
                </FormGroup>
                <FormGroup>
                  <Button
                    color="primary"
                    onClick={handleSubmit}
                    className="mr-2"
                  >
                    Create
                  </Button>
                  <Button
                    color="secondary"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </FormGroup>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </Fragment>
  );
};

export default CreateStore;
