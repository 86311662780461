import React, { Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Card, CardBody, Container } from "reactstrap";
import AddEditCategory from "./AddEditCategory";
import { useHistory } from "react-router-dom";
import { gql } from "@apollo/client";
import { initializeApollo } from "src/helpers/apollo";
import { err_toast } from "src/helpers/errors";
import { toast } from "react-toastify";

const ADMIN_ADD_CATEGORY = gql`
    mutation adminAddCategory($input: CategoryInput!) {
        adminAddCategory(input: $input) {
            uuid
            type
        }
    }
`;

const AddCategory = () => {
  const history = useHistory();

  let client = initializeApollo();

  const handleAddCategory = async (vals, cb) => {
    client
      .mutate({
        mutation: ADMIN_ADD_CATEGORY,
        variables: { input: vals },
      })
      .then((r) => {
        toast.success("Category added successfully!");
        cb();
        setTimeout(() => {
          window.location.assign(
            "/category-details/" + r?.data?.adminAddCategory?.type,
          );
        }, 1000);
      })
      .catch((err) => {
        err_toast(err);
        cb();
      });
  };

  return (
    <Fragment>
      <Breadcrumb
        title="Add Category"
        parent="Home"
      />
      <Container fluid={true}>
        <Card>
          <CardBody>
            <AddEditCategory
              category={{}}
              onClose={() => history.push("/categories")}
              onSubmit={handleAddCategory}
            />
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
};

export default AddCategory;
