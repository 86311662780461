import React, { Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Container } from "reactstrap";
import { ToastContainer } from "react-toastify";
import BlogEditor from "./BlogEditor";

const AddBlog = () => {
  return (
    <Fragment>
      <Breadcrumb
        title="Blog"
        parent="Add Blog"
      />
      <Container fluid={true}>
        <BlogEditor />
      </Container>
      <ToastContainer />
    </Fragment>
  );
};

export default AddBlog;
