import React, { useState } from "react";
import styles from "../../donate/donate.module.scss";
import { Col, Row, Button, Card, CardBody } from "reactstrap";
import AddProductBundleModal from "./components/AddProductBundleModal";
import { deleteProductBundle } from "./components/services/productBundle";
import Remove from "../../../assets/images/svgs/remove.svg";
import Edit from "../../../assets/images/svgs/Edit.svg";

const ProductBundle = ({
  retailerUUID,
  bundleUUID,
  bundleProducts,
  setBundleProducts,
}) => {
  const [showCreatePopup, setShowCreatePopup] = useState(false);

  const toggleShowCreatePopop = () => {
    setShowCreatePopup(() => !showCreatePopup);
  };

  const removeRow = async (indexToRemove) => {
    const row = bundleProducts[indexToRemove];

    if (
      window.confirm("Are you sure you wish to remove this item from bundle?")
    ) {
      const res = await deleteProductBundle(
        bundleUUID,
        row.product?.uuid,
        setBundleProducts,
      );

      console.log("res: ", res);

      const updatedRows = bundleProducts.filter(
        (row, index) => index !== indexToRemove,
      );

      setBundleProducts(updatedRows);
    }
  };

  return (
    <>
      <hr />
      <Row>
        <Col>
          <label className={styles.label}>Bundle</label>
        </Col>
        <Col style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            type="button"
            color="primary"
            onClick={toggleShowCreatePopop}
          >
            Add Product
          </Button>
        </Col>
      </Row>
      <div className="my-4">
        {bundleProducts?.map((bundleProduct, index) => (
          <Card key={"bundle_" + index}>
            <CardBody>
              <Row>
                <Col>{bundleProduct?.product?.title}</Col>
                <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                  Quantity: {bundleProduct?.quantity}
                </Col>
                <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                  <a
                    href={"/product/" + bundleProduct?.product?.uuid + "/edit"}
                    {...{
                      target: "_blank",
                    }}
                  >
                    <img
                      src={Edit}
                      alt="Edit"
                      style={{
                        width: 40,
                        cursor: "pointer",
                        marginRight: "10px",
                      }}
                    />
                  </a>
                  <img
                    onClick={() => {
                      removeRow(index);
                    }}
                    src={Remove}
                    alt="Remove"
                    style={{ width: 40, cursor: "pointer" }}
                  />
                  {/* <Button onClick={() => removeRow(index)}>Remove Row</Button> */}
                </Col>
              </Row>
            </CardBody>
          </Card>
        ))}
      </div>
      <AddProductBundleModal
        show={showCreatePopup}
        toggle={toggleShowCreatePopop}
        setProductBundles={setBundleProducts}
        productBundles={bundleProducts}
        bundleUUID={bundleUUID}
        retailerUUID={retailerUUID}
      />
    </>
  );
};

export default ProductBundle;
