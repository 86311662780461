import React from "react";
import { Badge, ListGroup, ListGroupItem } from "reactstrap";
import moment from "moment";

const FulfillmentUpdate = ({ data }) => {
  return (
    <>
      <div className="row">
        <div className="col-12 pr-2">
          <h4>FULFILLMENT UPDATES</h4>
          <ListGroup>
            {data &&
              data.map((item, index) => (
                <ListGroupItem key={`fulfillment-update-${index}`}>
                  {item.description}{" "}
                  <Badge
                    color={item.eventType === "custom" ? "primary" : "success"}
                    style={{ textTransform: "capitalize" }}
                  >
                    {item.status.split("_")}
                  </Badge>
                  <span className={"float-right"}>
                    {moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                  </span>
                </ListGroupItem>
              ))}
          </ListGroup>
        </div>
      </div>
    </>
  );
};

export default FulfillmentUpdate;
