import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Col,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import { toast } from "react-toastify";
import "@mdxeditor/editor/style.css";
import { initializeApollo } from "src/helpers/apollo";
import { gql } from "@apollo/client";
import { err_toast } from "src/helpers/errors";
import moment from "moment";
import { ChevronDown } from "react-feather";
import styles from "./pricing.module.scss";

const ADMIN_SAVE_SUBSCRIPTION_PLAN = gql`
    mutation adminEditRetailerSubscriptionPlan($input: RetailerSubscriptionPlanInput!) {
      adminEditRetailerSubscriptionPlan(input: $input) {
          uuid
          id
          status
          monthlyAmount {
            value
            currencyCode
          }
          includedDonationValue {
            value
            currencyCode
          }
          individualItemPrice {
            value
            currencyCode
          }
          unboxedPrice {
            value
            currencyCode
          }
          boxPrice {
            value
            currencyCode
          }
          largeBoxPrice {
            value
            currencyCode
          }
          oversizedItemPrice {
            value
            currencyCode
          }
          palletPrice {
            value
            currencyCode
          }
          largePalletPrice {
            value
            currencyCode
          }
          isShippingFeePassThrough
          shippingFee {
            value
            currencyCode
          }
          isDeliveryFeePassThrough
          deliveryFee {
            value
            currencyCode
          }
      }
    }
`;

const Pricing = ({ data }) => {
  // get the authentication token from cookie if it exists

  const [values, setValues] = useState({
    status: "active",
  });

  const [loading, setLoading] = useState(false);
  const [dropdowns, setDropdowns] = useState({
    isShippingFeePassThrough: false,
    isDeliveryFeePassThrough: false,
  });

  const setItem = (name, value) => {
    setValues((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      setValues({
        uuid: data?.uuid,
        status: data?.status,
        monthlyAmount: data?.monthlyAmount?.value,
        includedDonationValue: data?.includedDonationValue?.value,
        includedDonationUnitTypes: data?.includedDonationUnitTypes || [],
        individualItemPrice: data?.individualItemPrice
          ? data?.individualItemPrice.value
          : null,
        unboxedPrice: data?.unboxedPrice ? data?.unboxedPrice.value : null,
        boxPrice: data?.boxPrice ? data?.boxPrice.value : null,
        largeBoxPrice: data?.largeBoxPrice ? data?.largeBoxPrice.value : null,
        oversizedItemPrice: data?.oversizedItemPrice
          ? data?.oversizedItemPrice.value
          : null,
        palletPrice: data?.palletPrice ? data?.palletPrice.value : null,
        largePalletPrice: data?.largePalletPrice
          ? data?.largePalletPrice.value
          : null,
        isShippingFeePassThrough: data?.isShippingFeePassThrough,
        shippingFee: data?.shippingFee ? data?.shippingFee.value : null,
        isDeliveryFeePassThrough: data?.isDeliveryFeePassThrough,
        deliveryFee: data?.deliveryFee ? data?.deliveryFee.value : null,
      });
    }
  }, [data]);

  const client = initializeApollo();

  const saveSubscriptionPlan = () => {
    setLoading(true);
    client
      .mutate({
        mutation: ADMIN_SAVE_SUBSCRIPTION_PLAN,
        variables: {
          input: {
            uuid: data?.uuid,
            retailerUUID: data?.retailer?.uuid,
            monthlyAmount: values?.monthlyAmount
              ? {
                  value: values?.monthlyAmount,
                  currencyCode: "USD",
                }
              : null,
            includedDonationValue: values?.includedDonationValue
              ? {
                  value: values?.includedDonationValue,
                  currencyCode: "USD",
                }
              : null,
            includedDonationUnitTypes: values?.includedDonationUnitTypes || [],
            individualItemPrice: values?.individualItemPrice
              ? {
                  value: values?.individualItemPrice,
                  currencyCode: "USD",
                }
              : null,
            unboxedPrice: values?.unboxedPrice
              ? {
                  value: values?.unboxedPrice,
                  currencyCode: "USD",
                }
              : null,
            boxPrice: values?.boxPrice
              ? {
                  value: values?.boxPrice,
                  currencyCode: "USD",
                }
              : null,
            largeBoxPrice: values?.largeBoxPrice
              ? {
                  value: values?.largeBoxPrice,
                  currencyCode: "USD",
                }
              : null,
            oversizedItemPrice: values?.oversizedItemPrice
              ? {
                  value: values?.oversizedItemPrice,
                  currencyCode: "USD",
                }
              : null,
            palletPrice: values?.palletPrice
              ? {
                  value: values?.palletPrice,
                  currencyCode: "USD",
                }
              : null,
            largePalletPrice: values?.largePalletPrice
              ? {
                  value: values?.largePalletPrice,
                  currencyCode: "USD",
                }
              : null,
            isShippingFeePassThrough: values?.isShippingFeePassThrough,
            shippingFee: values?.shippingFee
              ? {
                  value: values?.shippingFee,
                  currencyCode: "USD",
                }
              : null,
            isDeliveryFeePassThrough: values?.isDeliveryFeePassThrough,
            deliveryFee: values?.deliveryFee
              ? {
                  value: values?.deliveryFee,
                  currencyCode: "USD",
                }
              : null,
          },
        },
      })
      .then((r) => {
        toast.success("Saved subscriptionPlan");
        if (!values?.uuid) {
          setItem("uuid", r?.data?.adminEditRetailerSubscriptionPlan?.uuid);

          setTimeout(() => {
            window.location.replace(
              `/pricing/edit/${r?.data?.adminEditRetailerSubscriptionPlan?.uuid}`,
            );
          }, 1000);
        }

        setLoading(false);
      })
      .catch((err) => {
        err_toast(err);
        setLoading(false);
      });
  };

  return (
    <Card>
      <CardHeader>
        <Row className="mb-3">
          <Col md="12">
            <>
              {data?.subscriptionPlan?.title}
              {data?.retailer?.name && (
                <h3 style={{ color: "black" }}>{data?.retailer?.name}</h3>
              )}

              <Badge color={data?.status === "active" ? "success" : "warning"}>
                <h5>{data?.status}</h5>
              </Badge>
            </>
            <hr />
          </Col>
          <Col
            md="6"
            className="d-flex justify-content-end"
          >
            <span>
              Created: {moment(data?.createdAt).format("YYYY-MM-DD HH:mm:ss")}
            </span>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col
            md="12"
            className="d-flex justify-content-end"
          >
            <Button
              style={{
                float: "inline-start",
                height: "38px",
              }}
              className="btn btn-info mr-2"
              onClick={() =>
                window.location.assign(
                  "/retailers/details/" + data?.retailer?.uuid,
                )
              }
              disabled={loading}
            >
              Back to Retailer
            </Button>
            <Button
              style={{
                float: "inline-start",
                height: "38px",
              }}
              className="btn btn-success mr-2"
              onClick={saveSubscriptionPlan}
              disabled={loading}
            >
              {loading ? <Spinner /> : "Save"}
            </Button>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md="6">
            <h4>
              Monthly Amount<span style={{ color: "red" }}>*</span>
            </h4>
            <Input
              name="monthlyAmount"
              type="text"
              value={values.monthlyAmount}
              onChange={(e) => setItem("monthlyAmount", e.target.value)}
            />
          </Col>
          <Col md="6">
            <h4>
              Included Donation Value<span style={{ color: "red" }}>*</span>
            </h4>
            <Input
              name="includedDonationValue"
              type="text"
              value={values.includedDonationValue}
              onChange={(e) => setItem("includedDonationValue", e.target.value)}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md="6">
            <h4>
              Shipping Fee Type<span style={{ color: "red" }}>*</span> &nbsp;
            </h4>
            <Dropdown
              required
              isOpen={dropdowns.isShippingFeePassThrough}
              toggle={(_) => {
                setDropdowns((prev) => ({
                  ...prev,
                  isShippingFeePassThrough: !prev.isShippingFeePassThrough,
                }));
              }}
            >
              <DropdownToggle
                className={styles.dropdown}
                style={{
                  backgroundColor: "white",
                  color: "black",
                  width: "100%",
                }}
              >
                <span style={{ paddingRight: "1em" }}>
                  {values.isShippingFeePassThrough ? "Pass through" : "Fixed"}
                </span>
                <ChevronDown />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={(_) => {
                    setItem("isShippingFeePassThrough", true);
                  }}
                >
                  Pass through
                </DropdownItem>
                <DropdownItem
                  onClick={(_) => {
                    setItem("isShippingFeePassThrough", false);
                  }}
                >
                  Fixed
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Col>
          {!values?.isShippingFeePassThrough && (
            <Col md="6">
              <h4>
                Shipping Fee<span style={{ color: "red" }}>*</span> &nbsp;
              </h4>
              <Input
                name="shippingFee"
                type="text"
                value={values.shippingFee}
                onChange={(e) => setItem("shippingFee", e.target.value)}
              />
            </Col>
          )}
        </Row>
        <Row className="mb-3">
          <Col md="6">
            <h4>
              Delivery Fee Type<span style={{ color: "red" }}>*</span> &nbsp;
            </h4>
            <Dropdown
              required
              isOpen={dropdowns.isDeliveryFeePassThrough}
              toggle={(_) => {
                setDropdowns((prev) => ({
                  ...prev,
                  isDeliveryFeePassThrough: !prev.isDeliveryFeePassThrough,
                }));
              }}
            >
              <DropdownToggle
                className={styles.dropdown}
                style={{
                  backgroundColor: "white",
                  color: "black",
                  width: "100%",
                }}
              >
                <span style={{ paddingRight: "1em" }}>
                  {values.isDeliveryFeePassThrough ? "Pass through" : "Fixed"}
                </span>
                <ChevronDown />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={(_) => {
                    setItem("isDeliveryFeePassThrough", true);
                  }}
                >
                  Pass through
                </DropdownItem>
                <DropdownItem
                  onClick={(_) => {
                    setItem("isDeliveryFeePassThrough", false);
                  }}
                >
                  Fixed
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Col>
          {!values?.isDeliveryFeePassThrough && (
            <Col md="6">
              <h4>
                Delivery Fee <span style={{ color: "red" }}>*</span> &nbsp;
              </h4>
              <Input
                name="deliveryFee"
                type="text"
                value={values.deliveryFee}
                onChange={(e) => setItem("deliveryFee", e.target.value)}
              />
            </Col>
          )}
        </Row>
      </CardHeader>
      <CardBody>
        <Row className="mb-3">
          <Col md="6">
            <h4>
              Individual Item<span style={{ color: "red" }}>*</span> &nbsp;
            </h4>
            <Input
              name="individualItemPrice"
              type="text"
              value={values.individualItemPrice}
              onChange={(e) => setItem("individualItemPrice", e.target.value)}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md="6">
            <h4>
              Oversized Item<span style={{ color: "red" }}>*</span> &nbsp;
            </h4>
            <Input
              name="oversizedItemPrice"
              type="text"
              value={values.oversizedItemPrice}
              onChange={(e) => setItem("oversizedItemPrice", e.target.value)}
            />
          </Col>
          <Col md="6">
            <h4>
              Unboxed<span style={{ color: "red" }}>*</span> &nbsp;
            </h4>
            <Input
              name="unboxedPrice"
              type="text"
              value={values.unboxedPrice}
              onChange={(e) => setItem("unboxedPrice", e.target.value)}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md="6">
            <h4>
              Boxed<span style={{ color: "red" }}>*</span> &nbsp;
            </h4>
            <Input
              name="boxPrice"
              type="text"
              value={values.boxPrice}
              onChange={(e) => setItem("boxPrice", e.target.value)}
            />
          </Col>
          <Col md="6">
            <h4>
              Large Box<span style={{ color: "red" }}>*</span> &nbsp;
            </h4>
            <Input
              name="largeBoxPrice"
              type="text"
              value={values.largeBoxPrice}
              onChange={(e) => setItem("largeBoxPrice", e.target.value)}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md="6">
            <h4>
              Pallet<span style={{ color: "red" }}>*</span> &nbsp;
            </h4>
            <Input
              name="palletPrice"
              type="text"
              value={values.palletPrice}
              onChange={(e) => setItem("palletPrice", e.target.value)}
            />
          </Col>
          <Col md="6">
            <h4>
              Large Pallet<span style={{ color: "red" }}>*</span> &nbsp;
            </h4>
            <Input
              name="largePalletPrice"
              type="text"
              value={values.largePalletPrice}
              onChange={(e) => setItem("largePalletPrice", e.target.value)}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default Pricing;
