import React, { Fragment } from "react";
import { Col, Container, Row } from "reactstrap";
import Breadcrumb from "../common/breadcrumb";
import styles from "../donate/donate.module.scss";
import NonprofitList from "./NonprofitList";
import { useParams } from "react-router-dom";

const NonprofitMatches = () => {
  const { productUUID } = useParams();
  return (
    <Fragment>
      <Breadcrumb
        title="Nonprofit Matches"
        parent="Dashboard"
      />
      <Container
        fluid={true}
        className={styles.donateItemEdit}
      >
        <Row>
          <Col sm="12">
            <NonprofitList
              productUUID={productUUID}
              showCSVDownload={true}
            />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default NonprofitMatches;
