import React, { Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Container } from "reactstrap";
import { ToastContainer } from "react-toastify";
import BlogEditor from "./BlogEditor";
import { useParams } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";

const ADMIN_GET_BLOG = gql`
    query($input: GetBlogInput!) {
        adminGetBlog(input: $input) {
            uuid
            url
            title
            slug
            url
            status
            description
            content
            seoKeywords
            categoryTags
            author {
                email
            }
            images {
                uuid
            }
            createdAt
            updatedAt
            publishedAt
        }
    }
`;

const EditBlog = () => {
  const { blogUUID } = useParams();
  const { data } = useQuery(ADMIN_GET_BLOG, {
    fetchPolicy: "no-cache",
    variables: {
      input: {
        uuid: blogUUID,
      },
    },
  });

  return (
    <Fragment>
      <Breadcrumb
        title="Blog"
        parent="Edit Blog"
      />
      <Container fluid={true}>
        <BlogEditor data={data?.adminGetBlog} />
      </Container>
      <ToastContainer />
    </Fragment>
  );
};

export default EditBlog;
