import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import s from "../sales/orders/components/OrderDetails.module.scss";
import TrackModal from "../sales/orders/components/TrackOrder/TrackModal";
import {
  formatAddress,
  getGoogleMapsAddress,
  copyAddress,
} from "../../helpers/address";
import Text from "../common/text";
import Bold from "../common/bold";

import Notes from "../notes/notes";
import moment from "moment";
import { Copy, List, Truck } from "react-feather";
import FulfillmentUpdate from "./update/fulfillmentUpdate";
import { useHistory } from "react-router-dom";
import {
  CancelledFulfillmentStatuses,
  FulfilledFulfillmentStatuses,
  OnGoingFulfillmentStatuses,
} from "src/constants/fulfillmentStatus";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import TransactionList from "../transactions/transaction-list";
import ItemQuantity from "./itemQuantity";
import DownloadReceipt from "../donationReceipts/download-receipt";
import CancelFulfillment from "./cancel-fulfillment";
import GenerateLabel from "./generate-label";
import icons from "src/assets/icons";
import { DateTime } from "luxon";

const MARK_FULFILLMENT_FULFILLED = gql`
  mutation ($input: MarkFulfillmentFulfilledInput!) {
    adminMarkFulfillmentAsFulfilled(input: $input) {
      uuid
    }
  }
`;

const getMarkFulfilledVariables = (uuid) => ({
  variables: {
    input: {
      uuid: uuid,
    },
  },
});

const orderDesc = (status, pickupBy) => {
  switch (status) {
    case "pickup_pending": {
      return `Pickup by ${moment(pickupBy).format("MMMM Do, YYYY")}`;
    }
    default: {
      return false;
    }
  }
};

const FulfillmentCard = ({
  distance,
  donor,
  donorRetailer,
  recipientNonprofit,
  pickupLocation,
  recipientLocation,
  items,
  pickupBy,
  priceInfo,
  status,
  type,
  statusDescription,
  notes,
  uuid,
  donationReceipt,
  updates,
  orderUUID,
  hours,
  fulfillmentOptions,
  noResaleAgreed,
  labels,
}) => {
  const [loading, setLoading] = useState(false);
  const [trackModalOpen, setTrackModalOpen] = useState(false);
  const [trackModalData, setTrackModalData] = useState({
    source: null,
    destination: null,
    status: 2,
  });
  const [markFulfilled] = useMutation(MARK_FULFILLMENT_FULFILLED);
  const history = useHistory();

  const handleMarkFulfilledClick = (fulfillmentUUID) => {
    setLoading(true);
    markFulfilled(getMarkFulfilledVariables(fulfillmentUUID))
      .then(() => window.location.reload())
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  const StatusTitle = (status) => status?.split("_").join(" ");

  const OnClickTrack = (address) => {
    setTrackModalData({
      ...trackModalData,
      source: {
        lng: recipientLocation?.address?.longitude,
        lat: recipientLocation?.address?.latitude,
      },
      destination: { lng: address?.longitude, lat: address?.latitude },
    });
    setTrackModalOpen(true);
  };

  return (
    <Card
      key={uuid}
      className="card card-body"
    >
      <CardHeader>
        <Row>
          <Col className="d-flex justify-content-end gap-x-2">
            {!FulfilledFulfillmentStatuses.includes(status) &&
              !CancelledFulfillmentStatuses.includes(status) && (
                <button
                  type="button"
                  className="btn btn-info"
                  disabled={loading}
                  onClick={() => {
                    handleMarkFulfilledClick(uuid);
                  }}
                >
                  <span>Fulfill</span>
                </button>
              )}
            <button
              type="button"
              className="btn btn-info mx-2"
              disabled={loading}
              onClick={() => {
                history.push(`/message-details/` + uuid);
              }}
            >
              <span>View conversation</span>
            </button>
            <DownloadReceipt
              donationReceipt={donationReceipt}
              fulfillment={{
                uuid: uuid,
                nonprofit: recipientNonprofit,
                status: status,
              }}
            />
            <GenerateLabel
              fulfillmentUUID={uuid}
              labels
              fulfillmentType={type}
            />
            <CancelFulfillment
              fulfillmentUUID={uuid}
              status={status}
            />
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md="6">
            <p
              style={{ fontWeight: 700, textTransform: "uppercase" }}
              className={`text-default font`}
            >
              FULFILLMENT# {uuid}
            </p>
            <p>
              {noResaleAgreed && (
                <span>Agreed to Restricted Use of Donation</span>
              )}
            </p>
            {orderUUID && (
              <p>
                <a
                  style={{
                    color: "tomato",
                    letterSpacing: -0.1,
                  }}
                  href={"/order/" + orderUUID}
                >
                  ORDER# {orderUUID}
                </a>
              </p>
            )}
          </Col>
          <Col
            md="6"
            className="d-flex justify-content-end"
          >
            <p
              style={{ fontWeight: 700, textTransform: "uppercase" }}
              className={`text-default font ${
                OnGoingFulfillmentStatuses.includes(status) && "c-crimson"
              }`}
            >
              {status}
            </p>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col
            md="4"
            sm="12"
          >
            {items?.map(({ product, quantity, uuid: puuid }, index) => (
              <div
                className="d-flex mb-2"
                key={puuid}
              >
                <div className={s.imgWrap}>
                  <a href={"/product/" + product.uuid}>
                    <img
                      src={
                        product.images &&
                        product.images.length > 0 &&
                        product.images[0].resolutions.s.url
                      }
                      alt={product?.title}
                    />
                  </a>
                </div>
                <div>
                  <p className="text-default mb-2">
                    <a href={"/product/" + product.uuid}>
                      <b>
                        <u>{product?.title}</u>
                      </b>
                    </a>
                  </p>
                  {product?.fairMarketValue && (
                    <p className="text-default mb-2">
                      <span>
                        Total FMV: &nbsp;
                        {`$`}
                        {product?.fairMarketValue?.value * quantity}
                      </span>
                    </p>
                  )}
                  {product?.weightLbs && (
                    <p className="text-default mb-2">
                      <span>
                        Total Weight: &nbsp;
                        {product?.weightLbs * quantity} lbs
                      </span>
                    </p>
                  )}
                  <div className="info text-default font-weight-bold">
                    <ItemQuantity
                      item={items[index]}
                      fulfillmentUUID={uuid}
                      fulfillmentStatus={status}
                    />
                  </div>
                  {product?.noResale && (
                    <p className="text-default mb-2">
                      <span>
                        {product?.noResale && (
                          <span>
                            <img
                              src={icons.info}
                              width={15}
                            />{" "}
                            No Resale
                          </span>
                        )}
                      </span>
                    </p>
                  )}
                </div>
              </div>
            ))}
          </Col>

          <Col
            md="4"
            sm="12"
          >
            <div>
              <h6 className="font-weight-bold c-black">
                <u>DONOR</u>
              </h6>
              <div className={`text-default`}>
                {donor?.user && (
                  <>
                    <b>
                      <a
                        style={{
                          color: "tomato",
                          letterSpacing: -0.1,
                        }}
                        href={"/users/search?uuid=" + donor?.user?.uuid}
                      >
                        <u>
                          {donor?.user?.firstName ||
                            "<no name>" + " " + donor?.user?.lastName}
                        </u>
                      </a>
                    </b>
                  </>
                )}
                {donorRetailer && (
                  <h5>
                    <a
                      style={{
                        color: "tomato",
                        letterSpacing: -0.1,
                      }}
                      href={"/retailers/details/" + donorRetailer?.uuid}
                    >
                      <u>{donorRetailer?.name}</u>
                    </a>
                  </h5>
                )}
              </div>
            </div>
          </Col>

          <Col
            md="4"
            sm="12"
          >
            <h6 className="font-weight-bold c-black">
              <u>FULFILLMENT METHOD</u>
            </h6>
            <p
              className={`mb-0 info text-default ${status}`}
              style={{ textTransform: "capitalize" }}
            >
              <b>{statusDescription || StatusTitle(status) || type}</b>
            </p>
            <div>
              {status === "delivery_in_progress" && (
                <p
                  style={{
                    textDecoration: "underline",
                    fontSize: "small",
                  }}
                  className={`cursor-pointer`}
                  onClick={() => OnClickTrack(pickupLocation?.address)}
                >
                  Track
                </p>
              )}
            </div>
            <div>
              {!!priceInfo ? (
                priceInfo.lineItems?.map(({ label, amount }, i) => (
                  <div
                    key={i}
                    className={`d-flex justify-content-between mt-2`}
                  >
                    {/* <p
                            style={{ marginRight: 40, fontWeight: 600 }}
                            className={`mb-2 c-black text-default`}
                          >
                            Delivery
                          </p> */}
                    <p
                      style={{ fontSize: "1rem", fontWeight: 600 }}
                      className="info mb-0"
                    >
                      {label} ${amount?.value}
                    </p>
                  </div>
                ))
              ) : (
                <p
                  style={{
                    textTransform: "capitalize",
                    fontSize: 16,
                    marginRight: 40,
                  }}
                  className={`mb-2 c-black info`}
                >
                  {orderDesc(status, pickupBy)}
                </p>
              )}
            </div>
            <div>
              <h6 className="font-weight-bold c-black">
                <u>FULFILLMENT OPTIONS</u>
              </h6>
              {fulfillmentOptions?.map((o, i) => (
                <div key={i}>
                  <p className="info mb-0">
                    {o?.type} ${o?.amount?.value}
                  </p>
                </div>
              ))}
            </div>
            {labels?.length > 0 && (
              <div>
                <h6 className="font-weight-bold c-black">
                  <u>LABELS</u>
                </h6>
                {labels?.map((l, i) => (
                  <div
                    key={i}
                    className="info my-2"
                  >
                    <p>
                      <a
                        href={l.trackingURL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {l.trackingNumber}
                      </a>{" "}
                      <span>{l.status}</span>
                      <br />
                      <a
                        href={l.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Download
                      </a>
                    </p>
                  </div>
                ))}
              </div>
            )}
            {hours && hours?.intervals?.length > 0 && (
              <div className="mt-3">
                <h6 className="font-weight-bold c-black">
                  <u>
                    {type === "pickup"
                      ? "PICKUP"
                      : type === "delivery"
                      ? "DELIVERY"
                      : "SHIPPING"}
                    REQUESTED FOR
                  </u>
                  {hours.intervals.map(
                    ({ date, startTimeLocal, endTimeLocal }, i) => (
                      <div key={i}>
                        <p
                          style={{ fontSize: "1rem", fontWeight: 600 }}
                          className="info mb-0"
                        >
                          {DateTime.fromISO(date)
                            .toUTC()
                            .toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
                        </p>
                        <p
                          style={{ fontSize: "1rem", fontWeight: 600 }}
                          className="info mb-0"
                        >
                          {DateTime.fromISO(startTimeLocal).toLocaleString(
                            DateTime.TIME_SIMPLE,
                          )}{" "}
                          to{" "}
                          {DateTime.fromISO(endTimeLocal).toLocaleString(
                            DateTime.TIME_SIMPLE,
                          )}{" "}
                          {hours?.timezone}
                        </p>
                      </div>
                    ),
                  )}
                </h6>
              </div>
            )}
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <Tabs>
              <TabList className="nav nav-tabs tab-coupon">
                <Tab className="nav-link d-flex align-items-center">
                  <Truck className="mr-2" />
                  Details
                </Tab>
                <Tab className="nav-link d-flex align-items-center">
                  <List className="mr-2" />
                  Transactions
                </Tab>
              </TabList>
              <TabPanel>
                <h4>PICKUP LOCATION</h4>
                <Row>
                  <Col
                    xs="12"
                    md="6"
                  >
                    <Bold>ADDRESS</Bold>
                    <Text>
                      <a
                        href={getGoogleMapsAddress(pickupLocation?.address)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {formatAddress(pickupLocation?.address)}
                      </a>
                      <a href="#">
                        <Copy
                          onClick={() => copyAddress(pickupLocation?.address)}
                        />
                      </a>
                    </Text>
                    <Text className="text-default">{distance}</Text>
                    {pickupLocation?.notes && (
                      <Text>
                        <i>Pickup Notes: </i>
                        {pickupLocation?.notes}
                      </Text>
                    )}
                  </Col>
                  {pickupLocation?.contactNumber && (
                    <Col
                      xs="12"
                      md="4"
                    >
                      <Bold>CONTACT NUMBER</Bold>
                      <Text>
                        <a
                          style={{
                            color: "tomato",
                            letterSpacing: -0.1,
                          }}
                          href={
                            "/users/search?q=" + pickupLocation?.contactNumber
                          }
                        >
                          <u>{pickupLocation?.contactNumber}</u>
                        </a>
                      </Text>
                      {status === "pickup_pending" && (
                        <>
                          <p className="mb-2 text-default">
                            Contact Number: {pickupLocation?.contactNumber}
                          </p>
                          <p className="mb-2 text-default">
                            Masked Number: {pickupLocation?.maskedContactNumber}
                          </p>
                        </>
                      )}
                      <div>
                        {status == "ongoing" &&
                          recipientLocation?.maskedContactNumber && (
                            <div>
                              <h6 className="font-weight-bold c-black">
                                <u>RECIPIENT MASKED NUMBER</u>
                              </h6>
                              <h5>{recipientLocation?.maskedContactNumber}</h5>
                            </div>
                          )}
                      </div>
                    </Col>
                  )}
                  {pickupLocation?.contactEmail && (
                    <Col
                      xs="12"
                      md="4"
                    >
                      <Bold>CONTACT EMAIL</Bold>
                      <Text>
                        <a
                          style={{
                            color: "tomato",
                            letterSpacing: -0.1,
                          }}
                          href={
                            "/users/search?q=" + pickupLocation?.contactEmail
                          }
                        >
                          <u>{pickupLocation?.contactEmail}</u>
                        </a>
                      </Text>
                    </Col>
                  )}
                </Row>
                <hr />
                <Row>
                  <Col
                    sm="12"
                    md="6"
                  >
                    <FulfillmentUpdate data={updates} />
                  </Col>
                  <Col
                    sm="12"
                    md="6"
                  >
                    <Notes
                      notes={notes}
                      objectUuid={uuid}
                      objectType={"fulfillment"}
                    />
                  </Col>
                </Row>
              </TabPanel>
              <TabPanel>
                <TransactionList
                  retailerUUID={donorRetailer?.uuid || donor?.retailer?.uuid}
                  fulfillmentUUID={uuid}
                  hideRetailerSearch={true}
                  hideDateSelector={true}
                />
              </TabPanel>
            </Tabs>
          </Col>
        </Row>
      </CardBody>
      <TrackModal
        open={trackModalOpen}
        onClose={() => setTrackModalOpen(false)}
        data={trackModalData}
      />
    </Card>
  );
};

export default FulfillmentCard;
