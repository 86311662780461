import React, { useState, useEffect } from "react";
import admin from "../../../assets/images/admin.jpg";
import { LIQUID_COOKIE, LIQUID_USER } from "../../../constants/cookie";
import Cookies from "universal-cookie";

const UserPanel = () => {
  const [user, setUser] = useState("");

  useEffect(() => {
    // get the authentication token from cookie if it exists
    const cookies = new Cookies();
    const liquid_cookie = cookies.get(LIQUID_COOKIE);
    if (liquid_cookie) {
      const loser = liquid_cookie[LIQUID_USER]; // lUser = loser :)
      setUser(loser);
    }
  }, []);

  const getName = () => {
    return [user?.firstName, user?.lastName].filter((n) => n).join(" ");
  };

  return (
    <div>
      <div className="sidebar-user text-center">
        <div>
          <img
            className="img-60 rounded-circle lazyloaded blur-up"
            src={admin}
            alt="#"
          />
        </div>
        <h6 className="mt-3 f-14">{getName()}</h6>
        <span className="f-14">{user?.email}</span>
      </div>
    </div>
  );
};

export default UserPanel;
