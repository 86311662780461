import { gql } from "@apollo/client";
import React, { Fragment } from "react";
import { useState } from "react";
import { File, Upload } from "react-feather";
import { toast, ToastContainer } from "react-toastify";
import { Button, Card, Container, Row, Spinner } from "reactstrap";
import { initializeApollo } from "../../../helpers/apollo";
import Breadcrumb from "../../common/breadcrumb";
import phoneFormatter from "phone-formatter";

import SearchLocationInput from "../../common/SearchLocationInput";
import useFormattedPhone from "../../../helpers/hooks/useFormattedPhone";

import pstyles from "./products.module.scss";
import styles from "../../donate/donate.module.scss";

import {
  id,
  get,
  set,
  insMap,
  delMap,
  eventValue,
  setField,
} from "../../../helpers/utils";

const ADD_PRODUCTS = gql`
  mutation adminUploadProductsCSV($data: AdminUploadProductsCSVInput!) {
    adminUploadProductsCSV(input: $data) {
      numberOfProductsUploaded
      errors {
        rowNumber
        field
        error
      }
    }
  }
`;

const UploadProductCsv = () => {
  const file = useState(null);
  const loc = useState({});
  const phone = useFormattedPhone("");
  const [notes, setNotes] = useState("");
  const [donatedByStoreUUID, setDonatedByStoreUUID] = useState("");
  const csvErrors = useState([]);
  const inputErrors = useState(new Map());

  const [numberOfProductsUploaded, setNumberOfProductsUploaded] =
    useState(null);
  const [loading, setLoading] = useState(false);

  const displayError = (k) => {
    return (
      get(inputErrors).get(k) && (
        <p className="error mb-0 mt-0"> {get(inputErrors).get(k)}</p>
      )
    );
  };

  const clearErrors = () => {
    set(csvErrors)([]);
  };
  const setError = (k) => (v) => {
    insMap(inputErrors)(k)(v);
  };
  const clearError = (k) => {
    delMap(inputErrors)(k);
  };

  const onPhone = eventValue((v) => {
    clearError("phone");
    set(phone)(v);
  });
  const checkPhone = (_e) => {
    const displayed = get(phone);
    if (!displayed) {
      setError("phone")("this field is required");
      return true;
    }

    setError("phone")("invalid phone number");
    const e164 = phoneFormatter.format(displayed, "+1NNNNNNNNNN");
    const E164 = /^\+[1-9]\d{1,14}$/;
    if (!e164.match(E164)) {
      return true;
    }

    clearError("phone");
    setField(loc)("contactNumber")(e164);
    return false;
  };

  const onAddr = (v) => {
    setField(loc)("address")(v);
    if (addrBad(v)) {
      setError("address")("this field is required");
      return true;
    }
    clearError("address");
    return false;
  };
  const checkAddr = (_e) => {
    if (addrBad(get(loc).address)) {
      setError("address")("missing");
      return true;
    }

    clearError("address");
    return false;
  };
  const addrBad = (a) => !a;

  const onClickUpload = () => {
    document.getElementById("file").click();
  };
  const onFileChange = (e) => {
    const f = e.target.files[0];
    set(file)(f);
    if (!f.type.includes("csv")) {
      setError("select")("Selected file is not a CSV");
      return;
    }

    clearError("select");
    return;
  };
  const checkFile = (_e) => {
    if (!get(file)) {
      setError("select")("CSV file missing");
      return true;
    }

    clearError("select");
    return false;
  };

  const checkAll = () =>
    [checkFile(), checkAddr(), checkPhone()].filter(id).length;

  const onSubmit = async () => {
    clearError("submit");
    clearErrors();

    if (checkAll()) {
      setError("submit")("Please fix any errors above");
      return;
    }

    setLoading(true);

    var client = initializeApollo();
    client
      .mutate({
        mutation: ADD_PRODUCTS,
        variables: {
          data: {
            csvFile: get(file),
            storeUUID: donatedByStoreUUID || null,
            location: { ...get(loc), notes },
          },
        },
      })
      .then((res) => {
        const { numberOfProductsUploaded, errors } =
          res.data.adminUploadProductsCSV;
        if (errors) {
          setError("select")("CSV contains errors");
          set(csvErrors)(errors);
          setLoading(false);
          return;
        }
        setNumberOfProductsUploaded(numberOfProductsUploaded);
        toast.success("CSV uploaded successfully!", {
          toastId: "csv_success",
        });
        set(file)(null);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.message || "something might went wrong!");
      });
  };

  const handleEditNotes = (e) => {
    setNotes(e.target.value);
  };

  return (
    <Fragment>
      <Breadcrumb
        title="Product Bulk Upload"
        parent="Physical"
      />
      <Container fluid={true}>
        <Card>
          <Row
            xs="12"
            className="mb-3 d-flex align-items-center w-full"
            nogutters="true"
          >
            <div className="products-admin mx-auto my-5">
              <div className="my-5">
                <div
                  className="csvUploadBox"
                  onClick={onClickUpload}
                >
                  <Upload className="fs-20" />
                  <p className="mt-3">Please select a csv file to upload</p>
                  {!!get(file) && (
                    <div className="c-primary d-flex items-center">
                      <File className="fs-14" />{" "}
                      <p className="c-black fs-16">{get(file).name}</p>
                    </div>
                  )}
                  <input
                    id="file"
                    type="file"
                    hidden
                    onChange={onFileChange}
                  />
                  {displayError("select")}
                </div>
                <label className="mt-2 mb-1">Donated By Store UUID</label>
                {displayError("donated_by_store_uuid")}
                <input
                  className={styles.input + " form-control w-100 w-full"}
                  value={donatedByStoreUUID}
                  type="text"
                  onChange={(e) => setDonatedByStoreUUID(e.target.value)}
                />
                <label className="mb-1 mt-3">
                  Pickup Address<span className="c-crimson">*</span>
                </label>
                <SearchLocationInput
                  required={true}
                  value={get(loc).address}
                  setAddress={onAddr}
                  styles={{
                    height: "40px",
                    marginBottom: "0px",
                    padding: "0px 10px",
                    fontSize: "15px",
                  }}
                />
                {displayError("address")}
                <label className="mt-2 mb-1">
                  Pickup Phone<span className="c-crimson">*</span>
                </label>
                <input
                  className={styles.input + " form-control w-100 w-full"}
                  required={true}
                  value={get(phone)}
                  id="phone"
                  type="text"
                  onChange={onPhone}
                  onBlur={checkPhone}
                />
                {displayError("phone")}
                <label className="mt-2 mb-1">Pickup Notes</label>
                {displayError("notes")}
                <input
                  className={styles.input + " form-control w-100 w-full"}
                  value={notes}
                  type="text"
                  onChange={handleEditNotes}
                />
                <div>
                  <Button
                    className="btn btn-solid mt-3 selectBtn"
                    onClick={onSubmit}
                  >
                    {loading ? <Spinner /> : "Upload"}
                  </Button>
                </div>
                <div>{displayError("submit")}</div>
                {!!get(csvErrors).length && (
                  <div className="mt-5">
                    <h4 className="c-black">
                      CSV file contains the following errors:
                    </h4>
                    <table className={pstyles.errortable}>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Row</th>
                          <th>Field</th>
                          <th>Error</th>
                        </tr>
                      </thead>
                      <tbody>
                        {get(csvErrors).map((e, i) => (
                          <tr key={i}>
                            <td className={pstyles.number}>{i + 1}</td>
                            <td className={pstyles.number}>{e.rowNumber}</td>
                            <td className={pstyles.field}>{e.field}</td>
                            <td className={pstyles.error}>
                              <span className="error mb-0">{e.error}</span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
                {!!numberOfProductsUploaded && numberOfProductsUploaded > 0 && (
                  <div className="mt-5">
                    <h4>
                      <i className="fa fa-check-circle font-success" />{" "}
                      Successfully added {numberOfProductsUploaded} products
                    </h4>
                  </div>
                )}
              </div>
            </div>
          </Row>
        </Card>
        <ToastContainer />
      </Container>
    </Fragment>
  );
};

export default UploadProductCsv;
