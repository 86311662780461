import React from "react";
import { Link } from "react-router-dom";

const getBadgeForRecipientType = (status) => {
  switch (status) {
    case "retailer":
      return "badge-danger";
    case "nonprofit":
      return "badge-success";
    case "individual":
      return "badge-warning";
  }
};

const getUserName = (user) => {
  const parts = [user.firstName, user.lastName];
  return parts.filter((p) => p).join(" ") || "<no name>";
};

const userDetailsFormatter = (userDetails, donorRetailer) => {

  if (!userDetails && !donorRetailer) {
    return;
  }
  const userType = donorRetailer
    ? "retailer"
    : userDetails?.nonprofit
    ? "nonprofit"
    : userDetails?.retailer
    ? "retailer"
    : "individual";

  let to = "";
  let name = "";

  switch (userType) {
    case "nonprofit":
      to = `/nonprofit/${userDetails?.nonprofit?.uuid}`;
      name = userDetails?.nonprofit?.organizationName;
      break;
    case "retailer":
      if (donorRetailer) {
        to = `/retailers/details/${donorRetailer?.uuid}`;
        name = donorRetailer?.name;
      } else {
        to = `/retailers/details/${userDetails?.retailer?.uuid}`;
        name = userDetails?.retailer?.name;
      }
      break;
    case "individual":
      to = `/users/search?uuid=${userDetails?.user?.uuid}`;
      name = getUserName(userDetails?.user);
      break;
  }

  return (
    <Link
      target="_blank"
      to={to}
      key={userDetails?.user?.uuid ?? donorRetailer?.uuid}
    >
      {name} &nbsp;&nbsp;
      <span className={`badge ${getBadgeForRecipientType(userType)}`}>
        {userType}
      </span>
    </Link>
  );
};

export default userDetailsFormatter;
