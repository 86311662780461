import React, { Fragment, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { gql, useMutation } from "@apollo/client";
import { toast, ToastContainer } from "react-toastify";
import { err_toast } from "src/helpers/errors";

const ADMIN_MIGRATE_RETAILER_DATA = gql`
    mutation adminMigrateRetailerData($srcRetailerUUID: String!, $destRetailerUUID: String!) {
        adminMigrateRetailerData(srcRetailerUUID: $srcRetailerUUID, destRetailerUUID: $destRetailerUUID) 
    }
`;

const MergeRetailers = () => {
  const [srcRetailerUUID, setSrcRetailerUUID] = useState("");
  const [destRetailerUUID, setDestRetailerUUID] = useState("");

  const [adminMigrateRetailerData] = useMutation(ADMIN_MIGRATE_RETAILER_DATA);
  const mergeRetailerData = () => {
    if (!srcRetailerUUID) {
      toast.error("need a SrcRetailerUUID");
      return;
    }

    if (!destRetailerUUID) {
      toast.error("need a DestRetailerUUID");
      return;
    }
    adminMigrateRetailerData({
      variables: {
        srcRetailerUUID: srcRetailerUUID,
        destRetailerUUID: destRetailerUUID,
      },
    })
      .then((_) => {
        toast.success("Successfully migrated retailer data");
        setSrcRetailerUUID("");
        setDestRetailerUUID("");
      })
      .catch((err) => {
        err_toast(err);
      });
  };

  return (
    <Fragment>
      <Breadcrumb
        title="Merge Retailers"
        parent="Retailers"
      />
      <Container fluid={true}>
        <Card>
          <CardHeader>
            <h4 style={{ color: "red" }}>
              Caution: Use this tool if you want all the data from one retailer
              to be migrated to another
            </h4>
            <p>
              The following data will be migrated from <b>SrcRetailerUUID</b> to{" "}
              <b>DestRetailerUUID</b>{" "}
            </p>
            <ul>
              <li>APIKey</li>
              <li>DonationReceipt</li>
              <li>EasyPostAPIKey</li>
              <li>Fulfillment</li>
              <li>Invoice</li>
              <li>ItemDonation</li>
              <li>LoopAPIKey</li>
              <li>PaymentMethod</li>
              <li>Pricing</li>
              <li>Product</li>
              <li>Match</li>
              <li>ReturnAuthorization</li>
              <li>RetailerSku</li>
              <li>RetailerUser</li>
              <li>SelectNonprofit</li>
              <li>Shipment</li>
              <li>Location</li>
              <li>StripeCustomer</li>
              <li>Transaction</li>
              <li>Webhook</li>
            </ul>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="4">
                <label>SrcRetailerUUID</label>
                <input
                  type="text"
                  value={srcRetailerUUID}
                  onChange={(e) => setSrcRetailerUUID(e.target.value)}
                ></input>
              </Col>
              <Col md="4">
                <label>DestRetailerUUID</label>
                <input
                  type="text"
                  value={destRetailerUUID}
                  onChange={(e) => setDestRetailerUUID(e.target.value)}
                ></input>
              </Col>
              <Col md="4">
                <button onClick={() => mergeRetailerData()}>Merge</button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
      <ToastContainer />
    </Fragment>
  );
};

export default MergeRetailers;
