import React from "react";
import { gql, useLazyQuery } from "@apollo/client";
import SelectSearch from "react-select-search";
import "../../retailers/retailer.scss";

const GET_ADMIN_PRODUCTS = gql`
  query getAdminProducts($input: GetAdminProductsInput!) {
    getAdminProducts(input: $input) {
      total
      items {
        uuid
        slug
        sku
        productClass {
          uuid
        }
        title
        availableQuantity
        quantity
        createdDate
        categories {
          name
        }
        conditionTags {
          label
          type
        }
        location {
          address {
            state
            city
          }
        }
        images {
          resolutions {
            xs {
              url
            }
          }
        }
      }
      hasMore
    }
  }
`;

const ProductDropdown = ({ retailerUUID, product, setProduct, style }) => {
  const PRODUCTS_VARIABLES = {
    fetchPolicy: "no-cache",
    variables: {
      input: {
        retailerUUID: retailerUUID,
        sortOrder: "availability",
        indexFrom: 0,
        limit: 10,
      },
    },
  };

  const [getAdminProducts] = useLazyQuery(
    GET_ADMIN_PRODUCTS,
    PRODUCTS_VARIABLES,
  );

  const getProductOptions = (query) => {
    return new Promise((resolve, reject) => {
      if (query) {
        getAdminProducts({
          fetchPolicy: "no-cache",
          variables: {
            input: {
              retailerUUID: retailerUUID,
              indexFrom: 0,
              sortOrder: "availability",
              limit: 10,
              term: query,
            },
          },
        })
          .then((response) => {
            const opts = response?.data?.getAdminProducts?.items?.map(
              ({ title, uuid }) => ({
                name: title,
                value: uuid,
              }),
            );
            resolve(opts);
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        resolve([]);
      }
    });
  };

  const handleSetProduct = (uuid, { name }) => {
    setProduct({ uuid, title: name });
  };

  const onClear = (_evt) => {
    setProduct(null);
  };

  return (
    <div
      {...{
        style: { ...style, display: "flex", padding: 0, background: "#dfdfdf" },
        className: "form-control",
      }}
    >
      <SelectSearch
        getOptions={getProductOptions}
        search
        placeholder="Product search"
        onChange={handleSetProduct}
        style={style}
      />
      {product?.uuid && (
        <div
          {...{
            style: {
              width: "fit-content",
              display: "flex",
              maxWidth: "140px",
              overflow: "scroll",
              alignItems: "center",
              padding: "0.5em",
              gap: "0.5em",
            },
          }}
        >
          {product?.title}
          <button
            {...{
              onClick: onClear,
              style: {
                border: "none",
                background: "none",
                padding: 0,
              },
            }}
          >
            {"\u2715"}
          </button>
        </div>
      )}
    </div>
  );
};

export default ProductDropdown;
