import React from "react";
import { money_to_str } from "../helpers/utils";
import moment from "moment";
import { Link } from "react-router-dom";

const mapStatusToBadge = {
  open: "badge-secondary",
  awaiting_payment: "badge-warning",
  processing: "badge-warning",
  retry: "badge-danger",
  failed: "badge-danger",
  closed: "badge-success",
};

const mapStatusToLabel = {
  pending: "Current",
  open: "In Review",
  closed: "Paid",
};

const invoicesFormatter = (invoices) => {
  if (!invoices?.length) {
    return;
  }

  const formattedInvoices = invoices.map((d) => {
    return {
      uuid: (
        <Link
          key={d.uuid}
          target="_blank"
          to={`/invoice/${d?.uuid}`}
        >
          {d.uuid}
        </Link>
      ),
      status: (
        <span
          key={d.uuid}
          className={`badge ${mapStatusToBadge[d.status]}`}
        >
          {mapStatusToLabel[d.status] || d.status}
        </span>
      ),
      Retailer: (
        <Link
          key={d.retailer.uuid}
          target="_blank"
          to={`./retailers/details/${d.retailer.uuid}`}
        >
          {d.retailer.name} &nbsp;&nbsp;
        </Link>
      ),
      "Start Date": moment(d.startDate).utc().format("DD MMM, YYYY"),
      "End Date": moment(d.endDate).utc().format("DD MMM, YYYY"),
      "Total Items": d.totalItems,
      Total: money_to_str(d.total),
      "Donation Service Fees": money_to_str(d.donationServiceFees),
      "Shipping Fees": money_to_str(d.shippingFees),
      "Delivery Fees": money_to_str(d.deliveryFees),
      "Service Fees": money_to_str(d.serviceFees),
      created: moment(d.createdAt).format("DD MMM, YYYY hh:mm:SS A"),
    };
  });

  return formattedInvoices;
};

export default invoicesFormatter;
