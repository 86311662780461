import React, { useState } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import Datatable from "../common/datatable";
import Text from "../common/text";
import styles from "../donate/donate.module.scss";

const StoreList = ({ title, stores, loading }) => {
  const [limit, setLimit] = useState(10);
  const [_, setIndexFrom] = useState(0);

  const onChangePage = (newPage, _) => {
    setIndexFrom((newPage - 1) * limit);
  };

  const onChangeRowsPerPage = (newPerPage, page) => {
    setIndexFrom((page - 1) * newPerPage);
    setLimit(newPerPage);
  };

  return (
    <Card className={styles.donateItemEdit}>
      {title && (
        <CardHeader>
          <h3>{{ title }}</h3>
        </CardHeader>
      )}
      <CardBody>
        {stores && stores?.length ? (
          <Datatable
            hideActions={true}
            myData={stores}
            loading={loading}
            pageSize={limit}
            pagination={true}
            paginationServer={false}
            totalRows={stores?.length}
            myClass="-striped -highlight"
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
          />
        ) : (
          <Text>No stores</Text>
        )}
      </CardBody>
    </Card>
  );
};

export default StoreList;
