import React, { Fragment } from "react";
import MatchesList from "./matchesList";
import Breadcrumb from "../common/breadcrumb";
import { Container, Col, Row } from "reactstrap";

const Matches = () => {
  return (
    <Fragment>
      <Breadcrumb
        title="Matches"
        parent="Dashboard"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <MatchesList />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Matches;
