import React from "react";
import { Card, CardBody, Col, Row, Spinner, CardHeader } from "reactstrap";
import { gql, useMutation } from "@apollo/client";
import { initializeApollo } from "../../helpers/apollo";
import { useState } from "react";
import styles from "./users.module.scss";
import {
  Globe,
  Home,
  Info,
  Mail,
  Phone,
  ExternalLink,
  Copy,
  DollarSign,
  Box,
  UserPlus,
  Send,
  Trash,
} from "react-feather";
import { useHistory } from "react-router-dom";
import OrdersList from "../sales/orders/ordersList";
import ProductsList from "../products/physical/product-list";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import RolesAndGroups from "../perms/rolesAndGroups";
import SendgridEvents from "../sendgrid/events";
import { err_toast } from "src/helpers/errors";
import ConfirmationModal from "../modals/ConfirmationModal";
import { toast } from "react-toastify";

const ADMIN_VERIFY_USER = gql`
  mutation adminVerifyUser($userUUID: String!) {
    adminVerifyUser(userUUID: $userUUID) {
      user {
        uuid
      }
    }
  }
`;

const ADMIN_DELETE_USER = gql`
  mutation adminDeleteUser($userUUID: String!) {
    adminDeleteUser(userUUID: $userUUID)
  }
`;

const USER_DETAILS_OPTIONS = [
  {
    id: 1,
    title: "Email",
    icon: <Mail />,
    color: "orange",
    attribute: "email",
  },
  {
    id: 2,
    title: "Phone Number",
    icon: <Phone />,
    color: "#dc3545",
    attribute: "phoneNumber",
  },
];

const NONPROFIT_DETAILS_OPTIONS = [
  {
    id: 1,
    title: "Organization Name",
    icon: <Home />,
    color: "#721cca",
    attribute: "organizationName",
  },
  {
    id: 2,
    title: "Business Phone",
    icon: <Phone />,
    color: "#13c9ca",
    attribute: "businessPhone",
  },
  {
    id: 3,
    title: "EIN",
    icon: <Info />,
    color: "#e22199",
    attribute: "ein",
  },
  {
    id: 4,
    title: "Website",
    icon: <Globe />,
    color: "#007bff",
    attribute: "website",
  },
];

const RETAILER_DETAILS_OPTIONS = [
  {
    id: 1,
    title: "Name",
    icon: <Home />,
    color: "#721cca",
    attribute: "name",
  },
  {
    id: 2,
    title: "Business Phone",
    icon: <Phone />,
    color: "#13c9ca",
    attribute: "businessPhoneNumber",
  },
  {
    id: 3,
    title: "Description",
    icon: <Info />,
    color: "#e22199",
    attribute: "description",
  },
  {
    id: 4,
    title: "Website",
    icon: <Globe />,
    color: "#007bff",
    attribute: "website",
  },
];

const UserDetails = ({ user, nonprofit, retailer }) => {
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [deleteUserModalOpen, setDeleteUserModalOpen] = useState(false);

  const client = initializeApollo();
  const history = useHistory();

  const isSuperAdmin = () => {
    return (
      user &&
      ((user?.groups && user?.groups?.indexOf("super_admin") !== -1) ||
        (user?.roles && user?.roles?.indexOf("super_admin") !== -1))
    );
  };

  const [adminDeleteUser, { loading: deleteUserLoading }] =
    useMutation(ADMIN_DELETE_USER);
  const onConfirmDeleteUser = () => {
    adminDeleteUser({
      variables: {
        userUUID: user?.uuid,
      },
    })
      .then(() => {
        history.push("/users/search");
        setDeleteUserModalOpen(false);
      })
      .catch((err) => toast.error(err.message));
  };

  const verifyUser = () => {
    if (!user) {
      return;
    }

    setVerifyLoading(true);
    client
      .mutate({
        mutation: ADMIN_VERIFY_USER,
        variables: {
          userUUID: user?.uuid,
        },
      })
      .then((_res) => {
        setVerifyLoading(false);
        user.verifiedEmail = true;
      })
      .catch((err) => {
        setVerifyLoading(false);
        err_toast(err);
      });
  };

  if (!user) {
    return (
      <Card>
        <CardBody>No user</CardBody>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col className="d-flex align-items-start justify-content-end">
            <div>
              <button
                className="btn btn-danger"
                onClick={() => setDeleteUserModalOpen(true)}
                disabled={isSuperAdmin()}
              >
                <Trash size={15} /> Delete User
              </button>
              <br />
              {isSuperAdmin() && <span>Can&#39;t delete super admins</span>}
            </div>
          </Col>
        </Row>

        <ConfirmationModal
          open={deleteUserModalOpen}
          onClose={() => setDeleteUserModalOpen(false)}
          title={"Delete user"}
          desc={<>Are you sure you want to delete user?</>}
          onConfirm={onConfirmDeleteUser}
          loading={deleteUserLoading}
        />
      </CardHeader>
      <CardBody>
        <Row>
          <Col
            sm="12"
            md="12"
            lg="4"
          >
            <Card>
              <Row></Row>
              <CardBody className="d-flex align-items-center flex-column justify-content-center">
                <img
                  className={styles.avatar}
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSgSmojUgwjIB87c4Q0hLCAyl__oiTySWGWJUZtUNHlHjBALLzTsu_vMHYMaEwLts4QEoo&usqp=CAU"
                  alt="#"
                />
                <h2 className="mt-4 mb-3">
                  {user?.firstName + " " + user?.lastName}
                </h2>
                <span>
                  {user?.uuid}

                  <Copy
                    style={{
                      cursor: "pointer",
                      color: "tomato",
                      letterSpacing: -0.1,
                    }}
                    onClick={() => {
                      navigator.clipboard.writeText(user?.uuid);
                    }}
                  ></Copy>
                </span>
              </CardBody>
            </Card>
          </Col>
          <Col
            sm="12"
            md="12"
            lg="4"
          >
            <Card>
              <CardBody>
                <h2 className={styles.header}>User Details</h2>
                {USER_DETAILS_OPTIONS.map((d, i) => (
                  <div
                    className={styles.detailItem}
                    key={i}
                  >
                    {((d.id === 2 && user?.verifiedPhoneNumber) ||
                      (d.id === 1 && user?.verifiedEmail)) && (
                      <div className={styles.status}>VERIFIED</div>
                    )}
                    {d.id === 1 && !user?.verifiedEmail && user?.email && (
                      <div
                        onClick={verifyUser}
                        className={styles.status}
                      >
                        {verifyLoading ? (
                          <Spinner size="sm" />
                        ) : (
                          <span style={{ color: "tomato" }}>VERIFY</span>
                        )}
                      </div>
                    )}
                    <div
                      className={styles.icon}
                      style={{ background: d.color }}
                    >
                      {d.icon}
                    </div>
                    <div className={`${styles.detail} ml-3`}>
                      <h4>{d.title}</h4>
                      <p>
                        {(d.id === 3 || d.id === 4
                          ? user[d?.attribute]?.join("/")
                          : user[d.attribute]) || "-"}
                      </p>
                    </div>
                  </div>
                ))}
              </CardBody>
            </Card>
          </Col>
          {nonprofit && (
            <Col
              sm="12"
              md="12"
              lg="4"
            >
              <Card>
                <CardBody>
                  <h2
                    onClick={() =>
                      history.push("/nonprofit/" + nonprofit?.uuid)
                    }
                    className={`${styles.header} cursor-pointer`}
                  >
                    Nonprofit <ExternalLink />
                  </h2>
                  {NONPROFIT_DETAILS_OPTIONS.map((d, i) => (
                    <div
                      className={styles.detailItem}
                      key={i}
                    >
                      <div
                        className={styles.icon}
                        style={{ background: d.color }}
                      >
                        {d.icon}
                      </div>
                      <div className={`${styles.detail} ml-3`}>
                        <h4>{d.title}</h4>
                        <p>{nonprofit?.[d.attribute] || "-"}</p>
                      </div>
                    </div>
                  ))}
                </CardBody>
              </Card>
            </Col>
          )}
          {retailer && (
            <Col
              sm="12"
              md="12"
              lg="4"
            >
              <Card>
                <CardBody>
                  <h2
                    onClick={() =>
                      history.push("/retailers/details/" + retailer?.uuid)
                    }
                    className={`${styles.header} cursor-pointer`}
                  >
                    Retailer <ExternalLink />
                  </h2>
                  {RETAILER_DETAILS_OPTIONS.map((d, i) => (
                    <div
                      className={styles.detailItem}
                      key={i}
                    >
                      <div
                        className={styles.icon}
                        style={{ background: d.color }}
                      >
                        {d.icon}
                      </div>
                      <div className={`${styles.detail} ml-3`}>
                        <h4>{d.title}</h4>
                        <p>{retailer?.[d.attribute] || "-"}</p>
                      </div>
                    </div>
                  ))}
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>

        <Row>
          <Col>
            <Tabs>
              <TabList className="nav nav-tabs tab-coupon">
                <Tab className="nav-link">
                  <DollarSign className="mr-2" /> Orders Placed
                </Tab>
                <Tab className="nav-link">
                  <Box className="mr-2" /> Donated Products
                </Tab>
                <Tab className="nav-link">
                  <UserPlus className="mr-2" /> Groups And Roles
                </Tab>
                <Tab className="nav-link">
                  <Send className="mr-2" /> Sendgrid Events
                </Tab>
              </TabList>

              <TabPanel>
                <OrdersList
                  userUUID={user?.uuid}
                  userSearch={true}
                  hideNonprofitSearch={true}
                />
              </TabPanel>
              <TabPanel>
                <ProductsList userUUID={user?.uuid} />
              </TabPanel>

              <TabPanel>
                <RolesAndGroups
                  selectedRoles={user?.roles}
                  selectedGroups={user?.groups}
                  userUUID={user?.uuid}
                />
              </TabPanel>
              <TabPanel>
                <SendgridEvents userUUID={user?.uuid} />
              </TabPanel>
            </Tabs>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
export default UserDetails;
