import React from "react";
import { BarChart, Copy, Edit, MapPin, Trash } from "react-feather";
import { Button, Card, CardBody, Row, Col } from "reactstrap";
import { formatAddress } from "../../../helpers/address";
import product_styles from "./products.module.scss";
import moment from "moment";
import cx from "classnames";

const ProductItem = ({
  myData,
  onClickEdit,
  onClickDelete,
  onClickNonProfits,
  onOpenProductDetails,
  copyToClipboard,
  hasAddress,
}) => {
  const isProductInStock = myData.availableQuantity > 0;

  let productStatusClassName;
  switch (myData?.status) {
    case "active":
      productStatusClassName = product_styles.active;
      break;
    case "inactive":
      productStatusClassName = product_styles.inactive;
      break;
    case "donated":
      productStatusClassName = product_styles.donated;
      break;
    case "expired":
      productStatusClassName = product_styles.expired;
      break;
    default:
      productStatusClassName = product_styles.active;
      break;
  }

  return (
    <Col
      xl="3"
      lg="3"
      md="4"
      sm="6"
      xs="6"
    >
      <Card>
        <div className="products-admin">
          <CardBody
            style={{ padding: "0px", position: "relative" }}
            className="product-box"
          >
            <div className="img-wrapper">
              <div
                className={cx(product_styles.ribbon, productStatusClassName)}
              >
                <span>
                  {moment(myData?.createdDate).format("DD MMM, YYYY")}
                </span>
              </div>
              <div className="front">
                <div className={product_styles.imageContainer}>
                  <img
                    alt=""
                    className="img-fluid blur-up bg-img lazyloaded w-full object-cover"
                    src={myData.images[0].resolutions.m.url}
                  />
                  <div
                    className="product-hover"
                    style={{ cursor: "pointer" }}
                  >
                    <ul>
                      <li>
                        <Button
                          color="btn"
                          type="button"
                          onClick={() => onClickEdit(myData)}
                        >
                          <Edit />
                        </Button>
                      </li>
                      <li>
                        <Button
                          color="btn"
                          type="button"
                          onClick={() => onClickNonProfits(myData)}
                        >
                          <BarChart />
                        </Button>
                      </li>
                      <li>
                        <Button
                          color="btn"
                          type="button"
                          onClick={() => copyToClipboard(myData.slug)}
                        >
                          <Copy />
                        </Button>
                      </li>
                      <li>
                        <Button
                          color="btn"
                          type="button"
                          onClick={() => onClickDelete(myData.uuid)}
                        >
                          <Trash className="copyBtn" />
                        </Button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="product-detail mt-2">
              <Row>
                <Col
                  xs="12"
                  sm="8"
                >
                  <h6
                    className={`${product_styles.title} cursor-pointer`}
                    onClick={() => onOpenProductDetails(myData.slug)}
                  >
                    {myData.title}
                  </h6>
                </Col>
                <Col
                  xs="12"
                  sm="4"
                  className="d-flex justify-content-end"
                >
                  {myData.donorRetailer ? (
                    <p
                      className={cx(
                        product_styles.donor,
                        product_styles.donorRetailer,
                      )}
                    >
                      {myData.donorRetailer.name}
                    </p>
                  ) : myData.donor ? (
                    <p className={product_styles.donor}>
                      {myData.donor?.firstName + " " + myData.donor?.lastName}
                    </p>
                  ) : (
                    <p className={product_styles.donor}>Unknown donor</p>
                  )}
                </Col>
              </Row>
              <Row>
                <Col
                  xs="12"
                  sm="8"
                >
                  <span className={`${product_styles.location} mb-0`}>
                    <MapPin
                      size={14}
                      style={{
                        opacity: 0.6,
                        marginRight: "2px",
                        marginLeft: "-2px",
                      }}
                    />{" "}
                    {formatAddress(myData.location.address, true)}
                  </span>
                </Col>
                <Col
                  xs="12"
                  sm="4"
                  className="d-flex justify-content-end"
                >
                  <span>
                    {myData.availableQuantity} of {myData.quantity}
                  </span>
                </Col>
              </Row>
              <div className="d-flex justify-content-between mt-3">
                {myData.fairMarketValue && (
                  <h3 className={product_styles.money}>
                    <del>
                      <span className="money">
                        {`$`}
                        {myData.fairMarketValue?.value}
                      </span>
                    </del>
                  </h3>
                )}
                <span
                  style={{
                    opacity: !hasAddress && 0,
                  }}
                  className={`${product_styles.miles} mb-0`}
                >
                  {myData.distanceMiles || "0.00"} miles
                </span>
              </div>
            </div>
          </CardBody>
          {!isProductInStock && (
            <div className={product_styles.outofstockOverlay}></div>
          )}
        </div>
      </Card>
    </Col>
  );
};

export default ProductItem;
