import React, { useEffect, useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import styles from "../donate.module.scss";
import Delivery from "../../../assets/images/svgs/delivery.svg";
import Remove from "../../../assets/images/svgs/remove.svg";
import Edit from "../../../assets/images/svgs/Edit.svg";
import Back from "../../../assets/images/svgs/back.svg";
import { MapPin, CreditCard, User, ShoppingBag } from "react-feather";
import { Card, CardBody, Col, Input, Spinner } from "reactstrap";
import { Mail, Phone, X } from "react-feather";
import Select from "react-dropdown-select";
import { gql, useMutation, useQuery } from "@apollo/client";
import DonateItemCardStatus from "../../../constants/editProductStatus";
import { useHistory } from "react-router-dom";

const GET_REJECT_RESONS = gql`
  query {
    getItemDonationRejectionReasons {
      type
      label
    }
  }
`;

const DonateCard = ({ data, titleHeight, setTitleHeight }) => {
  const history = useHistory();
  const [status, setStatus] = useState(DonateItemCardStatus.DEFAULT);
  const [openRejModal, setOpenRejModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const ref = useRef(null);
  useEffect(() => {
    setTitleHeight((x) =>
      x > ref?.current?.clientHeight ? x : ref?.current?.clientHeight,
    );
  });
  const [rejData, setRejData] = useState({ reason: "", details: "" });

  var { data: rejectReasons } = useQuery(GET_REJECT_RESONS);

  const [rejectItemDonation] = useMutation(gql`
    mutation RejectItemDonation($data: RejectItemDonationInput!) {
      rejectItemDonation(input: $data) {
        itemDonationUUID
        reason
        details
      }
    }
  `);

  const getNameOrEmail = (u) => {
    return (
      (u && ((u?.firstName + " " + u?.lastName).trim() || u?.email)) ||
      "<no-name>"
    );
  };

  const getNameOrPhoneNumber = (u) => {
    return (
      (u && ((u?.firstName + " " + u?.lastName).trim() || u?.phoneNumber)) ||
      "<no-name>"
    );
  };

  const getTotal = () => {
    if (!data.total && !data.donationServiceFee) {
      return <i>Self Serve</i>;
    }
    return (
      <div className="flex flex-col">
        <div
          style={{ color: "red", fontWeight: "bold", fontSize: "1rem" }}
          className="ml-1"
        >
          <CreditCard size={15} /> $
          {data?.total?.value || data?.donationServiceFee?.value}
        </div>
        {data?.totalDiscounts && data?.totalDiscounts?.value != "0.00" && (
          <div style={{ color: "green", fontWeight: "bold" }}>
            With discounts
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <Col
        sm="12"
        style={{ position: "relative" }}
      >
        <Card
          style={{
            border: "2px solid",
            borderColor:
              status === DonateItemCardStatus.APPROVED
                ? "#63D08F"
                : status === DonateItemCardStatus.REJECTED
                ? "#FE7F7F"
                : "#00000000",
          }}
        >
          <div className={styles.itemDonationsCard}>
            <CardBody className={styles.itemDonationContent}>
              <img
                src={data.images[0]?.resolutions?.l?.url}
                className={styles.productImage}
              />

              <div className={styles.productBody}>
                <div className={styles.productTitleContainer}>
                  <h6
                    className={styles.productTitle}
                    ref={ref}
                    style={titleHeight > 0 ? { height: titleHeight } : {}}
                  >
                    {data.title}
                  </h6>
                  <div className={styles.productQuantity}>
                    {!data?.unitType && "Qty: "} {data.quantity}{" "}
                    {data?.unitType ? " x " + data.unitType : ""}
                  </div>
                  <div
                    style={{
                      textDecorationLine: "line-through",

                      color: "#A3A3A3",
                    }}
                  >
                    {data.fairMarketValue && `$${data.fairMarketValue.value}`}
                  </div>
                </div>
                <div
                  className={`d-flex align-items-center ${styles.description}`}
                >
                  {getTotal()}
                </div>
                <div className={styles.productMeta2}>
                  <div className="d-flex align-items-center">
                    <MapPin size={15} />
                    <p className="ml-1">
                      {data.location.address.city},{" "}
                      {data.location.address.state}
                    </p>
                  </div>
                  <p>{data.location.notes}</p>
                  <div>
                    {data.fulfillmentTypes?.includes("shipping") && (
                      <img
                        src={Delivery}
                        alt="delivery"
                        className="ml-2"
                      />
                    )}
                  </div>
                </div>
                <div className={styles.productMeta4}>
                  {!data.donor && !data.donorRetailer && (
                    <div>
                      {data.location.contactEmail && (
                        <div className="d-flex align-items-center">
                          <Mail
                            size={15}
                            className="mr-1"
                          />{" "}
                          <p>
                            <a
                              style={{
                                color: "tomato",
                                letterSpacing: -0.1,
                              }}
                              rel="noreferrer"
                              target="_blank"
                              href={
                                "/users/search?q=" +
                                data?.location?.contactEmail
                              }
                            >
                              <u>{data.location.contactEmail}</u>
                            </a>
                          </p>
                        </div>
                      )}
                      <div className="d-flex align-items-center">
                        <Phone
                          size={15}
                          className="mr-1"
                        />{" "}
                        <p>
                          <a
                            style={{
                              color: "tomato",
                              letterSpacing: -0.1,
                            }}
                            rel="noreferrer"
                            target="_blank"
                            href={
                              "/users/search?q=" + data?.location?.contactNumber
                            }
                          >
                            <u>{data.location.contactNumber}</u>
                          </a>
                        </p>
                      </div>
                    </div>
                  )}
                  {data.donor && (
                    <div>
                      {data.donor?.user?.email ? (
                        <div className="d-flex align-items-center">
                          <User
                            size={15}
                            className="mr-1"
                          />
                          <p>
                            <a
                              style={{
                                color: "tomato",
                                letterSpacing: -0.1,
                              }}
                              rel="noreferrer"
                              target="_blank"
                              href={
                                "/users/search?uuid=" + data.donor?.user?.uuid
                              }
                            >
                              <u>{getNameOrEmail(data?.donor?.user)}</u>
                            </a>
                          </p>
                        </div>
                      ) : data.donor?.user?.phoneNumber ? (
                        <div className="d-flex align-items-center">
                          <Phone
                            size={15}
                            className="mr-1"
                          />{" "}
                          <p>
                            <a
                              style={{
                                color: "tomato",
                                letterSpacing: -0.1,
                              }}
                              rel="noreferrer"
                              target="_blank"
                              href={
                                "/users/search?uuid=" + data.donor?.user?.uuid
                              }
                            >
                              <u>{getNameOrPhoneNumber(data.donor?.user)}</u>
                            </a>
                          </p>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                  {data.donorRetailer && (
                    <div className="d-flex align-items-center">
                      <ShoppingBag
                        size={15}
                        className="mr-1"
                      />
                      <p>
                        <a
                          style={{
                            color: "tomato",
                            letterSpacing: -0.1,
                          }}
                          rel="noreferrer"
                          target="_blank"
                          href={"/retailers/details/" + data.donorRetailer.uuid}
                        >
                          <u>{data.donorRetailer.name}</u>
                        </a>
                      </p>
                    </div>
                  )}
                </div>
                <div className={styles.tags}>
                  <div className={`w-50 ${styles.donatetagRowMobileWidth}`}>
                    {data.categoryTypes?.map((x) => (
                      <div
                        className={styles.category}
                        key={x}
                      >
                        {x}
                      </div>
                    ))}
                  </div>
                  <div className={`w-50 ${styles.donatetagRowMobileWidth}`}>
                    {data.conditionTagTypes?.map((x) => (
                      <div
                        className={styles.tag}
                        key={x}
                      >
                        {x}
                      </div>
                    ))}
                  </div>
                </div>
                {status === DonateItemCardStatus.DEFAULT ? (
                  loading ? (
                    <div className={styles.actions}>
                      <Spinner />
                    </div>
                  ) : (
                    <div className={styles.actions}>
                      <img
                        src={Remove}
                        alt="REJECT"
                        onClick={() => setOpenRejModal(true)}
                      />
                      <img
                        src={Edit}
                        alt="Edit"
                        onClick={() => {
                          history.push("/item-donation/edit/" + data.uuid);
                        }}
                      />
                    </div>
                  )
                ) : null}
                {status === DonateItemCardStatus.REJECTED && (
                  <div className={styles.reject}>REJECTED</div>
                )}
                {status === DonateItemCardStatus.APPROVED && (
                  <div className={styles.approve}>APPROVED</div>
                )}
              </div>
            </CardBody>
          </div>
        </Card>
        {openRejModal && (
          <div
            className={styles.rejectWrapper}
            onClick={(e) => {
              setOpenRejModal(false);
              e.stopPropagation();
            }}
          >
            <div
              className={styles.rejectBox}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <X
                className={styles.x}
                onClick={(e) => {
                  setOpenRejModal(false);
                  e.stopPropagation();
                }}
              />
              <label className={styles.label}>Rejection Reason</label>
              <Select
                options={
                  rejectReasons.getItemDonationRejectionReasons
                    ? rejectReasons.getItemDonationRejectionReasons.map(
                        (x) => ({
                          value: x.type,
                          label: x.label,
                        }),
                      )
                    : []
                }
                value={rejData.reason}
                onChange={(v) => {
                  setRejData((prev) => ({ ...prev, reason: v[0].value }));
                }}
              />
              <label className={styles.label}>Details</label>
              <Input
                value={rejData.details}
                onChange={(e) =>
                  setRejData((prev) => ({ ...prev, details: e.target.value }))
                }
              />
              {loading ? (
                <div className={styles.row}>
                  <Spinner />
                </div>
              ) : (
                <div className={styles.row}>
                  <img
                    onClick={() => {
                      setOpenRejModal(false);
                    }}
                    src={Back}
                    alt="Back"
                    style={{ width: 40, cursor: "pointer" }}
                  />

                  <div
                    className={styles.reject}
                    onClick={() => {
                      if (rejData.reason !== "") {
                        setLoading(true);
                        rejectItemDonation({
                          variables: {
                            data: {
                              reason: rejData.reason,
                              details: rejData.details,
                              itemDonationUUID: data.uuid,
                            },
                          },
                        })
                          .then((_) => {
                            setLoading(false);
                            setStatus(DonateItemCardStatus.REJECTED);
                            setOpenRejModal(false);
                          })
                          .catch((rej) =>
                            toast.error(
                              "Unable to reject item donation: " +
                                JSON.stringify(rej),
                            ),
                          );
                      }
                    }}
                  >
                    REJECT
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </Col>
      <ToastContainer />
    </>
  );
};

export default DonateCard;
