import { gql, useQuery } from "@apollo/client";
import React from "react";
import { Spinner } from "reactstrap";

import OrderDetails from "./components/OrderDetails";

const GET_ORDER = gql`
  query getAdminOrder($uuid: String!) {
    getAdminOrder(uuid: $uuid) {
      uuid
      status
      notes {
        uuid
        text
        user {
          uuid
          firstName
          lastName
        }
        createdDate
      }
      priceInfo {
        lineItems {
          type
          label
          amount {
            value
            currencyCode
          }
        }
      }
      createdDate
      paymentIntentID
      paymentMethod {
        type
      }
      recipientLocation {
        address {
          streetAddress1
          streetAddress2
          streetAddress3
          streetAddress4
          state
          zip
          city
          countryCode
          longitude
          latitude
        }
        notes
        maskedContactNumber
        contactNumber
      }
      fulfillments {
        uuid
        labels {
          url
          trackingNumber
          trackingURL
          status
        }
        updates {
          createdAt
          description
          eventType
          fulfillmentUUID
          status
        }
        noResaleAgreed
        items {
          uuid
          quantity
          product {
            noResale
            uuid
            slug
            title
            unitType
            weightLbs
            availableQuantity
            fairMarketValue {
              value
            }
            images {
              uuid
              resolutions {
                s {
                  url
                  height
                  width
                  size
                }
              }
            }
          }
        }
        type
        pickupLocation {
          address {
            streetAddress1
            streetAddress2
            streetAddress3
            streetAddress4
            state
            zip
            city
            countryCode
            longitude
            latitude
          }
          notes
          maskedContactNumber
          contactNumber
          contactEmail
        }
        recipientLocation {
          maskedContactNumber
          contactNumber
        }
        distance
        priceInfo {
          lineItems {
            type
            label
            amount {
              value
              currencyCode
            }
          }
        }
        pickupBy
        donor {
          user {
            firstName
            lastName
            uuid
          }
          retailer {
            uuid
            name
          }
        }
        donorRetailer {
          name
          uuid
        }
        recipientNonprofit {
          organizationName
          uuid
        }
        status
        statusDescription
        notes {
          uuid
          text
          user {
            uuid
            firstName
            lastName
          }
          createdDate
        }
        donationReceipt {
          uuid
          status
        }
        hours {
          timezone
          intervals {
            date
            days
            startTimeLocal
            endTimeLocal
          }
        }
        distance
        fulfillmentOptions {
          label
          type
          selected
          amount {
            value
            currencyCode
          }
          disabledReason
        }
      }
      recipient {
        user {
          firstName
          lastName
          uuid
        }
        nonprofit {
          uuid
          organizationName
        }
      }
    }
  }
`;

const OrderDetailsPage = ({ match }) => {
  const orderUUID = match?.params?.orderUUID;
  const { loading, error, data } = useQuery(GET_ORDER, {
    variables: {
      uuid: orderUUID,
    },
  });

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-around mt-4">
          <Spinner />
        </div>
      ) : error ? (
        <>Some Error Occured! Try Reloading the page.</>
      ) : (
        <OrderDetails data={data?.getAdminOrder} />
      )}
    </>
  );
};

export default OrderDetailsPage;
