import { Card, CardHeader, CardBody, Col, Row } from "reactstrap";
import { gql, useLazyQuery } from "@apollo/client";
import Datatable from "../common/datatable";
import React, { useState, useEffect } from "react";
import styles from "../donate/donate.module.scss";
import Text from "../common/text";
import moment from "moment";
import DateRangePicker from "react-daterange-picker";
import { Edit } from "react-feather";
import RetailerSelect from "../retailers/retailer-select";
import TransactionTypeSelect from "./TransactionTypeSelect";
import transactionsFormatter from "src/helpers/transactionsFormatter";
import AddTransaction from "./addTransaction";

const ADMIN_GET_TRANSACTIONS = gql`
  query ($input: AdminGetTransactionsInput!) {
    adminGetTransactions(input: $input) { 
      total
      transactions {
        uuid
        description
        type
        amount {
          value
          currencyCode
        }
        retailer {
          name
          uuid
        }
        invoice {
            uuid
        }
        match {
            uuid
        }
        fulfillment {
            uuid
            type
        }
        item {
            uuid
            product {
                uuid
                slug
                title
            }
        }
        retailerSubscriptionPlan {
            id
            createdDate
        }
        createdAt
      }
      hasMore
    }
  }
`;

const TransactionList = ({
  title,
  hideRetailerSearch,
  retailerUUID,
  invoiceUUID,
  matchUUID,
  fulfillmentUUID,
  donationReceiptUUID,
  hideDateSelector,
  hideAddTransactions,
}) => {
  const END_OF_DAY = {
    hour: 23,
    minute: 59,
    second: 59,
    millisecond: 0,
  };
  const MIDNIGHT = {
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  };

  const [selectOpen, setSelectOpen] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(25);
  const [indexFrom, setIndexFrom] = useState(0);
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [types, setTypes] = useState();
  const [dateEditMode, setDateEditMode] = useState(false);
  const [date, setDate] = useState(null);
  const [retailer, setRetailer] = useState(null);

  const [adminGetTransactions, { loading, error, data }] = useLazyQuery(
    ADMIN_GET_TRANSACTIONS,
    {
      variables: {
        fetchPolicy: "no-cache",
        input: {
          retailerUUID: retailer?.uuid || retailerUUID,
          invoiceUUID: invoiceUUID,
          matchUUID: matchUUID,
          fulfillmentUUID: fulfillmentUUID,
          donationReceiptUUID: donationReceiptUUID,
          startDate:
            hideDateSelector || !startTime
              ? undefined
              : startTime.toISOString(),
          endDate:
            hideDateSelector || !endTime ? undefined : endTime.toISOString(),
          types: types,
          indexFrom: indexFrom,
          limit: limit,
        },
      },
    },
  );

  const onChangePage = (newPage, _) => {
    setIndexFrom((newPage - 1) * limit);
  };

  const onChangeRowsPerPage = (newPerPage, page) => {
    setIndexFrom((page - 1) * newPerPage);
    setLimit(newPerPage);
  };

  const onClickEditDate = () => setDateEditMode(true);
  const onSelectRange = (value) => {
    setStartTime(
      moment()
        .utc(true)
        .set({
          year: value.start.year(),
          month: value.start.month(),
          date: value.start.date(),
        })
        .set(MIDNIGHT),
    );
    setEndTime(
      moment()
        .utc(true)
        .set({
          year: value.end.year(),
          month: value.end.month(),
          date: value.end.date(),
        })
        .set(END_OF_DAY),
    );
    setDate(value);
    setDateEditMode(false);
  };

  const toggleSelect = () => setSelectOpen((val) => !val);

  useEffect(() => {
    if (!loading) {
      if (data && data.adminGetTransactions) {
        setTransactions(
          transactionsFormatter(data.adminGetTransactions.transactions),
        );
        setTotalRows(data.adminGetTransactions.total);
      } else {
        setTransactions([]);
        setTotalRows(0);
      }
    }
  }, [loading, types, retailerUUID, retailer]);

  useEffect(() => {
    if (retailerUUID) {
      setRetailer({ uuid: retailerUUID });
    }
    if (
      !retailerUUID &&
      !fulfillmentUUID &&
      !matchUUID &&
      !invoiceUUID &&
      !donationReceiptUUID
    ) {
      return;
    }

    adminGetTransactions();
  }, [
    indexFrom,
    retailerUUID,
    fulfillmentUUID,
    invoiceUUID,
    matchUUID,
    donationReceiptUUID,
    types,
  ]);

  return (
    <Card className={styles.donateItemEdit}>
      <CardHeader>
        <h3>{title}</h3>
        <Row>
          {!hideDateSelector && (
            <Col
              xs="12"
              md="3"
              style={{
                position: "relative",
                height: "35px",
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              {dateEditMode ? (
                <DateRangePicker
                  value={date}
                  onSelect={onSelectRange}
                  singleDateRange={true}
                />
              ) : (
                <div className="d-flex align-items-center">
                  <p
                    className="mb-0 fs-16 mt-1"
                    style={{
                      opacity: 0.5,
                      fontWeight: 600,
                      letterSpacing: "-0.1px",
                    }}
                  >
                    {!!startTime && !!endTime ? (
                      <i className="fw-600">
                        {startTime?.format("DD MMM, YYYY")} to{" "}
                        {endTime?.format("DD MMM, YYYY")} (midnight, PST)
                      </i>
                    ) : (
                      "No dates set"
                    )}
                  </p>
                  <Edit
                    onClick={onClickEditDate}
                    className="ml-2 cursor-pointer c-primary"
                  />
                </div>
              )}
            </Col>
          )}
          <Col md="3">
            <TransactionTypeSelect
              isOpen={selectOpen}
              placeholder="Filter By Type"
              setValues={setTypes}
              values={types}
              style={{
                width: "300px",
                marginLeft: "20px",
                height: "38px",
                overflow: "hidden",
              }}
              toggle={toggleSelect}
              onSelect={() => {
                setIndexFrom(0);
              }}
            />
          </Col>
          {!hideRetailerSearch && (
            <Col md="3">
              <RetailerSelect
                {...{
                  retailer: {
                    uuid: retailer?.uuid,
                    name: retailer?.name,
                  },
                  style: {
                    width: "300px",
                    marginLeft: "20px",
                    height: "38px",
                    overflow: "hidden",
                  },
                  onSelect: (r) => {
                    setIndexFrom(0);
                    setRetailer(r);
                  },
                }}
              />
            </Col>
          )}
          <Col md="3">
            {!hideAddTransactions && (
              <AddTransaction
                retailerUUID={retailer?.uuid}
                invoiceUUID={invoiceUUID}
                matchUUID={matchUUID}
                fulfillmentUUID={fulfillmentUUID}
              />
            )}
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        {error ? (
          <>Some Error Occured! Try Reloading the page.</>
        ) : transactions ? (
          <Datatable
            hideActions={true}
            myData={transactions}
            loading={loading}
            pageSize={limit}
            pagination={true}
            paginationServer={true}
            totalRows={totalRows}
            myClass="-striped -highlight"
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            noHeader
          />
        ) : (
          <Text>No transactions</Text>
        )}
      </CardBody>
    </Card>
  );
};

export default TransactionList;
