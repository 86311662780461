import React from "react";

const usersFormatter = (users) => {
  if (!users?.length) {
    return;
  }

  const formattedUsers = users.map((d) => {
    return {
      uuid: (
        <a
          style={{
            color: "tomato",
            letterSpacing: -0.1,
          }}
          href={"/users/search?uuid=" + d?.uuid}
        >
          {d?.uuid}
        </a>
      ),
      firstName: d.firstName,
      lastName: d?.lastName,
      roles: d?.roles?.join(" "),
      email: d?.email,
      phoneNumber: d?.phoneNumber ? d?.phoneNumber : "-",
    };
  });
  return formattedUsers;
};

export default usersFormatter;
