import React, { Fragment } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumb from "../common/breadcrumb";
import { useQuery, gql } from "@apollo/client";
import { useParams } from "react-router-dom";
import AddEditCategory from "./AddEditCategory";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { initializeApollo } from "src/helpers/apollo";
import { toast } from "react-toastify";
import { err_toast } from "src/helpers/errors";

const GET_CATEGORY = gql`
  query getCategory($categoryType: String!) {
    getCategory(categoryType: $categoryType) {
      uuid
      type
      name
      description
      images {
        url
        uuid
        resolutions {
            xl {
                url
            }
            m {
                url
            }
            xs {
                url
            }
        }
      }
      parentCategories {
        type
        uuid
        name
      }
      subCategories {
        type
        uuid
        name
        description
        images {
            url
            uuid
            resolutions {
                xl {
                    url
                }
                m {
                    url
                }
                xs {
                    url
                }
            }
        }
        subCategories {
            type
            uuid
            name
            description
            images {
                url
                uuid
                resolutions {
                    xl {
                        url
                    }
                    m {
                        url
                    }
                    xs {
                        url
                    }
                }
            }
        }
      }
    }
  }
`;

const ADMIN_EDIT_CATEGORY = gql`
    mutation adminEditCategory($input: CategoryInput!) {
        adminEditCategory(input: $input) {
            uuid
            type
            name
            description
            images {
                url
                uuid
                resolutions {
                    xl {
                        url
                    }
                    m {
                        url
                    }
                    xs {
                        url
                    }
                }
            }
            parentCategories {
                type
                uuid
                name
            }
            subCategories {
                type
                uuid
                name
                description
                images {
                    url
                    uuid
                    resolutions {
                        xl {
                            url
                        }
                        m {
                            url
                        }
                        xs {
                            url
                        }
                    }
                }
                subCategories {
                    type
                    uuid
                    name
                    description
                    images {
                        url
                        uuid
                        resolutions {
                            xl {
                                url
                            }
                            m {
                                url
                            }
                            xs {
                                url
                            }
                        }
                    }
                }
            }
        }
    }
`;

const CategoryDetails = () => {
  const { categoryType } = useParams();

  const { data: categoryData } = useQuery(GET_CATEGORY, {
    fetchPolicy: "no-cache",
    variables: {
      categoryType,
    },
  });

  const category = categoryData?.getCategory;
  const history = useHistory();

  let client = initializeApollo();

  const handleEditCategory = (vals, cb) => {
    client
      .mutate({
        mutation: ADMIN_EDIT_CATEGORY,
        variables: {
          input: vals,
        },
      })
      .then((_) => {
        toast.success("Category edited successfully!");
        setTimeout(() => {
          cb();
        }, 1000);
      })
      .catch((err) => {
        err_toast(err);
        cb();
      });
  };

  return (
    <Fragment>
      <Breadcrumb
        title="Category Details"
        parent="Categories"
      />
      <Container fluid={true}>
        <Card>
          <CardBody>
            <h4>
              {category?.parentCategories?.length > 0 && (
                <>
                  <Link
                    to={`/category-details/${category?.parentCategories[0]?.type}`}
                  >
                    {category?.parentCategories[0]?.name}
                  </Link>{" "}
                  {" > "}
                </>
              )}
              <Link to={`/category-details/${category?.type}`}>
                {category?.name}
              </Link>
            </h4>

            <AddEditCategory
              category={category}
              onClose={() => history.push("/categories")}
              onSubmit={handleEditCategory}
            />
          </CardBody>
        </Card>
        {category?.subCategories?.length > 0 && (
          <Card>
            <CardBody>
              <h3>Subcategories</h3>
              {category?.subCategories?.map((level2) => (
                <div
                  key={level2.uuid}
                  className="my-2"
                >
                  <Row className="align-items-center">
                    <Col xs="1">
                      <img
                        alt={level2.type}
                        width={75}
                        src={level2.images[0].resolutions.xs.url}
                      />
                    </Col>
                    <Col xs="4">
                      <h3>
                        <Link to={`/category-details/${level2.type}`}>
                          {level2.name}
                        </Link>
                      </h3>
                    </Col>
                  </Row>
                  {level2?.subCategories?.map((level3) => (
                    <div
                      key={level3.uuid}
                      className="my-2"
                    >
                      <Row className="align-items-center">
                        <Col xs="1">&nbsp;</Col>
                        <Col xs="1">
                          <img
                            alt={level3.type}
                            width={75}
                            src={level3.images[0].resolutions.xs.url}
                          />
                        </Col>
                        <Col xs="3">
                          <h4>
                            <Link to={`/category-details/${level3.type}`}>
                              {level3.name}
                            </Link>
                          </h4>
                        </Col>
                      </Row>
                    </div>
                  ))}
                </div>
              ))}
            </CardBody>
          </Card>
        )}
      </Container>
    </Fragment>
  );
};

export default CategoryDetails;
