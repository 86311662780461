import { useMemo } from "react";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import merge from "deepmerge";
import isEqual from "lodash/isEqual";
import { createUploadLink } from "apollo-upload-client";
import { LIQUID_COOKIE, LIQUID_TOKEN } from "../constants/cookie";
import { setContext } from "@apollo/client/link/context";
import Cookies from "universal-cookie";
import getConfigs from "../config/base";

export const APOLLO_STATE_PROP_NAME = "__APOLLO_STATE__";

let apolloClient;

const authLink = setContext((_, { headers }) => {
  // get the authentication token from cookie if it exists

  const cookies = new Cookies();
  const liquid_cookie = cookies.get(LIQUID_COOKIE);

  if (liquid_cookie) {
    const token = liquid_cookie[LIQUID_TOKEN];

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  } else {
    return {
      headers: {
        ...headers,
      },
    };
  }
});

function createApolloClient() {
  var configs = getConfigs();
  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: authLink.concat(
      createUploadLink({
        uri: configs.graphql, // Server URL (must be absolute)
        credentials: "include", // Additional fetch() options like `credentials` or `headers`
        fetch,
      }),
    ),
    cache: new InMemoryCache(),
  });
}

export function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s)),
        ),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function addApolloState(client, pageProps) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function useApollo(pageProps) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state), [state]);
  return store;
}
