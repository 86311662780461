import React from "react";
import CheckboxTree from "react-checkbox-tree";
import { ChevronDown } from "react-feather";
import { DropdownMenu, Dropdown, DropdownToggle } from "reactstrap";
import styles from "../donate/donate.module.scss";

const EMAIL_CATEGORIES = [
  { title: "OrderPlaced", value: "order_placed" },
  { title: "VerifyAccount", value: "verify_account" },
  { title: "ForgotPassword", value: "forgot_password" },
  { title: "PickupComplete", value: "pickup_complete" },
  { title: "PasswordChanged", value: "password_changed" },
  { title: "DonationReceived", value: "donation_received" },
  { title: "Availability", value: "availability" },
  { title: "NonprofitWelcome", value: "nonprofit_welcome" },
  {
    title: "AssociateWithNonprofit",
    value: "associate_user_with_nonprofit",
  },
  {
    title: "AssociateWithRetailer",
    value: "associate_user_with_retailer",
  },
  { title: "ProductsExpired", value: "products_expired" },
  { title: "InvoiceNotification", value: "invoice_notification" },
  { title: "FulfillmentUpdate", value: "fulfillment_update" },
  { title: "RecipientPipe", value: "recipient_pipe" },
  { title: "DonorPipe", value: "donor_pipe" },
  { title: "LabelStatusNotification", value: "label_status_notification" },
];

const EmailCategorySelect = ({
  isOpen,
  toggle,
  values,
  setValues,
  style = {},
  placeholder,
  onSelect,
}) => {
  return (
    <Dropdown
      isOpen={isOpen}
      toggle={toggle}
    >
      <DropdownToggle
        className={styles.dropdown}
        style={style}
      >
        <div className="d-flex flex-wrap">
          {values?.length ? (
            values?.map((x, i) => (
              <div
                key={i}
                className={styles.category + " mr-1"}
              >
                {x}
              </div>
            ))
          ) : (
            <span style={{ fontWeight: 500, color: "gray" }}>
              {placeholder}
            </span>
          )}
        </div>
        <ChevronDown />
      </DropdownToggle>
      <DropdownMenu>
        <CheckboxTree
          onlyLeafCheckboxes
          nodes={EMAIL_CATEGORIES.map((x) => ({
            value: x.value,
            label: x.title,
            className: "d-block w-100",
          }))}
          icons={{
            parentClose: null,
            parentOpen: null,
            leaf: null,
          }}
          checked={values}
          onCheck={(checked) => {
            setValues(checked);
            if (onSelect) {
              onSelect();
            }
          }}
        />
      </DropdownMenu>
    </Dropdown>
  );
};

export default EmailCategorySelect;
