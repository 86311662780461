import React, { Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import {
  Container,
  Card,
  CardBody,
  Button,
  Col,
  Row,
  Input,
  Spinner,
  Form,
} from "reactstrap";
import { gql } from "@apollo/client";
import { initializeApollo } from "../../helpers/apollo";
import { addCountryCode } from "../../helpers/utils";
import { useState, useEffect } from "react";
import useFormattedPhone from "../../helpers/hooks/useFormattedPhone";
import { normalize } from "phone-formatter";
import styles from "./users.module.scss";
import UserDetails from "./UserDetails";

const isUUID = (value) => {
  return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
    value,
  );
};

const isOnlyDigits = (value) => {
  return /^[()\-+\d\s]+$/.test(value);
};

const GET_ADMIN_USER_DETAILS = gql`
  query getAdminUserDetails($input: AdminUserDetailsInput!) {
    getAdminUserDetails(input: $input) {
      user {
        uuid
        firstName
        lastName
        email
        phoneNumber
        verifiedEmail
        verifiedPhoneNumber
        roles
        groups
      }
      nonprofit {
        uuid
        organizationName
        businessPhone
        website
        ein
      }
      retailer {
        uuid
        name
        website
        businessPhoneNumber
        description
      }
    }
  }
`;

const SearchUser = () => {
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const [q, setQ] = useState(null);
  const [uuid, setUUID] = useState(null);

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const client = initializeApollo();
  const [phone, setPhone] = useFormattedPhone();
  const [isPhone, setIsPhone] = useState(false);

  const getSearchParams = () => {
    const params = new URLSearchParams(window.location.search);
    const q = params.get("q");
    const uuid = params.get("uuid");
    return [q, uuid];
  };

  const setSearchParams = () => {
    const [qP, uuidP] = getSearchParams();
    if (qP && q !== qP) {
      setQ(qP);
      setSearchValue(qP);
      if (isOnlyDigits(qP)) {
        setIsPhone(true);
        setPhone(qP);
      }
    } else {
      setIsPhone(false);
      setUUID(uuidP);
      setSearchValue(uuidP);
    }
  };

  useEffect(() => {
    setSearchParams();
  }, []);

  useEffect(() => {
    getAdminUserDetails();
  }, [q, uuid]);

  const handleAddSearchParams = (type, value) => {
    const params = new URLSearchParams();
    if (type === "q" || type === "uuid") {
      params.set(type, value);
    }
    window.history.pushState(null, "", "/users/search?" + params.toString());
  };

  const getEmailOrPhone = () => {
    return isUUID(uuid) ? null : isPhone ? addCountryCode(q) : q;
  };

  const getUserUUID = () => {
    return isUUID(uuid) ? uuid : null;
  };

  const getAdminUserDetails = () => {
    if (!q && !uuid) {
      return;
    }

    setLoading(true);
    if (isUUID(uuid)) {
      handleAddSearchParams("uuid", uuid);
    } else {
      handleAddSearchParams("q", isOnlyDigits(q) ? normalize(q) : q);
    }

    client
      .mutate({
        mutation: GET_ADMIN_USER_DETAILS,
        variables: {
          input: {
            emailOrPhone: getEmailOrPhone(),
            uuid: getUserUUID(),
          },
        },
      })
      .then((res) => {
        setLoading(false);
        setData(res.data.getAdminUserDetails);
      })
      .catch((err) => {
        setLoading(false);
        setData(null);
        setError(String(err));
      });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setError(null);
    setQ(searchValue);
    if (isUUID(searchValue)) {
      setUUID(searchValue);
    }
  };

  const handleChangeSearchVal = (e) => {
    const value = e.target.value;
    if (!value.length) {
      setSearchValue("");
      setPhone("");
      return;
    }

    setSearchValue(value);

    setIsPhone(isOnlyDigits(value));
    if (isOnlyDigits(value)) {
      setPhone(value);
    }

    if (!isUUID(value)) {
      setUUID(null);
    }
  };

  return (
    <Fragment>
      <Breadcrumb
        title="Search User"
        parent="Users"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <h2 className={styles.header}>Email, Phone or UUID</h2>
                <Form className="form-horizontal">
                  <Row>
                    <Col
                      xl="3"
                      md="6"
                    >
                      <Input
                        className="form-control"
                        id="validationCustom0"
                        type="text"
                        required
                        value={isPhone ? phone : searchValue}
                        onChange={handleChangeSearchVal}
                      />
                    </Col>
                    <Col>
                      {loading ? (
                        <Spinner />
                      ) : (
                        <Button
                          type="submit"
                          color="primary"
                          onClick={handleSearch}
                        >
                          Search
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {error && (
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <h4 style={{ color: "red" }}>{error}</h4>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}

        <Row>
          <Col>
            {data?.user && (
              <UserDetails
                user={data?.user}
                nonprofit={data?.nonprofit}
                retailer={data?.retailer}
              />
            )}
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default SearchUser;
