import React from "react";
import CheckboxTree from "react-checkbox-tree";
import { ChevronDown } from "react-feather";
import { DropdownMenu, Dropdown, DropdownToggle } from "reactstrap";
import styles from "../donate/donate.module.scss";

const TRANSACTION_TYPE = [
  { title: "Donation Service Fee", value: "donation_service_fees" },
  { title: "Shipping Fees", value: "shipping_fees" },
  { title: "Delivery Fees", value: "delivery_fees" },
  { title: "Service Fees", value: "service_fees" },
];

const TransactionTypeSelect = ({
  isOpen,
  toggle,
  values,
  setValues,
  style = {},
  placeholder,
  onSelect,
}) => {
  return (
    <Dropdown
      isOpen={isOpen}
      toggle={toggle}
    >
      <DropdownToggle
        className={styles.dropdown}
        style={style}
      >
        <div className="d-flex flex-wrap">
          {values?.length ? (
            values?.map((x, i) => (
              <div
                key={i}
                className={styles.category + " mr-1"}
              >
                {x}
              </div>
            ))
          ) : (
            <span style={{ fontWeight: 500, color: "gray" }}>
              {placeholder}
            </span>
          )}
        </div>
        <ChevronDown />
      </DropdownToggle>
      <DropdownMenu>
        <CheckboxTree
          onlyLeafCheckboxes
          nodes={TRANSACTION_TYPE.map((x) => ({
            value: x.value,
            label: x.title,
            className: "d-block w-100",
          }))}
          icons={{
            parentClose: null,
            parentOpen: null,
            leaf: null,
          }}
          checked={values}
          onCheck={(checked) => {
            setValues(checked);
            if (onSelect) {
              onSelect();
            }
          }}
        />
      </DropdownMenu>
    </Dropdown>
  );
};

export default TransactionTypeSelect;
