import { gql, useLazyQuery } from "@apollo/client";
import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap";
import Text from "../common/text";
import Datatable from "../common/datatable";
import { initializeApollo } from "src/helpers/apollo";
import { err_toast } from "src/helpers/errors";
import subscriptionPlansFormatter from "src/helpers/chooseSubscriptionPlansFormatter";

const ADMIN_ADD_PRICING = gql`
    mutation adminAddRetailerSubscriptionPlan($input: AddRetailerSubscriptionPlanInput!) {
      adminAddRetailerSubscriptionPlan(input: $input) {
            id
            uuid
            status
            createdDate
            monthlyAmount {
                value
                currencyCode
            }
            includedDonationValue {
                value
                currencyCode
            }
            individualItemPrice {
                value
                currencyCode
            }
            unboxedPrice {
                value
                currencyCode
            }
            boxPrice {
                value
                currencyCode
            }
            largeBoxPrice {
                value
                currencyCode
            }
            oversizedItemPrice{
              value
              currencyCode
            }
            palletPrice {
                value
                currencyCode
            }
            largePalletPrice {
              value
              currencyCode
            }
            isShippingFeePassThrough
            shippingFee {
                value
                currencyCode
            }
            isDeliveryFeePassThrough
            deliveryFee {
              value
              currencyCode
            }
        }
    }
`;

const ADMIN_GET_SUBSCRIPTION_PLANS = gql`
    query($input: AdminGetSubscriptionPlansInput!) {
      adminGetAllSubscriptionPlans(input: $input) {
        subscriptionPlans {
          uuid
          id
          status
          title
          isDefault
          monthlyAmount {
            value
            currencyCode
          }
          includedDonationValue {
            value
            currencyCode
          }
          individualItemPrice {
            value
            currencyCode
          }
          unboxedPrice {
            value
            currencyCode
          }
          boxPrice {
            value
            currencyCode
          }
          largeBoxPrice {
            value
            currencyCode
          }
          oversizedItemPrice {
            value
            currencyCode
          }
          palletPrice {
            value
            currencyCode
          }
          largePalletPrice {
            value
            currencyCode
          }
          isShippingFeePassThrough
          shippingFee {
            value
            currencyCode
          }
          isDeliveryFeePassThrough
          deliveryFee {
            value
            currencyCode
          }
        }
        hasMore
        total
      }
    }
`;

const ChooseSubscriptionPlan = ({ retailer }) => {
  const [open, setOpen] = useState(false);
  const [subscriptionPlan, setSubscriptionPlan] = useState({});

  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [statuses, _] = useState();
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(25);
  const [indexFrom, setIndexFrom] = useState(0);

  const [adminGetAllSubscriptionPlans, { loading, error, data }] = useLazyQuery(
    ADMIN_GET_SUBSCRIPTION_PLANS,
    {
      variables: {
        fetchPolicy: "no-cache",
        input: {
          statuses: statuses,
          indexFrom: indexFrom,
          limit: limit,
        },
      },
    },
  );
  const onOpenModal = () => {
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };
  const onChoose = (uuid) => {
    setSubscriptionPlan({
      uuid: uuid,
    });
    chooseSubscriptionPlan(uuid);
  };

  useEffect(() => {
    if (data && data.adminGetAllSubscriptionPlans) {
      setSubscriptionPlans(
        subscriptionPlansFormatter(
          data.adminGetAllSubscriptionPlans.subscriptionPlans,
          onChoose,
        ),
      );
      setTotalRows(data.adminGetAllSubscriptionPlans.total);
    } else {
      setSubscriptionPlans([]);
    }
  }, [data, loading, statuses]);

  useEffect(() => {
    setTotalRows(0);
    setIndexFrom(0);
    setLimit(25);
    setSubscriptionPlans([]);
    adminGetAllSubscriptionPlans();
  }, []);

  const onChangePage = (newPage, _) => {
    setIndexFrom((newPage - 1) * limit);
  };

  const onChangeRowsPerPage = (newPerPage, page) => {
    setIndexFrom((page - 1) * newPerPage);
    setLimit(newPerPage);
  };
  let client = initializeApollo();

  const chooseSubscriptionPlan = (uuid) => {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: ADMIN_ADD_PRICING,
          variables: {
            input: {
              retailerUUID: retailer?.uuid,
              subscriptionPlanUUID: uuid ?? subscriptionPlan?.uuid,
            },
          },
        })
        .then((d) => {
          setTimeout(() => {
            window.location.replace(
              `/pricing/edit/${d?.data?.adminAddRetailerSubscriptionPlan?.uuid}`,
            );
          }, 1000);
        })
        .catch((e) => {
          reject(e);
          err_toast(e);
        });

      setTimeout(() => resolve("done"), 2000);
    });
  };

  return (
    <div>
      <Button
        color="primary"
        onClick={onOpenModal}
      >
        Change subscription plan
      </Button>
      <Modal
        isOpen={open}
        toggle={onCloseModal}
        size="lg"
      >
        <ModalHeader>
          <span>Change subscription plan</span>
        </ModalHeader>
        <ModalBody>
          <span>All prices in USD</span>
          {error ? (
            <>Some Error Occured! Try Reloading the page.</>
          ) : subscriptionPlans ? (
            <Datatable
              hideActions={true}
              myData={subscriptionPlans}
              loading={loading}
              pageSize={limit}
              pagination={true}
              paginationServer={true}
              totalRows={totalRows}
              myClass="-striped -highlight"
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              noHeader
            />
          ) : (
            <Text>No Subscription Plans</Text>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};
export default ChooseSubscriptionPlan;
