import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";

const styles = {
  title: {
    fontSize: "24px",
  },
  desc: {
    fontSize: "15px",
    lineHeight: "normal",
  },
  button: {
    height: "40px",
  },
};

const ConfirmationModal = ({
  open,
  onClose,
  title,
  desc,
  onConfirm,
  loading,
}) => {
  return (
    <Modal
      centered
      onClosed={onClose}
      isOpen={open}
      toggle={onClose}
    >
      <ModalHeader>
        <p
          style={styles.title}
          className="fs-16"
        >
          {title}
        </p>
      </ModalHeader>
      <ModalBody className="p-4">
        <p
          style={styles.desc}
          className="c-black"
        >
          {desc}
        </p>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex align-items-center justify-content-end w-full">
          <p
            className="mr-2 mt-2 cursor-pointer"
            style={{ fontSize: "16px" }}
            onClick={onClose}
          >
            Cancel
          </p>
          <Button
            style={styles.button}
            onClick={onConfirm}
            className="btn btn-solid py-0"
          >
            {loading ? (
              <Spinner
                small
                mt="mt-2"
                loading={!!loading}
              />
            ) : (
              <span>Confirm</span>
            )}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationModal;
