import { gql, useQuery, useLazyQuery } from "@apollo/client";
import React, { useState } from "react";
import { useEffect } from "react";
import { CSVLink } from "react-csv";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Spinner,
  Input,
} from "reactstrap";
import Datatable from "../common/datatable";
import CategoryDropdown from "../products/physical/CategoryDropdown";
import Select from "react-dropdown-select";
import { toast } from "react-toastify";
import moment from "moment/moment";
import nonprofitsFormatter from "../../helpers/nonprofitsFormatter";
import SearchLocationInput from "../common/SearchLocationInput";
import { formatAddress } from "../../helpers/address";
import { XCircle } from "react-feather";
import { err_toast } from "src/helpers/errors";

const sort_by_options = [
  { label: "Distance", value: "distance" },
  { label: "City", value: "city" },
  { label: "State", value: "state" },
  { label: "Zip", value: "zip" },
  { label: "Category", value: "category" },
  { label: "Number of Orders", value: "number_of_orders" },
  { label: "Verified Users", value: "verified_users" },
];

const NON_PROFITS = gql`
  query ($input: GetAdminNonprofitsInput!) {
    getAdminNonprofits(input: $input) {
      total
      nonprofits {
        uuid
        categoryTypes
        organizationName
        numberOfOrders
        verifiedUsers
        distanceMiles
        address {
          streetAddress1
          streetAddress2
          city
          state
          zip
          countryCode
          latitude
          longitude
        }
        magicMatchesPreferences {
          enabled
          agreeResaleRestriction
        }
        ein
        businessPhone
        website
        description
        users {
          uuid
          firstName
          lastName
          email
        }
        createdDate
      }
      hasMore
    }
  }
`;

const NonprofitList = ({
  title,
  productUUID,
  retailerUUID,
  showTermSearch,
  showCategoryFilter,
  showLocationInput,
  showCSVDownload,
  hideActions = true,
  onDeleteRows,
}) => {
  const [nonProfits, setNonProfits] = useState([]);
  const [categoryTreeValues, setCategoryTreeValues] = useState({
    checked: [],
    expanded: [],
  });
  const [catDropdownOpen, setCatDropdownOpen] = useState(false);
  const [term, setTerm] = useState();
  const [category, setCategory] = useState();
  const [sort, setSort] = useState();
  const [csvData, setCsvData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const [indexFrom, setIndexFrom] = useState(0);
  const [loc, setLocation] = useState({ address: null });

  let { loading, error, data, refetch } = useQuery(NON_PROFITS, {
    fetchPolicy: "no-cache",
    variables: {
      input: {
        productUUID: productUUID,
        retailerUUID: retailerUUID,
        indexFrom: indexFrom,
        limit: limit,
        categoryTypes: category,
        location: loc?.address ? loc : undefined,
        sortOrder: sort,
        term: term,
      },
    },
  });

  let [fetchCsvData, { loading: csvLoading }] = useLazyQuery(NON_PROFITS, {
    fetchPolicy: "no-cache",
    variables: {
      input: {
        productUUID: productUUID,
        retailerUUID: retailerUUID,
        categoryTypes: category,
        location: loc?.address ? loc : undefined,
        sortOrder: sort,
        term: term,
      },
    },
  });

  const onClickDownloadCSV = () => {
    fetchCsvData()
      .then((r) => {
        setCsvData([
          ...r.data.getAdminNonprofits.nonprofits.map((d) => ({
            uuid: d?.uuid,
            organizationName: d?.organizationName,
            numberOfOrders: d?.numberOfOrders,
            verifiedUsers: d?.verifiedUsers,
            ein: d?.ein,
            categoryTypes: d?.categoryTypes
              ? d?.categoryTypes?.join("/")
              : "N/A",
            businessPhone: d?.businessPhone,
            email: d?.users?.length ? d?.users[0]?.email : null,
            streetAddress1: d?.address?.streetAddress1,
            streetAddress2: d?.address?.streetAddress2,
            city: d?.address?.city,
            state: d?.address?.state,
            zip: d?.address?.zip,
            countryCode: d?.address?.countryCode,
            createdDate:
              d?.createdDate && moment(d.createdDate).format("YYYY-MM-DD"),
          })),
        ]);
        toast.success("Downloaded Successfully!");
      })
      .catch((e) => {
        err_toast(e);
      });
  };

  const handleSetLocation = (value) => setLocation({ address: { ...value } });
  const handleRemoveLocation = () => setLocation({ address: null });

  useEffect(() => {
    if (!loading) {
      if (data && data.getAdminNonprofits) {
        setNonProfits(
          nonprofitsFormatter(data?.getAdminNonprofits?.nonprofits),
        );
        setTotalRows(data?.getAdminNonprofits.total);
      } else {
        setNonProfits([]);
        setTotalRows(0);
      }
    }
  }, [loading, data]);

  useEffect(() => {
    if (csvData?.length > 0) document.getElementById("csv-link").click();
  }, [csvData]);

  const handleSetCategory = (value) => {
    setCategory(value);
  };

  const handleSetSort = (value) => {
    const val = value[0].value;
    setSort(val);
  };

  const handleChangeTerm = (e) => {
    const value = e.target.value;
    setTerm(value);
  };

  const onChangePage = (newPage, _) => {
    setIndexFrom((newPage - 1) * limit);
  };

  const onChangeRowsPerPage = (newPerPage, page) => {
    setIndexFrom((page - 1) * newPerPage);
    setLimit(newPerPage);
  };

  const getCSVFileName = () => {
    if (productUUID) {
      return `nonprofit-matches-product-${productUUID}.csv`;
    }
    return `nonprofits.csv`;
  };

  useEffect(() => {
    refetch();
  }, [indexFrom]);

  return (
    <>
      <Card>
        <CardHeader>
          {title && <h3>{title}</h3>}
          <Row>
            {showTermSearch && (
              <Col
                sm="12"
                md="3"
              >
                <p className="mb-0 mt-2">Search term</p>
                <Input
                  className="form-control"
                  id="searchTerm"
                  type="text"
                  value={term}
                  onChange={handleChangeTerm}
                />
              </Col>
            )}
            {showCategoryFilter && (
              <Col
                sm="12"
                md="2"
              >
                <p className="mb-0 mt-2">Filter By Category</p>
                <CategoryDropdown
                  isOpen={catDropdownOpen}
                  toggle={() => setCatDropdownOpen((prev) => !prev)}
                  categoryTreeValues={categoryTreeValues}
                  onCheck={handleSetCategory}
                  setCategoryTreeValues={setCategoryTreeValues}
                />
              </Col>
            )}
            <Col
              sm="12"
              md="2"
            >
              <p className="mb-0 mt-2">Sort by</p>
              <Select
                options={sort_by_options}
                value={sort}
                placeholder={sort || "Sort By"}
                style={{
                  textTransform: "capitalize",
                  fontWeight: 500,
                }}
                onChange={handleSetSort}
              />
            </Col>
            {showLocationInput && (
              <Col
                sm="12"
                md="2"
              >
                <Row>
                  <Col sm="10">
                    <p className="mb-0 mt-2">Location</p>
                    <SearchLocationInput
                      placeholdertext={"Enter location"}
                      required={false}
                      value={formatAddress(loc.address)}
                      setAddress={handleSetLocation}
                      styles={{ padding: "12px 10px" }}
                    />
                  </Col>
                  <Col sm="2">
                    {loc && loc.address && (
                      <XCircle
                        color="red"
                        className="mt-2 ml-1 cursor-pointer"
                        onClick={handleRemoveLocation}
                      />
                    )}
                  </Col>
                </Row>
              </Col>
            )}
            {showCSVDownload && (
              <Col
                sm="12"
                md="2"
              >
                <p className="mb-0 mt-2">&nbsp;</p>
                <Button onClick={onClickDownloadCSV}>
                  {csvLoading ? <Spinner size="sm" /> : "Download CSV"}
                </Button>

                <CSVLink
                  data={csvData}
                  filename={getCSVFileName()}
                  id="csv-link"
                  style={{ opacity: 0 }}
                />
              </Col>
            )}
          </Row>
        </CardHeader>
        <CardBody>
          {error ? (
            <>Some Error Occurred! Try Reloading the page.</>
          ) : (
            nonProfits && (
              <Datatable
                hideActions={hideActions}
                myData={nonProfits}
                loading={loading}
                pageSize={limit}
                pagination={true}
                paginationServer={true}
                totalRows={totalRows}
                myClass="-striped -highlight"
                multiSelectOption={!hideActions}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                onDeleteRows={onDeleteRows}
                noHeader
              />
            )
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default NonprofitList;
