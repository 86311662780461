import Breadcrumb from "../common/breadcrumb";
import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Container,
  Spinner,
} from "reactstrap";
import React, { Fragment, useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import Bold from "../common/bold";
import Text from "../common/text";
import { ToastContainer } from "react-toastify";
import moment from "moment";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { List, Truck } from "react-feather";
import TransactionList from "../transactions/transaction-list";
import { Link } from "react-router-dom";
import FulfillmentDetails from "../fulfillment/details";
import DownloadReceipt from "./download-receipt";

const mapStatusToBadge = {
  pending: "badge-secondary",
  open: "badge-warning",
  completed: "badge-success",
  sent: "badge-info",
};

const mapStatusToLabel = {
  pending: "Current",
  open: "In Review",
  completed: "Signed",
  sent: "Sent",
};

const ADMIN_GET_DONATION_RECEIPT = gql`
  query($uuid: String!) {
    adminGetDonationReceipt(uuid: $uuid) {
      uuid
      status
      startDate
      endDate
      totalItems
      totalFairMarketValue {
        value
        currencyCode
      }
      retailer {
        name
        uuid
      }
      nonprofit {
        organizationName
        uuid
      }
      transactions {
        uuid
        description
        type
        amount {
          value
          currencyCode
        }
        match {
          uuid
          status
          createdDate
        }
      }
      fulfillment {
        uuid
        status
        type
        createdDate
        order {
          uuid
        }
      }
    }
  }
`;

const DonationReceiptDetails = () => {
  const { donationReceiptUUID } = useParams();
  const [donationReceipt, setDonationReceipt] = useState({});

  const { loading, data, refetch } = useQuery(ADMIN_GET_DONATION_RECEIPT, {
    variables: {
      uuid: donationReceiptUUID,
    },
  });

  const showTransactions = () => donationReceipt?.transactions?.length > 0;
  const showFulfillment = () => !!donationReceipt?.fulfillment;

  useEffect(() => {
    if (!loading) {
      if (data && data.adminGetDonationReceipt) {
        setDonationReceipt(data.adminGetDonationReceipt);
      } else {
        setDonationReceipt({});
      }
    }
  }, [loading]);

  useEffect(() => {
    refetch();
  }, []);

  return (
    <Fragment>
      <Breadcrumb
        title="Donation Receipt Details"
        parent="Dashboard"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col
                    className="product-title"
                    sm={"12"}
                    md={"6"}
                  >
                    <h6>{donationReceipt.uuid}</h6>
                    <Text>
                      <span
                        className={`badge ${
                          mapStatusToBadge[donationReceipt.status]
                        }`}
                      >
                        {mapStatusToLabel[donationReceipt.status] ||
                          donationReceipt.status}
                      </span>
                    </Text>
                  </Col>
                  <Col
                    sm={"12"}
                    md={"6"}
                    className="d-flex align-items-start justify-content-end"
                  >
                    <DownloadReceipt
                      donationReceipt={donationReceipt}
                      fulfillment={donationReceipt?.fulfillment}
                    />
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {loading ? (
                  <div className="text-center my-5">
                    <Spinner />
                  </div>
                ) : (
                  <Row>
                    <Col
                      xs="12"
                      md="6"
                    >
                      <Bold>Nonprofit</Bold>
                      <h4>
                        <Link
                          target="_blank"
                          to={`/nonprofit/${donationReceipt?.nonprofit?.uuid}`}
                        >
                          {donationReceipt?.nonprofit?.organizationName}
                        </Link>
                      </h4>
                    </Col>
                    <Col
                      xs="12"
                      md="6"
                    >
                      <Bold>Retailer</Bold>
                      <h4>
                        <Link
                          target="_blank"
                          to={`/retailers/details/${donationReceipt?.retailer?.uuid}`}
                        >
                          {donationReceipt?.retailer?.name}
                        </Link>
                      </h4>
                    </Col>
                    <Col
                      xs="12"
                      md="6"
                    >
                      <Bold>Total Items</Bold>
                      <Text>{donationReceipt.totalItems}</Text>
                    </Col>
                    <Col
                      xs="12"
                      md="6"
                    >
                      <Bold>Total Fair Market Value</Bold>
                      <Text>
                        ${donationReceipt.totalFairMarketValue?.value}
                      </Text>
                    </Col>
                    {donationReceipt?.startDate && (
                      <Col
                        xs="12"
                        md="6"
                      >
                        <Bold>Start Date</Bold>
                        <Text>{donationReceipt.startDate}</Text>
                      </Col>
                    )}
                    {donationReceipt?.endDate && (
                      <Col
                        xs="12"
                        md="6"
                      >
                        <Bold>End Date</Bold>
                        <Text>{donationReceipt.endDate}</Text>
                      </Col>
                    )}

                    <Col
                      xs="12"
                      md="6"
                    >
                      <Bold>Date Created</Bold>
                      <Text>
                        {moment(donationReceipt.createdDate).format(
                          "DD MMM, YYYY hh:mm:SS A",
                        )}
                      </Text>
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Tabs>
                  <TabList className="nav nav-tabs tab-coupon">
                    {showTransactions() && (
                      <Tab className="nav-link d-flex align-items-center">
                        <List className="mr-2" />
                        Transactions
                      </Tab>
                    )}
                    {showFulfillment() && (
                      <Tab className="nav-link d-flex align-items-center">
                        <Truck className="mr-2" />
                        Fulfillment
                      </Tab>
                    )}
                  </TabList>
                  {showTransactions() && (
                    <TabPanel>
                      <TransactionList
                        donationReceiptUUID={donationReceipt?.uuid}
                        hideRetailerSearch={true}
                        hideDateSelector={true}
                        hideAddTransactions={true}
                      />
                    </TabPanel>
                  )}
                  {showFulfillment() && (
                    <TabPanel>
                      {donationReceipt?.fulfillment ? (
                        <FulfillmentDetails
                          fulfillmentUUID={donationReceipt?.fulfillment.uuid}
                        />
                      ) : (
                        <span>
                          No fulfillment associated with donation receipt
                        </span>
                      )}
                    </TabPanel>
                  )}
                </Tabs>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </Fragment>
  );
};

export default DonationReceiptDetails;
