import React, { useState } from "react";
import Sidebar from "./common/sidebar_components/sidebar";
import Footer from "./common/footer";
import Header from "./common/header_components/header";
import ScrollTop from "./ScrollTop";
import { useEffect } from "react";
import Cookies from "universal-cookie";
import { LIQUID_COOKIE } from "../constants/cookie";
import useWindowWidth from "../helpers/hooks/useWindowWidth";

const App = (props) => {
  const windowWidth = useWindowWidth();
  const isMobileView = windowWidth < 1200;

  const [sidebar, setSidebar] = useState(!isMobileView);

  const openCloseSidebar = () => {
    if (sidebar) {
      setSidebar(false);
      document.querySelector(".page-main-header").classList.add("open");
      document.querySelector(".page-sidebar").classList.add("open");
    } else {
      setSidebar(true);
      document.querySelector(".page-main-header").classList.remove("open");
      document.querySelector(".page-sidebar").classList.remove("open");
    }
  };

  useEffect(() => {
    const cookie = new Cookies();
    const data = cookie.get(LIQUID_COOKIE);
    if (!data) window.location.assign("/login");
  }, []);

  return (
    <div>
      <ScrollTop />
      <div className="page-wrapper">
        <Header openCloseSidebar={openCloseSidebar} />
        <div className="page-body-wrapper">
          <Sidebar openCloseSidebar={openCloseSidebar} />
          <div className="page-body">{props.children}</div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default App;
