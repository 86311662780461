export const OnGoingFulfillmentStatuses = [
  "shipping_pending",
  "pickup_pending",
  "delivery_pending",
  "delivery_in_progress",
];

export const FulfilledFulfillmentStatuses = [
  "pickup_complete",
  "delivery_complete",
  "shipping_complete",
];

export const CancelledFulfillmentStatuses = [
  "pickup_cancelled",
  "delivery_cancelled",
  "shipping_cancelled",
];
