import React, { Fragment, useState } from "react";
import CountUp from "react-countup";
import { toast } from "react-toastify";
import { Container, Input, Spinner } from "reactstrap";
import { initializeApollo } from "../../helpers/apollo";
import Breadcrumb from "../common/breadcrumb";
import SearchLocationInput from "../common/SearchLocationInput";
import styles from "./delivery.module.scss";
import { gql } from "@apollo/client";
import { formatAddress } from "../../helpers/address";

const QUOTE = gql`
  mutation adminCreateDeliveryQuote($provider: String!, $input: DeliveryQuoteInput!) {
    adminCreateDeliveryQuote(provider: $provider, input: $input) {
        deliveryInfo {
          externalQuoteID
          provider
          etaPickup
          etaDropoff
          distanceMiles
        }
    }
  }
`;

const GenerateQuotes = () => {
  const [data, setData] = useState({
    origin: null,
    destination: null,
    pickupTime: 0.0,
  });
  const [loading, setLoading] = useState(false);
  const [quotes, setQuotes] = useState(null);

  const handleGenerateQuote = () => {
    setLoading(true);
    const { origin, destination, pickupTime } = data;
    const date = new Date(pickupTime);
    const client = initializeApollo();
    client
      .mutate({
        mutation: QUOTE,
        variables: {
          provider: "uber",
          input: {
            source: origin,
            destination: destination,
            pickupTime: date.getTime() / 1000,
          },
        },
      })
      .then((res) => {
        setLoading(false);
        if (res?.data?.adminCreateDeliveryQuote?.deliveryInfo)
          setQuotes(res?.data?.adminCreateDeliveryQuote?.deliveryInfo);
      })
      .catch((err) => {
        toast.error(err.message || "something might went wrong!");
        setLoading(false);
      });
  };

  const handleChange = (e, type) => {
    if (!type) {
      data.pickupTime = e.target.value;
    } else {
      data[type] = e;
    }
    setData({ ...data });
  };

  return (
    <Fragment>
      <Breadcrumb
        title="Quotes"
        parent="Delivery"
      />

      <Container
        fluid={true}
        className={styles.container}
      >
        <div className={styles.box}>
          <div className={styles.top}>
            <h1>GET QUOTES</h1>
          </div>
          <div className={styles.bottom}>
            {!!quotes && (
              <div className={styles.quotes}>
                <div className={styles.quote}>
                  <p>Distance Miles</p>
                  <h2>
                    <CountUp end={quotes?.distanceMiles} />
                  </h2>
                </div>
                <div className={styles.quote}>
                  <p>ETA DropOff</p>
                  <h2>
                    <CountUp end={quotes?.etaDropoff} />
                  </h2>
                </div>
                <div className={styles.quote}>
                  <p>ETA Pickup</p>
                  <h2>
                    <CountUp end={quotes?.etaPickup} />
                  </h2>
                </div>
              </div>
            )}
            {!data.origin ? (
              <Location
                value={data.origin}
                setAddress={handleChange}
                type="origin"
                placeholder="Origin Address"
              />
            ) : (
              <Detail
                title="Origin"
                value={formatAddress(data.origin)}
              />
            )}
            {!!data.origin && !data.destination ? (
              <Location
                value={data.destination}
                setAddress={handleChange}
                type="destination"
                placeholder="Destination Address"
              />
            ) : !!data.destination ? (
              <Detail
                title="Destination"
                value={formatAddress(data.destination)}
              />
            ) : null}
            {!!data.origin && !!data.destination && (
              <Input
                onChange={handleChange}
                type="datetime-local"
                placeholder="Pickup Time"
              />
            )}
            <button
              disabled={
                !data.origin || !data.destination || !data.pickupTime || loading
              }
              onClick={handleGenerateQuote}
            >
              {loading ? <Spinner size="sm" /> : "Generate Quote"}
            </button>
          </div>
        </div>
      </Container>
    </Fragment>
  );
};

export default GenerateQuotes;

const Location = ({ value, setAddress, type, placeholder }) => {
  return (
    <SearchLocationInput
      styles={{ fontSize: "16px", padding: "12px 10px" }}
      placeholdertext={placeholder}
      setAddress={(data) => setAddress(data, type)}
      value={formatAddress(value)}
    />
  );
};

const Detail = ({ title, value }) => {
  return (
    <div className={styles.info}>
      <p>
        <i>{title}</i>
      </p>
      <p>
        <i>{value}</i>
      </p>
    </div>
  );
};
