import React from "react";
import { formatAddress } from "./address";
import { Button } from "reactstrap";
import { Trash2 } from "react-feather";

const addressesFormatter = (addresses, handleDelete) => {
  if (!addresses?.length) {
    return;
  }

  const formattedAddress = addresses.map((d) => {
    return {
      uuid: d?.uuid,
      address: formatAddress(d?.address),
      action: (
        <Button
          className="mr-2"
          color="danger"
          onClick={() => handleDelete(d?.uuid)}
        >
          <Trash2 height="15px" />
        </Button>
      ),
    };
  });
  return formattedAddress;
};

export default addressesFormatter;
