import React, { useState } from "react";
import {
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  ModalFooter,
} from "reactstrap";
import ProductDropdown from "../ProductDropdown";
import { createProductBundle } from "./services/productBundle";

const AddProductBundleModal = ({
  show,
  toggle,
  bundleUUID,
  productBundles,
  setProductBundles,
  retailerUUID,
}) => {
  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [product, setProduct] = useState();

  const handleCreate = async () => {
    const res = await createProductBundle(
      quantity,
      product,
      bundleUUID,
      setLoading,
      setProductBundles,
    );
    if (res) {
      setProductBundles([
        ...productBundles,
        {
          id: res?.id,
          quantity: res?.quantity,
          product: { uuid: res?.product?.uuid, title: res?.product?.title },
        },
      ]);
    }
    toggle();
  };

  return (
    <>
      <Modal
        isOpen={show}
        toggle={toggle}
      >
        <ModalHeader toggle={toggle}>Add Product</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label
                htmlFor="recipient-name"
                className="col-form-label"
              >
                Quantity :
              </Label>
              <input
                className={"form-control"}
                value={quantity ?? 1}
                onChange={(e) => setQuantity(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label
                htmlFor="message-text"
                className="col-form-label"
              >
                Product :
              </Label>
              <ProductDropdown
                {...{
                  retailerUUID,
                  product,
                  setProduct,
                }}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            small
            color="primary"
            style={{ height: "40px" }}
            loading={loading}
            onClick={handleCreate}
          >
            Save
          </Button>
          <Button
            type="button"
            color="secondary"
            onClick={toggle}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>

      {/* <Popover
      flip
      placement="bottom"
      target="api-key-popover"
      isOpen={show}
      toggle={toggle}
      style={{ width: "340px" }}
    >
      <PopoverHeader>
        <div className="flex justify-content-between align-items-center w-full">
          <h4
            className="c-black pt-2"
            style={{ fontWeight: 600 }}
          >
            Add Product
          </h4>
          <X
            size={20}
            className="cursor-pointer"
            onClick={handleClose}
          />
        </div>
      </PopoverHeader>
      <PopoverBody
        className="w-full"
        style={{ position: "relative" }}
      >
        <div className={cx("", "mt-2 mb-1")}>
        </div>
            <p className={cx("", "mt-3")}>Quantity</p>
            <input
              className={"form-control"}
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
            />
            <p className={cx("", "mt-3")}>Product</p>
            <ProductDropdown
                {...{
                    retailerUUID,
                    product,
                    setProduct,
                }}
              />{" "}
<div className="flex justify-content-end mt-4 mb-2">
            <Button
              small
              style={{ height: "40px" }}
              loading={loading}
              onClick={handleCreate}
            >
              Add Product
            </Button>
          </div>
      </PopoverBody>
    </Popover> */}
    </>
  );
};

export default AddProductBundleModal;
