import React, { Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Container } from "reactstrap";
import { gql } from "@apollo/client";
import { initializeApollo } from "../../helpers/apollo";
import { toast, ToastContainer } from "react-toastify";
import { addCountryCode } from "../../helpers/utils";
import { err_toast } from "src/helpers/errors";
import AddEditNonprofit from "./AddEditNonprofit";

const ADMIN_ADD_NONPROFIT = gql`
  mutation adminAddNonprofit($input: AdminAddNonprofitInput!) {
    adminAddNonprofit(input: $input) {
      uuid
    }
  }
`;

const CreateNonprofit = () => {
  const handleSubmit = async (vals, cb) => {
    let client = initializeApollo();

    client
      .mutate({
        mutation: ADMIN_ADD_NONPROFIT,
        variables: {
          input: {
            nonprofit: {
              ...vals,
              businessPhone: addCountryCode(vals?.businessPhone) || null,
            },
          },
        },
      })
      .then((_) => {
        toast.success("Created Nonprofit successfully!");
        cb();
        setTimeout(() => {
          window.location.replace("/nonprofits");
        }, 1000);
      })
      .catch((err) => {
        err_toast(err);
        cb();
      });
  };

  return (
    <Fragment>
      <Breadcrumb
        title="Create Nonprofit"
        parent="Nonprofitss"
      />
      <Container fluid={true}>
        <AddEditNonprofit
          data={{}}
          onSubmit={handleSubmit}
        />
      </Container>
      <ToastContainer />
    </Fragment>
  );
};

export default CreateNonprofit;
