import React from "react";
import { gql, useLazyQuery } from "@apollo/client";
import SelectSearch from "react-select-search";
import "./retailer.scss";

const ADMIN_GET_RETAILERS = gql`
  query($input: GetRetailersInput!) {
    adminGetRetailers(input: $input) {
      retailers {
        uuid
        name
      }
    }
  }
`;

const RetailerSelect = ({ retailer, onSelect, style = {} }) => {
  const [adminGetRetailers] = useLazyQuery(ADMIN_GET_RETAILERS);

  const getRetailerOptions = (query) => {
    return new Promise((resolve, reject) => {
      if (query) {
        adminGetRetailers({
          fetchPolicy: "no-cache",
          variables: {
            input: {
              indexFrom: 0,
              limit: 10,
              term: query,
            },
          },
        })
          .then((response) => {
            const opts = response?.data?.adminGetRetailers?.retailers.map(
              ({ name, uuid }) => ({
                name: name,
                value: uuid,
              }),
            );
            resolve(opts);
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        resolve([]);
      }
    });
  };

  const handleSetRetailerUUID = (uuid, { name }) => {
    onSelect({ uuid, name });
  };

  const onClear = (_evt) => {
    onSelect(Object.prototype);
  };

  const text = retailer.uuid && (retailer.name || `#${retailer.uuid}`);

  return (
    <div
      {...{
        style: { ...style, display: "flex", padding: 0, background: "#dfdfdf" },
        className: "form-control",
      }}
    >
      <SelectSearch
        getOptions={getRetailerOptions}
        search
        placeholder="Retailer search"
        onChange={handleSetRetailerUUID}
        style={style}
      />
      {text && (
        <div
          {...{
            style: {
              width: "fit-content",
              display: "flex",
              maxWidth: "140px",
              overflow: "scroll",
              alignItems: "center",
              padding: "0.5em",
              gap: "0.5em",
            },
          }}
        >
          {text}
          <button
            {...{
              onClick: onClear,
              style: {
                border: "none",
                background: "none",
                padding: 0,
              },
            }}
          >
            {"\u2715"}
          </button>
        </div>
      )}
    </div>
  );
};

export default RetailerSelect;
