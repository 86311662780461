import React from "react";
import { formatAddressLine1 } from "../../../../../helpers/address";
import useWindowWidth from "../../../../../helpers/hooks/useWindowWidth";
import google_pay_img from "../../../../../assets/icons/google_pay.png";
import apple_pay_img from "../../../../../assets/icons/apple_pay.png";
import card_img from "../../../../../assets/icons/credit-card.svg";
import Notes from "../../../../notes/notes";
import moment from "moment";
import { Card, CardBody, Col, Row } from "reactstrap";
import { getStripePaymentIntentIDLink } from "src/helpers/stripe";
import Bold from "src/components/common/bold";
import Text from "src/components/common/text";

const mapPaymentTypeToImg = {
  google_pay: google_pay_img,
  apple_pay: apple_pay_img,
  card: card_img,
};

const PaymentDetailsCard = ({
  addressData,
  priceData,
  paymentIntentID,
  method,
  created,
  recipient,
  notes,
  uuid,
}) => {
  const windowWidth = useWindowWidth();
  const isMobileView = windowWidth < 700;

  const RecipientAddressBlock = () => {
    return (
      <>
        <Row>
          <Col>
            <div>
              <h6 className="font-weight-bold card-title c-black">
                <u>RECIPIENT</u>
              </h6>
              <div className="m1 text-default">
                <b>
                  <a
                    style={{
                      color: "tomato",
                      letterSpacing: -0.1,
                    }}
                    href={"/users/search?uuid=" + recipient.user.uuid}
                  >
                    <u>
                      {recipient.user.firstName || "<no name>"}{" "}
                      {recipient.user.lastName}
                    </u>
                  </a>
                </b>
                {recipient?.nonprofit && (
                  <h5>
                    <a
                      style={{
                        color: "tomato",
                        letterSpacing: -0.1,
                      }}
                      href={"/nonprofit/" + recipient?.nonprofit?.uuid}
                    >
                      <u>{recipient?.nonprofit?.organizationName}</u>
                    </a>
                  </h5>
                )}
              </div>
              <p className="mb-0 text-default">
                {formatAddressLine1(addressData)}
              </p>
              <p className="text-default mb-2">
                {addressData?.address?.city +
                  ", " +
                  addressData?.address?.state}
              </p>
              <p className={`mt-2 text-default mb-1}`}>
                Contact Number: {addressData?.contactNumber}
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            {addressData?.notes && (
              <div>
                <p className="title__underline"></p>
                <p
                  style={{ textDecoration: "underline" }}
                  className="mt-3 c-black mb-0 text-default"
                >
                  Delivery Instructions
                </p>
                <p className="text-default">{addressData?.notes}</p>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <div
                style={{ width: "100%" }}
                className="payment__detailsRight"
              >
                <div className="row">
                  <div className={`col-4`}>
                    <div>
                      <h6 className="font-weight-bold card-title c-black">
                        {" "}
                        <u>ORDER SUMMARY</u>
                      </h6>
                      {priceData?.lineItems.map(
                        ({ amount, label, type }, i) => (
                          <div
                            key={i}
                            style={{ fontWeight: type === "total" && 600 }}
                            className="d-flex align-items-center justify-between mb-2"
                          >
                            <span
                              className={`${
                                type === "total"
                                  ? "info font-weight-bold text-xl"
                                  : "c-black text-default"
                              }`}
                              style={{
                                width: isMobileView ? "70%" : 250,
                              }}
                            >
                              {label}
                            </span>
                            <span
                              className={`info font-weight-bold ${
                                type === "total" ? "text-xl" : "text-default"
                              }`}
                            >
                              ${amount?.value}
                            </span>
                          </div>
                        ),
                      )}
                      {paymentIntentID && (
                        <div>
                          <Bold>Payment Intent ID</Bold>
                          {paymentIntentID ? (
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={getStripePaymentIntentIDLink(
                                paymentIntentID,
                              )}
                            >
                              {paymentIntentID}
                            </a>
                          ) : (
                            <Text>-</Text>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={`${isMobileView ? "col-5 pl-2" : "col-2"}`}>
                    <div style={{ maxWidth: 300 }}>
                      <h6 className="font-weight-bold card-title c-black">
                        <u>ORDERED ON</u>
                      </h6>
                      <p className="text-default">
                        {moment(created).format("DD MMM, YYYY hh:mm:SS A")}
                      </p>

                      {method && (
                        <div>
                          <h6
                            style={{ marginToh5: isMobileView ? 20 : 30 }}
                            className="font-weight-bold card-title c-black"
                          >
                            <u>PAYMENT METHOD</u>
                          </h6>
                          <div className="d-flex align-items-center">
                            <img
                              className=""
                              height={23}
                              width={23}
                              src={mapPaymentTypeToImg[method?.type]}
                              alt="Payment Method"
                            />
                            <b
                              className="ml-1 "
                              style={{
                                textTransform: "capitalize",
                                fontSize: isMobileView ? 12 : 16,
                              }}
                            >
                              {method?.type?.split("_").join(" ")}
                            </b>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={"col-3"}>
                    <RecipientAddressBlock />
                  </div>
                  <div className={`"col-3"`}>
                    <Notes
                      notes={notes}
                      objectUuid={uuid}
                      objectType={"order"}
                    />
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PaymentDetailsCard;
