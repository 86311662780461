import React from "react";
import { money_to_str, truncate } from "./utils";
import moment from "moment";
import { Link } from "react-router-dom";

const mapStatusToBadge = {
  pending: "badge-secondary",
  open: "badge-warning",
  completed: "badge-success",
  sent: "badge-info",
};

const mapStatusToLabel = {
  pending: "Current",
  open: "In Review",
  completed: "Signed",
  sent: "Sent",
};

const donationReceiptsFormatter = (donationReceipts) => {
  if (!donationReceipts?.length) {
    return;
  }

  const formattedDonationReceipts = donationReceipts.map((d) => {
    return {
      uuid: (
        <Link
          key={d.uuid}
          target="_blank"
          to={`/donation-receipt/${d?.uuid}`}
        >
          {d.uuid}
        </Link>
      ),
      status: (
        <span
          key={d.uuid}
          className={`badge ${mapStatusToBadge[d.status]}`}
        >
          {mapStatusToLabel[d.status] || d.status}
        </span>
      ),
      Nonprofit: d?.nonprofit ? (
        <Link
          key={d.nonprofit.organizationName}
          target="_blank"
          to={`./nonprofit/${d.nonprofit.uuid}`}
        >
          {d.nonprofit.organizationName} &nbsp;&nbsp;
        </Link>
      ) : (
        "-"
      ),
      Retailer: d?.retailer ? (
        <Link
          key={d.retailer.uuid}
          target="_blank"
          to={`./retailers/details/${d.retailer.uuid}`}
        >
          {d.retailer.name} &nbsp;&nbsp;
        </Link>
      ) : (
        "-"
      ),
      "Donation Receipt Type (Country)": d?.nonprofit?.address?.countryCode ? (
        <a key={d.nonprofit.organizationName}>
          {d.nonprofit.address.countryCode === "CA"
            ? "Canada"
            : "United States"}{" "}
          &nbsp;&nbsp;
        </a>
      ) : (
        "-"
      ),
      Fulfillment: d?.fulfillment ? (
        <Link
          key={d.fulfillment.uuid}
          target="_blank"
          to={`./fulfillment/${d.fulfillment.uuid}`}
        >
          {truncate(d.fulfillment.uuid, 8)} &nbsp;&nbsp;
        </Link>
      ) : (
        "-"
      ),
      "Start Date": d?.startDate
        ? moment(d.startDate).utc().format("DD MMM, YYYY")
        : "-",
      "End Date": d?.endDate
        ? moment(d.endDate).utc().format("DD MMM, YYYY")
        : "-",
      "Total FMV": money_to_str(d.totalFairMarketValue),
      "Total Items": d.totalItems,
      created: moment(d.createdAt).format("DD MMM, YYYY hh:mm:SS A"),
    };
  });

  return formattedDonationReceipts;
};

export default donationReceiptsFormatter;
