import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import {
  LAST_USED_ADDRESS,
  LAST_USED_PHONE,
  LAST_USED_EMAIL,
  LAST_USED_PICKUP_NOTES,
} from "../constants/cookie";
import phoneFormatter from "phone-formatter";

export const removeCountryCode = (phone) => {
  if (phone.startsWith("+1")) {
    return phone.substring(2, phone.length);
  } else {
    return phone;
  }
};

export const addCountryCode = (value) => {
  if (!value) return value;
  let phone = phoneFormatter.normalize(value);
  if (phone.startsWith("+1")) {
    return phone;
  } else {
    return "+1" + phone;
  }
};

export const truncate = (str, len) => {
  return str.length > len ? str.substring(0, len - 3) + "..." : str;
};

export const id = (x) => x;
export const comp = (f) => (g) => (x) => f(g(x));
export const pure = (v) => (_) => v;
export const get = ([v, _]) => v;
export const set = ([_, s]) => s;
export const eventValue = (f) => comp(f)((e) => e.target.value);

export const updateField =
  ([_, set]) =>
  (k) =>
  (f) => {
    set((state) => ({ ...state, [k]: f(state.k) }));
  };
export const setField = (lens) => (k) => (v) => {
  updateField(lens)(k)(pure(v));
};

export const updateMap =
  ([_, set]) =>
  (k) =>
  (f) => {
    set((m) => new Map([...m, [k, f(m.get(k))]]));
  };
export const insMap = (lens) => (k) => (v) => {
  updateMap(lens)(k)(pure(v));
};
export const delMap =
  ([_, set]) =>
  (k) => {
    set((m) => new Map([...m].filter(([ki, _vi]) => ki !== k)));
  };

export const SuccessToastAndReload = (message) => {
  toast.success(message || "Success!");
  setTimeout(() => {
    window.location.reload();
  }, 1000);
};

export const fetchLastUsedAddress = () => {
  const cookies = new Cookies();
  const address = cookies.get(LAST_USED_ADDRESS);
  return address || undefined;
};

export const fetchLastUsedPhoneNumber = () => {
  const cookies = new Cookies();
  let phone = cookies.get(LAST_USED_PHONE);
  return phone ? phone.replace(/\D+/g, "") : undefined;
};

export const fetchLastUsedEmail = () => {
  const cookies = new Cookies();
  let email = cookies.get(LAST_USED_EMAIL);
  return email || undefined;
};

export const fetchLastUsedPickupNotes = () => {
  const cookies = new Cookies();
  let pickupNotes = cookies.get(LAST_USED_PICKUP_NOTES);
  return pickupNotes || undefined;
};

export const handleSetCookies = (key, value) => {
  if (!value) {
    removeCookies(key);
    return;
  }
  const cookies = new Cookies();
  cookies.set(key, value, {
    path: "/",
    maxAge: 3600, //ONE HOUR EXPIRATION
    sameSite: true,
  });
};

export const removeCookies = (key) => {
  const cookies = new Cookies();
  cookies.remove(key, { path: "/", sameSite: true });
};

export const num_to_graph = (num) =>
  ((flt) => (isNaN(flt) ? undefined : flt))(parseFloat(num));

export const date_to_graph = (dt) =>
  ((date) =>
    date == null
      ? undefined
      : date.includes("T")
      ? date
      : date + "T00:00:00.876Z")(dt);

export const num_to_money = (num, currencyCode) => {
  return {
    value: String(num_to_graph(num)),
    currencyCode: currencyCode,
  };
};

export const money_to_str = (money) => {
  return money && money?.value !== undefined
    ? money?.value < 0
      ? `- $ ${Math.abs(money.value).toFixed(2)}`
      : `$ ${money.value}`
    : `-`;
};

export const contactNumberToGraph = (cn) =>
  cn ? addCountryCode(cn) : undefined;
export const contactEmailToGraph = (ce) => (ce ? ce : undefined);
export const locationToGraph = (location) =>
  Object.values(location).every(
    (value) => value === null || value === undefined,
  )
    ? null
    : location?.contactNumber || location?.contactEmail
    ? update_fields({
        contactNumber: contactNumberToGraph,
        contactEmail: contactEmailToGraph,
      })(location)
    : location;

// Update `obj[key]` with `updates[key]`
// This may write `[key]: undefined`.
export const update_fields = (updates) => (obj) => {
  if (obj === undefined) {
    return;
  }

  if (typeof obj !== "object") {
    throw "not an object";
  }

  if (obj === null) {
    throw "null object";
  }

  const next = { ...obj };
  let change = false;

  for (const key in updates) {
    const prev_val = next[key];
    const next_val = updates[key](prev_val);

    if (!Object.is(prev_val, next_val)) {
      change = true;
      next[key] = next_val;
    }
  }

  return change ? Object.freeze(next) : obj;
};

export const imagesToGraph = (images) =>
  images?.map((img) =>
    img?.uuid
      ? { uuid: img.uuid, displayOrder: img?.displayOrder }
      : img?.upload
      ? { upload: img?.upload, displayOrder: img?.displayOrder }
      : img?.stockImage
      ? { url: img?.url, displayOrder: img?.displayOrder }
      : {},
  );
