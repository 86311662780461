import React, { useEffect, useState } from "react";
import { Col, Form, ListGroup, ListGroupItem, Row } from "reactstrap";
import { DateTime } from "luxon";
import { gql } from "@apollo/client";
import { initializeApollo } from "../../helpers/apollo";
import { toast } from "react-toastify";
import TextareaAutosize from "react-textarea-autosize";
import "./notes.scss";

const Notes = ({ notes: initialNotes, objectUuid, objectType }) => {
  const [notes, setNotes] = useState([]);
  const [note, setNote] = useState("");

  useEffect(() => {
    setNotes(initialNotes);
  }, [initialNotes]);

  const CREATE_NOTE = gql`
    mutation ($input: CreateNoteInput!) {
      createNote(input: $input) {
        uuid
        text
        user {
          uuid
          firstName
          lastName
        }
        createdDate
      }
    }
  `;

  const createNote = () => {
    let client = initializeApollo();

    client
      .mutate({
        mutation: CREATE_NOTE,
        variables: {
          input: { objectUuid: objectUuid, objectType, text: note },
        },
      })
      .then((newNote) => {
        setNotes(
          notes
            ? [...notes, newNote.data.createNote]
            : [newNote.data.createNote],
        );
      })
      .catch((err) => {
        toast.error(err.message || "something went wrong!");
      });
  };

  const parseTime = (time) => {
    return DateTime.fromISO(time).toRelative();
  };

  return (
    <div className={"notesParent"}>
      <h4>NOTES</h4>
      <Form onSubmit={(e) => e.preventDefault()}>
        <Row className="row-cols-lg-auto g-4 align-items-center">
          <Col>
            <div className="input-group grow-wrap">
              <TextareaAutosize
                name="note"
                placeholder="Enter note..."
                value={note}
                onChange={(e) => setNote(e.target.value)}
                autoComplete={"off"}
                onKeyDown={(e) => {
                  if (
                    (e.key === "Enter" && e.ctrlKey) ||
                    (e.key === "Enter" && e.metaKey)
                  ) {
                    createNote();
                    setNote("");
                  }
                }}
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span
                    onClick={() => {
                      createNote();
                      setNote("");
                    }}
                    className="badge badge-secondary"
                    style={{ cursor: "pointer" }}
                  >
                    {" "}
                    &gt;{" "}
                  </span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <p>Notes will not be shared with external users</p>
      </Form>

      <br />

      <ListGroup>
        {Array.isArray(notes) &&
          notes.map((note) => (
            <ListGroupItem key={`note-${note.uuid}`}>
              <span className="float-left text-muted">
                {note.user.firstName} {note.user.lastName}
              </span>
              <span className="float-right text-muted">
                {parseTime(note.createdDate)}
              </span>{" "}
              <br />
              {note.text}
            </ListGroupItem>
          ))}
      </ListGroup>
    </div>
  );
};

export default Notes;
