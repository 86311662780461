import addUser from "./add-user.png";
import apple_pay from "./apple_pay.png";
import google_pay from "./google_pay.png";
import credit_card from "./credit-card.svg";
import info from "./info.svg";

const icons = {
  addUser,
  apple_pay,
  google_pay,
  credit_card,
  info,
};

export default icons;
