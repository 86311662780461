import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

const mapStatusToBadge = {
  draft: "badge-secondary",
  unpublished: "badge-warning",
  published: "badge-success",
};

const blogsFormatter = (blogs) => {
  if (!blogs?.length) {
    return;
  }

  const formattedInvoices = blogs.map((d) => {
    return {
      Title: (
        <Link
          key={d.uuid}
          to={`/blog/edit/${d?.uuid}`}
        >
          {d.title || "<no-title>"}
        </Link>
      ),
      slug: <>{d.slug || "<no-slug>"}</>,
      url: d?.url ? (
        <a
          key={d.uuid}
          href={d?.url}
          target="_blank"
          rel="noreferrer"
        >
          {d.url || "<no-title>"}
        </a>
      ) : (
        "<no-url>"
      ),

      status: (
        <span
          key={d.uuid}
          className={`badge ${mapStatusToBadge[d.status]}`}
        >
          {d.status}
        </span>
      ),

      "Author Email": (
        <Link
          key={d.uuid}
          target="_blank"
          to={`/users/search?q=${d?.author?.email}`}
        >
          {d?.author?.email}
        </Link>
      ),
      description:
        d?.description?.substring(0, 100) +
        (d?.description?.length > 100 ? " ..." : ""),
      categoryTags: d?.categoryTags?.join(", "),
      Created: moment(d.createdAt).utc().format("DD MMM, YYYY, HH:mm:ss"),
      Updated: moment(d.updatedAt).utc().format("DD MMM, YYYY, HH:mm:ss"),
      Published: d?.publishedAt
        ? moment(d.publishedAt).utc().format("DD MMM, YYYY, HH:mm:ss")
        : "-",
    };
  });

  return formattedInvoices;
};

export default blogsFormatter;
