import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import "./index.scss";
import App from "./components/app";
import PerfectScrollbar from "react-perfect-scrollbar";

// Components
import Dashboard from "./components/dashboard";

// Products physical
import Category from "./components/products/physical/category";
import Sub_category from "./components/products/physical/sub-category";
import Products from "./components/products/physical/products";
import Add_product from "./components/products/physical/add-product";
import ProductDetail from "./components/products/physical/product-detail";

//Sales
import Orders from "./components/sales/orders";
import OrderDetail from "./components/sales/orders/orderDetailContainer";
import Transactions_sales from "./components/sales/transactions-sales";

//Pages
import SearchUser from "./components/users/search";
import Datatable from "./components/common/datatable";
import Login from "./components/auth/login";
import { useApollo } from "./helpers/apollo";
import ItemDonations from "./components/donate/item-donations";
import NonProfits from "./components/nonprofits";
import UploadProductCsv from "./components/products/physical/UploadProductCsv";
import NonprofitDetails from "./components/nonprofits/NonprofitDetails";
import NonprofitEdit from "./components/nonprofits/NonprofitEdit";
import EditRetailer from "./components/retailers/RetailerEdit";

import Retailers from "./components/retailers";
import CreateRetailer from "./components/retailers/RetailerCreate";
import RetailerDetails from "./components/retailers/retailer-details";
import StoreDetails from "./components/stores/details";
import CreateStore from "./components/stores/create";
import MergeRetailers from "./components/retailers/MergeRetailers";
import GenerateQuotes from "./components/delivery/GenerateQuotes";
import EditProduct from "./components/products/physical/product-edit";
import FulfillmentDetailsContainer from "./components/fulfillment/detailsContainer";
import NonprofitMatches from "./components/nonprofits/NonprofitMatches";
import MatchDetails from "./components/matches/MatchDetails";
import Matches from "./components/matches";
import Invoices from "./components/invoices";
import InvoiceDetails from "./components/invoices/invoice-details";
import CircularityPartners from "./components/circularityPartners";
import CircularityPartnerDetails from "./components/circularityPartners/CircularityPartnersDetails";
import CreateNonprofit from "./components/nonprofits/NonprofitCreate";
import AddBlog from "./components/blog/AddBlog";
import EditBlog from "./components/blog/EditBlog";
import ListBlogs from "./components/blog/ListBlogs";
import EditDonation from "./components/donate/edit-donation";
import DonationReceipts from "./components/donationReceipts";
import DonationReceiptDetails from "./components/donationReceipts/donation-receipt-details";
import Categories from "./components/categories/categories";
import CategoryDetails from "./components/categories/category-details";
import AddCategory from "./components/categories/add-category";
import MessagingDetails from "./components/messaging/message-details";
import ListSubscriptionPlans from "./components/subscriptionplans/ListSubscriptionPlans";
import AddSubscriptionPlan from "./components/subscriptionplans/AddSubscriptionPlan";
import EditSubscriptionPlan from "./components/subscriptionplans/EditSubscriptionPlan";
import EditPricing from "./components/pricing/EditPricing";

const Root = () => {
  const client = useApollo({});
  return (
    <ApolloProvider client={client}>
      <BrowserRouter basename={"/"}>
        <PerfectScrollbar>
          <Switch>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/`}
              component={Login}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/login`}
              component={Login}
            />

            <App>
              <Route
                path={`${process.env.PUBLIC_URL}/dashboard`}
                component={Dashboard}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/products/physical/category`}
                component={Category}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/products/physical/sub-category`}
                component={Sub_category}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/products/add-product`}
                component={Add_product}
                exact={true}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/products`}
                component={Products}
                exact={true}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/product/:uuidOrSlug`}
                component={ProductDetail}
                exact={true}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/product/:productUUID/nonprofits`}
                component={NonprofitMatches}
                exact={true}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/categories`}
                component={Categories}
                exact={true}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/category-details/:categoryType`}
                component={CategoryDetails}
                exact={true}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/categories/add`}
                component={AddCategory}
                exact={true}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/fulfillment/:fulfillmentUuid`}
                component={FulfillmentDetailsContainer}
                exact={true}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/product/:uuid/edit`}
                component={EditProduct}
                exact={true}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/retailer/details/:uuid/edit`}
                component={EditRetailer}
                exact={true}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/products/physical/product-csv-upload`}
                component={UploadProductCsv}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/order/:orderUUID`}
                component={OrderDetail}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/orders`}
                component={Orders}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/message-details/:uuid`}
                component={MessagingDetails}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/sales/transactions`}
                component={Transactions_sales}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/users/search`}
                component={SearchUser}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/data-table`}
                component={Datatable}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/donate/list`}
                component={ItemDonations}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/item-donation/edit/:uuid`}
                component={EditDonation}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/matches`}
                component={Matches}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/match/:uuid`}
                component={MatchDetails}
                exact={true}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/nonprofits`}
                component={NonProfits}
                exact={true}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/nonprofit/:id`}
                component={NonprofitDetails}
                exact={true}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/nonprofits/create`}
                component={CreateNonprofit}
                exact={true}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/nonprofit/:uuid/edit`}
                component={NonprofitEdit}
                exact={true}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/circularity-partners`}
                component={CircularityPartners}
                exact={true}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/circularity-partner/:uuid`}
                component={CircularityPartnerDetails}
                exact={true}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/retailers`}
                exact={true}
                component={Retailers}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/retailers/details/:uuid`}
                exact={true}
                component={RetailerDetails}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/store/details/:uuid`}
                exact={true}
                component={StoreDetails}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/retailers/create`}
                exact={true}
                component={CreateRetailer}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/retailers/:uuid/store/create`}
                exact={true}
                component={CreateStore}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/retailers/merge`}
                exact={true}
                component={MergeRetailers}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/invoices`}
                exact={true}
                component={Invoices}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/invoice/:invoiceUUID`}
                exact={true}
                component={InvoiceDetails}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/donation-receipts`}
                exact={true}
                component={DonationReceipts}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/donation-receipt/:donationReceiptUUID`}
                exact={true}
                component={DonationReceiptDetails}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/delivery/generate-quotes`}
                component={GenerateQuotes}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/pricing/edit/:uuid`}
                component={EditPricing}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/subscriptionplans`}
                component={ListSubscriptionPlans}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/subscriptionplan/add`}
                component={AddSubscriptionPlan}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/subscriptionplan/edit/:subscriptionPlanUUID`}
                component={EditSubscriptionPlan}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/blogs`}
                component={ListBlogs}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/blog/add`}
                component={AddBlog}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/blog/edit/:blogUUID`}
                component={EditBlog}
              />
            </App>
          </Switch>
        </PerfectScrollbar>
      </BrowserRouter>
    </ApolloProvider>
  );
};
const root = createRoot(document.getElementById("root"));
root.render(<Root />);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
