import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Breadcrumb from "../../common/breadcrumb";
import { XCircle } from "react-feather";
import {
  Button,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
  Spinner,
} from "reactstrap";
import { gql, useQuery } from "@apollo/client";
import { formatAddress } from "../../../helpers/address";
import { useHistory, useLocation } from "react-router-dom";
import SearchLocationInput from "../../common/SearchLocationInput";
import { initializeApollo } from "../../../helpers/apollo";
import { toast, ToastContainer } from "react-toastify";
import getConfigs from "../../../config/base";
import CategoryDropdown from "./CategoryDropdown";
import styles from "../../donate/donate.module.scss";
import Select from "react-dropdown-select";
import ProductItem from "./product-item";
import RetailerSelect from "../../retailers/retailer-select";

const stock_options = [
  { label: "In Stock", value: "in-stock" },
  { label: "Out of Stock", value: "out-of-stock" },
  { label: "All", value: "all" },
];

const donorOptions = [
  { label: "Retailer", value: "retailer" },
  { label: "Non-retailer", value: "non-retailer" },
  { label: "All Donors", value: "all" },
];

const PRODUCTS = gql`
  query ($input: GetAdminProductsInput!) {
    getAdminProducts(input: $input) {
      items {
        uuid
        title
        slug
        description
        fairMarketValue {
          value
          currencyCode
        }
        quantity
        availableQuantity
        unitType
        conditionTagTypes
        fulfillmentTypes
        recipientTypes
        lengthInches
        widthInches
        heightInches
        weightLbs
        isNonprofitGift
        status
        categories {
          name
          type
        }
        images {
          uuid
          alt
          resolutions {
            m {
              url
            }
            l {
              url
            }
            s {
              url
            }
          }
        }
        distanceMiles
        donor {
          firstName
          lastName
        }
        donorRetailer{
          name
        }
        location {
          address {
            state
            city
            latitude
            longitude
          }
          contactNumber
          notes
        }
        createdDate
      }
      hasMore
    }
  }
`;

const SORT_OPTIONS = gql`
  query getSortOrder {
    getSortOrder {
      label
      type
    }
  }
`;

const DELETE_PRODUCT = gql`
  mutation adminDeleteDonation($data: String!) {
    adminDeleteDonation(uuid: $data)
  }
`;

const itemsPerPage = 8;

const Products = () => {
  const [page, setPage] = useState(0);
  const [loc, setLocation] = useState({ address: null });
  const [sortBy, setSortBy] = useState("new");
  const [inStock, setInStock] = useState(true);
  const [isDonorRetailer, setIsDonorRetailer] = useState(null);
  const [typedTerm, setTypedTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const { data: sortOptions } = useQuery(SORT_OPTIONS);
  const [_, setEditMode] = useState(null);
  const [categoryTreeValues, setCategoryTreeValues] = useState({
    checked: [],
    expanded: [],
  });
  const [catDropdownOpen, setCatDropdownOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState();
  const [fetchLoading, setFetchLoading] = useState(false);
  const location = useLocation();
  const loader = useRef(null);
  const [retailerUUID, setRetailerUUID] = useState();
  const [retailerName, setRetailerName] = useState();

  const resetPagination = () => {
    setPage(0);
  };

  let { loading, data, fetchMore } = useQuery(PRODUCTS, {
    fetchPolicy: "no-cache",
    variables: {
      input: {
        inStock: inStock,
        isDonorRetailer: isDonorRetailer,
        indexFrom: 0,
        limit: itemsPerPage,
        term: searchTerm,
        sortOrder: sortBy,
        location: loc?.address ? loc : undefined,
        categoryTypes: category,
        retailerUUID: retailerUUID,
      },
    },
  });

  const handleObserver = useCallback(
    (entries) => {
      const target = entries[0];
      if (target.isIntersecting) {
        if (!products.length) return;
        setPage((prev) => prev + 1);
      }
    },
    [products?.length === 0],
  );

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "10px",
      threshold: 0,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) observer.observe(loader.current);
  }, [handleObserver]);

  const getSearchParams = () => {
    const params = new URLSearchParams(window.location.search);
    const sortSearch = params.get("sort");
    const catSearch = params.get("category");
    const termSearch = params.get("term");
    const stock = params.get("stock");
    const retailerUUID = params.get("retailerUUID");
    return [sortSearch, catSearch, termSearch, stock, retailerUUID];
  };

  const setSearchParams = () => {
    const [sortSearch, catSearch, termSearch, stock, donor, retailerUUID] =
      getSearchParams();
    if (sortSearch) {
      setSortBy(sortSearch);
    }
    if (termSearch && typedTerm !== termSearch) {
      setSearchTerm(termSearch);
      setTypedTerm(termSearch);
    }

    if (stock) {
      const stockVal =
        stock === "all"
          ? null
          : stock === "in-stock"
          ? true
          : stock === "out-of-stock" && false;
      setInStock(stockVal);
    }

    if (donor) {
      const donorVal =
        donor === "all"
          ? null
          : donor === "retailer"
          ? true
          : donor === "non-retailer" && false;
      setIsDonorRetailer(donorVal);
    }

    if (catSearch && catSearch !== category?.join(",") && catSearch?.length) {
      setCategory(catSearch.split(","));
      categoryTreeValues.checked = catSearch.split(",");
      setCategoryTreeValues({ ...categoryTreeValues });
    }

    if (retailerUUID) {
      setRetailerUUID(retailerUUID);
    }
  };

  useEffect(() => {
    setSearchParams();
  }, []);

  const handleAddSearchParams = (type, value) => {
    resetPagination();
    const params = new URLSearchParams(window.location.search);
    if (
      type === "sort" ||
      type === "term" ||
      type === "stock" ||
      type === "donor" ||
      type === "retailerUUID"
    ) {
      params.set(type, value);
    }
    if (type === "category") {
      params.set(type, value.join(","));
    }
    window.history.pushState(null, "", "/products?" + params.toString());
  };

  const handleRemoveSearchParams = (type) => {
    setPage(0);
    const params = new URLSearchParams(window.location.search);
    params.delete(type);
    window.history.pushState(null, "", "/products?" + params.toString());
  };

  useEffect(() => {
    if (page === 0 && !!data?.getAdminProducts?.items)
      setProducts([...data.getAdminProducts.items]);
  }, [data]);

  useEffect(() => {
    page !== 0 && loadMore();
  }, [page]);

  const loadMore = () => {
    setFetchLoading(true);
    fetchMore({
      fetchPolicy: "no-cache",
      variables: {
        input: {
          inStock: inStock,
          isDonorRetailer: isDonorRetailer,
          indexFrom: page * itemsPerPage,
          limit: itemsPerPage,
          term: searchTerm,
          sortOrder: sortBy,
          location: loc?.address ? loc : undefined,
          categoryTypes: category,
          retailerUUID: retailerUUID,
        },
      },
    })
      .then((result) => {
        setFetchLoading(false);
        if (result?.data?.getAdminProducts?.items) {
          setProducts([...products, ...result.data.getAdminProducts.items]);
        }
      })
      .catch((_) => {
        setFetchLoading(false);
      });
  };

  const history = useHistory();

  useEffect(() => {
    const param = location.pathname.split("/").reverse()[0];
    if (
      param === "product-list" ||
      !data?.getAdminProducts?.items ||
      param.length < 36
    )
      return;
    const productId = param;
    const product = data.getAdminProducts.items.find(
      (d) => d.uuid === productId,
    );
    if (!product) return;
    setEditMode(product);
  }, [data, location.pathname]);

  const handleSetLocation = (value) => {
    resetPagination();
    setLocation({ address: { ...value } });
  };
  const handleRemoveLocation = () => {
    resetPagination();
    setLocation({ address: null });
  };

  const copyToClipboard = (slugOrUUID) => {
    navigator.clipboard.writeText(
      getConfigs().frontend + "/product-details/" + slugOrUUID,
    );
    toast.success("Copied to the clipboard successfully!");
  };

  const onClickEdit = (data) => {
    history.push("/product/" + data.uuid + "/edit");
  };

  const onClickDelete = (data) => {
    let client = initializeApollo();

    client
      .mutate({
        mutation: DELETE_PRODUCT,
        variables: { data: data },
      })
      .then((_) => {
        toast.success("Product deleted successfully!");
        setTimeout(() => {
          window.location.replace("/products");
        }, 1000);
      })
      .catch((err) => {
        toast.error(err.message || "something went wrong!");
      });
  };

  const onClickNonProfits = (data) => {
    setEditMode(data);
    history.push("product/" + data.uuid + "/nonprofits");
  };

  const onOpenProductDetails = (uuidOrSlug) => {
    history.push("/product/" + uuidOrSlug);
  };
  const handleSearchTerm = (_) => {
    setSearchTerm(typedTerm);
    handleAddSearchParams("term", typedTerm);
  };

  const handleSetSort = (value) => {
    resetPagination();
    const val = value[0].value;
    setSortBy(val);
    handleAddSearchParams("sort", val);
  };

  const handleSetStock = (value) => {
    resetPagination();
    const val = value[0].value;
    handleAddSearchParams("stock", val);
    const stockVal =
      val === "all"
        ? null
        : val === "in-stock"
        ? true
        : val === "out-of-stock" && false;
    setInStock(stockVal);
  };

  const handleSetDonor = (value) => {
    resetPagination();
    const val = value[0].value;
    handleAddSearchParams("donor", val);
    const donorVal =
      val === "all"
        ? null
        : val === "retailer"
        ? true
        : val === "non-retailer" && false;
    setIsDonorRetailer(donorVal);
  };

  const handleSetCategories = (value) => {
    resetPagination();
    setCategory(value);
    handleAddSearchParams("category", value);
  };

  const handleRemoveSearchTerm = (_) => {
    setTypedTerm("");
    setSearchTerm("");
    handleRemoveSearchParams("term");
  };

  const handleRemoveCategories = (_) => {
    resetPagination();
    handleSetCategories([]);
    setCategoryTreeValues({
      checked: [],
      expanded: [],
    });
  };

  const handleClearAll = () => {
    if (category && category.length > 0) {
      handleRemoveCategories();
    }
    if (!!loc.address) {
      handleRemoveLocation();
    }
    if (!!retailerUUID) {
      setRetailerUUID(null);
      setRetailerName(null);
    }
    if (typedTerm) {
      handleRemoveSearchTerm();
    }
  };

  const showClearFilterButton = Boolean(
    (category && category.length > 0) || !!loc.address || !!retailerUUID,
  );

  return (
    <Fragment>
      <Breadcrumb
        title="Products"
        parent="Physical"
      />
      <Container fluid={true}>
        <Row
          className="pb-2 d-flex"
          noGutters
        >
          <Button
            color="primary"
            type="button"
            style={{ padding: "8px 10px" }}
          >
            <a
              style={{ color: "white" }}
              href="/products/add-product"
            >
              Add Product
            </a>
          </Button>
          {showClearFilterButton && (
            <Button
              color="danger"
              type="button"
              outline
              className="d-flex ml-2"
              style={{ padding: "8px 10px" }}
              onClick={handleClearAll}
            >
              <XCircle
                size={20}
                className="mr-1"
              />
              Clear All
            </Button>
          )}
        </Row>
        <Row
          className="mb-3 align-items-start mt-3"
          noGutters
          style={{ rowGap: "10px" }}
        >
          <Col
            xs={12}
            md={3}
            className={`${styles.donateItemEdit}`}
            style={{
              background: "transparent",
              boxShadow: "none",
              padding: "0px",
              marginTop: "-3px",
            }}
          >
            <CategoryDropdown
              isOpen={catDropdownOpen}
              toggle={() => setCatDropdownOpen((prev) => !prev)}
              categoryTreeValues={categoryTreeValues}
              onCheck={handleSetCategories}
              style={{ height: "37px", marginTop: "4px", padding: "8px" }}
              setCategoryTreeValues={setCategoryTreeValues}
              placeholder="Search by Category"
            />
          </Col>
          <Col
            xs={12}
            md={4}
            className="pl-2"
          >
            <InputGroup>
              <Input
                placeholder="search by name"
                value={typedTerm}
                onChange={(e) => {
                  resetPagination();
                  setTypedTerm(e.target.value);
                }}
              />
              <InputGroupAddon
                addonType="append"
                style={{ cursor: "pointer" }}
                onClick={handleSearchTerm}
              >
                Search
              </InputGroupAddon>
              <InputGroupAddon
                addonType="append"
                style={{ cursor: "pointer" }}
                onClick={handleRemoveSearchTerm}
              >
                X
              </InputGroupAddon>
            </InputGroup>
          </Col>
          <Col
            xs={12}
            md={5}
          >
            <Row
              noGutters
              className="d-flex"
            >
              <Col xs={6}>
                <Select
                  options={
                    !!sortOptions?.getSortOrder
                      ? sortOptions.getSortOrder.map(({ type, label }) => ({
                          label,
                          value: type,
                        }))
                      : []
                  }
                  value={sortBy}
                  placeholder={sortBy || "Sort By"}
                  style={{
                    textTransform: "capitalize",
                    fontWeight: 500,
                    fontSize: "15px",
                    width: "95%",
                    height: "37px",
                    margin: "0 10px",
                    color: "darkslategray",
                    letterSpacing: "-0.5px",
                  }}
                  onChange={handleSetSort}
                />
              </Col>
              <Col
                xs={6}
                className="pl-2"
              >
                <Select
                  options={stock_options}
                  placeholder={
                    inStock === null
                      ? "All"
                      : !!inStock
                      ? "In Stock"
                      : "Out Of Stock"
                  }
                  style={{
                    textTransform: "capitalize",
                    fontWeight: 500,
                    fontSize: "15px",
                    width: "95%",
                    height: "37px",
                    color: "darkslategray",
                    letterSpacing: "-0.5px",
                  }}
                  onChange={handleSetStock}
                />
              </Col>
            </Row>
          </Col>
          <Col
            xs={12}
            md={4}
          >
            <SearchLocationInput
              placeholdertext="enter location"
              required={false}
              value={formatAddress(loc.address)}
              setAddress={handleSetLocation}
              styles={{
                height: "35px",
                marginBottom: "0px",
                fontSize: "15px",
                width: "100%",
              }}
            />
          </Col>
          <Col
            xs={12}
            md={4}
            className="pl-2"
          >
            <RetailerSelect
              {...{
                retailer: {
                  uuid: retailerUUID,
                  name: retailerName,
                },
                onSelect: (retailer) => {
                  resetPagination();
                  setRetailerUUID((_) => retailer.uuid);
                  setRetailerName((_) => retailer.name);
                },
              }}
            />
          </Col>
          <Col
            xs={12}
            md={4}
            className="pl-2"
          >
            <Select
              options={donorOptions}
              placeholder={
                isDonorRetailer === null
                  ? "All Donors"
                  : !!isDonorRetailer
                  ? "Retailer"
                  : "Non-retailer"
              }
              style={{
                textTransform: "capitalize",
                fontWeight: 500,
                fontSize: "15px",
                width: "95%",
                height: "37px",
                color: "darkslategray",
                letterSpacing: "-0.5px",
              }}
              onChange={handleSetDonor}
            />
          </Col>
        </Row>
        <Row className="products-admin mt-4">
          {products?.length
            ? products?.map((myData, i) => {
                return (
                  <ProductItem
                    myData={myData}
                    copyToClipboard={copyToClipboard}
                    onClickEdit={onClickEdit}
                    onClickDelete={onClickDelete}
                    onOpenProductDetails={onOpenProductDetails}
                    onClickNonProfits={onClickNonProfits}
                    hasAddress={!!loc?.address}
                    key={i}
                  />
                );
              })
            : !fetchLoading &&
              !loading && (
                <p className="text-center my-5 mx-auto">
                  <i>No Products Available!</i>
                </p>
              )}
        </Row>
        {(fetchLoading || loading) && (
          <div style={{ width: "100%", textAlign: "center" }}>
            <Spinner
              className="my-3"
              color="darkgray"
            />
          </div>
        )}
        <div ref={loader} />
      </Container>
      <ToastContainer />
    </Fragment>
  );
};

export default Products;
