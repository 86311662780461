import React, { Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Container } from "reactstrap";
import { ToastContainer } from "react-toastify";
import SubscriptionPlan from "./SubscriptionPlan";

const AddSubscriptionPlan = () => {
  return (
    <Fragment>
      <Breadcrumb
        title="Plan"
        parent="Add Subscription Plan"
      />
      <Container fluid={true}>
        <SubscriptionPlan />
      </Container>
      <ToastContainer />
    </Fragment>
  );
};

export default AddSubscriptionPlan;
