import Breadcrumb from "../common/breadcrumb";
import React, { Fragment } from "react";
import InvoiceList from "./invoice-list";

const Invoices = () => {
  return (
    <Fragment>
      <Breadcrumb
        title="Invoices"
        parent="Dashboard"
      />
      <InvoiceList />
    </Fragment>
  );
};

export default Invoices;
