import getConfigs from "src/config/base";

const STRIPE_URL = "https://dashboard.stripe.com";

export const getStripePaymentIntentIDLink = (paymentIntentID) => {
  const path = `payments/${paymentIntentID}`;
  if (getConfigs().environment === "production") {
    return `${STRIPE_URL}/${path}`;
  }
  return `${STRIPE_URL}/test/${path}`;
};

export const getStripePaymentLinkIDLink = (paymentLinkID) => {
  const path = `payment-links/${paymentLinkID}`;
  if (getConfigs().environment === "production") {
    return `${STRIPE_URL}/${path}`;
  }
  return `${STRIPE_URL}/test/${path}`;
};

export const getStripeInvoiceIDLink = (stripeInvoiceID) => {
  const path = `invoices/${stripeInvoiceID}`;
  if (getConfigs().environment === "production") {
    return `${STRIPE_URL}/${path}`;
  }
  return `${STRIPE_URL}/test/${path}`;
};
