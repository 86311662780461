import React, { Fragment, useState, useEffect, useMemo } from "react";
import Breadcrumb from "../../common/breadcrumb";
import "../../../assets/scss/slick.scss";
import "../../../assets/scss/slick-theme.scss";
import { Card, CardBody, Col, Container, Row, Button } from "reactstrap";
import Text from "../../common/text";
import Bold from "../../common/bold";
import { gql } from "@apollo/client";
import { initializeApollo } from "../../../helpers/apollo";
import Photos from "../../common/photos";
import { useHistory } from "react-router-dom";
import {
  formatAddress,
  getGoogleMapsAddress,
  copyAddress,
} from "../../../helpers/address";
import { Copy, Trash, Edit, LifeBuoy, Truck, Zap } from "react-feather";
import Notes from "../../notes/notes";
import { toast } from "react-toastify";
import getConfigs from "../../../config/base";
import { DateTime } from "luxon";
import Fulfillments from "../../fulfillment/fulfillments";
import NonprofitList from "../../nonprofits/NonprofitList";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import MatchesList from "src/components/matches/matchesList";
import Prices from "./Prices";
import { getIntervalsByDays } from "src/components/pickupWindows";

const DELETE_PRODUCT = gql`
	mutation deleteDonation($data: String!) {
		adminDeleteDonation(uuid: $data)
	}
`;

const GET_SINGLE_PRODUCT = gql`
	query product($input: GetProductInput!) {
		product(input: $input) {
			uuid
      slug
      sku
      productClass {
        uuid
      }
			title
			description
			fairMarketValue {
				value
				currencyCode
			}
			notes {
				uuid
				createdDate
				objectType
				objectUUID
				user {
					firstName
					lastName
				}
				text
			}
      pickupHours{
        intervals{
          days,
          startTimeLocal,
          endTimeLocal
        }
      }
			donor {
				firstName
				lastName
				isGuest
				uuid
			}
      donorRetailer {
        name
        uuid
      }
			store {
				name
				uuid
				businessAddress {
					streetAddress1
					streetAddress2
					streetAddress3
					streetAddress4
					city
					countryCode
					state
					zip
				}
				createdDate
				businessPhoneNumber
				description
				locations {
					address {
						streetAddress1
						streetAddress2
						streetAddress3
						streetAddress4
						city
						countryCode
						state
						zip
            timezone
					}
          contactNumber
          contactEmail
				}
			}
			lengthInches
			widthInches
			heightInches
			weightLbs
      nextPostingDate
      isNonprofitGift
      noResale
			categories {
				name
				description
				subCategories {
					name
					description
				}
			}
			recipientTypes
			quantity
      quantityPalletsPerTruckload
      quantityBoxPerPallet
      quantityIndividualItemPerBox
			availableQuantity
			conditionTagTypes
			conditionTags {
				label
				type
			}
			fulfillmentTypes
      visibility
			categories {
				name
				type
				description
				images {
					alt
					uuid
				}
			}
			images
			{
				url
				uuid
				alt
        displayOrder
				resolutions {
					xs {
						url
						height
						width
						size
					}
					s {
						url
						height
						width
						size
					}

					m {
						url
						height
						width
						size
					}
					l {
						url
						height
						width
						size
					}
					xl {
						url
						height
						width
						size
					}
					xxl {
						url
						height
						width
						size
					}
				}
			}
			location {
				address {
					streetAddress1
					streetAddress2
					streetAddress3
					streetAddress4
					state
					zip
					city
					countryCode
					latitude
					longitude
          timezone
				}
				contactNumber
        contactEmail
				notes
			}
      sku
      status
			createdDate
			unitType
			distanceMiles
      itemDonationApproval {
        itemDonation {
          total {
            value
            currencyCode
          }
          totalDiscounts {
            value
            currencyCode
          }
          serviceFees {
            value
            currencyCode
          }
          donationServiceFee {
            value
            currencyCode
          }
          promoCode
          paymentIntentID
        }
      }
		}
	}
`;

const ProductDetail = ({ match }) => {
  const [data, setData] = useState({});
  const history = useHistory();
  const uuidOrSlug = match.params.uuidOrSlug;
  const [error, setError] = useState(null);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(
      getConfigs().frontend + "/product-details/" + data?.slug,
    );
    toast.success("Copied to the clipboard successfully!");
  };

  useEffect(() => {
    const client = initializeApollo();

    const input = {
      uuidOrSlug,
    };

    client
      .query({
        query: GET_SINGLE_PRODUCT,
        variables: { input: input },
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        setData(res.data.product);
      })
      .catch((err) => {
        setError(String(err));
        setData(null);
      });
  }, []);

  const deleteProduct = () => {
    let client = initializeApollo();

    client
      .mutate({
        mutation: DELETE_PRODUCT,
        variables: { data: uuidOrSlug },
      })
      .then((_) => {
        toast.success("Product deleted successfully!");
        setTimeout(() => {
          history.push("/products");
        }, 1000);
      })
      .catch((err) => {
        toast.error(err.message || "something went wrong!");
      });
  };

  const editProduct = () => {
    history.replace("/product/" + data?.uuid + "/edit");
  };

  const isInStock = () => {
    return data?.availableQuantity > 0;
  };

  const getUserInfo = () => {
    const firstName = data?.donor?.firstName;
    const lastName = data?.donor?.lastName;
    return firstName && lastName
      ? `${firstName} ${lastName}`
      : data?.donor?.uuid;
  };

  const getPrices = () => {
    return {
      total: data?.itemDonationApproval?.itemDonation?.total,
      totalDiscounts: data?.itemDonationApproval?.itemDonation?.totalDiscounts,
      serviceFees: data?.itemDonationApproval?.itemDonation?.serviceFees,
      promoCode: data?.itemDonationApproval?.itemDonation?.promoCode,
      donationServiceFee:
        data?.itemDonationApproval?.itemDonation?.donationServiceFee,
    };
  };

  const windowsByDays = useMemo(
    () => getIntervalsByDays(data?.pickupHours),
    [data],
  );

  return (
    <Fragment>
      <Breadcrumb
        title="Product Detail"
        parent="Physical"
        subtitle={data?.uuid}
      />

      {!!data && (
        <Container fluid={true}>
          <Row>
            <Col>
              <Card>
                <Row className="product-page-main card-body">
                  <Col
                    xs={{ size: 12, order: 2 }}
                    lg={{ size: 6, order: 1 }}
                  >
                    <Row>
                      <Col
                        xs={{ size: 12, order: 2 }}
                        lg={{ size: 12, order: 1 }}
                      >
                        <Photos
                          hideDescription
                          selected={data?.images ? data.images : []}
                          review
                        />
                      </Col>
                      <Col
                        xs={{ size: 12, order: 1 }}
                        lg={{ size: 12, order: 2 }}
                      >
                        <Prices prices={getPrices()} />
                      </Col>
                      <Col xs={{ size: 12, order: 3 }}>
                        <Notes
                          notes={data?.notes}
                          objectType={"product"}
                          objectUuid={data?.uuid}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    xs={{ size: 12, order: 1 }}
                    lg={{ size: 6, order: 2 }}
                  >
                    <Row>
                      <Col
                        xs="12"
                        md="8"
                      >
                        <h2>{data?.title}</h2>

                        {data?.fairMarketValue?.value !== undefined && (
                          <div className="product-price digits mt-2">
                            <h3>${data?.fairMarketValue?.value} </h3>
                          </div>
                        )}
                      </Col>
                      <Col
                        xs="12"
                        md="4"
                        className="d-flex align-items-start justify-content-end"
                      >
                        <button
                          className="btn btn-danger"
                          onClick={deleteProduct}
                        >
                          <Trash size={20} />
                        </button>
                        <button
                          className="btn btn-success mx-1"
                          onClick={editProduct}
                        >
                          <Edit size={20} />
                        </button>
                        <Button
                          color="info"
                          className="mr-1"
                          onClick={copyToClipboard}
                        >
                          <Copy size={20} />
                        </Button>
                      </Col>
                      {!isInStock() && (
                        <Col
                          xs="12"
                          md="4"
                        >
                          <h3>Out of stock</h3>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      {data.donor && (
                        <Col
                          xs="12"
                          md="4"
                        >
                          <Bold>DONOR USER</Bold>
                          <Text>
                            <a
                              style={{
                                color: "tomato",
                                letterSpacing: -0.1,
                              }}
                              href={"/users/search?uuid=" + data?.donor?.uuid}
                            >
                              <u>{getUserInfo()}</u>
                            </a>
                          </Text>
                        </Col>
                      )}
                      {data.donorRetailer && (
                        <Col
                          xs="12"
                          md="4"
                        >
                          <Bold>DONOR RETAILER</Bold>
                          <Text>
                            <a
                              style={{
                                color: "tomato",
                              }}
                              href={
                                "/retailers/details/" +
                                data?.donorRetailer?.uuid
                              }
                            >
                              <u>{data?.donorRetailer?.name}</u>
                            </a>
                          </Text>
                        </Col>
                      )}
                      {data.store && (
                        <Col
                          xs="12"
                          md="4"
                        >
                          <Bold>STORE</Bold>
                          <Text>{data?.store?.name}</Text>
                        </Col>
                      )}
                      {data.sku && (
                        <Col
                          xs="12"
                          md="4"
                        >
                          <Bold>SKU</Bold>
                          <Text>{data?.sku}</Text>
                        </Col>
                      )}
                      <Col
                        md="4"
                        xs="12"
                      >
                        <Bold>DATE CREATED</Bold>
                        <Text>
                          {DateTime.fromISO(data?.createdDate).toFormat(
                            "dd MMM, yyyy hh:mm:ss a",
                          )}
                        </Text>
                      </Col>
                      {data?.nextPostingDate && (
                        <Col
                          md="4"
                          xs="12"
                        >
                          <Bold>NEXT POSTING DATE</Bold>
                          <Text>{data.nextPostingDate.substring(0, 10)}</Text>
                        </Col>
                      )}
                    </Row>
                    <hr />
                    <Row>
                      <Col
                        md="4"
                        xs="6"
                      >
                        <Bold>STATUS</Bold>
                        <Text>{data?.status}</Text>
                      </Col>
                      <Col
                        md="4"
                        xs="12"
                      >
                        <Bold>VISIBILITY</Bold>
                        <Text>
                          {data.visibility?.length
                            ? data.visibility.join(", ")
                            : "-"}
                        </Text>
                      </Col>
                      <Col
                        md="4"
                        xs="6"
                      >
                        <Bold>RECIPIENT TYPES</Bold>
                        <Text>
                          {data.recipientTypes?.length
                            ? data.recipientTypes.join(", ")
                            : "-"}
                        </Text>
                      </Col>
                      <Col
                        md="4"
                        xs="12"
                      >
                        <Bold>FULFILLMENT TYPES</Bold>
                        <Text>
                          {data.fulfillmentTypes?.length
                            ? data.fulfillmentTypes.join(", ")
                            : "-"}
                        </Text>
                      </Col>
                      <Col
                        md="4"
                        xs="6"
                      >
                        <Bold>NONPROFIT GIFT</Bold>
                        <Text>{data?.isNonprofitGift ? "Yes" : "No"}</Text>
                      </Col>
                      <Col
                        md="4"
                        xs="6"
                      >
                        <Bold>NO RESALE</Bold>
                        <Text>{data?.noResale ? "Yes" : "No"}</Text>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col
                        md="4"
                        xs="12"
                      >
                        <Bold>DESCRIPTION</Bold>
                        <Text>{data?.description}</Text>
                      </Col>
                      <Col
                        md="4"
                        xs="6"
                      >
                        {data?.conditionTags?.length > 0 && (
                          <>
                            <Bold>CONDITION</Bold>
                            <p>
                              {data?.conditionTags.reduce((acc, el, i) => {
                                acc += el.label;
                                if (i < data?.conditionTags.length - 1) {
                                  acc += ", ";
                                }
                                return acc;
                              }, "")}
                            </p>
                          </>
                        )}
                      </Col>
                      <Col
                        md="4"
                        sm="6"
                        xs="12"
                      >
                        <Bold>DIMENSIONS</Bold>
                        <Text>
                          {data?.lengthInches || "-"} (L) x{" "}
                          {data?.widthInches || "-"} (W) x{" "}
                          {data?.heightInches || "-"} (H) inches
                        </Text>
                      </Col>
                      <Col
                        md="4"
                        xs="6"
                      >
                        <Bold>WEIGHT</Bold>
                        <Text>{data?.weightLbs || "-"} lb</Text>
                      </Col>
                      <Col
                        md="4"
                        xs="6"
                      >
                        <Bold>CATEGORIES</Bold>
                        <Text>
                          {data.categories?.length
                            ? data.categories.map((d) => d?.name).join(", ")
                            : "-"}
                        </Text>
                      </Col>
                      <Col
                        md="4"
                        xs="6"
                      >
                        <Bold>CONDITION</Bold>
                        <Text>
                          {data.conditionTags?.length
                            ? data.conditionTags.map((d) => d?.label).join(", ")
                            : "-"}
                        </Text>
                      </Col>

                      <Col
                        md="4"
                        xs="6"
                      >
                        <Bold>UNIT TYPE</Bold>
                        <Text>{data?.unitType || "-"}</Text>
                      </Col>
                      <Col
                        md="4"
                        xs="6"
                      >
                        <Bold>QUANTITY</Bold>
                        <Text>
                          {data?.availableQuantity} available out of{" "}
                          {data?.quantity}
                        </Text>
                      </Col>
                      {data?.unitType === "truckload" &&
                        data?.quantityPalletsPerTruckload > 0 && (
                          <Col
                            md="4"
                            xs="6"
                          >
                            <Bold>PALLETS PER TRUCKLOAD</Bold>
                            <Text>{data?.quantityPalletsPerTruckload}</Text>
                          </Col>
                        )}
                      {(data?.unitType === "truckload" ||
                        data?.unitType === "pallet" ||
                        data?.unitType === "large_pallet" ||
                        data?.unitType === "xl_pallet" ||
                        data?.unitType === "xxl_pallet") &&
                        data?.quantityBoxPerPallet > 0 && (
                          <Col
                            md="4"
                            xs="6"
                          >
                            <Bold>BOXES PER PALLET</Bold>
                            <Text>{data?.quantityBoxPerPallet}</Text>
                          </Col>
                        )}
                      {(data?.unitType === "truckload" ||
                        data?.unitType === "pallet" ||
                        data?.unitType === "large_pallet" ||
                        data?.unitType === "xl_pallet" ||
                        data?.unitType === "xxl_pallet" ||
                        data?.unitType === "box") &&
                        data?.quantityIndividualItemPerBox > 0 && (
                          <Col
                            md="4"
                            xs="6"
                          >
                            <Bold>ITEMS PER BOX</Bold>
                            <Text>{data?.quantityIndividualItemPerBox}</Text>
                          </Col>
                        )}
                    </Row>
                    {data?.location && <hr />}
                    {data?.location && (
                      <Row>
                        <Col
                          xs="12"
                          md="6"
                        >
                          <h4>LOCATION</h4>
                          <Bold>ADDRESS</Bold>
                          <Text>
                            <a
                              href={getGoogleMapsAddress(
                                data?.location?.address,
                              )}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {formatAddress(data?.location?.address)}
                            </a>
                            <a href="#">
                              <Copy
                                onClick={() =>
                                  copyAddress(data?.location?.address)
                                }
                              />
                            </a>
                          </Text>
                          <Text>{data?.location?.address?.streetAddress2}</Text>
                          {data?.location?.notes && (
                            <Text>
                              <i>Pickup Notes: </i>
                              {data?.location?.notes}
                            </Text>
                          )}
                        </Col>
                        {data?.location?.contactNumber && (
                          <Col
                            xs="12"
                            md="4"
                          >
                            <Bold>CONTACT NUMBER</Bold>
                            <Text>
                              <a
                                style={{
                                  color: "tomato",
                                  letterSpacing: -0.1,
                                }}
                                href={
                                  "/users/search?q=" +
                                  data?.location?.contactNumber
                                }
                              >
                                <u>{data?.location?.contactNumber}</u>
                              </a>
                            </Text>
                          </Col>
                        )}
                        {data?.location?.contactEmail && (
                          <Col
                            xs="12"
                            md="4"
                          >
                            <Bold>CONTACT EMAIL</Bold>
                            <Text>
                              <a
                                style={{
                                  color: "tomato",
                                  letterSpacing: -0.1,
                                }}
                                href={
                                  "/users/search?q=" +
                                  data?.location?.contactEmail
                                }
                              >
                                <u>{data?.location?.contactEmail}</u>
                              </a>
                            </Text>
                          </Col>
                        )}
                      </Row>
                    )}
                    <hr />
                    <Row>
                      {windowsByDays && (
                        <Col>
                          <h4>PICKUP TIMES</h4>
                          {[
                            "Monday",
                            "Tuesday",
                            "Wednesday",
                            "Thursday",
                            "Friday",
                            "Saturday",
                            "Sunday",
                          ].map((d) => {
                            let windows = windowsByDays?.[d.toLowerCase()];
                            if (windows && windows.length > 0) {
                              return (
                                <Row
                                  key={d}
                                  className="my-2"
                                >
                                  <Col
                                    xs="5"
                                    sm="4"
                                    md="3"
                                    lg="4"
                                    xl="3"
                                  >
                                    <Text>
                                      <span
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        <b>
                                          <u>{d}</u>
                                        </b>
                                      </span>
                                    </Text>
                                  </Col>
                                  <Col>
                                    <Row>
                                      {windows.map((w, i) => (
                                        <Col
                                          key={w?.id}
                                          xs="12"
                                          sm="6"
                                          md="4"
                                          lg="6"
                                          xl="4"
                                        >
                                          {w?.display}
                                          {i !== windows.length - 1 && (
                                            <span>,</span>
                                          )}
                                        </Col>
                                      ))}
                                    </Row>
                                  </Col>
                                </Row>
                              );
                            }
                          })}
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Tabs>
                    <TabList className="nav nav-tabs tab-coupon">
                      <Tab className="nav-link">
                        <LifeBuoy className="mr-2" /> Nonprofit Matches
                      </Tab>
                      <Tab className="nav-link">
                        <Truck className="mr-2" /> Fulfillments
                      </Tab>
                      <Tab className="nav-link">
                        <Zap className="mr-2" /> Matches
                      </Tab>
                    </TabList>

                    <TabPanel>
                      <NonprofitList
                        productUUID={data?.uuid}
                        showCSVDownload={true}
                        showLocationInput={true}
                      />
                    </TabPanel>
                    <TabPanel>
                      <Fulfillments productUUID={data?.uuid} />
                    </TabPanel>
                    <TabPanel>
                      <MatchesList
                        hideRetailerSearch={true}
                        productUUID={data?.uuid}
                      />
                    </TabPanel>
                  </Tabs>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {error && (
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <h4 style={{ color: "red" }}>{error}</h4>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      )}
    </Fragment>
  );
};

export default ProductDetail;
