import React, { useState, useEffect } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { Card, CardBody, CardHeader, Row, Col } from "reactstrap";
import eventsFormatter from "src/helpers/eventsFormatter";
import styles from "../donate/donate.module.scss";
import Datatable from "../common/datatable";
import Text from "../common/text";
import EmailCategorySelect from "./EmailCategorySelect";
import EventsSelect from "./EventSelect";

const ADMIN_GET_SENDGRID_EVENTS = gql`
    query ($input: AdminGetSendgridEventsInput!) {
        adminGetSendgridEvents(input: $input) {
            total
            hasMore
            events {
                email
                timestamp
                event
                category
                response
                url
                status
                reason
                bounceClassification
                singleSendName
                marketingCampaignName
                groupID
            }
        }
    }
`;

const SendgridEvents = ({ email, userUUID }) => {
  const [selectOpen, setSelectOpen] = useState(false);
  const [categories, setCategories] = useState();

  const [selectEventTypesOpen, setSelectEventTypesOpen] = useState(false);
  const [eventTypes, setEventTypes] = useState();

  const [events, setEvents] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(25);
  const [indexFrom, setIndexFrom] = useState(0);

  const [adminGetSendgridEvents, { loading, error, data }] = useLazyQuery(
    ADMIN_GET_SENDGRID_EVENTS,
    {
      variables: {
        fetchPolicy: "no-cache",
        input: {
          email: email,
          userUUID: userUUID,
          indexFrom: indexFrom,
          emailCategories: categories,
          eventTypes: eventTypes,
          limit: limit,
        },
      },
    },
  );

  const onChangePage = (newPage, _) => {
    setIndexFrom((newPage - 1) * limit);
  };

  const onChangeRowsPerPage = (newPerPage, page) => {
    setIndexFrom((page - 1) * newPerPage);
    setLimit(newPerPage);
  };

  useEffect(() => {
    if (!loading) {
      if (data && data.adminGetSendgridEvents) {
        setEvents(eventsFormatter(data.adminGetSendgridEvents.events));
        setTotalRows(data.adminGetSendgridEvents.total);
      } else {
        setEvents([]);
      }
    }
  }, [loading, email, categories, eventTypes]);

  useEffect(() => {
    if (!email && !userUUID) {
      return;
    }

    adminGetSendgridEvents();
  }, [indexFrom, email, categories, eventTypes]);

  return (
    <Card className={styles.donateItemEdit}>
      <CardHeader>
        <h3>Sendgrid Events</h3>
        <Row>
          <Col md="4">
            <EmailCategorySelect
              isOpen={selectOpen}
              placeholder="Filter By Email Category"
              setValues={setCategories}
              values={categories}
              style={{
                width: "300px",
                marginLeft: "20px",
                height: "38px",
                overflow: "hidden",
              }}
              toggle={() => setSelectOpen((val) => !val)}
              onSelect={() => {
                setIndexFrom(0);
              }}
            />
          </Col>

          <Col md="4">
            <EventsSelect
              isOpen={selectEventTypesOpen}
              placeholder="Filter By Event"
              setValues={setEventTypes}
              values={eventTypes}
              style={{
                width: "300px",
                marginLeft: "20px",
                height: "38px",
                overflow: "hidden",
              }}
              toggle={() => setSelectEventTypesOpen((val) => !val)}
              onSelect={() => {
                setIndexFrom(0);
              }}
            />
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        {error ? (
          <>Some Error Occured! Try Reloading the page.</>
        ) : events ? (
          <Datatable
            hideActions={true}
            myData={events}
            loading={loading}
            pageSize={limit}
            pagination={true}
            paginationServer={true}
            totalRows={totalRows}
            myClass="-striped -highlight"
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            noHeader
          />
        ) : (
          <Text>No events</Text>
        )}
      </CardBody>
    </Card>
  );
};

export default SendgridEvents;
