import { gql, useQuery } from "@apollo/client";
import React from "react";
import CheckboxTree from "react-checkbox-tree";
import { ChevronDown } from "react-feather";
import { DropdownMenu, Dropdown, DropdownToggle } from "reactstrap";
import styles from "../../donate/donate.module.scss";

const GET_CATEGORIES = gql`
  query getCategories {
    getCategories {
      uuid
      type
      name
      description
      subCategories {
        type
        uuid
        name
        description
        subCategories {
          type
          uuid
          name
          description
        }
      }
    }
  }
`;

const CategoryDropdown = ({
  isOpen,
  toggle,
  categoryTreeValues,
  onCheck,
  setCategoryTreeValues,
  style = {},
  placeholder,
  onlyLeafCheckboxes = true,
  noCascade = false,
}) => {
  var { data: categories } = useQuery(GET_CATEGORIES);

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={toggle}
    >
      <DropdownToggle
        className={styles.dropdown}
        style={style}
      >
        <div className="d-flex flex-wrap">
          {categoryTreeValues.checked?.length ? (
            categoryTreeValues.checked?.map((x, i) => (
              <div
                key={i}
                className={styles.category + " mr-1"}
              >
                {x}
              </div>
            ))
          ) : (
            <span style={{ fontWeight: 500, color: "gray" }}>
              {placeholder}
            </span>
          )}
        </div>
        <ChevronDown />
      </DropdownToggle>
      <DropdownMenu style={{ width: "100%" }}>
        <CheckboxTree
          onlyLeafCheckboxes={onlyLeafCheckboxes}
          noCascade={noCascade}
          nodes={
            categories
              ? categories.getCategories?.map((x) => ({
                  value: x.type,
                  label: x.name,
                  className: "d-block w-100",
                  children: x.subCategories
                    ? x.subCategories.map((y) => ({
                        value: y.type,
                        label: y.name,
                        className: "d-block w-100",
                        children: y.subCategories
                          ? y.subCategories.map((z) => ({
                              value: z.type,
                              label: z.name,
                              className: "d-block w-100",
                            }))
                          : undefined,
                      }))
                    : undefined,
                }))
              : []
          }
          icons={{
            parentClose: null,
            parentOpen: null,
            leaf: null,
          }}
          checked={categoryTreeValues.checked}
          expanded={categoryTreeValues.expanded}
          onCheck={(checked) => {
            setCategoryTreeValues(() => ({
              checked,
              expanded: categoryTreeValues.expanded,
            }));
            onCheck(checked);
          }}
          onExpand={(expanded) =>
            setCategoryTreeValues(() => ({
              expanded,
              checked: categoryTreeValues.checked,
            }))
          }
        />
      </DropdownMenu>
    </Dropdown>
  );
};

export default CategoryDropdown;
