import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { OnGoingFulfillmentStatuses } from "src/constants/fulfillmentStatus";
import ConfirmationModal from "../modals/ConfirmationModal";

const ADMIN_CANCEL_FULFILLMENT = gql`
  mutation($input: AdminCancelFulfillmentInput!) {
    adminCancelFulfillment(input: $input) {
      uuid
    }
  }
`;

const CancelFulfillment = ({ fulfillmentUUID, status }) => {
  const [cancelFulfillmentModalOpen, setCancelFulfillmentModalOpen] =
    useState(false);
  const [adminCancelFulfillment, { loading: cancelFulfillmentLoading }] =
    useMutation(ADMIN_CANCEL_FULFILLMENT);
  const onConfirmCancelFulfillment = () => {
    adminCancelFulfillment({
      variables: {
        input: {
          fulfillmentUUID: fulfillmentUUID,
        },
      },
    })
      .then(() => {
        setTimeout(() => {
          toast.success("Cancelled fulfillment");
          window.location.reload();
        }, 1500);
        setCancelFulfillmentModalOpen(false);
      })
      .catch((err) => toast.error(err.message));
  };

  const showCancel = () => OnGoingFulfillmentStatuses.includes(status);

  return (
    <>
      {showCancel() && (
        <button
          className="btn btn-danger mx-2"
          onClick={() => setCancelFulfillmentModalOpen(true)}
        >
          Cancel Fulfillment
        </button>
      )}

      <ConfirmationModal
        open={cancelFulfillmentModalOpen}
        onClose={() => setCancelFulfillmentModalOpen(false)}
        title={"Cancel Fulfillment"}
        desc={"Are you sure you want to cancel this fulfillment?"}
        onConfirm={onConfirmCancelFulfillment}
        loading={cancelFulfillmentLoading}
      />
    </>
  );
};

export default CancelFulfillment;
