import { gql, useQuery } from "@apollo/client";
import React, { Fragment, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";
import Breadcrumb from "../common/breadcrumb";
import Text from "../common/text";
import Bold from "../common/bold";
import { initializeApollo } from "../../helpers/apollo";
import { SuccessToastAndReload } from "../../helpers/utils";
import { toast, ToastContainer } from "react-toastify";
import { ExternalLink, Copy, User } from "react-feather";
import {
  formatAddress,
  copyAddress,
  getGoogleMapsAddress,
} from "../../helpers/address";
import moment from "moment";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import UserList from "../users/user-list";
import usersFormatter from "../../helpers/usersFormatter";
import AssignUser from "../users/assign-user";

const ADD_CIRCULARITY_PARTNER_USER = gql`
  mutation adminAddCircularityPartnerUser($data: AdminAddCircularityPartnerUserInput!) {
    adminAddCircularityPartnerUser(input: $data)
  }
`;

const GET_ADMIN_CIRCULARITY_PARTNER = gql`
  query ($input: GetCircularityPartnerInput!) {
    getAdminCircularityPartner(input: $input) {
      uuid
      createdDate
      description
      referralSource
      organizationName
      address {
        streetAddress1
        streetAddress2
        city
        state
        zip
        countryCode
        latitude
        longitude
      }
      ein
      categoryTypes
      businessPhone
      website
      users {
        uuid
        firstName
        lastName
        email
        phoneNumber
        roles
      }
    }
  }
`;

const CircularityPartnerDetails = () => {
  const { uuid } = useParams();
  const [user, setUser] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const { loading, data } = useQuery(GET_ADMIN_CIRCULARITY_PARTNER, {
    fetchPolicy: "no-cache",
    variables: {
      input: {
        uuid: uuid,
      },
    },
  });

  const circularityPartner = useMemo(() => {
    return loading ? {} : data?.getAdminCircularityPartner;
  }, [loading]);

  const handleAddCircularityPartnerUser = () => {
    const { email, role } = user;
    if (!email || !role) {
      toast.error("Enter email and role to assign user to circularity partner");
      return;
    }
    setSubmitting(true);
    let client = initializeApollo();
    client
      .mutate({
        mutation: ADD_CIRCULARITY_PARTNER_USER,
        variables: { data: { ...user, circularityPartnerUUID: uuid } },
      })
      .then(() => {
        SuccessToastAndReload("User added successfully!");
        setSubmitting(false);
      })
      .catch((err) => {
        toast.error(err.message || "something might went wrong!");
        setSubmitting(false);
      });
  };

  const getWebsite = (site) => {
    if (!site) return "N/A";
    if (!site.includes("http")) {
      return "https://" + site;
    }
    return site;
  };

  const onChangeUser = (e) => {
    if (e && e.target) {
      const { name, value } = e.target;
      user[name] = value;
    } else {
      user["role"] = e[0].value;
    }
    setUser({ ...user });
  };

  return (
    <Fragment>
      <Breadcrumb
        title="Nonprofit Details"
        parent="Dashboard"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                {loading ? (
                  <div className="text-center my-5">
                    <Spinner />
                  </div>
                ) : (
                  <>
                    <Row>
                      <Col
                        xs="12"
                        md="6"
                      >
                        <Bold>Organization</Bold>
                        <Text>{circularityPartner?.organizationName}</Text>
                      </Col>
                      <Col
                        xs="12"
                        md="6"
                      >
                        <Bold>EIN</Bold>
                        <Text>{circularityPartner?.ein}</Text>
                      </Col>
                      <Col
                        xs="12"
                        md="6"
                      >
                        <Bold>Description</Bold>
                        <Text>{circularityPartner?.description}</Text>
                      </Col>
                      <Col
                        xs="12"
                        md="6"
                      >
                        <Bold>Address</Bold>
                        <Text>
                          {formatAddress(circularityPartner?.address)}{" "}
                          &nbsp;&nbsp;
                          <a href="#">
                            <Copy
                              onClick={() =>
                                copyAddress(circularityPartner?.address)
                              }
                            />
                          </a>
                          {"  "}
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={getGoogleMapsAddress(
                              circularityPartner?.address,
                            )}
                          >
                            <ExternalLink />
                          </a>
                        </Text>
                      </Col>
                      <Col
                        xs="12"
                        md="6"
                      >
                        <Bold>Business Phone</Bold>
                        <Text>{circularityPartner?.businessPhone}</Text>
                      </Col>
                      <Col
                        xs="12"
                        md="6"
                      >
                        <Bold>Website</Bold>
                        <Text>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={getWebsite(circularityPartner?.website)}
                          >
                            {circularityPartner?.website}
                          </a>
                        </Text>
                      </Col>
                      <Col
                        xs="12"
                        md="6"
                      >
                        <Bold>Referral Source</Bold>
                        <Text>
                          {circularityPartner?.referralSource || "N/A"}
                        </Text>
                      </Col>
                      <Col
                        xs="12"
                        md="6"
                      >
                        <Bold>Created Date</Bold>
                        <Text>
                          {moment(circularityPartner?.createdDate).format(
                            "DD MMM, YYYY hh:mm:SS A",
                          )}
                        </Text>
                      </Col>
                      <Col
                        xs="12"
                        md="6"
                      >
                        <Bold>Category Types</Bold>
                        <Text>
                          {circularityPartner?.categoryTypes?.length
                            ? circularityPartner?.categoryTypes.join(", ")
                            : "N/A"}
                        </Text>
                      </Col>
                    </Row>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Tabs>
                  <TabList className="nav nav-tabs tab-coupon">
                    <Tab className="nav-link">
                      <User className="mr-2" /> Users
                    </Tab>
                  </TabList>

                  <TabPanel>
                    <Row>
                      <Col>
                        <AssignUser
                          isNonprofit={true}
                          loading={submitting}
                          onChange={onChangeUser}
                          onSubmit={handleAddCircularityPartnerUser}
                          user={user}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs="12"
                        className="mt-2"
                      >
                        <UserList
                          users={usersFormatter(circularityPartner.users)}
                          loading={loading}
                        />
                      </Col>
                    </Row>
                  </TabPanel>
                </Tabs>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </Fragment>
  );
};

export default CircularityPartnerDetails;
