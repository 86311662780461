import React, { Fragment, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { toast, ToastContainer } from "react-toastify";
import PaymentDetailsCard from "./PaymentDetailsCard";

import FulfillmentCard from "src/components/fulfillment/card";
import Breadcrumb from "src/components/common/breadcrumb";
import { Col, Container, Row, Button } from "reactstrap";

const CANCEL_ORDER = gql`
  mutation ($input: CancelOrderInput!) {
    adminCancelOrder(input: $input) {
      orderCancellationReasonType
      description
    }
  }
`;

const MARK_ORDER_FULFILLED = gql`
  mutation ($input: MarkOrderFulfilledInput!) {
    adminMarkOrderAsFulfilled(input: $input) {
      uuid
    }
  }
`;

const getCancelOrderVariables = (uuid) => ({
  variables: {
    input: {
      orderUUID: uuid,
      orderCancellationReasonType: "not_required",
      description: "",
    },
  },
});

const OrderDetails = ({ data }) => {
  const [loading, setLoading] = useState(false);

  const {
    createdDate,
    fulfillments,
    paymentMethod,
    paymentIntentID,
    priceInfo,
    recipientLocation,
    status,
    uuid,
    recipient,
  } = data;

  const [cancelOrder] = useMutation(CANCEL_ORDER);
  const [markOrderFulfilled] = useMutation(MARK_ORDER_FULFILLED);

  const handleCancelClick = () => {
    setLoading(true);
    cancelOrder(getCancelOrderVariables(uuid))
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getMarkFulfilledVariables = (uuid) => ({
    variables: {
      input: {
        uuid: uuid,
      },
    },
  });

  const handleMarkOrderFulfilledClick = () => {
    setLoading(true);
    markOrderFulfilled(getMarkFulfilledVariables(uuid))
      .then(() => window.location.reload())
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  return (
    <Fragment>
      <Breadcrumb
        title="Order Details"
        parent="Dashboard"
      />
      <Container fluid={true}>
        <Row>
          <Col
            xs="6"
            md="6"
          >
            <p
              className="text-default mb-0"
              style={{ fontWeight: 600, color: "gray" }}
            >
              ORDER# {data?.uuid}
            </p>
            <p
              style={{ fontWeight: 700, textTransform: "uppercase" }}
              className={`text-default font ${
                status === "ongoing" && "c-crimson"
              }`}
            >
              {status}
            </p>
          </Col>
          <Col
            xs="12"
            md="6"
            className="d-flex align-items-start justify-content-end"
          >
            {status === "ongoing" && (
              <>
                <Button
                  className={"btn btn-info mr-2"}
                  disabled={loading}
                  onClick={handleMarkOrderFulfilledClick}
                >
                  <span>Fulfill Order</span>
                </Button>
                <Button
                  className={"btn btn-danger"}
                  disabled={loading}
                  onClick={handleCancelClick}
                >
                  <span>Cancel Order</span>
                </Button>
              </>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <PaymentDetailsCard
              addressData={recipientLocation}
              priceData={priceInfo}
              method={paymentMethod}
              paymentIntentID={paymentIntentID}
              created={createdDate}
              recipient={recipient}
              notes={data?.notes}
              uuid={data?.uuid}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            {fulfillments.map(
              ({
                distance,
                donor,
                donorRetailer,
                pickupLocation,
                recipientLocation,
                items,
                pickupBy,
                priceInfo: fPriceInfo,
                status: fStatus,
                type,
                statusDescription,
                notes,
                uuid,
                donationReceipt,
                updates,
                hours,
                fulfillmentOptions,
                noResaleAgreed,
                recipientNonprofit,
                labels,
              }) => (
                <FulfillmentCard
                  key={uuid}
                  distance={distance}
                  donor={donor}
                  donorRetailer={donorRetailer || donor?.retailer}
                  pickupLocation={pickupLocation}
                  recipientLocation={recipientLocation}
                  items={items}
                  pickupBy={pickupBy}
                  priceInfo={fPriceInfo}
                  status={fStatus}
                  type={type}
                  statusDescription={statusDescription}
                  notes={notes}
                  uuid={uuid}
                  donationReceipt={donationReceipt}
                  updates={updates}
                  hours={hours}
                  fulfillmentOptions={fulfillmentOptions}
                  noResaleAgreed={noResaleAgreed}
                  recipientNonprofit={recipientNonprofit}
                  labels={labels}
                />
              ),
            )}
          </Col>
        </Row>
        <ToastContainer />
      </Container>
    </Fragment>
  );
};

export default OrderDetails;
