import React from "react";
import { gql, useLazyQuery } from "@apollo/client";
import SelectSearch from "react-select-search";
import "./nonprofit.scss";

const GET_ADMIN_NONPROFITS = gql`
  query($input: GetAdminNonprofitsInput!) {
    getAdminNonprofits(input: $input) {
      nonprofits {
        uuid
        organizationName
      }
    }
  }
`;

const NonprofitSelect = ({ nonprofit, onSelect, style = {} }) => {
  const [getAdminNonprofits] = useLazyQuery(GET_ADMIN_NONPROFITS);

  const getNonprofitOptions = (query) => {
    return new Promise((resolve, reject) => {
      if (query) {
        getAdminNonprofits({
          fetchPolicy: "no-cache",
          variables: {
            input: {
              indexFrom: 0,
              limit: 10,
              term: query,
            },
          },
        })
          .then((response) => {
            const opts = response?.data?.getAdminNonprofits?.nonprofits.map(
              ({ organizationName, uuid }) => ({
                name: organizationName,
                value: uuid,
              }),
            );
            resolve(opts);
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        resolve([]);
      }
    });
  };

  const handleSetNonprofitUUID = (uuid, { name }) => {
    if (onSelect) {
      onSelect({ uuid, name });
    }
  };

  const onClear = (_evt) => {
    if (onSelect) {
      onSelect(Object.prototype);
    }
  };

  const text = nonprofit?.uuid && (nonprofit.name || `#${nonprofit.uuid}`);

  return (
    <div
      {...{
        style: { ...style, display: "flex", padding: 0, background: "#dfdfdf" },
        className: "form-control",
      }}
    >
      <SelectSearch
        getOptions={getNonprofitOptions}
        search
        placeholder="Nonprofit search"
        onChange={handleSetNonprofitUUID}
        style={style}
      />
      {text && (
        <div
          {...{
            style: {
              width: "fit-content",
              display: "flex",
              alignItems: "center",
              padding: "0.5em",
              gap: "0.5em",
            },
          }}
        >
          {text}
          <button
            {...{
              onClick: onClear,
              style: {
                border: "none",
                background: "none",
                padding: 0,
              },
            }}
          >
            {"\u2715"}
          </button>
        </div>
      )}
    </div>
  );
};

export default NonprofitSelect;
