import React from "react";
import { Modal, ModalBody } from "reactstrap";
import { Wrapper } from "@googlemaps/react-wrapper";
import Map from "./Map";
import { renderMap } from "./map-utils";

const status_titles = ["Dispatched", "Picked Up", "In Transit", "Delivered"];

const TrackModal = ({ open, onClose, data }) => {
  return (
    <Modal
      centered
      onClosed={onClose}
      isOpen={open}
      toggle={onClose}
      size="lg"
    >
      <ModalBody className="p-4">
        <Wrapper
          apiKey={process.env.NEXT_PUBLIC_GOOGLE_FRONTEND_API_KEY}
          render={renderMap}
        >
          <Map
            source={data?.source}
            destination={data?.destination}
          />
        </Wrapper>

        <LineProgress
          curr={data?.status}
          length={4}
        />
        <div className="flex justify-between mt-1 fs-14 mb-4">
          {status_titles.map((d) => (
            <p key={d}>{d}</p>
          ))}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default TrackModal;

const LineProgress = ({ length, curr, classes }) => {
  return (
    <div className="lineProgress">
      {Array(length)
        .fill(1)
        .map((d, i) => (
          <div
            key={i}
            className={`lineProgress__step ${classes}`}
            style={{ width: i !== length - 1 && "100%" }}
          >
            <span
              className={`lineProgress__dot ${i <= curr && "filled"}`}
            ></span>
            <span
              style={{ width: "100%" }}
              className={`lineProgress__line ${i === length - 1 && "hidden"} ${
                i <= curr - 1 && "filled"
              }`}
            ></span>
          </div>
        ))}
    </div>
  );
};
