import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap";
import { ToastContainer } from "react-toastify";
import { gql, useLazyQuery } from "@apollo/client";
import Datatable from "../common/datatable";
import Text from "../common/text";
import blogsFormatter from "src/helpers/blogsFormatter";

const ADMIN_GET_BLOGS = gql`
    query($input: AdminGetBlogsInput!) {
        adminGetBlogs(input: $input) {
            blogs {
                uuid
                title
                slug
                url
                status
                description
                content
                seoKeywords
                categoryTags
                author {
                    email
                }
                images {
                    uuid
                }
                createdAt
                updatedAt
                publishedAt
            }
            hasMore
            total
        }
    }
`;

const BlogList = () => {
  const [blogs, setBlogs] = useState([]);
  const [term, setTerm] = useState("");
  const [statuses, _] = useState();
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(25);
  const [indexFrom, setIndexFrom] = useState(0);

  const [adminGetBlogs, { loading, error, data }] = useLazyQuery(
    ADMIN_GET_BLOGS,
    {
      variables: {
        fetchPolicy: "no-cache",
        input: {
          term: term,
          statuses: statuses,
          indexFrom: indexFrom,
          limit: limit,
        },
      },
    },
  );

  const onChangePage = (newPage, _) => {
    setIndexFrom((newPage - 1) * limit);
  };

  const onChangeRowsPerPage = (newPerPage, page) => {
    setIndexFrom((page - 1) * newPerPage);
    setLimit(newPerPage);
  };

  useEffect(() => {
    if (data && data.adminGetBlogs) {
      setBlogs(blogsFormatter(data.adminGetBlogs.blogs));
      setTotalRows(data.adminGetBlogs.total);
    } else {
      setBlogs([]);
    }
  }, [data, loading, statuses, term]);

  useEffect(() => {
    setTotalRows(0);
    setIndexFrom(0);
    setLimit(25);
    setBlogs([]);
    adminGetBlogs();
  }, [term]);

  return (
    <Card>
      <CardHeader>
        <h3>Blogs</h3>
        <Row>
          <Col md="4">
            <Label>Term </Label>
            <Input
              name="term"
              type="text"
              value={term}
              onChange={(e) => setTerm(e.target.value)}
            />
          </Col>
          <Col md="4">
            <Label>Status select</Label>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        {error ? (
          <>Some Error Occured! Try Reloading the page.</>
        ) : blogs ? (
          <Datatable
            hideActions={true}
            myData={blogs}
            loading={loading}
            pageSize={limit}
            pagination={true}
            paginationServer={true}
            totalRows={totalRows}
            myClass="-striped -highlight"
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            noHeader
          />
        ) : (
          <Text>No Blogs</Text>
        )}
      </CardBody>
    </Card>
  );
};

const ListBlogs = () => {
  return (
    <Fragment>
      <Breadcrumb
        title="Blog"
        parent="List Blogs"
      />
      <Container fluid={true}>
        <BlogList />
      </Container>
      <ToastContainer />
    </Fragment>
  );
};

export default ListBlogs;
