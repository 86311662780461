import React from "react";
import cx from "classnames";

const Bold = ({ children, classes, style = {}, ...props }) => {
  return (
    <p
      className={cx("c-black mb-0 mt-3", classes)}
      style={{ fontSize: "15px", ...style }}
      {...props}
    >
      <b>{children}</b>
    </p>
  );
};

export default Bold;
