import React, { Fragment } from "react";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumb from "../common/breadcrumb";
import { useQuery, gql } from "@apollo/client";
import { Link } from "react-router-dom";

const GET_CATEGORIES = gql`
  query getCategories {
    getCategories {
      uuid
      type
      name
      description
      images {
        url
        resolutions {
            xs {
                url
            }
        }
      }
      subCategories {
        type
        uuid
        name
        description
        images {
            url
            resolutions {
                xs {
                    url
                }
            }
        }
        subCategories {
            type
            uuid
            name
            description
            images {
                url
                resolutions {
                    xs {
                        url
                    }
                }
            }
        }
      }
    }
  }
`;

const Categories = () => {
  const { data: categoriesData } = useQuery(GET_CATEGORIES);
  const categories = categoriesData?.getCategories || [];

  return (
    <Fragment>
      <Breadcrumb
        title="Categories"
        parent="Home"
      />
      <Container fluid={true}>
        <Button
          color="primary"
          className="mb-3"
        >
          <Link
            to={"/categories/add"}
            style={{ color: "white" }}
          >
            {" "}
            Add Category
          </Link>
        </Button>
        {categories.map((level1) => (
          <div key={level1.uuid}>
            <Card>
              <CardBody>
                <Row className="align-items-center">
                  <Col xs="1">
                    <img
                      alt={level1.type}
                      width={75}
                      src={level1.images?.[0]?.resolutions?.xs?.url}
                    />
                  </Col>
                  <Col xs="3">
                    <h3>
                      <Link to={`/category-details/${level1.type}`}>
                        {level1.name}
                      </Link>
                    </h3>
                  </Col>
                </Row>
                {level1?.subCategories?.map((level2) => (
                  <div
                    key={level2.uuid}
                    className="my-2"
                  >
                    <Row className="align-items-center">
                      <Col xs="1">&nbsp;</Col>
                      <Col xs="1">
                        <img
                          alt={level2.type}
                          width={75}
                          src={level2.images?.[0]?.resolutions?.xs?.url}
                        />
                      </Col>
                      <Col xs="3">
                        <h4>
                          <Link to={`/category-details/${level2.type}`}>
                            {level2.name}
                          </Link>
                        </h4>
                      </Col>
                    </Row>
                    {level2?.subCategories?.map((level3) => (
                      <div
                        key={level3.uuid}
                        className="my-2"
                      >
                        <Row className="align-items-center">
                          <Col xs="2">&nbsp;</Col>
                          <Col xs="1">
                            <img
                              alt={level3.type}
                              width={75}
                              src={level3.images?.[0]?.resolutions?.xs?.url}
                            />
                          </Col>
                          <Col xs="3">
                            <h4>
                              <Link to={`/category-details/${level3.type}`}>
                                {level3.name}
                              </Link>
                            </h4>
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </div>
                ))}
              </CardBody>
            </Card>
          </div>
        ))}
      </Container>
    </Fragment>
  );
};

export default Categories;
