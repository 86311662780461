import React, { useState, useEffect } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { Card, CardBody, CardHeader } from "reactstrap";
import Datatable from "../../common/datatable";
import OrderStatusSelect from "./components/OrderStatusSelect";
import styles from "../../donate/donate.module.scss";
import ordersFormatter from "../../../helpers/ordersFormatter";
import Text from "../../common/text";
import RetailerSelect from "../../retailers/retailer-select";
import NonprofitSelect from "../../nonprofits/nonprofit-select";

const GET_ADMIN_ORDERS = gql`
  query ($input: GetAdminOrdersInput!) {
    getAdminOrders(input: $input) {
      hasMore
      total
      orders {
        uuid
        recipient {
          user {
            firstName
            uuid
          }
          retailer {
            uuid
            name
          }
          nonprofit {
            uuid
            organizationName
          }
        }
        fulfillments {
          donorRetailer {
            name
            uuid
          }
          donor {
            user {
              firstName
              uuid
            }
            retailer {
              uuid
              name
            }
            nonprofit {
              uuid
              organizationName
            }
          }
          items {
            product {
              uuid
              slug
              title
              images {
                uuid
                resolutions {
                  xs {
                    url
                    size
                  }
                }
              }
            }
          }
        }
        status
        createdDate
        paymentMethod {
          type
        }
        priceInfo {
          lineItems {
            type
            label
            amount {
              value
              currencyCode
            }
          }
        }
      }
    }
  }
`;

const OrdersList = ({
  title,
  hideStatusFilter,
  hideRetailerSearch,
  hideNonprofitSearch,
  emailOrPhone,
  userSearch,
  userUUID,
  nonprofitUUID,
  retailerUUID,
}) => {
  const [selectOpen, setSelectOpen] = useState(false);
  const [orderStatus, setOrderStatus] = useState([]);
  const [orders, setOrders] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const [indexFrom, setIndexFrom] = useState(0);
  const [retailer, setRetailer] = useState(null);
  const [nonprofit, setNonprofit] = useState(null);

  const ORDERS_VARIABLES = {
    fetchPolicy: "no-cache",
    variables: {
      input: {
        emailOrPhone: emailOrPhone,
        userUUID: userUUID,
        retailerUUID: retailer?.uuid,
        nonprofitUUID: nonprofit?.uuid,
        orderStatuses: orderStatus,
        paymentMethodTypes: ["google_pay", "apple_pay", "card"],
        limit: limit,
        indexFrom: indexFrom,
      },
    },
  };
  const [getAdminOrders, { loading, error, data: _data }] = useLazyQuery(
    GET_ADMIN_ORDERS,
    ORDERS_VARIABLES,
  );

  const onChangePage = (newPage, _) => {
    setIndexFrom((newPage - 1) * limit);
  };

  const onChangeRowsPerPage = (newPerPage, page) => {
    setIndexFrom((page - 1) * newPerPage);
    setLimit(newPerPage);
  };

  useEffect(() => {
    if (userSearch && !emailOrPhone && !userUUID) {
      return;
    }

    if (retailerUUID) {
      setRetailer({ uuid: retailerUUID });
    }

    if (nonprofitUUID) {
      setNonprofit({ uuid: nonprofitUUID });
    }

    getAdminOrders();
  }, [
    indexFrom,
    emailOrPhone,
    userUUID,
    nonprofitUUID,
    retailerUUID,
    orderStatus,
  ]);

  useEffect(() => {
    if (!loading) {
      if (_data && _data.getAdminOrders) {
        setOrders(ordersFormatter(_data?.getAdminOrders?.orders));
        setTotalRows(_data?.getAdminOrders.total);
      } else {
        setOrders([]);
      }
    }
  }, [loading]);

  const toggleSelect = () => setSelectOpen((val) => !val);

  return (
    <>
      <Card className={styles.donateItemEdit}>
        <h3>{title}</h3>

        <CardHeader>
          <div className="d-flex align-items-center">
            {!hideStatusFilter && (
              <OrderStatusSelect
                isOpen={selectOpen}
                placeholder="Filter By status"
                setValues={setOrderStatus}
                values={orderStatus}
                style={{
                  width: "300px",
                  marginLeft: "20px",
                  height: "38px",
                  overflow: "hidden",
                }}
                toggle={toggleSelect}
              />
            )}

            {!hideRetailerSearch && (
              <RetailerSelect
                {...{
                  retailer: {
                    uuid: retailer?.uuid,
                    name: retailer?.name,
                  },
                  style: {
                    width: "300px",
                    marginLeft: "20px",
                    height: "38px",
                    overflow: "hidden",
                  },
                  onSelect: (r) => {
                    setIndexFrom(0);
                    setRetailer(r);
                  },
                }}
              />
            )}
            {!hideNonprofitSearch && (
              <NonprofitSelect
                {...{
                  nonprofit: {
                    uuid: nonprofit?.uuid,
                    name: nonprofit?.name,
                  },
                  style: {
                    width: "300px",
                    marginLeft: "20px",
                    height: "38px",
                    overflow: "hidden",
                  },
                  onSelect: (np) => {
                    setIndexFrom(0);
                    setNonprofit(np);
                  },
                }}
              />
            )}
          </div>
        </CardHeader>
        <CardBody className="order-datatable">
          {error ? (
            <>Some Error Occured! Try Reloading the page.</>
          ) : orders ? (
            <Datatable
              hideActions={true}
              myData={orders}
              loading={loading}
              pageSize={limit}
              pagination={true}
              paginationServer={true}
              totalRows={totalRows}
              myClass="-striped -highlight"
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
            />
          ) : (
            <Text>No orders</Text>
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default OrdersList;
