import Breadcrumb from "../common/breadcrumb";
import React, { Fragment } from "react";
import DonationReceiptsList from "./donation-receipts-list";

const DonationReceipts = () => {
  return (
    <Fragment>
      <Breadcrumb
        title="Donation Receipts"
        parent="Dashboard"
      />
      <DonationReceiptsList />
    </Fragment>
  );
};

export default DonationReceipts;
