import React, { useEffect, useState } from "react";
import { Col, Media, Row, Button } from "reactstrap";
import ImageZoom from "./image-zoom";
import styles from "./photos.module.scss";
import { RefreshCw, X } from "react-feather";
import { toast } from "react-toastify";
import placeholder from "../../assets/images/placeholder.png";
import { gql, useLazyQuery } from "@apollo/client";

const MAX_SIZE_MB = 50;

const SEARCH_IMAGES = gql`
  query searchImages($input: SearchImagesInput!) {
    searchImages(input: $input) {
      images {
        url
        resolutions {
          xl {
            url
          }
          m {
            url
          }
        }
      }
    }
  }
`;

const addDisplayOrder = (imgs) =>
  imgs.map((img, idx) => ({ ...img, displayOrder: idx }));

const Photos = ({
  selected = [],
  onSelect,
  hidePreview,
  review,
  addButtonStyles = {},
  fullWidthButton,
  addButtonTitle,
  hideDescription,
  stockImageSearchTerm,
  autoSuggestStockImage,
  hideStockImageFinder,
  numberOfPhotos = 5,
}) => {
  const [images, setImages] = useState([]);
  const [stockImages, setStockImages] = useState([]);
  const [selectedPhoto, setSelectedPhoto] = useState(0);
  const [dragging, setDragging] = useState(false);
  const [currentStockImage, setCurrentStockImage] = useState(0);
  const [debouncedSearchStockImageTerm, setDebouncedValue] = useState(
    stockImageSearchTerm || "",
  );
  const [shouldPerformNewSearch, setShouldPerformNewSearch] = useState(false);
  const [hasStockImages, setHasStockImages] = useState(false);

  const [searchImages] = useLazyQuery(SEARCH_IMAGES);

  const onDragStart = (_, position) => {
    setDragging(position);
    document.body.style.cursor = "grabbing";
  };

  const onDragEnter = (_, position) => {
    const newImages = [...images];
    const draggedImage = newImages[dragging];
    newImages.splice(dragging, 1);
    newImages.splice(position, 0, draggedImage);
    setImages(newImages);
    setDragging(position);
  };

  const onDragEnd = () => {
    setDragging(null);
    document.body.style.cursor = "default";
    onUpdate(images);
  };

  const onUpdate = (imgs) => {
    onSelect(addDisplayOrder(imgs));
  };

  useEffect(() => {
    setImages(selected);
    setSelectedPhoto(0);
  }, [selected]);

  // Update the debounced value after a delay when stockImageSearchTerm changes
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(stockImageSearchTerm);
    }, 1000); // 1 second delay

    return () => {
      clearTimeout(handler);
    };
  }, [stockImageSearchTerm]);

  // Call searchImages when debounced value changes
  useEffect(() => {
    setShouldPerformNewSearch(true);
    if (
      debouncedSearchStockImageTerm &&
      !hasStockImages &&
      autoSuggestStockImage
    ) {
      newImageSearch();
    }
  }, [debouncedSearchStockImageTerm]);

  const getImgSrc = (img, size) =>
    img
      ? typeof img === "string"
        ? img
        : img?.resolutions
        ? img?.resolutions?.[size]?.url
        : img?.upload
        ? URL.createObjectURL(img.upload)
        : null
      : null;

  const sizes = {
    xs: hidePreview ? "12" : fullWidthButton ? "12" : "7",
    sm: hidePreview ? "12" : fullWidthButton ? "12" : "6",
    md: hidePreview ? "12" : fullWidthButton ? "12" : "4",
  };

  const onClickCross = (index) => {
    if (images[index]?.stockImage) {
      setHasStockImages(false);
    }
    let imgs = images.filter((x) => x !== images[index]);
    onUpdate(imgs);
  };

  const setFirstImage = (img) => {
    const stockImage = { ...img, stockImage: true };

    let newImages = images;
    if (images?.[0]?.stockImage) {
      newImages[0] = stockImage;
    } else {
      newImages = [stockImage, ...newImages];
    }

    setImages(newImages);
    onUpdate(newImages);
  };

  const onClickNextStockImage = () => {
    let next = (currentStockImage + 1) % stockImages.length;
    setFirstImage(stockImages[next]);
    setCurrentStockImage(next);
  };

  const onClickSearchNewImages = () => {
    if (shouldPerformNewSearch) {
      setStockImages([]);
      setCurrentStockImage(0);
      newImageSearch();
    } else {
      onClickNextStockImage();
    }
  };

  const newImageSearch = () => {
    if (!debouncedSearchStockImageTerm) {
      return;
    }
    setStockImages([]);
    setCurrentStockImage(0);

    searchImages({
      variables: {
        input: {
          term: debouncedSearchStockImageTerm,
          indexFrom: 0,
          limit: 25,
        },
      },
    })
      .then((r) => {
        if (r.data?.searchImages?.images?.length > 0) {
          setStockImages((prev) => [...prev, ...r.data.searchImages.images]);
          setFirstImage(r.data?.searchImages?.images[0]);
          setShouldPerformNewSearch(false);
          setHasStockImages(true);
        } else if (r?.errors) {
          r?.errors.forEach((e) => {
            toast.error(`${e.message} ${e?.extensions?.["cause"]}`);
          });
        } else {
          toast.warn("No stock images were found based on the title");
        }
      })
      .catch((err) => {
        toast.error(
          `error finding stock images from google: ${
            err || err?.message || err?.msg
          }`,
        );
      });
  };

  return (
    <div className={`${(hidePreview || review) && "p-0"}`}>
      <Row>
        <Col
          sm="12"
          className={styles.photo}
        >
          <div>
            <ImageZoom
              image={getImgSrc(images[selectedPhoto], "xl") || placeholder}
            />
            {!review && images.length ? (
              <div className={styles.actions}>
                {images[selectedPhoto]?.stockImage ? (
                  <button
                    type="button"
                    title={
                      shouldPerformNewSearch
                        ? "Search new images"
                        : "Next image"
                    }
                    onClick={() => onClickSearchNewImages()}
                  >
                    <RefreshCw
                      size={22}
                      style={{
                        background: "#212E2B",
                        borderRadius: "100%",
                        padding: "2px",
                        color: "white",
                      }}
                    />
                  </button>
                ) : (
                  <button
                    type="button"
                    title="Remove Image"
                    onClick={() => onClickCross(selectedPhoto)}
                  >
                    <X
                      size={18}
                      style={{
                        background: "#212E2B",
                        borderRadius: "100%",
                        padding: "2px",
                        color: "white",
                      }}
                    />
                  </button>
                )}
              </div>
            ) : null}
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <div className={`${styles.mediaGrid}`}>
            {images.map((vari, index) => (
              <div
                key={index}
                className="flex flex-col"
              >
                <div
                  key={index}
                  className={styles.media}
                >
                  <Media
                    src={getImgSrc(vari, "m")}
                    key={index}
                    alt={vari}
                    style={{
                      cursor: review || vari?.stockImage ? "pointer" : "grab",
                      borderRadius: "8px",
                    }}
                    title={review ? "" : "Click and drag to change order"}
                    onClick={() => {
                      setSelectedPhoto(index);
                    }}
                    draggable={!review || !vari?.stockImage}
                    onDragStart={
                      vari?.stockImage ? null : (e) => onDragStart(e, index)
                    }
                    onDragEnter={
                      dragging === null || vari?.stockImage
                        ? null
                        : (e) => onDragEnter(e, index)
                    }
                    onDragEnd={onDragEnd}
                    className={dragging === index ? "dragging" : ""}
                  />
                  {!review && (
                    <div
                      className={styles.actions}
                      id="donate-photo-close"
                    >
                      {vari?.stockImage ? (
                        <button
                          type="button"
                          title={
                            shouldPerformNewSearch
                              ? "Search new images"
                              : "Next image"
                          }
                          onClick={() => onClickSearchNewImages()}
                        >
                          <RefreshCw
                            size={18}
                            style={{
                              background: "#212E2B",
                              borderRadius: "100%",
                              padding: "2px",
                              color: "white",
                            }}
                          />
                        </button>
                      ) : (
                        <button
                          type="button"
                          title="Remove Image"
                          onClick={() => onClickCross(index)}
                        >
                          <X
                            size={18}
                            style={{
                              background: "#212E2B",
                              borderRadius: "100%",
                              padding: "2px",
                              color: "white",
                            }}
                          />
                        </button>
                      )}
                    </div>
                  )}
                </div>
                {vari?.stockImage ? <span>Stock Image</span> : <span> </span>}
              </div>
            ))}
            {!review &&
              numberOfPhotos - images.length >= 0 &&
              [...Array(numberOfPhotos - images.length)].map((_, i) => (
                <div
                  className="flex flex-col"
                  key={i}
                >
                  <div className={styles.media}>
                    <Media
                      src={placeholder}
                      className="img-fluid"
                      style={{ borderRadius: "8px" }}
                    />
                  </div>
                  <span> </span>
                </div>
              ))}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          {!review &&
            !hasStockImages &&
            !hideStockImageFinder &&
            debouncedSearchStockImageTerm && (
              <span
                style={{ cursor: "pointer", color: "tomato" }}
                onClick={() => newImageSearch()}
                className="text-link"
              >
                <u>Find stock image</u>
              </span>
            )}
        </Col>
      </Row>
      <Row className="mb-2">
        {!review && (
          <Col
            xs={sizes["xs"]}
            sm={sizes["sm"]}
            md={sizes["md"]}
            className="d-flex flex-column m-auto mb-5"
          >
            <div style={{ position: "relative", marginTop: "16px" }}>
              <Button
                style={addButtonStyles}
                className={`${!hidePreview ? "btn btn-solid" : "big-btn"}`}
                type="button"
                onClick={() => {
                  document.getElementById("get_files").click();
                }}
                disabled={selected?.length === numberOfPhotos}
              >
                {addButtonTitle
                  ? addButtonTitle
                  : hidePreview
                  ? "Add Photos"
                  : "Select Photos"}
              </Button>
              <input
                id="get_files"
                className="w-100"
                style={{ display: "none" }}
                type="file"
                accept="image/png, image/jpeg, image/heic"
                multiple={true}
                onChange={(e) => {
                  let combined = [...selected, ...e.target.files];
                  const totalSizeMB =
                    1 +
                    (combined.reduce((tot, file) => tot + file.size, 0) >> 20);
                  if (totalSizeMB > MAX_SIZE_MB) {
                    toast.error(
                      `File upload${
                        combined.length === 1 ? " is" : "s are"
                      } too big (max ${MAX_SIZE_MB}MB)`,
                    );
                    return;
                  }
                  if (
                    selected.length + e.target.files.length <=
                    numberOfPhotos
                  ) {
                    let imgs = [...selected];
                    Array.from(e.target.files).forEach((file) => {
                      if (
                        file.type.toLowerCase() === "image/heic" ||
                        file.name.toLowerCase().includes(".heic")
                      ) {
                        const heic2any = require("heic2any");
                        heic2any({
                          blob: file,
                          toType: "image/png",
                          quality: 1,
                        }).then((newImage) => {
                          imgs = [...imgs, { upload: newImage }];
                          onUpdate(imgs);
                        });
                      } else {
                        imgs = [...imgs, { upload: file }];
                        onUpdate(imgs);
                      }
                    });
                  }
                }}
              />
            </div>
            {!hidePreview && !hideDescription && (
              <div className="mt-2 text-center">
                Select upto {numberOfPhotos} images
              </div>
            )}
          </Col>
        )}
      </Row>
    </div>
  );
};

export default Photos;
