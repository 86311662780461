import { gql, useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import Bold from "../common/bold";
import Text from "../common/text";
import moment from "moment";
import { formatAddress } from "src/helpers/address";

const ADMIN_GET_BANK_ACCOUNTS = gql`
    query adminGetBankAccounts($input: GetBankAccountsInput!) {
        adminGetBankAccounts(input: $input) {
            id
            type
            createdDate
            bankAccount {
                last4
                accountHolderType
                accountType
                bankName
                routingNumber
            }
            billingDetails {
                name
                email
                phone
                address {
                    streetAddress1
                    streetAddress2
                    city
                    state
                    zip
                    countryCode
                }
            }
        }
    }
`;

const BankAccounts = ({ retailer }) => {
  const [adminGetBankAccounts, { loading, data }] = useLazyQuery(
    ADMIN_GET_BANK_ACCOUNTS,
    {
      fetchPolicy: "no-cache",
      variables: {
        input: {
          retailerUUID: retailer?.uuid,
        },
      },
    },
  );

  const [paymentMethods, setPaymentMethods] = useState([]);

  useEffect(() => {
    if (!loading) {
      if (data && data.adminGetBankAccounts) {
        setPaymentMethods(data.adminGetBankAccounts);
      }
    } else {
      setPaymentMethods([]);
    }
  }, [loading]);

  useEffect(() => {
    if (!retailer || !retailer?.uuid) {
      return;
    }

    adminGetBankAccounts();
  }, [retailer]);

  return (
    <>
      {paymentMethods.map((paymentMethod) => (
        <Card
          key={paymentMethod.id}
          style={{
            boxShadow: "1px 4px 14px rgba(60, 72, 72, 0.4)",
          }}
        >
          <CardBody>
            <Row>
              <Col>
                <Bold>Created Date</Bold>
                <Text>
                  {moment(paymentMethod.createdDate).format(
                    "YYYY-MM-DD HH:mm:SS",
                  )}
                </Text>
              </Col>
              <Col>
                <Bold>Type</Bold>
                <Text>{paymentMethod.type}</Text>
              </Col>
              <Col>
                <Bold>Last4</Bold>
                <Text> •••••• {paymentMethod.bankAccount.last4}</Text>
              </Col>
              <Col>
                <Bold>Holder Type</Bold>
                <Text>{paymentMethod.bankAccount.accountHolderType}</Text>
              </Col>
              <Col>
                <Bold>Account Type</Bold>
                <Text>{paymentMethod.bankAccount.accountType}</Text>
              </Col>
              {paymentMethod?.billingDetails?.name && (
                <Col>
                  <Bold>Billing Name</Bold>
                  <Text>{paymentMethod?.billingDetails?.name}</Text>
                </Col>
              )}
              {paymentMethod?.billingDetails?.email && (
                <Col>
                  <Bold>Billing Email</Bold>
                  <Text>{paymentMethod?.billingDetails?.email}</Text>
                </Col>
              )}
              {paymentMethod?.billingDetails?.phone && (
                <Col>
                  <Bold>Billing Phone</Bold>
                  <Text>{paymentMethod?.billingDetails?.phone}</Text>
                </Col>
              )}
              {paymentMethod?.billingDetails?.address && (
                <Col>
                  <Bold>Billing Address</Bold>
                  <Text>
                    {formatAddress(paymentMethod?.billingDetails?.address)}
                  </Text>
                </Col>
              )}
            </Row>
          </CardBody>
        </Card>
      ))}
      {!loading && paymentMethods?.length === 0 && (
        <Card style={{ background: "red" }}>
          <CardBody>
            <h4 style={{ color: "white", fontWeight: "bold" }}>
              {"No bank accounts added by this retailer"}
            </h4>
            <h5>
              {"Without a bank account we won't be able to invoice this retailer. " +
                "Remind this retailer to add a bank account on the retailer dashboard."}
            </h5>
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default BankAccounts;
