import React from "react";
import { Link } from "react-router-dom";

const subscriptionPlansFormatter = (subscriptionPlans) => {
  if (!subscriptionPlans?.length) {
    return;
  }

  const formattedInvoices = subscriptionPlans.map((d) => {
    return {
      Title: (
        <>
          <Link
            key={d.uuid}
            to={`/subscriptionPlan/edit/${d?.uuid}`}
          >
            {d.title || "<no-title>"}
          </Link>{" "}
          {d.isDefault ? " (Default)" : <></>}
        </>
      ),
      "Monthly Amount":
        d?.monthlyAmount?.value + " " + d?.monthlyAmount?.currencyCode,
      "Included Donation Value":
        d?.includedDonationValue?.value +
        " " +
        d?.includedDonationValue?.currencyCode,
      "Shipping Fee Type": d?.isShippingFeePassThrough
        ? "Pass-through"
        : "Fixed",
      "Shipping Fee": d?.isShippingFeePassThrough
        ? "N/A"
        : d?.shippingFee?.value + " " + d?.shippingFee?.currencyCode,
      "Delivery Fee Type": d?.isDeliveryFeePassThrough
        ? "Pass-through"
        : "Fixed",
      "Delivery Fee": d?.isDeliveryFeePassThrough
        ? "N/A"
        : d?.deliveryFee?.value + " " + d?.deliveryFee?.currencyCode,
      Status: d?.status,
    };
  });

  return formattedInvoices;
};

export default subscriptionPlansFormatter;
