import React from "react";
import CheckboxTree from "react-checkbox-tree";
import { ChevronDown } from "react-feather";
import { DropdownMenu, Dropdown, DropdownToggle } from "reactstrap";
import styles from "../donate/donate.module.scss";

const LABEL_STATUS = [
  { title: "New", value: "new" },
  { title: "Open", value: "open" },
  { title: "Needs Review", value: "needs_review" },
  { title: "Processed", value: "processed" },
  { title: "Pre-Transit", value: "pre_transit" },
  { title: "In Transit", value: "in_transit" },
  { title: "Out for Delivery", value: "out_for_delivery" },
  { title: "Delivered", value: "delivered" },
  { title: "Refund Requested", value: "refund_requested" },
  { title: "Refunded", value: "refunded" },
  { title: "Failure", value: "failure" },
  { title: "Return to Sender", value: "return_to_sender" },
  { title: "Flagged", value: "flagged" },
  { title: "Cancelled", value: "cancelled" },
  { title: "Error", value: "error" },
  { title: "Unknown", value: "unknown" },
  { title: "Available for Pickup", value: "available_for_pickup" },
];

const LabelStatusSelect = ({
  isOpen,
  toggle,
  values,
  setValues,
  style = {},
  placeholder,
}) => {
  return (
    <Dropdown
      isOpen={isOpen}
      toggle={toggle}
    >
      <DropdownToggle
        className={styles.dropdown}
        style={style}
      >
        <div className="d-flex flex-wrap">
          {values?.length ? (
            values?.map((x, i) => (
              <div
                key={i}
                className={styles.category + " mr-1"}
              >
                {x}
              </div>
            ))
          ) : (
            <span style={{ fontWeight: 500, color: "gray" }}>
              {placeholder}
            </span>
          )}
        </div>
        <ChevronDown />
      </DropdownToggle>
      <DropdownMenu>
        <CheckboxTree
          onlyLeafCheckboxes
          nodes={LABEL_STATUS.map((x) => ({
            value: x.value,
            label: x.title,
            className: "d-block w-100",
          }))}
          icons={{
            parentClose: null,
            parentOpen: null,
            leaf: null,
          }}
          checked={values}
          onCheck={(checked) => {
            setValues(checked);
          }}
        />
      </DropdownMenu>
    </Dropdown>
  );
};

export default LabelStatusSelect;
