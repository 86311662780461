import React, { Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Container } from "reactstrap";
import { gql } from "@apollo/client";
import { initializeApollo } from "../../helpers/apollo";
import { toast, ToastContainer } from "react-toastify";
import { addCountryCode } from "../../helpers/utils";
import AddEditRetailer from "./AddEditRetailer";
import { err_toast } from "src/helpers/errors";

const CREATE_RETAILER = gql`
  mutation adminAddRetailer($data: AdminAddRetailerInput!) {
    adminAddRetailer(input: $data) {
      uuid
    }
  }
`;

const CreateRetailer = () => {
  const handleSubmit = async (vals, cb) => {
    let client = initializeApollo();

    client
      .mutate({
        mutation: CREATE_RETAILER,
        variables: {
          data: {
            ...vals,
            businessPhoneNumber:
              addCountryCode(vals?.businessPhoneNumber) || null,
          },
        },
      })
      .then(() => {
        toast.success("Created Retailer successfully!");
        cb();
        setTimeout(() => {
          window.location.replace("/retailers");
        }, 1000);
      })
      .catch((err) => {
        err_toast(err);
        cb();
      });
  };

  return (
    <Fragment>
      <Breadcrumb
        title="Create Retailer"
        parent="Retailers"
      />
      <Container fluid={true}>
        <AddEditRetailer
          data={{}}
          onSubmit={handleSubmit}
        />
      </Container>
      <ToastContainer />
    </Fragment>
  );
};

export default CreateRetailer;
