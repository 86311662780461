const getConfigs = () => {
  const env = process.env.REACT_APP_ENV || "development";
  return {
    local: {
      frontend: "http://localhost:3000",
      graphql: "http://localhost:8000/graphql/query",
      backend: "http://localhost:8000",
      environment: "local",
    },
    development: {
      frontend: "https://app-stage.liquidonate.com",
      graphql: "https://api-stage.liquidonate.com/graphql/query",
      backend: "https://api-stage.liquidonate.com",
      environment: "development",
    },
    stage: {
      frontend: "https://app-stage.liquidonate.com",
      graphql: "https://api-stage.liquidonate.com/graphql/query",
      backend: "https://api-stage.liquidonate.com",
      environment: "stage",
    },
    sandbox: {
      frontend: "https://app-sandbox.liquidonate.com",
      graphql: "https://api-sandbox.liquidonate.com/graphql/query",
      backend: "https://api-sandbox.liquidonate.com",
      environment: "sandbox",
    },
    production: {
      frontend: "https://app.liquidonate.com",
      graphql: "https://api.liquidonate.com/graphql/query",
      backend: "https://api.liquidonate.com",
      environment: "production",
    },
  }[env];
};

export default getConfigs;
