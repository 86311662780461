import { Col, Row, Input, Spinner, Button } from "reactstrap";
import React from "react";
import styles from "../donate/donate.module.scss";
import Select from "react-dropdown-select";

const nonprofitRoles = [
  { label: "Nonprofit Admin", value: "nonprofit_admin" },
  { label: "Nonprofit Manager", value: "nonprofit_manager" },
  { label: "Nonprofit Coordinator", value: "nonprofit_coordinator" },
  { label: "Nonprofit Signatory", value: "nonprofit_signatory" },
];

const retailerRoles = [
  { label: "Retailer Admin", value: "retailer_admin" },
  { label: "Retailer Manager", value: "retailer_manager" },
  { label: "Retailer Donor", value: "retailer_coordinator" },
];

const AssignUser = ({
  user,
  isRetailer = false,
  isNonprofit = false,
  onChange,
  onSubmit,
  loading,
}) => {
  return (
    <div
      style={{ padding: "10px 20px" }}
      className={styles.donateItemEdit}
    >
      <h5>
        <b>Assign new user</b>
      </h5>
      <Row className="align-items-center">
        <Col
          xs="12"
          lg="2"
        >
          <Input
            name="firstName"
            value={user.firstName}
            placeholder="First name"
            onChange={onChange}
          />
        </Col>
        <Col
          xs="12"
          lg="2"
        >
          <Input
            name="lastName"
            value={user.lastName}
            placeholder="Last name"
            onChange={onChange}
          />
        </Col>
        <Col
          xs="12"
          lg="3"
        >
          <Input
            name="email"
            value={user.email}
            placeholder="Email"
            onChange={onChange}
          />
        </Col>
        <Col
          xs="12"
          lg="3"
        >
          <Select
            options={
              isRetailer ? retailerRoles : isNonprofit ? nonprofitRoles : []
            }
            value={user.role}
            placeholder={"Role"}
            onChange={onChange}
          />
        </Col>
        <Col
          xs="12"
          lg="2"
        >
          {loading ? (
            <Spinner />
          ) : (
            <Button
              disabled={
                loading ||
                !user.email ||
                ((isRetailer || isNonprofit) && !user.role)
              }
              onClick={onSubmit}
              color="primary"
            >
              Submit
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AssignUser;
