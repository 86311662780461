import React, { Fragment } from "react";
import OrdersList from "./ordersList";
import Breadcrumb from "../../common/breadcrumb";
import { Container, Col, Row } from "reactstrap";

const Orders = () => {
  return (
    <Fragment>
      <Breadcrumb
        title="Orders"
        parent="Dashboard"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <OrdersList />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Orders;
